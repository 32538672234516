// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAVctbw__exw4DyHPf9KdzVW3HuynNdM68",
    authDomain: "curryosity-3038e.firebaseapp.com",
    projectId: "curryosity-3038e",
    storageBucket: "curryosity-3038e.appspot.com",
    messagingSenderId: "879823258074",
    appId: "1:879823258074:web:13a4c55b69f3e6a21d40e3",
    measurementId: "G-S92G8Y3E5X"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };