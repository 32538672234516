import React, { useEffect, useState } from 'react';
import './BreakingNews.css';
import { news } from './News';

const BreakingNews = () => {
  const [shuffledNews, setShuffledNews] = useState([]);

  useEffect(() => {
    setShuffledNews(shuffleArray(news)); // Shuffle e mostra subito
  }, []);

  const shuffleArray = (array) => {
    return array
      .map((item) => ({ item, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ item }) => item);
  };

  return (
    <div className="breaking-news-container">
      <div className="breaking-news-label">Did you know that</div>
      <div className="breaking-news-content">
        {shuffledNews.map((item, index) => (
          <span key={index} className="news-item">
            {item.quote}
            {index < shuffledNews.length - 1 && <span className="divider"> | </span>}
          </span>
        ))}
      </div>
    </div>
  );
};

export default BreakingNews;
