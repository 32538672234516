import React, { useState, useEffect } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';

const Timer = ({ startTimer, onFinish }) => {
  const [timeLeft, setTimeLeft] = useState(120);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let interval;
    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      onFinish()
      setIsRunning(false);
    }

    return () => clearInterval(interval);
  }, [isRunning, timeLeft, onFinish]);

  useEffect(() => {
    setIsRunning(startTimer);
  }, [startTimer]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className="flex items-center justify-center p-2 backdrop-blur-lg bg-transparent rounded-lg shadow-lg">
      <div className="bg-gray-100 rounded-lg px-3 py-1 text-center">
        <h1 className="text-lg font-semibold text-gray-800">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h1>
      </div>

      <button
        onClick={() => setIsRunning(!isRunning)}
        className="ml-2 text-gray-600 p-2 bg-gray-200 rounded-full shadow hover:bg-gray-300 focus:outline-none transition duration-300"
      >
        {isRunning ? <FaPause size={16} /> : <FaPlay size={16} />}
      </button>
    </div>
  );
};

export default Timer;
