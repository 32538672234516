import React from 'react'
import Picture from './assets/basketballcourt2.jpg'
import Landscape from './assets/jc-gellidon-XmYSlYrupL8-unsplash.jpg'
import { player } from '../Players';
import { useState, useEffect } from 'react';
import Basketball from './assets/basketball-svgrepo-com.svg'
import Overlay from './Helpers/Overlay';
import Ball from './assets/basketballcourt21.jpg'
import { useAuth } from '../context/AuthContext';
import { auth, firestore } from '../Firebase';
import { doc, updateDoc } from 'firebase/firestore';
import ArrowRight from './assets/arrow-right.svg'

function GuessPlayers() {
    const accessibleTeams = [
        {name: "Atlanta Hawks", logo: "https://th.bing.com/th/id/OIP.0N2m0E3EP2Tck4cAzFCcnQAAAA?rs=1&pid=ImgDetMain"},
        {name: "Boston Celtics", logo: "https://c2.staticflickr.com/8/7303/9165283038_c624a50d24_b.jpg"},
        {name: "Brooklyn Nets", logo: "https://c1.staticflickr.com/8/7404/9163061219_0bcc364023_n.jpg"},
        {name: "Charlotte Hornets", logo: "https://c2.staticflickr.com/6/5522/11543095774_c1cd22a887_b.jpg"},
        {name: "Chicago Bulls", logo: "https://th.bing.com/th/id/R.215d1ed77b91311663d0b30eafb55683?rik=YBdvj3CY9VAgww&pid=ImgRaw&r=0"},
        {name: "Cleveland Cavaliers", logo: "https://c2.staticflickr.com/8/7318/9165295218_0b1cf1c66c_b.jpg"},
        {name: "Dallas Mavericks", logo: "https://live.staticflickr.com/7316/9165297070_d26f85dd26.jpg"},
        {name: "Denver Nuggets", logo: "https://th.bing.com/th/id/OIP.kHDTYwEyJsUDae5ydxnG4AAAAA?pid=ImgDet&rs=1"},
        {name: "Detroit Pistons", logo: "https://th.bing.com/th/id/OIP.8xAvGpd-raTg5uP3ZKqfhwHaHa?pid=ImgDet&rs=1"},
        {name: "Golden State Warriors", logo: "https://upload.wikimedia.org/wikipedia/en/thumb/0/01/Golden_State_Warriors_logo.svg/1200px-Golden_State_Warriors_logo.svg.png"},
        {name: "Houston Rockets", logo: "https://c2.staticflickr.com/6/5323/9163085107_53853f4cf8_b.jpg"},
        {name: "Indiana Pacers", logo: "https://upload.wikimedia.org/wikipedia/sco/thumb/1/1b/Indiana_Pacers.svg/120px-Indiana_Pacers.svg.png"},
        {name: "Los Angeles Clippers", logo: "https://th.bing.com/th/id/R.642fc3516fccad6ce4ec04f09d1b567d?rik=Iq3BPWfHrEvizg&riu=http%3a%2f%2ffarm4.staticflickr.com%2f3706%2f9163093007_faab93d530_z.jpg&ehk=dT9U8JR812g1t7wtPnJfeGc34nYkPxpc4hA7Jer0lBw%3d&risl=&pid=ImgRaw&r=0"},
        {name: "Los Angeles Lakers", logo: "https://th.bing.com/th/id/R.2d0c77009a59f7f467a3156e9c007f78?rik=xglw%2f0uyEaguxg&riu=http%3a%2f%2ffarm8.staticflickr.com%2f7424%2f9163097199_a6113527eb_z.jpg&ehk=L60CIgkjLBYwJl2Pq3sR86RU1pnvpqUhBt6unsg5a%2bw%3d&risl=&pid=ImgRaw&r=0"},
        {name: "Memphis Grizzlies", logo: "https://th.bing.com/th/id/OIP.lJO3gqX06MfW00MrM09KgQHaG6?pid=ImgDet&rs=1"},
        {name: "Miami Heat", logo: "https://c1.staticflickr.com/3/2839/9165325852_2da3dbda0e_b.jpg"},
        {name: "Milwaukee Bucks", logo: "https://th.bing.com/th/id/R.ed0b232333f83e3fab8d1a093183cbbc?rik=G6FnTCtVsvL17Q&pid=ImgRaw&r=0"},
        {name: "Minnesota Timberwolves", logo: "https://upload.wikimedia.org/wikipedia/en/thumb/c/c2/Minnesota_Timberwolves_logo.svg/1200px-Minnesota_Timberwolves_logo.svg.png"},
        {name: "New Orleans Pelicans", logo: "https://c2.staticflickr.com/8/7412/9165344128_73c5fbd872_b.jpg"},
        {name: "New York Knicks", logo: "https://c1.staticflickr.com/3/2856/9163126439_ba341593de_b.jpg"},
        {name: "Oklahoma City Thunder", logo: "https://c2.staticflickr.com/8/7443/9165352690_fe2326dd13_b.jpg"},
        {name: "Orlando Magic", logo: "https://c2.staticflickr.com/6/5520/9163134135_742e34fa29_b.jpg"},
        {name: "Philadelphia 76ers", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Philadelphia76ers2.png/95px-Philadelphia76ers2.png"},
        {name: "Phoenix Suns", logo: "https://c1.staticflickr.com/3/2839/9163137719_2a5af4e261_b.jpg"},
        {name: "Portland Trail Blazers", logo: "https://upload.wikimedia.org/wikipedia/en/thumb/2/21/Portland_Trail_Blazers_logo.svg/1200px-Portland_Trail_Blazers_logo.svg.png"},
        {name: "Sacramento Kings", logo: "https://th.bing.com/th/id/R.95d62e25deabbefbff22574ce1d2c0f4?rik=iEtbQUihU0p0Nw&pid=ImgRaw&r=0"},
        {name: "San Antonio Spurs", logo: "https://c2.staticflickr.com/4/3736/9163151905_f3219cffab_z.jpg"},
        {name: "Toronto Raptors", logo: "https://upload.wikimedia.org/wikipedia/en/thumb/3/36/Toronto_Raptors_logo.svg/1200px-Toronto_Raptors_logo.svg.png"},
        {name: "Utah Jazz", logo: "https://c1.staticflickr.com/5/4047/4329565096_f84bfa9c19_z.jpg?zz=1"},
        {name: "Washington Wizards", logo: "https://th.bing.com/th/id/OIP.W9EJOGU9t8yOr3fNzdi0gAAAAA?pid=ImgDet&rs=1"}
    ];

    const [easyFilter, setEasyFilter] = useState(player.filter((player) => player.level === "easy" && player.teams.length > 1));
    const [easymediumFilter, setEasymediumFilter] = useState(player.filter((player) => ((player.level === "easy" || player.level === "medium") && player.teams.length > 1)));
    const [mediumFilter, setMediumFilter] = useState(player.filter((player) => player.level === "medium" && player.teams.length > 1));
    const [mediumhardFilter, setMediumhardFilter] = useState(player.filter((player) => ((player.level === "medium" || player.level === "hard") && player.teams.length > 1)));
    const [hardFilter, setHardFilter] = useState(player.filter((player) => player.level === "hard" && player.teams.length > 1));
    const [extrahardFilter, setExtraHardFilter] = useState(player.filter((player) => player.level === "extra-hard" && player.teams.length > 1));

    const [randomPlayer, setRandomPlayer] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [lives, setLives] = useState(3);
    const [gameOver, setGameOver] = useState(false);
    const [gameInit, setGameInit] = useState(true);
    const [correctGuesses, setCorrectGuesses] = useState(0);
    const [playerTeams, setPlayerTeams] = useState([]);
    const [playerName, setPlayerName] = useState("");
    const [wrongGuesses, setWrongGuesses] = useState(0);
    const [firstNbaYear, setFirstNbaYear] = useState('');

    const { currentUser, userData } = useAuth();
    

    const startGame = () => {
        setGameInit(!gameInit);
    }
  
    useEffect(() => {
      generateRandomEasyPlayer();
    }, []);

    useEffect(() => {
        if (randomPlayer) {
          setPlayerTeams(randomPlayer.teams);
          // console.log(playerTeams);
          setPlayerName(`${randomPlayer.firstName} ${randomPlayer.lastName} ${randomPlayer.level}`);
          setFirstNbaYear(randomPlayer.firstNBAyear);
        }
    }, [randomPlayer]);

    useEffect(() => {
      if (wrongGuesses === 3) {
          setGameOver(true);
          saveScore();
      }
  },[wrongGuesses])

    const generateRandomPlayer = () => {
        const randomIndex = Math.floor(Math.random() * player.length);
        setRandomPlayer(player[randomIndex]);
        setInputValue('');
        setFilteredPlayers([]);
        setActiveIndex(0);
        setPlayerTeams(randomPlayer.teams);
      };
  
    const generateRandomEasyPlayer = () => {
      const randomIndex = Math.floor(Math.random() * easyFilter.length);
      setRandomPlayer(easyFilter[randomIndex]);
      setInputValue('');
      setFilteredPlayers([]);
      setActiveIndex(0);
      setEasyFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      // console.log("abubu", easyFilter);
      // console.log(randomPlayer.teams);
      // setPlayerTeams(randomPlayer.teams);
    };

    const generateRandomEasyMediumPlayer = () => {
      const randomIndex = Math.floor(Math.random() * easymediumFilter.length);
      setRandomPlayer(easymediumFilter[randomIndex]);
      setInputValue('');
      setFilteredPlayers([]);
      setActiveIndex(0);
      setEasymediumFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      // console.log(randomPlayer.teams);
      // setPlayerTeams(randomPlayer.teams);
    };

    const generateRandomMediumPlayer = () => {
      const randomIndex = Math.floor(Math.random() * mediumFilter.length);
      setRandomPlayer(mediumFilter[randomIndex]);
      setInputValue('');
      setFilteredPlayers([]);
      setActiveIndex(0);
      setMediumFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      // console.log(randomPlayer.teams);
      // setPlayerTeams(randomPlayer.teams);
    };

    const generateRandomMediumHardPlayer = () => {
      const randomIndex = Math.floor(Math.random() * mediumhardFilter.length);
      setRandomPlayer(mediumhardFilter[randomIndex]);
      setInputValue('');
      setFilteredPlayers([]);
      setActiveIndex(0);
      setMediumhardFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      // console.log(randomPlayer.teams);
      // setPlayerTeams(randomPlayer.teams);
    };

    const generateRandomHardPlayer = () => {
      const randomIndex = Math.floor(Math.random() * hardFilter.length);
      setRandomPlayer(hardFilter[randomIndex]);
      setInputValue('');
      setFilteredPlayers([]);
      setActiveIndex(0);
      setHardFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      // console.log(randomPlayer.teams);
      // setPlayerTeams(randomPlayer.teams);
    };

    const generateRandomExtraHardPlayer = () => {
      const randomIndex = Math.floor(Math.random() * extrahardFilter.length);
      setRandomPlayer(extrahardFilter[randomIndex]);
      setInputValue('');
      setFilteredPlayers([]);
      setActiveIndex(0);
      setExtraHardFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      // console.log(randomPlayer.teams);
      // setPlayerTeams(randomPlayer.teams);
    };

    const handleInputChange = (e) => {
        const query = e.target.value.toLowerCase();
        setInputValue(query);
    
        if (query) {
          const filtered = player.filter(player => 
            player.firstName.toLowerCase().startsWith(query) || 
            player.lastName.toLowerCase().startsWith(query)
          );
          setFilteredPlayers(filtered);
        } else {
          setFilteredPlayers([]);
        }
      };

      const handleSelectPlayer = (player) => {
        setInputValue('');
        setFilteredPlayers([]);

        // generateRandomPlayer();
    
        if (randomPlayer.firstName === player.firstName && randomPlayer.lastName === player.lastName) {
          setCorrectGuesses(correctGuesses + 1);
        } else {
          setLives(lives - 1)
          setWrongGuesses(wrongGuesses + 1)
        }
    
        if (correctGuesses >= 0 && correctGuesses <= 4) {
          generateRandomEasyPlayer();
        } else if (correctGuesses >= 5 && correctGuesses <= 6) {
          generateRandomEasyMediumPlayer();
        } else if (correctGuesses >= 7 && correctGuesses <= 9) {
          generateRandomMediumPlayer();
        } else if (correctGuesses >= 10 && correctGuesses <= 13) {
          generateRandomMediumHardPlayer();
        } else if (correctGuesses >= 14 && correctGuesses <= 16) {
          generateRandomHardPlayer();
        } else if (correctGuesses >= 17 && correctGuesses <= 20) {
          generateRandomExtraHardPlayer();
        } else {
          generateRandomPlayer();
        }
      };
    
      const handleFormSubmit = (e) => {
        e.preventDefault();
        if (filteredPlayers.length > 0) {
          handleSelectPlayer(filteredPlayers[activeIndex]);
        }
      };
    
      const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
          setActiveIndex((prevIndex) => 
            (prevIndex + 1) % filteredPlayers.length
          );
        } else if (e.key === 'ArrowUp') {
          setActiveIndex((prevIndex) => 
            (prevIndex - 1 + filteredPlayers.length) % filteredPlayers.length
          );
        } else if (e.key === 'Enter' && filteredPlayers.length > 0) {
          e.preventDefault(); 
          handleSelectPlayer(filteredPlayers[activeIndex]);
        }
      };

      const saveScore = async () => {
        if (currentUser) {
            const userDoc = doc(firestore, 'users', currentUser.uid);
            try {
                await updateDoc(userDoc, {
                    game3: [...(userData.game3 || []), correctGuesses],
                });
                console.log('Punteggio salvato:', correctGuesses);
            } catch (error) {
                console.error('Errore durante il salvataggio del punteggio:', error);
            }
        }
      };
  
      const refresh = () => {
          window.location.reload();
      }

  return (
    <div>
        <div style={{ 
        backgroundImage: `url(${Landscape})`, 
        backgroundSize: 'cover',
        width: '99vw',
        padding: '10px'
      }}>
      <div className="max-w-xl mx-auto">
        <form className="mt-10" onSubmit={handleFormSubmit}>
          <div className="relative">
            <input
              type="text"
              className="border-2 border-gray-300 rounded-lg p-2 w-full"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Search player by name or surname"
            />
  
            {filteredPlayers.length > 0 && (
              <ul className="absolute z-10 bg-white border border-gray-300 w-full mt-1 max-h-60 overflow-y-auto rounded-lg shadow-lg">
                {filteredPlayers.map((player, index) => (
                  <li
                    key={index}
                    className={`p-2 cursor-pointer ${
                      index === activeIndex ? 'bg-gray-400' : ''
                    }`}
                    onClick={() => handleSelectPlayer(player)}
                  >
                    {player.firstName} {player.lastName}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </form>
        <div className="relative max-w-xl mx-auto mt-10">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-700 rounded-md pointer-events-none"></div>
          <img
            className="h-64 w-full object-cover rounded-md opacity-80"
            src={Picture}
            alt="Quiz image"
          />
          <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>

          <div className="absolute inset-0 flex items-center justify-center">
            <div>
              {/* Griglia per i loghi */}
              <div className="flex items-center justify-center space-x-4">
                {/* Itera sulle squadre */}
                {playerTeams.map((teamName, index) => {
                  const team = accessibleTeams.find(t => t.name === teamName);
                  return team ? (
                    <div key={team.name} className="flex items-center">
                      {/* Logo della squadra */}
                      <div className="text-center">
                        <img
                          src={team.logo}
                          alt={team.name}
                          className="h-20 w-20 rounded-full mx-auto"
                        />
                        <p className="text-white text-sm mt-2">{team.name}</p>
                      </div>

                      {/* Aggiungi la freccia solo se non è l'ultimo elemento */}
                      {index < playerTeams.length - 1 && (
                        <img
                          src={ArrowRight}
                          alt="Arrow Icon"
                          className="h-6 w-6 mx-4"
                        />
                      )}
                    </div>
                  ) : null;
                })}
              </div>
            </div>
          </div>
        </div>
        {/*
        <div className="mt-5">
          <h3 className="text-lg">Wrong Guesses: {wrongGuesses}</h3>
        </div>
        */}
        <div className="flex justify-between mt-10 relative mb-10">
        <div className="imdb-box w-20">
            <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-lg opacity-70"></div>
            <div className="flex flex-col rounded-full p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] w-full h-full items-center justify-center relative z-10">
                <div className="imdb-score text-4xl text-center font-bold text-white">{correctGuesses}</div>
                <div className="imdb-title text-center text-sm text-gray-300">SCORE</div>
            </div>
            </div>
        </div>
        {firstNbaYear && (
          <div className="imdb-box w-32 md:w-40" style={{ backgroundImage: `url(${Ball})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '100%' }}>
            <div className="relative">
              {/* Sfocatura e ombra colorata */}
              <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-2xl opacity-75"></div>
              {/* Contenitore del contenuto */}
              <div className="flex flex-col rounded-full p-6 bg-trasparent w-full h-full items-center justify-center relative z-10 shadow-2xl">
                {/* Titolo */}
                <div className="imdb-title text-center text-sm text-white uppercase tracking-wide mb-1">
                  Plays in NBA since
                </div>
                {/* Anno */}
                <div className="imdb-score text-4xl md:text-5xl text-center font-extrabold text-white">
                  {firstNbaYear}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="imdb-box w-30">
            <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-lg opacity-70"></div>
            <div className="flex flex-col rounded-full p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] w-full h-full items-center justify-center relative z-10">
                <div className="flex justify-center items-center mt-2">
                {Array.from({ length: lives }).map((_, index) => (
                    <img key={index} src={Basketball} alt="Basketball" className="w-8 h-8 mx-1" />
                ))}
                </div>
                <div className="imdb-title text-center text-sm text-gray-300">LIVES</div>
            </div>
            </div>
        </div>
        </div>
      </div>
      </div>
      {gameOver && (
        <Overlay homeButton={true} closeIcon={'New game'} onExit={refresh} text={`Game Over! Final score: ${correctGuesses}`}></Overlay>
      )}
      {gameInit && (
        <Overlay closeIcon={'Let’s play'} onExit={startGame} text={'This mode is basically current’s backwords. Indeed you have to get the player by knowing the teams he has played for. Players will be more difficult as the score increases. DISCLAIMERS: 1) Who never even switched jersey once is not included 2) To be considered on a specific team, the player must have played at least one regular season match for that team'}></Overlay>
      )}
    </div>
  )
}

export default GuessPlayers