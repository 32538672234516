import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../Firebase';
import logo from './assets/Immagine_2023-09-24_162522-removebg-preview.png';
import Landscape from './assets/jc-gellidon-XmYSlYrupL8-unsplash.jpg'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  // const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      setError('Error in authentication: ' + error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(firestore, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        const signUpDate = new Date();
        const userName = user.displayName;
        const game1 = [];
        const game2 = [];
        const game3 = [];
        const game4 = [];
        const game5 = [];
        const game6 = [];

        await setDoc(userDocRef, {
          userName,
          email: user.email,
          signUpDate,
          game1,
          game2,
          game3,
          game4,
          game5,
          game6
        });
      }

      navigate('/home');
    } catch (error) {
      setError('Errore nell\'autenticazione con Google: ' + error.message);
    }
  };
/*
  if (currentUser) {
    return <p>You're already logged with this email: {currentUser.email}</p>;
  }*/

  return (
    <div style={{ 
        backgroundImage: `url(${Landscape})`, 
        backgroundSize: 'cover',
        width: '99vw',
      }}>
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <div className="text-center mb-6">
          <img src={logo} alt="Site logo" className="w-16 mx-auto mb-2" />
          <h2 className="text-2xl font-semibold text-gray-700">Login</h2>
        </div>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Email */}
          <div className="relative bg-inherit">
            <input
              type="email"
              id="email"
              name="email"
              className="peer bg-transparent h-10 w-full rounded-lg text-gray-700 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label
              htmlFor="email"
              className="absolute cursor-text left-2 -top-3 text-sm text-gray-500 bg-inherit px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
            >
              Email
            </label>
          </div>

          {/* Password */}
          <div className="relative bg-inherit">
            <input
              type="password"
              id="password"
              name="password"
              className="peer bg-transparent h-10 w-full rounded-lg text-gray-700 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label
              htmlFor="password"
              className="absolute cursor-text left-2 -top-3 text-sm text-gray-500 bg-inherit px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
            >
              Password
            </label>
          </div>

          <button
            type="submit"
            className="w-full py-2 bg-sky-600 text-white font-semibold rounded-lg hover:bg-sky-700 transition-all"
          >
            Login
          </button>
        </form>

        <div className="relative text-center my-4">
          <span className="text-gray-500">Or</span>
        </div>

        <button
          onClick={handleGoogleSignIn}
          className="w-full flex items-center justify-center py-2 bg-white text-gray-700 border rounded-lg shadow-md hover:bg-gray-100 transition-all"
        >
          <img src="https://th.bing.com/th/id/R.16597b58fb4d4fa8ebcf5a013fc19b0a?rik=a0DcRRp3bMzLow&pid=ImgRaw&r=0" alt="Google icon" className="w-5 h-5 mr-2" />
          Sign in with Google
        </button>

        <p className="text-center text-sm text-gray-500 mt-4">
          Don't have an account?{' '}
          <a href="/register" className="text-sky-600 hover:underline">
            Sign up here
          </a>
        </p>
      </div>
    </div>
    </div>
  );
};

export default Login;
