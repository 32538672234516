import React from 'react'
import Picture from './assets/quiz.jpg'
import { nickname } from '../Nicknames'
import { useState, useEffect } from 'react';
import Basketball from './assets/basketball-svgrepo-com.svg'
import Landscape from './assets/jc-gellidon-XmYSlYrupL8-unsplash.jpg'
import Overlay from './Helpers/Overlay';
import { useAuth } from '../context/AuthContext';
import { auth, firestore } from '../Firebase';
import { doc, updateDoc } from 'firebase/firestore';
import Right from './assets/skip-forward.svg'

/**
 * @typedef {Object} Player
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} img
 * @property {string[]} teams
 * @property {string} level
 * @property {number} firstNBAyear
 */

function Nicknames() {
    const [inputValue, setInputValue] = useState('');
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const [randomPlayer, setRandomPlayer] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [correctGuesses, setCorrectGuesses] = useState(0);
    const [wrongGuesses, setWrongGuesses] = useState(0);
    const [goldenSkip, setGoldenskip] = useState(true);
    const [nrGoldenSkip, setNrGoldenskip] = useState(1);

    const { currentUser, userData } = useAuth();

    const [easyFilter, setEasyFilter] = useState(nickname.filter((player) => player.level === "easy"));
    const [easymediumFilter, setEasymediumFilter] = useState(nickname.filter((player) => (player.level === "easy" || player.level === "medium")));
    const [mediumFilter, setMediumFilter] = useState(nickname.filter((player) => player.level === "medium"));
    const [mediumhardFilter, setMediumhardFilter] = useState(nickname.filter((player) => (player.level === "medium" || player.level === "hard")));
    const [hardFilter, setHardFilter] = useState(nickname.filter((player) => player.level === "hard"));
    const [extrahardFilter, setExtraHardFilter] = useState(nickname.filter((player) => player.level === "extra-hard"));

    const [lives, setLives] = useState(1);

    const [gameOver, setGameOver] = useState(false);

    const [gameInit, setGameInit] = useState(true);

    const startGame = () => {
        setGameInit(!gameInit);
    }
  
    useEffect(() => {
      generateRandomEasyPlayer();
    }, []);

    useEffect(() => {
        if (wrongGuesses === 1) {
            setGameOver(true);
            saveScore();
        }
    },[wrongGuesses])

    const generateRandomPlayer = () => {
        const randomIndex = Math.floor(Math.random() * nickname.length);
        setRandomPlayer(nickname[randomIndex]);
        setInputValue('');
        setFilteredPlayers([]);
        setActiveIndex(0);
      };
  
    const generateRandomEasyPlayer = () => {
      const randomIndex = Math.floor(Math.random() * easyFilter.length);
      setRandomPlayer(easyFilter[randomIndex]);
      setInputValue('');
      setFilteredPlayers([]);
      setActiveIndex(0);
      setEasyFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
    };

    const generateRandomEasyMediumPlayer = () => {
        const randomIndex = Math.floor(Math.random() * easymediumFilter.length);
        setRandomPlayer(easymediumFilter[randomIndex]);
        setInputValue('');
        setFilteredPlayers([]);
        setActiveIndex(0);
        setEasymediumFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      };

      const generateRandomMediumPlayer = () => {
        const randomIndex = Math.floor(Math.random() * mediumFilter.length);
        setRandomPlayer(mediumFilter[randomIndex]);
        setInputValue('');
        setFilteredPlayers([]);
        setActiveIndex(0);
        setMediumFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      };

      const generateRandomMediumHardPlayer = () => {
        const randomIndex = Math.floor(Math.random() * mediumhardFilter.length);
        setRandomPlayer(mediumhardFilter[randomIndex]);
        setInputValue('');
        setFilteredPlayers([]);
        setActiveIndex(0);
        setMediumhardFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      };

      const generateRandomHardPlayer = () => {
        const randomIndex = Math.floor(Math.random() * hardFilter.length);
        setRandomPlayer(hardFilter[randomIndex]);
        setInputValue('');
        setFilteredPlayers([]);
        setActiveIndex(0);
        setHardFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      };

      const generateRandomExtraHardPlayer = () => {
        const randomIndex = Math.floor(Math.random() * extrahardFilter.length);
        setRandomPlayer(extrahardFilter[randomIndex]);
        setInputValue('');
        setFilteredPlayers([]);
        setActiveIndex(0);
        setExtraHardFilter((prevFilter) => prevFilter.filter((_, index) => index !== randomIndex));
      };
  
    const handleInputChange = (e) => {
      const query = e.target.value.toLowerCase();
      setInputValue(query);
  
      if (query) {
        const filtered = nickname.filter(player => 
          player.firstName.toLowerCase().startsWith(query) || 
          player.lastName.toLowerCase().startsWith(query)
        );
        setFilteredPlayers(filtered);
      } else {
        setFilteredPlayers([]);
      }
    };
  
    const handleSelectPlayer = (player) => {
      setInputValue('');
      setFilteredPlayers([]);
  
      if (randomPlayer.firstName === player.firstName && randomPlayer.lastName === player.lastName) {
        setCorrectGuesses(correctGuesses + 1);
      } else {
        setWrongGuesses(wrongGuesses + 1);
        setLives(lives - 1)
      }
  
      if (correctGuesses >= 0 && correctGuesses <= 4) {
        generateRandomEasyPlayer();
      } else if (correctGuesses >= 5 && correctGuesses <= 6) {
        generateRandomEasyMediumPlayer();
      } else if (correctGuesses >= 7 && correctGuesses <= 8) {
        generateRandomMediumPlayer();
      } else if (correctGuesses >= 9 && correctGuesses <= 11) {
        generateRandomMediumHardPlayer();
      } else if (correctGuesses >= 12 && correctGuesses <= 15) {
        generateRandomHardPlayer();
      } else if (correctGuesses >= 16 && correctGuesses <= 20) {
        generateRandomExtraHardPlayer();
      } else {
        generateRandomPlayer();
      }
    };
  
    const handleFormSubmit = (e) => {
      e.preventDefault();
      if (filteredPlayers.length > 0) {
        handleSelectPlayer(filteredPlayers[activeIndex]);
      }
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => 
          (prevIndex + 1) % filteredPlayers.length
        );
      } else if (e.key === 'ArrowUp') {
        setActiveIndex((prevIndex) => 
          (prevIndex - 1 + filteredPlayers.length) % filteredPlayers.length
        );
      } else if (e.key === 'Enter' && filteredPlayers.length > 0) {
        e.preventDefault(); 
        handleSelectPlayer(filteredPlayers[activeIndex]);
      }
    };

    const saveScore = async () => {
      if (currentUser) {
          const userDoc = doc(firestore, 'users', currentUser.uid);
          try {
              await updateDoc(userDoc, {
                  game4: [...(userData.game4 || []), correctGuesses],
              });
              console.log('Punteggio salvato:', correctGuesses);
          } catch (error) {
              console.error('Errore durante il salvataggio del punteggio:', error);
          }
      }
    };

    const skipGolden = () => {
      generateRandomEasyPlayer();
      setNrGoldenskip(0);
      setGoldenskip(false);
    }

    const refresh = () => {
        window.location.reload();
    }
  
    return (
    <div style={{ 
        backgroundImage: `url(${Landscape})`, 
        backgroundSize: 'cover',
        width: '99vw',
        padding: '10px'
      }}>
      <div className="max-w-xl mx-auto">
        <form className="mt-10" onSubmit={handleFormSubmit}>
          <div className="relative">
            <input
              type="text"
              className="border-2 border-gray-300 rounded-lg p-2 w-full"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Search player by name or surname"
            />
  
            {filteredPlayers.length > 0 && (
              <ul className="absolute z-10 bg-white border border-gray-300 w-full mt-1 max-h-60 overflow-y-auto rounded-lg shadow-lg">
                {filteredPlayers.map((player, index) => (
                  <li
                    key={index}
                    className={`p-2 cursor-pointer ${
                      index === activeIndex ? 'bg-gray-400' : ''
                    }`}
                    onClick={() => handleSelectPlayer(player)}
                  >
                    {player.firstName} {player.lastName}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </form>
        <div className="relative max-w-xl mx-auto mt-10">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-700 rounded-md pointer-events-none"></div>
          <img
            className="h-64 w-full object-cover rounded-md opacity-30 "
            src={Picture}
            alt="Quiz image"
          />
          <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md" />
          <div className="absolute inset-0 flex items-center justify-center">
            <h2 className="text-white text-3xl font-bold">{randomPlayer?.soprannomi.join(', ')}</h2>
          </div>
        </div>
        {/*
        <div className="mt-5">
          <h3 className="text-lg">Wrong Guesses: {wrongGuesses}</h3>
        </div>
        */}
        <div className="flex justify-between mt-10 relative mb-10">
        <div className="imdb-box w-20">
            <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-lg opacity-70"></div>
            <div className="flex flex-col rounded-full p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] w-full h-full items-center justify-center relative z-10">
                <div className="imdb-score text-4xl text-center font-bold text-white">{correctGuesses}</div>
                <div className="imdb-title text-center text-sm text-gray-300">SCORE</div>
            </div>
            </div>
        </div>
        <div className='bg-white p-4 rounded bg-opacity-80'>
        <button
              onClick={skipGolden}
              disabled={!goldenSkip}
              className="cursor-pointer transform transition duration-300 hover:scale-110 text-white p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full shadow-md"
            >
              <img className="w-5 h-5" src={Right} alt="Golden Skip" />
        </button>
        <p>Skips: {nrGoldenSkip}</p>
        </div>
        <div className="imdb-box w-30">
            <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-lg opacity-70"></div>
            <div className="flex flex-col rounded-full p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] w-full h-full items-center justify-center relative z-10">
                <div className="flex justify-center items-center mt-2">
                {Array.from({ length: lives }).map((_, index) => (
                    <img key={index} src={Basketball} alt="Basketball" className="w-8 h-8 mx-1" />
                ))}
                </div>
                <div className="imdb-title text-center text-sm text-gray-300">LIVES</div>
            </div>
            </div>
        </div>
        </div>
      </div>
      {gameOver && (
        <Overlay homeButton={true} closeIcon={'New game'} onExit={refresh} text={`Game Over! Final score: ${correctGuesses}`}></Overlay>
      )}
      {gameInit && (
        <Overlay closeIcon={'Let’s play'} onExit={startGame} text={'The easiest one. You get a player’s nickname and you have to get whose named after that. As the other modes, the furthest you get, the hardest it gets'}></Overlay>
      )}
      </div>
    );
  }
  
  export default Nicknames;