export const teams = [
    { city: 'Atlanta', name: 'Hawks' },
    { city: 'Boston', name: 'Celtics' },
    { city: 'Brooklyn', name: 'Nets' },
    { city: 'Charlotte', name: 'Hornets' },
    { city: 'Chicago', name: 'Bulls' },
    { city: 'Cleveland', name: 'Cavaliers' },
    { city: 'Dallas', name: 'Mavericks' },
    { city: 'Denver', name: 'Nuggets' },
    { city: 'Detroit', name: 'Pistons' },
    { city: 'Golden State', name: 'Warriors' },
    { city: 'Houston', name: 'Rockets' },
    { city: 'Indiana', name: 'Pacers' },
    { city: 'Los Angeles', name: 'Clippers' },
    { city: 'Los Angeles', name: 'Lakers' },
    { city: 'Memphis', name: 'Grizzlies' },
    { city: 'Miami', name: 'Heat' },
    { city: 'Milwaukee', name: 'Bucks' },
    { city: 'Minnesota', name: 'Timberwolves' },
    { city: 'New Orleans', name: 'Pelicans' },
    { city: 'New York', name: 'Knicks' },
    { city: 'Oklahoma City', name: 'Thunder' },
    { city: 'Orlando', name: 'Magic' },
    { city: 'Philadelphia', name: '76ers' },
    { city: 'Phoenix', name: 'Suns' },
    { city: 'Portland', name: 'Trail Blazers' },
    { city: 'Sacramento', name: 'Kings' },
    { city: 'San Antonio', name: 'Spurs' },
    { city: 'Toronto', name: 'Raptors' },
    { city: 'Utah', name: 'Jazz' },
    { city: 'Washington', name: 'Wizards' }
  ];
  