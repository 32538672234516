export const nickname = [
    {
    firstName: "Earvin",
    lastName: "Johnson",
    soprannomi: ["Magic", "Buck"],
    level: "easy"
    },
    {
    firstName: "Michael",
    lastName: "Jordan",
    soprannomi: ["Air", "His Airness", "G.O.A.T", "Black Jesus"],
    level: "easy"
    },
    {
    firstName: "Larry",
    lastName: "Bird",
    soprannomi: ["Legend"],
    level: "easy"
    },
    {
    firstName: "Shaquille",
    lastName: "O'Neal",
    soprannomi: ["The big Diesel", "The Big Aristotle"],
    level: "easy"
    },
    {
    firstName: "Kareem",
    lastName: "Abdul-Jabbar",
    soprannomi: ["The Tower of Power"],
    level: "easy"
    },
    {
    firstName: "Kobe",
    lastName: "Bryant",
    soprannomi: ["Black Mamba"],
    level: "easy"
    },
    {
    firstName: "LeBron",
    lastName: "James",
    soprannomi: ["The King", "The Chosen One"],
    level: "easy"
    },
    {
    firstName: "Charles",
    lastName: "Barkley",
    soprannomi: ["Sir", "The Round Mound of Rebound", "Chuck"],
    level: "medium"
    },
    {
    firstName: "Allen",
    lastName: "Iverson",
    soprannomi: ["The Answer"],
    level: "easy"
    },
    {
    firstName: "Dirk",
    lastName: "Nowitzki",
    soprannomi: ["Wunder"],
    level: "medium"
    },
    {
    firstName: "Dwyane",
    lastName: "Wade",
    soprannomi: ["Flash"],
    level: "easy"
    },
    {
    firstName: "Kevin",
    lastName: "Garnett",
    soprannomi: ["The Big Ticket", "The Revolution"],
    level: "easy"
    },
    {
    firstName: "Clyde",
    lastName: "Drexler",
    soprannomi: ["The Glide"],
    level: "easy"
    },
    {
    firstName: "Hakeem",
    lastName: "Olajuwon",
    soprannomi: ["The Dream"],
    level: "easy"
    },
    {
    firstName: "Reggie",
    lastName: "Miller",
    soprannomi: ["Knick Killer"],
    level: "medium"
    },
    {
    firstName: "Kawhi",
    lastName: "Leonard",
    soprannomi: ["The Claw", "The Terminator"],
    level: "medium"
    },
    {
    firstName: "Moses",
    lastName: "Malone",
    soprannomi: ["Chairman of the Boards", "Big Mo"],
    level: "medium"
    },
    {
    firstName: "Scottie",
    lastName: "Pippen",
    soprannomi: ["Robin"],
    level: "hard"
    },
    {
    firstName: "Chris",
    lastName: "Paul",
    soprannomi: ["The Point God"],
    level: "easy"
    },
    {
    firstName: "Karl",
    lastName: "Malone",
    soprannomi: ["The Mailman"],
    level: "easy"
    },
    {
    firstName: "Dennis",
    lastName: "Rodman",
    soprannomi: ["The Worm",],
    level: "medium"
    },
    {
    firstName: "Walt",
    lastName: "Frazier",
    soprannomi: ["Clyde"],
    level: "hard"
    },
    {
    firstName: "George",
    lastName: "Gervin",
    soprannomi: ["The Iceman"],
    level: "medium"
    },
    {
    firstName: "Paul",
    lastName: "Pierce",
    soprannomi: ["The Truth", "P-Double"],
    level: "medium"
    },
    {
    firstName: "Isiah",
    lastName: "Thomas",
    soprannomi: ["Zeke"],
    level: "medium"
    },
    {
    firstName: "George",
    lastName: "Mikan",
    soprannomi: ["Mr. Basketball"],
    level: "medium"
    },
    {
    firstName: "Elgin",
    lastName: "Baylor",
    soprannomi: ["The Rabbit"],
    level: "hard"
    },
    {
    firstName: "Dolph",
    lastName: "Schayes",
    soprannomi: ["Dolly", "The Rainbow Kid"],
    level: "extra-hard"
    },
    {
    firstName: "Dominique",
    lastName: "Wilkins",
    soprannomi: ["The Human Highlight Film"],
    level: "hard"
    },
    {
    firstName: "Chris",
    lastName: "Mullin",
    soprannomi: ["Mully"],
    level: "medium"
    },
    {
    firstName: "Carmelo",
    lastName: "Anthony",
    soprannomi: ["Melo", "Hoodie"],
    level: "easy"
    },
    {
    firstName: "Anfernee",
    lastName: "Hardaway",
    soprannomi: ["Penny"],
    level: "easy"
    },
    {
    firstName: "Tracy",
    lastName: "McGrady",
    soprannomi: ["T-Mac", "The Big Sleep"],
    level: "easy"
    },
    {
    firstName: "Kevin",
    lastName: "Durant",
    soprannomi: ["Slim Reaper", "Easy Money Sniper"],
    level: "easy"
    },
    {
    firstName: "Earl",
    lastName: "Monroe",
    soprannomi: ["The Pearl", "Thomas Edison"],
    level: "hard"
    },
    {
    firstName: "Wilt",
    lastName: "Chamberlain",
    soprannomi: ["The Stilt", "The Big Dipper"],
    level: "medium"
    },
    {
    firstName: "Pete",
    lastName: "Maravich",
    soprannomi: ["Pistol"],
    level: "easy"
    },
    {
    firstName: "Julius",
    lastName: "Erving",
    soprannomi: ["Dr. J", "The Doctor", "Black Moses"],
    level: "easy"
    },
    {
    firstName: "Darryl",
    lastName: "Dawkins",
    soprannomi: ["Chocolate Thunder"],
    level: "medium"
    },
    {
    firstName: "David",
    lastName: "Robinson",
    soprannomi: ["The Admiral"],
    level: "easy"
    },
    {
    firstName: "Gary",
    lastName: "Payton",
    soprannomi: ["The Glove"],
    level: "easy"
    },
    {
    firstName: "Shawn",
    lastName: "Marion",
    soprannomi: ["Matrix"],
    level: "medium"
    },
    {
    firstName: "Jason",
    lastName: "Williams",
    soprannomi: ["White Chocolate"],
    level: "easy"
    },
    {
    firstName: "Vince",
    lastName: "Carter",
    soprannomi: ["Vinsanity", "Air Canada", "Half-Man/Half-Amazing"],
    level: "medium"
    },
    {
    firstName: "Ray",
    lastName: "Allen",
    soprannomi: ["Jesus Shuttlesworth", "He Got Game"],
    level: "medium"
    },
    {
    firstName: "Gilbert",
    lastName: "Arenas",
    soprannomi: ["Agent Zero", "Hibachi"],
    level: "hard"
    },
    {
    firstName: "Chris",
    lastName: "Andersen",
    soprannomi: ["Birdman"],
    level: "medium"
    },
    {
    firstName: "James",
    lastName: "Harden",
    soprannomi: ["The Beard"],
    level: "easy"
    },
    {
    firstName: "Donovan",
    lastName: "Mitchell",
    soprannomi: ["Spida"],
    level: "easy"
    },
    {
    firstName: "Giannis",
    lastName: "Antetokounmpo",
    soprannomi: ["The Greek Freak"],
    level: "easy"
    },
    {
    firstName: "Nikola",
    lastName: "Jokic",
    soprannomi: ["The Joker"],
    level: "easy"
    },
    {
    firstName: "Damian",
    lastName: "Lillard",
    soprannomi: ["D-O-L-L-A"],
    level: "easy"
    },
    {
    firstName: "Stephen",
    lastName: "Curry",
    soprannomi: ["Chef", "Baby-Faced Assassin"],
    level: "medium"
    },
    {
    firstName: "Zach",
    lastName: "Randolph",
    soprannomi: ["Z-Bo"],
    level: "hard"
    },
    {
    firstName: "Joe",
    lastName: "Johnson",
    soprannomi: ["Iso"],
    level: "hard"
    },
    {
    firstName: "Cuttino",
    lastName: "Mobley",
    soprannomi: ["Cat"],
    level: "extra-hard"
    },
    {
    firstName: "Sam",
    lastName: "Cassell",
    soprannomi: ["I Am"],
    level: "hard"
    },
    {
    firstName: "Bill",
    lastName: "Bradley",
    soprannomi: ["Dollar Bill"],
    level: "hard"
    },
    {
    firstName: "Billy",
    lastName: "Cunningham",
    soprannomi: ["The Kangaroo Kid"],
    level: "hard"
    },
    {
    firstName: "Marvin",
    lastName: "Barnes",
    soprannomi: ["Bad News"],
    level: "extra-hard"
    },
    {
    firstName: "Harold",
    lastName: "Hairston",
    soprannomi: ["Happy"],
    level: "extra-hard"
    },
    {
    firstName: "Glenn",
    lastName: "Robinson",
    soprannomi: ["Big Dog"],
    level: "hard"
    },
    {
    firstName: "John",
    lastName: "Havlicek",
    soprannomi: ["Hondo"],
    level: "medium"
    },
    {
    firstName: "Steve",
    lastName: "Nash",
    soprannomi: ["Nashty"],
    level: "easy"
    },
    {
    firstName: "Ronald",
    lastName: "Jones",
    soprannomi: ["Popeye"],
    level: "extra-hard"
    },
    {
    firstName: "Billy",
    lastName: "Paultz",
    soprannomi: ["The Whopper"],
    level: "extra-hard"
    },
    {
    firstName: "Jamaal",
    lastName: "Wilkes",
    soprannomi: ["Silk"],
    level: "hard"
    },
    {
    firstName: "Chris",
    lastName: "Kaman",
    soprannomi: ["The Caveman"],
    level: "hard"
    },
    {
    firstName: "Joe",
    lastName: "Caldwell",
    soprannomi: ["Pogo"],
    level: "extra-hard"
    },
    {
    firstName: "Nate",
    lastName: "Archibald",
    soprannomi: ["Tiny"],
    level: "easy"
    },
    {
    firstName: "Corliss",
    lastName: "Williamson",
    soprannomi: ["Big Nasty"],
    level: "extra-hard"
    },
    {
    firstName: "Darrell",
    lastName: "Griffith",
    soprannomi: ["Dr. Dunkenstein"],
    level: "extra-hard"
    },
    {
    firstName: "Vitaly",
    lastName: "Potapenko",
    soprannomi: ["The Ukraine Train"],
    level: "extra-hard"
    },
    {
    firstName: "Nick",
    lastName: "Young",
    soprannomi: ["Swaggy P"],
    level: "medium"
    },
    {
    firstName: "Kirk",
    lastName: "Hinrich",
    soprannomi: ["Captain Kirk"],
    level: "easy"
    },
    {
    firstName: "Mark",
    lastName: "Madsen",
    soprannomi: ["Mad Dog"],
    level: "hard"
    },
    {
    firstName: "Michael",
    lastName: "Beasley",
    soprannomi: ["B-Easy", "Walking Bucket"],
    level: "hard"
    },
    {
    firstName: "Chauncey",
    lastName: "Billups",
    soprannomi: ["Mr. Big Shot"],
    level: "medium"
    },
    {
    firstName: "Leonard",
    lastName: "Robinson",
    soprannomi: ["Truck"],
    level: "extra-hard"
    },
    {
    firstName: "Toni",
    lastName: "Kukoč",
    soprannomi: ["The Waiter"],
    level: "hard"
    },
    {
    firstName: "Matt",
    lastName: "Bonner",
    soprannomi: ["The Red Rocket", "The Red Mamba"],
    level: "hard"
    },
    {
    firstName: "Brian",
    lastName: "Scalabrine",
    soprannomi: ["The White Mamba"],
    level: "medium"
    },
    {
    firstName: "Caron",
    lastName: "Butler",
    soprannomi: ["Tough Juice"],
    level: "hard"
    },
    {
    firstName: "Antoine",
    lastName: "Walker",
    soprannomi: ["Employee #8"],
    level: "hard"
    },
    {
    firstName: "Austin",
    lastName: "Croshere",
    soprannomi: ["The Crusher"],
    level: "extra-hard"
    },
    {
    firstName: "Baron",
    lastName: "Davis",
    soprannomi: ["B-Diddy"],
    level: "hard"
    },
    {
    firstName: "Manu",
    lastName: "Ginobili",
    soprannomi: ["Narigon", "El Contusion"],
    level: "medium"
    },
    {
    firstName: "Stephen",
    lastName: "Jackson",
    soprannomi: ["Captain Jack"],
    level: "medium"
    },
    {
    firstName: "Sarunas",
    lastName: "Jasikevicius",
    soprannomi: ["Jazzy Cabbages"],
    level: "extra-hard"
    },
    {
    firstName: "Rashard",
    lastName: "Lewis",
    soprannomi: ["Sweet Lew"],
    level: "medium"
    },
    {
    firstName: "Adam",
    lastName: "Morrison",
    soprannomi: ["Stache"],
    level: "hard"
    },
    {
    firstName: "Brian",
    lastName: "Cardinal",
    soprannomi: ["The Custodian"],
    level: "extra-hard"
    },
    {
    firstName: "Luke",
    lastName: "Ridnour",
    soprannomi: ["Frodo"],
    level: "extra-hard"
    },
    {
    firstName: "Josh",
    lastName: "Smith",
    soprannomi: ["J-Smoove"],
    level: "medium"
    },
    {
    firstName: "Gerald",
    lastName: "Wallace",
    soprannomi: ["Crash"],
    level: "hard"
    },
    {
    firstName: "Fred",
    lastName: "Hoiberg",
    soprannomi: ["The Mayor"],
    level: "medium"
    },
    {
    firstName: "Kenny",
    lastName: "Smith",
    soprannomi: ["The Jet"],
    level: "easy"
    },
    {
    firstName: "Jason",
    lastName: "Terry",
    soprannomi: ["JET"],
    level: "easy"
    },
    {
    firstName: "Robert",
    lastName: "Taylor",
    soprannomi: ["Tractor"],
    level: "hard"
    },
    {
    firstName: "Fred",
    lastName: "Brown",
    soprannomi: ["Downtown"],
    level: "extra-hard"
    },
    {
    firstName: "Jerry",
    lastName: "West",
    soprannomi: ["The Logo"],
    level: "easy"
    },
    {
    firstName: "Vinnie",
    lastName: "Johnson",
    soprannomi: ["The Microwave"],
    level: "medium"
    },
    {
    firstName: "Sam",
    lastName: "Perkins",
    soprannomi: ["Big Smooth"],
    level: "hard"
    },
    {
    firstName: "John",
    lastName: "Williams",
    soprannomi: ["Hot Rod"],
    level: "medium"
    },
    {
    firstName: "Robert",
    lastName: "Horry",
    soprannomi: ["Big Shot"],
    level: "medium"
    },
    {
    firstName: "Andrei",
    lastName: "Kirilenko",
    soprannomi: ["AK-47"],
    level: "easy"
    },
    {
    firstName: "Artis",
    lastName: "Gilmore",
    soprannomi: ["The A-Train"],
    level: "medium"
    },
    {
    firstName: "James",
    lastName: "Worthy",
    soprannomi: ["Big Game"],
    level: "hard"
    },
    {
    firstName: "Oscar",
    lastName: "Robertson",
    soprannomi: ["The Big O", "Mr Triple Double"],
    level: "easy"
    },
    {
    firstName: "Lloyd",
    lastName: "B. Free",
    soprannomi: ["World"],
    level: "hard"
    },
    {
    firstName: "Rafer",
    lastName: "Alston",
    soprannomi: ["Skip to My Lou"],
    level: "extra-hard"
    },
    {
    firstName: "Anthony",
    lastName: "Webb",
    soprannomi: ["Spud"],
    level: "easy"
    },
    {
    firstName: "Robert",
    lastName: "Parish",
    soprannomi: ["The Chief"],
    level: "medium"
    },
    {
    firstName: "Tyrone",
    lastName: "Bogues",
    soprannomi: ["Muggsy"],
    level: "easy"
    },
    {
    firstName: "Bryant",
    lastName: "Reeves",
    soprannomi: ["Big Country"],
    level: "extra-hard"
    },
    {
    firstName: "Kyrie",
    lastName: "Irving",
    soprannomi: ["Uncle Drew", "Kai"],
    level: "easy"
    },
    {
    firstName: "Trevor",
    lastName: "Ariza",
    soprannomi: ["Switchblade"],
    level: "extra-hard"
    },
    {
    firstName: "Paul",
    lastName: "Arizin",
    soprannomi: ["The Pitcher"],
    level: "extra-hard"
    },
    {
    firstName: "Andrea",
    lastName: "Bargnani",
    soprannomi: ["Il Mago"],
    level: "hard"
    },
    {
    firstName: "Chris",
    lastName: "Boucher",
    soprannomi: ["Slim Duck"],
    level: "hard"
    },
    {
    firstName: "Joe",
    lastName: "Bryant",
    soprannomi: ["Jellybean"],
    level: "extra-hard"
    },
    {
    firstName: "DeMarcus",
    lastName: "Cousins",
    soprannomi: ["Boogie"],
    level: "easy"
    },
    {
    firstName: "Bob",
    lastName: "Cousy",
    soprannomi: ["The Houdini of the Hardwood"],
    level: "medium"
    },
    {
    firstName: "Jamal",
    lastName: "Crawford",
    soprannomi: ["JCrossover"],
    level: "medium"
    },
    {
    firstName: "Anthony",
    lastName: "Davis",
    soprannomi: ["The Brow"],
    level: "easy"
    },
    {
    firstName: "Tim",
    lastName: "Duncan",
    soprannomi: ["The Big Fundamental"],
    level: "easy"
    },
    {
    firstName: "Goran",
    lastName: "Dragić",
    soprannomi: ["The Dragon"],
    level: "medium"
    },
    {
    firstName: "Joel",
    lastName: "Embiid",
    soprannomi: ["The Process"],
    level: "easy"
    },
    {
    firstName: "Steve",
    lastName: "Francis",
    soprannomi: ["Franchise"],
    level: "medium"
    },
    {
    firstName: "Dan",
    lastName: "Gadzuric",
    soprannomi: ["The Flying Dutchman"],
    level: "extra-hard"
    },
    {
    firstName: "Danilo",
    lastName: "Gallinari",
    soprannomi: ["Il Gallo"],
    level: "medium"
    },
    {
    firstName: "Draymond",
    lastName: "Green",
    soprannomi: ["The Dancing Bear"],
    level: "easy"
    },
    {
    firstName: "Paul",
    lastName: "George",
    soprannomi: ["Young Trece"],
    level: "medium"
    },
    {
    firstName: "Trae",
    lastName: "Young",
    soprannomi: ["Ice"],
    level: "medium"
    },
    {
    firstName: "Marcin",
    lastName: "Gortat",
    soprannomi: ["The Polish Hammer"],
    level: "hard"
    },
    {
    firstName: "Jeff",
    lastName: "Green",
    soprannomi: ["Uncle"],
    level: "medium"
    },
    {
    firstName: "Tyler",
    lastName: "Hansbrough",
    soprannomi: ["Psycho T"],
    level: "hard"
    },
    {
    firstName: "Juan",
    lastName: "Hernangomez",
    soprannomi: ["Bo Cruz"],
    level: "easy"
    },
    {
    firstName: "Dwight",
    lastName: "Howard",
    soprannomi: ["Superman"],
    level: "medium"
    },
    {
    firstName: "Nashon",
    lastName: "Hyland",
    soprannomi: ["Bones"],
    level: "easy"
    },
    {
    firstName: "Andre",
    lastName: "Iguodala",
    soprannomi: ["Iggy"],
    level: "easy"
    },
    {
    firstName: "Zydrunas",
    lastName: "Ilgauskas",
    soprannomi: ["Big Z"],
    level: "medium"
    },
    {
    firstName: "Shawn",
    lastName: "Kemp",
    soprannomi: ["Reign Man"],
    level: "medium"
    },
    {
    firstName: "Lafayette",
    lastName: "Lever",
    soprannomi: ["Fat"],
    level: "medium"
    },
    {
    firstName: "Jeremy",
    lastName: "Lin",
    soprannomi: ["Linsanity"],
    level: "easy"
    },
    {
    firstName: "Brook",
    lastName: "Lopez",
    soprannomi: ["Splash Mountain"],
    level: "hard"
    },
    {
    firstName: "Stephon",
    lastName: "Marbury",
    soprannomi: ["Starbury"],
    level: "medium"
    },
    {
    firstName: "Kevin",
    lastName: "McHale",
    soprannomi: ["Black Hole"],
    level: "hard"
    },
    {
    firstName: "Alonzo",
    lastName: "Mourning",
    soprannomi: ["Zo"],
    level: "easy"
    },
    {
    firstName: "Jamal",
    lastName: "Murray",
    soprannomi: ["The Blue Arrow"],
    level: "medium"
    },
    {
    firstName: "Lamar",
    lastName: "Odom",
    soprannomi: ["Candy Man"],
    level: "hard"
    },
    {
    firstName: "William",
    lastName: "Parker",
    soprannomi: ["Smush"],
    level: "extra-hard"
    },
    {
    firstName: "Paul",
    lastName: "Reed",
    soprannomi: ["B-Ball"],
    level: "medium"
    },
    {
    firstName: "Nate",
    lastName: "Robinson",
    soprannomi: ["KryptoNate"],
    level: "easy"
    },
    {
    firstName: "Sergio",
    lastName: "Rodriguez",
    soprannomi: ["Spanish Chocolate", "El Chacho"],
    level: "extra-hard"
    },
    {
    firstName: "D'Angelo",
    lastName: "Russell",
    soprannomi: ["D-Lo"],
    level: "easy"
    },
    {
    firstName: "Pascal",
    lastName: "Siakam",
    soprannomi: ["Spicy P"],
    level: "easy"
    },
    {
    firstName: "J.R.",
    lastName: "Smith",
    soprannomi: ["Swish"],
    level: "easy"
    },
    {
    firstName: "Rik",
    lastName: "Smits",
    soprannomi: ["The Dunkin' Dutchman"],
    level: "medium"
    },
    {
    firstName: "Lance",
    lastName: "Stephenson",
    soprannomi: ["Born Ready"],
    level: "easy"
    },
    {
    firstName: "Amar'e",
    lastName: "Stoudemire",
    soprannomi: ["STAT"],
    level: "hard"
    },
    {
    firstName: "Reece",
    lastName: "Tatum",
    soprannomi: ["Goose"],
    level: "extra-hard"
    },
    {
    firstName: "Karl-Anthony",
    lastName: "Towns",
    soprannomi: ["KAT"],
    level: "easy"
    },
    {
    firstName: "Stojko",
    lastName: "Vranković",
    soprannomi: ["The Human Victory Cigar"],
    level: "extra-hard"
    },
    {
    firstName: "Ben",
    lastName: "Wallace",
    soprannomi: ["Big Ben"],
    level: "medium"
    },
    {
    firstName: "Rasheed",
    lastName: "Wallace",
    soprannomi: ["Sheed"],
    level: "easy"
    },
    {
    firstName: "Russell",
    lastName: "Westbrook",
    soprannomi: ["Brodie"],
    level: "easy"
    },
    {
    firstName: "Andrew",
    lastName: "Wiggins",
    soprannomi: ["Maple Jordan"],
    level: "medium"
    },
    {
    firstName: "Larry",
    lastName: "Nance sr.",
    soprannomi: ["The High-Atolla of Slamola"],
    level: "hard"
    },
    {
    firstName: "Robert",
    lastName: "Williams III",
    soprannomi: ["Time Lord"],
    level: "medium"
    },
    {
    firstName: "Anthony",
    lastName: "Edwards",
    soprannomi: ["Ant-Man"],
    level: "easy"
    },
    {  
    firstName: "Kevin",
    lastName: "Huerter",
    soprannomi: ["K'Von"],
    level: "easy"
    },{
    firstName: "Clint",
    lastName: "Capela",
    soprannomi: ["Swiss Bank"],
    level: "medium"
    },{
    firstName: "Terry",
    lastName: "Rozier",
    soprannomi: ["Scary"],
    level: "medium"
    },{
    firstName: "Kelly",
    lastName: "Oubre jr.",
    soprannomi: ["Tsunami Papi"],
    level: "medium"
    },{
    firstName: "Jimmy",
    lastName: "Butler",
    soprannomi: ["G.Buckets"],
    level: "easy"
    },{
    firstName: "Montrezl",
    lastName: "Harrell",
    soprannomi: ["Trez"],
    level: "easy"
    },{
    firstName: "Kristaps",
    lastName: "Porzingis",
    soprannomi: ["Unicorn"],
    level: "easy"
    },{
    firstName: "Terrence",
    lastName: "Ross",
    soprannomi: ["Human Torch"],
    level: "hard"
    },{
    firstName: "Evan",
    lastName: "Fournier",
    soprannomi: ["Don't Google"],
    level: "hard"
    },{
    firstName: "Andre",
    lastName: "Drummond",
    soprannomi: ["Big Penguin"],
    level: "hard"
    },{
    firstName: "Danny",
    lastName: "Green",
    soprannomi: ["Icy-Hot"],
    level: "medium"
    },{
    firstName: "Lauri",
    lastName: "Markkanen",
    soprannomi: ["The Finnisher"],
    level: "easy"
    },{
    firstName: "Darius",
    lastName: "Garland",
    soprannomi: ["DG the PG"],
    level: "easy"
    },{
    firstName: "Jarrett",
    lastName: "Allen",
    soprannomi: ["Fro"],
    level: "easy"
    },{
    firstName: "Jaren",
    lastName: "Jackson Jr",
    soprannomi: ["Block Panther"],
    level: "medium"
    },{
    firstName: "Derrick",
    lastName: "Jones Jr.",
    soprannomi: ["Airplane Mode"],
    level: "hard"
    },
    {
      firstName: "Jalen",
      lastName: "Williams",
      soprannomi: ["J-Dub"],
      level: "hard"
    },
    {
      firstName: "Patrick",
      lastName: "Ewing",
      soprannomi: ["Hoya Destroya"],
      level: "hard"
    },{
      firstName: "Dave",
      lastName: "DeBusschere",
      soprannomi: ["Big D"],
      level: "hard"
    },
    {
      firstName: "Adrian",
      lastName: "Dantley",
      soprannomi: ["The Teacher"],
      level: "hard"
    },
    {
      firstName: "Bob",
      lastName: "Pettit",
      soprannomi: ["The Big Blue", "The Bombardier from Baton Rouge"],
      level: "hard"
    },
    {
      firstName: "Jerry",
      lastName: "Lucas",
      soprannomi: ["Dr. Memory"],
      level: "hard"
    },{
      firstName: "Dave",
      lastName: "Cowens",
      soprannomi: ["Big Red (celtics edition)"],
      level: "hard"
    },{
      firstName: "Willis",
      lastName: "Reed",
      soprannomi: ["The Captain"],
      level: "medium"
    },{
      firstName: "Sam",
      lastName: "Jones",
      soprannomi: ["Mr.Clutch"],
      level: "hard"
    },{
      firstName: "Rick",
      lastName: "Barry",
      soprannomi: ["The Jerk"],
      level: "hard"
    },{
      firstName: "Alex",
      lastName: "English",
      soprannomi: ["Flick"],
      level: "hard"
    },{
      firstName: "Jason",
      lastName: "Kidd",
      soprannomi: ["Ason('cause has no J)"],
      level: "medium"
    },{
      firstName: "Spencer",
      lastName: "Haywood",
      soprannomi: ["Weedie"],
      level: "hard"
    },{
      firstName: "Dave",
      lastName: "Bing",
      soprannomi: ["The Duke"],
      level: "hard"
    },{
      firstName: "Bill",
      lastName: "Walton",
      soprannomi: ["Big Red 2.0 (Blazer Mania edition)"],
      level: "hard"
    },{
      firstName: "Hal",
      lastName: "Greer",
      soprannomi: ["The Bulldog"],
      level: "hard"
    },{
      firstName: "Bill",
      lastName: "Sharman",
      soprannomi: ["Bull's eye"],
      level: "hard"
    },
    {
      firstName: "Wes",
      lastName: "Unseld",
      soprannomi: ["Baby Bull"],
      level: "hard"
    },
    {
      firstName: "Elvin",
      lastName: "Hayes",
      soprannomi: ["The Big E"],
      level: "hard"
    },
    
    
  
   ];