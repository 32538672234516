import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../Firebase';
import logo from './assets/Immagine_2023-09-24_162522-removebg-preview.png';
import Landscape from './assets/jc-gellidon-XmYSlYrupL8-unsplash.jpg'

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState('');
  const [game1, setGame1] = useState([]);
  const [game2, setGame2] = useState([]);
  const [game3, setGame3] = useState([]);
  const [game4, setGame4] = useState([]);
  const [game5, setGame5] = useState([]);
  const [game6, setGame6] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, {
        displayName: `${userName}`,
      });

      const signUpDate = new Date();

      await setDoc(doc(firestore, 'users', user.uid), {
        userName,
        email,
        signUpDate,
        game1,
        game2,
        game3,
        game4,
        game5,
        game6
      });

      navigate('/');
    } catch (error) {
      setError('Errore nella registrazione: ' + error.message);
    }
  };

  return (
    <div style={{ 
        backgroundImage: `url(${Landscape})`, 
        backgroundSize: 'cover',
        width: '99vw',
      }}>
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <div className="text-center mb-6">
          <img src={logo} alt="Site logo" className="w-16 mx-auto mb-2" />
          <h2 className="text-2xl font-semibold text-gray-700">Create an account</h2>
        </div>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Username */}
          <div className="relative bg-inherit">
            <input
              type="text"
              id="username"
              name="username"
              className="peer bg-transparent h-10 w-full rounded-lg text-gray-700 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none"
              placeholder="Username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
            <label
              htmlFor="username"
              className="absolute cursor-text left-2 -top-3 text-sm text-gray-500 bg-inherit px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
            >
              Username
            </label>
          </div>

          {/* Email */}
          <div className="relative bg-inherit">
            <input
              type="email"
              id="email"
              name="email"
              className="peer bg-transparent h-10 w-full rounded-lg text-gray-700 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label
              htmlFor="email"
              className="absolute cursor-text left-2 -top-3 text-sm text-gray-500 bg-inherit px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
            >
              Email
            </label>
          </div>

          {/* Password */}
          <div className="relative bg-inherit">
            <input
              type="password"
              id="password"
              name="password"
              className="peer bg-transparent h-10 w-full rounded-lg text-gray-700 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label
              htmlFor="password"
              className="absolute cursor-text left-2 -top-3 text-sm text-gray-500 bg-inherit px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
            >
              Password
            </label>
          </div>

          <button
            type="submit"
            className="w-full py-2 bg-sky-600 text-white font-semibold rounded-lg hover:bg-sky-700 transition-all"
          >
            Register
          </button>
        </form>
        <p className="text-center text-sm text-gray-500 mt-4">
          Already have an account?{' '}
          <a href="/login" className="text-sky-600 hover:underline">
            Log in
          </a>
        </p>
      </div>
    </div>
    </div>
  );
};

export default Register;
