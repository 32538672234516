import React from 'react';
import Court from '../assets/canestroArancioneAI2.jpg';
import { Link } from 'react-router-dom';
import HomeLogo from '../assets/home-svgrepo-com.svg'
import PlayLogo from '../assets/play.svg'
import Game1 from '../assets/LeGuessTheTeam.jpg';
import Game2 from '../assets/immagine top 75.jpg';
import Game3 from '../assets/LeGuessThePlayer.jpg';
import Game4 from '../assets/Nicknames.jpg';

function Overlay({ onExit, text, buttons, closeIcon, homeButton }) {
  return (
    <div>
      <div className="fixed z-10 inset-0 overflow-y-auto" id="my-modal">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          ></span>
          <div
            style={{ backgroundImage: `url(${Court})` }}
            className="inline-block align-bottom bg-cover rounded-lg p-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="relative">
                <div className="mt-2 bg-gray-500 bg-opacity-20 backdrop-blur-md p-4 rounded-lg">
                  <p className="text-sm text-white">{text}</p>
                </div>
              </div>
            </div>
            {buttons && (
              <div className="mt-5 sm:mt-6 flex justify-center gap-4">
                <Link
                  to="/leguesstheteam"
                  className="relative px-4 py-2 bg-opacity-50 inline-flex justify-center items-center border border-transparent shadow-sm bg-custom-violet text-white hover:bg-custom-dark-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded-md"
                  onClick={onExit}
                >
                  <span className="relative z-10">LeGuess the Team</span>

                  <span
                    className="absolute inset-0 z-0 opacity-30 rounded-md"
                    style={{
                      backgroundImage: `url(${Game1})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                </Link>
                <Link
                  to="/topplayers"
                  className="relative px-4 py-2 bg-opacity-50 inline-flex justify-center items-center border border-transparent shadow-sm bg-custom-violet text-white hover:bg-custom-dark-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded-md"
                  onClick={onExit}
                >
                  <span className="relative z-10">Top 75 of All Time</span>
                  <span
                    className="absolute inset-0 z-0 opacity-30 rounded-md"
                    style={{
                      backgroundImage: `url(${Game2})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                </Link>
                <Link
                  to="/leguesstheplayer"
                  className="relative px-4 py-2 bg-opacity-50 inline-flex justify-center items-center border border-transparent shadow-sm bg-custom-violet text-white hover:bg-custom-dark-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded-md"
                  onClick={onExit}
                >
                  <span className="relative z-10">LeGuess the Player</span>

                  <span
                    className="absolute inset-0 z-0 opacity-30 rounded-md"
                    style={{
                      backgroundImage: `url(${Game3})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                </Link>
                <Link
                  to="/nicknames"
                  className="relative px-4 py-2 bg-opacity-50 inline-flex justify-center items-center border border-transparent shadow-sm bg-custom-violet text-white hover:bg-custom-dark-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded-md"
                  onClick={onExit}
                >
                  <span className="relative z-10">Nicknames</span>
                  <span
                    className="absolute inset-0 z-0 opacity-30 rounded-md"
                    style={{
                      backgroundImage: `url(${Game4})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                </Link>
              </div>
            )}
            <div className="mt-5 sm:mt-6 flex justify-center gap-2">
              <button
                className="p-2 bg-opacity-80 inline-flex justify-center items-center border border-transparent shadow-sm bg-custom-dark-violet text-white hover:bg-custom-dark-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded"
                onClick={onExit}
              >
                {closeIcon && (
                  <img className="w-5 h-5 mr-2 cursor-pointer" src={PlayLogo} alt="User Icon" />
                )}
                {closeIcon ? closeIcon : 'X'}
              </button>
              {homeButton && (
                <Link to='/' className='p-2 bg-opacity-80 inline-flex justify-center items-center border border-transparent shadow-sm bg-custom-dark-violet text-white hover:bg-custom-dark-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded'>
                  <img className="w-5 h-5 mr-2 cursor-pointer" src={HomeLogo} alt="User Icon" />
                  Home
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overlay;
