import React from 'react'
import Picture from './assets/basketballcourt2.jpg'
import Landscape from './assets/jc-gellidon-XmYSlYrupL8-unsplash.jpg'
import { topPlayers } from '../TopPlayers';
import { useState, useEffect } from 'react';
import Basketball from './assets/basketball-svgrepo-com.svg'
import Overlay from './Helpers/Overlay';
import { teams } from '../Teams';
import '../style/Teams.css'
import Timer from './Helpers/Timer';
import { useAuth } from '../context/AuthContext';
import { auth, firestore } from '../Firebase';
import { doc, updateDoc } from 'firebase/firestore';
import Right from './assets/skip-forward.svg'
import Left from './assets/skip-back.svg'

function Top() {
  const [lives, setLives] = useState(3);
  const [inputValue, setInputValue] = useState('');
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [randomPlayers, setRandomPlayers] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [correctGuesses, setCorrectGuesses] = useState(0);
  const [wrongGuesses, setWrongGuesses] = useState(0);
  const [playerImage, setPlayerImage] = useState('');
  const [guessedTeams, setGuessedTeams] = useState([[],[],[],[],[],[],[],[],[],[]]);
  const [gameOver, setGameOver] = useState(false);
  const [gameInit, setGameInit] = useState(true);
  const [indexPlayer, setIndexPlayer] = useState(0);
  const [win, setWin] = useState(false);
  const cetri = 'cetriolo';

  const { currentUser, userData } = useAuth();

  useEffect(() => {
    generateRandomPlayer();
  }, []);

  useEffect(() => {
    // console.log('Random players updated:', randomPlayers);
  }, [randomPlayers]);

  const startGame = () => {
    setGameInit(!gameInit);
    handleStartStop();
  }

  const generateRandomPlayer = () => {
    setRandomPlayers((prevPlayers) => {
      let newRandomPlayers = [...prevPlayers];
  
      while (newRandomPlayers.length < prevPlayers.length + 5) {
        const randomIndex = Math.floor(Math.random() * topPlayers.length);
        const randomPlayer = topPlayers[randomIndex];
  
        const isDuplicate = newRandomPlayers.some(player => 
          player.firstName === randomPlayer.firstName && player.lastName === randomPlayer.lastName
        );
  
        if (!isDuplicate) {
          newRandomPlayers.push(randomPlayer);
        }
      }
  
      return newRandomPlayers;
    });
  };
  
  

  const setTheIndex = (input) => {
    setIndexPlayer(input);
  }
  

    const handleFormSubmit = (e) => {
      e.preventDefault();
      if (filteredTeams.length > 0) {
        handleSelectTeam(filteredTeams[activeIndex], indexPlayer);
      }
    }

    /*
    const handleSelectPlayer = (player) => {
      setInputValue('');
      setFilteredTeams([]);

      // generateRandomPlayer();
  
      if (randomPlayer.firstName === player.firstName && randomPlayer.lastName === player.lastName) {
        setCorrectGuesses(correctGuesses + 1);
      } else {
        setLives(lives - 1)
        setWrongGuesses(wrongGuesses + 1)
      }
  
      if (correctGuesses >= 0 && correctGuesses <= 4) {
        generateRandomEasyPlayer();
      } else if (correctGuesses >= 5 && correctGuesses <= 6) {
        generateRandomEasyMediumPlayer();
      } else if (correctGuesses >= 7 && correctGuesses <= 9) {
        generateRandomMediumPlayer();
      } else if (correctGuesses >= 10 && correctGuesses <= 13) {
        generateRandomMediumHardPlayer();
      } else if (correctGuesses >= 14 && correctGuesses <= 16) {
        generateRandomHardPlayer();
      } else if (correctGuesses >= 17 && correctGuesses <= 20) {
        generateRandomExtraHardPlayer();
      } else {
        generateRandomPlayer();
      }
    };*/
    
    const handleSelectTeam = (team, index) => {
      const playerTeams = randomPlayers[index]?.teams.map(t => `${t}`);
      /*console.log('alalal', randomPlayers, index);
      console.log('playerteams', playerTeams);
      console.log(guessedTeams);*/
  
      if (playerTeams.includes(`${team.city} ${team.name}`)) {
        if (!guessedTeams[index].includes(`${team.city} ${team.name}`)) {
          // setGuessedTeams([...guessedTeams, `${team.city} ${team.name}`]);
          const updatedGuessedTeams = [...guessedTeams];
          updatedGuessedTeams[index] = [...updatedGuessedTeams[index], `${team.city} ${team.name}`];
          setGuessedTeams(updatedGuessedTeams);
          // console.log(guessedTeams);
          // setCorrectGuesses(correctGuesses + 1);
        }
        if (guessedTeams[index].length + 1 === playerTeams.length) {
          setCorrectGuesses(correctGuesses + 1);
          console.log(correctGuesses);
          if (correctGuesses !== 10) {
            handleNextPlayer();
          }
          if (correctGuesses === 9) {
            setWin(true);
            saveScore();
          }
          // generateRandomPlayer();
          /*
          if (correctGuesses >= 0 && correctGuesses <= 4) {
            generateRandomEasyPlayer();
          } else if (correctGuesses >= 5 && correctGuesses <= 6) {
            generateRandomEasyMediumPlayer();
          } else if (correctGuesses >= 7 && correctGuesses <= 9) {
            generateRandomMediumPlayer();
          } else if (correctGuesses >= 10 && correctGuesses <= 13) {
            generateRandomMediumHardPlayer();
          } else if (correctGuesses >= 14 && correctGuesses <= 16) {
            generateRandomHardPlayer();
          } else if (correctGuesses >= 17 && correctGuesses <= 20) {
            generateRandomExtraHardPlayer();
          } else {
            generateRandomPlayer();
          }*/
        }
      } else {
        setLives(lives - 1);
        setWrongGuesses(wrongGuesses + 1);
        if (lives === 1) {
          // alert("Game over!");
          // Reset del gioco se vuoi far ricominciare
          setLives(3);
          // setCorrectGuesses(0);
          generateRandomPlayer();
          setGameOver(true);
          saveScore();
        }
      }
      setInputValue('');
      setFilteredTeams([]);
      setActiveIndex(0);
    };

    const handleInputChange = (e) => {
      const query = e.target.value.toLowerCase();
      setInputValue(query);
  
      if (query) {
        const filtered = teams.filter(team => 
          team.city.toLowerCase().startsWith(query) || 
          team.name.toLowerCase().startsWith(query)
        );
        setFilteredTeams(filtered);
      } else {
        setFilteredTeams([]);
      }
    };
  
    
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => 
          (prevIndex + 1) % filteredTeams.length
        );
      } else if (e.key === 'ArrowUp') {
        setActiveIndex((prevIndex) => 
          (prevIndex - 1 + filteredTeams.length) % filteredTeams.length
        );
      } else if (e.key === 'Enter' && filteredTeams.length > 0) {
        e.preventDefault(); 
        handleSelectTeam(filteredTeams[activeIndex], indexPlayer);
      }
    };

    const handleNextPlayer = () => {
      setIndexPlayer((prevIndex) => {
        let nextIndex = (prevIndex + 1) % randomPlayers.length;
    
        while (guessedTeams[nextIndex].length === randomPlayers[nextIndex].teams.length) {
          nextIndex = (nextIndex + 1) % randomPlayers.length;
          
          if (nextIndex === prevIndex) {
            break;
          }
        }
    
        return nextIndex;
      });
    };    
  
    const handlePrevPlayer = () => {
      setIndexPlayer((prevIndex) => {
        let nextIndex = (prevIndex - 1 + randomPlayers.length) % randomPlayers.length;
    
        while (guessedTeams[nextIndex].length === randomPlayers[nextIndex].teams.length) {
          nextIndex = (nextIndex - 1 + randomPlayers.length) % randomPlayers.length;
          
          if (nextIndex === prevIndex) {
            break;
          }
        }
    
        return nextIndex;
      });
    };    

    const [startTimer, setStartTimer] = useState(false);
    const handleStartStop = () => {
      setStartTimer(!startTimer);
    };

    const handleFinish = () => {
      setGameOver(true);
      saveScore();
    };

    const saveScore = async () => {
      if (currentUser) {
          const userDoc = doc(firestore, 'users', currentUser.uid);
          try {
              await updateDoc(userDoc, {
                  game2: [...(userData.game2 || []), correctGuesses],
              });
              console.log('Punteggio salvato:', correctGuesses);
          } catch (error) {
              console.error('Errore durante il salvataggio del punteggio:', error);
          }
      }
    };

    const refresh = () => {
        window.location.reload();
    }

  return (
    <div>
    <div className="flex justify-center items-center bg-gray-800 p-2 w-full top-0">
      <div className="grid grid-cols-5 gap-2 md:grid-cols-10">
        {randomPlayers.slice(0, 10).map((player, index) => {
          const allTeamsGuessed =
            guessedTeams[index].length === player.teams.length;

          return (
            <div
              key={index}
              className="relative w-12 h-12 rounded-full overflow-hidden border-2 border-white"
            >
              <img
                src={player.img}
                alt={`${player.firstName} ${player.lastName}`}
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => setTheIndex(index)}
              />
              {allTeamsGuessed && (
                <div className="absolute inset-0 bg-green-500 opacity-50 cursor-pointer" onClick={() => setTheIndex(index)}></div>
              )}
            </div>
          );
        })}
      </div>
    </div>

    <div style={{ 
      backgroundImage: `url(${Landscape})`, 
      backgroundSize: 'cover',
      width: '99vw',
      padding: '10px'
    }}>
      <Timer startTimer={startTimer} onFinish={handleFinish}></Timer>
    <div className="max-w-xl mx-auto">
      <form className="mt-10" onSubmit={handleFormSubmit}
      >
        <div className="relative">
          <input
            type="text"
            className="border-2 border-gray-300 rounded-lg p-2 w-full"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Search teams"
          />

          {filteredTeams.length > 0 && (
            <ul className="absolute z-10 bg-white border border-gray-300 w-full mt-1 max-h-60 overflow-y-auto rounded-lg shadow-lg">
              {filteredTeams.map((team, index) => (
                <li
                  key={index}
                  className={`p-2 cursor-pointer ${
                    index === activeIndex ? 'bg-gray-400' : ''
                  }`}
                  onClick={() => handleSelectTeam(team, indexPlayer)}
                >
                  {team.city} {team.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </form>
      <div className="relative max-w-xl mx-auto mt-10">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-700 rounded-md pointer-events-none"></div>

        <div
          className="h-64 w-full object-cover rounded-md opacity-30"
          style={{
            backgroundImage: `url(${randomPlayers[indexPlayer]?.img})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          alt="Quiz image"
        />

        <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>

        <div className="absolute inset-0 flex flex-col items-center justify-center space-y-4">
          <div className="w-24 h-24 rounded-full overflow-hidden border border-white">
            <div
              className="w-full h-full object-cover"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${randomPlayers[indexPlayer]?.img})`
              }}
              alt={`${randomPlayers[indexPlayer]?.firstName} ${randomPlayers[indexPlayer]?.lastName}`}
            />
          </div>

          <h2 className="text-white text-xl font-bold text-center">
            {randomPlayers[indexPlayer]?.firstName} {randomPlayers[indexPlayer]?.lastName}
          </h2>
          <div className="relative w-full flex items-center justify-center">
            <button
              onClick={handlePrevPlayer}
              className="transform transition duration-300 hover:scale-110 ml-3 absolute left-0 text-white p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full shadow-md transition duration-300"
            >
              <img className="w-5 h-5 cursor-pointer" src={Left} alt="User Icon" />
            </button>

            <div className="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] backdrop-blur-md text-white p-4 rounded-lg shadow-lg w-64 h-22 text-center overflow-y-auto custom-scrollbar mx-16">
              <h3 className="text-lg font-semibold mb-2">
                Guessed Teams ({guessedTeams[indexPlayer].length}/{randomPlayers[indexPlayer]?.teams.length})
              </h3>
              <p className="text-sm">
                {guessedTeams[indexPlayer].length > 0 ? guessedTeams[indexPlayer].join(', ') : 'No teams guessed yet'}
              </p>
            </div>

            <button
              onClick={handleNextPlayer}
              className="transform transition duration-300 hover:scale-110 mr-3 absolute right-0 text-white p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full shadow-md transition duration-300"
            >
              <img className="w-5 h-5 cursor-pointer" src={Right} alt="User Icon" />
            </button>
          </div>
        </div>
      </div>
      
      {/*
      <div className="mt-5">
        <h3 className="text-lg">Wrong Guesses: {wrongGuesses}</h3>
      </div>
      */}
      <div className="flex justify-between mt-10 relative mb-10">
      <div className="imdb-box w-20">
          <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-lg opacity-70"></div>
          <div className="flex flex-col rounded-full p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] w-full h-full items-center justify-center relative z-10">
              <div className="imdb-score text-3xl text-center font-bold text-white">{correctGuesses}/10</div>
              <div className="imdb-title text-center text-sm text-gray-300">SCORE</div>
          </div>
          </div>
      </div>
      <div className="imdb-box w-30">
          <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-lg opacity-70"></div>
          <div className="flex flex-col rounded-full p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] w-full h-full items-center justify-center relative z-10">
              <div className="flex justify-center items-center mt-2">
              {Array.from({ length: lives }).map((_, index) => (
                  <img key={index} src={Basketball} alt="Basketball" className="w-8 h-8 mx-1" />
              ))}
              </div>
              <div className="imdb-title text-center text-sm text-gray-300">LIVES</div>
          </div>
          </div>
      </div>
      </div>
    </div>
    </div>
    {gameOver && (
      <Overlay closeIcon={'New game'} onExit={refresh} text={`You win! Final score: ${correctGuesses}`}></Overlay>
    )}
    {gameOver && (
      <Overlay homeButton={true} closeIcon={'New game'} onExit={refresh} text={`Game Over! Final score: ${correctGuesses}`}></Overlay>
    )}
    {gameInit && (
      <Overlay closeIcon={'Let’s play'} onExit={startGame} text={'This mode is the only one in which you have time limitations. So hurry up. Indeed you have two minutes to get the highest score possible. Every game you will get 10 random players from the top 75 players of all time and you have to get all the teams this 10 legends played for. To help you there’s a pop-up that defines how the name of the team evolved during the years. ABA teams are not taken into considered.'}></Overlay>
    )}
    </div>
    );
}

export default Top