import React from 'react'
import Picture from './assets/basketballcourt2.jpg'
import Landscape from './assets/jc-gellidon-XmYSlYrupL8-unsplash.jpg'
import { player } from '../Players';
import { useState, useEffect } from 'react';
import Basketball from './assets/basketball-svgrepo-com.svg'
import Overlay from './Helpers/Overlay';
import { teams } from '../Teams';
import '../style/Teams.css';
import { useAuth } from '../context/AuthContext';
import { auth, firestore } from '../Firebase';
import { doc, updateDoc } from 'firebase/firestore';
import Right from './assets/skip-forward.svg'
import Info from './assets/info.svg'

function Teams() {
  const [lives, setLives] = useState(3);
  const [inputValue, setInputValue] = useState('');
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [randomPlayer, setRandomPlayer] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [correctGuesses, setCorrectGuesses] = useState(0);
  const [wrongGuesses, setWrongGuesses] = useState(0);
  const [playerImage, setPlayerImage] = useState('');
  const [guessedTeams, setGuessedTeams] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameInit, setGameInit] = useState(true);
  const [goldenSkip, setGoldenskip] = useState(true);
  const [silverSkip, setSilverSkip] = useState(false);
  const [nrGoldenSkip, setNrGoldenskip] = useState(1);
  const [nrSilverSkip, setNrSilverSkip] = useState(2);
  const [infoGolden, setInfoGolden] = useState(false);
  const [infoSilver, setInfoSilver] = useState(false);

  const { currentUser, userData } = useAuth();

  const [easyFilter, setEasyFilter] = useState(player.filter((player) => player.level === "easy"));
  const [easymediumFilter, setEasymediumFilter] = useState(player.filter((player) => (player.level === "easy" || player.level === "medium")));
  const [mediumFilter, setMediumFilter] = useState(player.filter((player) => player.level === "medium"));
  const [mediumhardFilter, setMediumhardFilter] = useState(player.filter((player) => (player.level === "medium" || player.level === "hard")));
  const [hardFilter, setHardFilter] = useState(player.filter((player) => player.level === "hard"));
  const [extrahardFilter, setExtraHardFilter] = useState(player.filter((player) => player.level === "extra-hard"));

  useEffect(() => {
    generateRandomEasyPlayer();
  }, []);

  useEffect(() => {
    if (randomPlayer) {
      const immagine = randomPlayer.img;
      setPlayerImage(immagine); 
      setInputValue(''); 
      setFilteredTeams([]); 
      setActiveIndex(0); 
      setGuessedTeams([]);
      setSilverSkip(false);
    }
  }, [randomPlayer]);

  useEffect(() => {
    if (playerImage) {
      console.log("Updated playerImage:", playerImage);
    }
  }, [playerImage]);

  const startGame = () => {
    setGameInit(!gameInit);
  }

  const generateRandomPlayer = () => {
    const randomIndex = Math.floor(Math.random() * player.length);
    setRandomPlayer(player[randomIndex]);
  };

  const generateRandomEasyPlayer = () => {
    const randomIndex = Math.floor(Math.random() * easyFilter.length);
    setRandomPlayer(easyFilter[randomIndex]);
  };

  const generateRandomEasyMediumPlayer = () => {
    const randomIndex = Math.floor(Math.random() * easymediumFilter.length);
    setRandomPlayer(easymediumFilter[randomIndex]);
  };

  const generateRandomMediumPlayer = () => {
    const randomIndex = Math.floor(Math.random() * mediumFilter.length);
    setRandomPlayer(mediumFilter[randomIndex]);
  };

  const generateRandomMediumHardPlayer = () => {
    const randomIndex = Math.floor(Math.random() * mediumhardFilter.length);
    setRandomPlayer(mediumhardFilter[randomIndex]);
  };

  const generateRandomHardPlayer = () => {
    const randomIndex = Math.floor(Math.random() * hardFilter.length);
    setRandomPlayer(hardFilter[randomIndex]);
  };

  const generateRandomExtraHardPlayer = () => {
    const randomIndex = Math.floor(Math.random() * extrahardFilter.length);
    setRandomPlayer(extrahardFilter[randomIndex]);
  };

    const handleFormSubmit = (e) => {
      e.preventDefault();
      if (filteredTeams.length > 0) {
        handleSelectTeam(filteredTeams[activeIndex]);
      }
    };

    /*
    const handleSelectPlayer = (player) => {
      setInputValue('');
      setFilteredTeams([]);

      // generateRandomPlayer();
  
      if (randomPlayer.firstName === player.firstName && randomPlayer.lastName === player.lastName) {
        setCorrectGuesses(correctGuesses + 1);
      } else {
        setLives(lives - 1)
        setWrongGuesses(wrongGuesses + 1)
      }
  
      if (correctGuesses >= 0 && correctGuesses <= 4) {
        generateRandomEasyPlayer();
      } else if (correctGuesses >= 5 && correctGuesses <= 6) {
        generateRandomEasyMediumPlayer();
      } else if (correctGuesses >= 7 && correctGuesses <= 9) {
        generateRandomMediumPlayer();
      } else if (correctGuesses >= 10 && correctGuesses <= 13) {
        generateRandomMediumHardPlayer();
      } else if (correctGuesses >= 14 && correctGuesses <= 16) {
        generateRandomHardPlayer();
      } else if (correctGuesses >= 17 && correctGuesses <= 20) {
        generateRandomExtraHardPlayer();
      } else {
        generateRandomPlayer();
      }
    };*/

    const handleSelectTeam = (team) => {
      const playerTeams = randomPlayer.teams.map(t => `${t}`);
      
      if (playerTeams.includes(`${team.city} ${team.name}`)) {
        setGuessedTeams(prevGuessedTeams => {
          if (!prevGuessedTeams.includes(`${team.city} ${team.name}`)) {
            const updatedGuessedTeams = [...prevGuessedTeams, `${team.city} ${team.name}`];

            if (nrSilverSkip > 0) {
              setSilverSkip(true);
            }

            if (updatedGuessedTeams.length === playerTeams.length) {
              setSilverSkip(false);
              setCorrectGuesses(prevCorrectGuesses => {
                const newCorrectGuesses = prevCorrectGuesses + 1;
    
                if (newCorrectGuesses >= 0 && newCorrectGuesses <= 4) {
                  generateRandomEasyPlayer();
                } else if (newCorrectGuesses >= 5 && newCorrectGuesses <= 6) {
                  generateRandomEasyMediumPlayer();
                } else if (newCorrectGuesses >= 7 && newCorrectGuesses <= 9) {
                  generateRandomMediumPlayer();
                } else if (newCorrectGuesses >= 10 && newCorrectGuesses <= 13) {
                  generateRandomMediumHardPlayer();
                } else if (newCorrectGuesses >= 14 && newCorrectGuesses <= 16) {
                  generateRandomHardPlayer();
                } else if (newCorrectGuesses >= 17 && newCorrectGuesses <= 20) {
                  generateRandomExtraHardPlayer();
                } else {
                  generateRandomPlayer();
                }
    
                return newCorrectGuesses;
              });
            }
    
            return updatedGuessedTeams;
          }
          return prevGuessedTeams;
        });
      } else {
        setLives(lives - 1);
        setWrongGuesses(wrongGuesses + 1);
    
        if (lives === 1) {
          setLives(3);
          generateRandomPlayer();
          setGameOver(true);
          saveScore();
        }
      }
    
      setInputValue('');
      setFilteredTeams([]);
      setActiveIndex(0);
    };
    

    const handleInputChange = (e) => {
      const query = e.target.value.toLowerCase();
      setInputValue(query);
  
      if (query) {
        const filtered = teams.filter(team => 
          team.city.toLowerCase().startsWith(query) || 
          team.name.toLowerCase().startsWith(query)
        );
        setFilteredTeams(filtered);
      } else {
        setFilteredTeams([]);
      }
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => 
          (prevIndex + 1) % filteredTeams.length
        );
      } else if (e.key === 'ArrowUp') {
        setActiveIndex((prevIndex) => 
          (prevIndex - 1 + filteredTeams.length) % filteredTeams.length
        );
      } else if (e.key === 'Enter' && filteredTeams.length > 0) {
        e.preventDefault(); 
        handleSelectTeam(filteredTeams[activeIndex]);
      }
    };

    const skipGolden = () => {
      generateRandomEasyPlayer();
      setNrGoldenskip(0);
      setGoldenskip(false);
    }

    const skipSilver = () => {
      generateRandomEasyPlayer();
      if (nrSilverSkip === 2) {
        setNrSilverSkip(1);
      } else if (nrSilverSkip === 1) {
        setNrSilverSkip(0);
      }
      setSilverSkip(false);
    }

    const saveScore = async () => {
      if (currentUser) {
          const userDoc = doc(firestore, 'users', currentUser.uid);
          try {
              await updateDoc(userDoc, {
                  game1: [...(userData.game1 || []), correctGuesses],
              });
              console.log('Punteggio salvato:', correctGuesses);
          } catch (error) {
              console.error('Errore durante il salvataggio del punteggio:', error);
          }
      }
    };

    const refresh = () => {
        window.location.reload();
    }

  return (
    <div>
      <div style={{ 
        backgroundImage: `url(${Landscape})`, 
        backgroundSize: 'cover',
        width: '99vw',
        padding: '10px'
      }}>
      <div className="max-w-xl mx-auto">
        <form className="mt-10" onSubmit={handleFormSubmit}>
          <div className="relative">
            <input
              type="text"
              className="border-2 border-gray-300 rounded-lg p-2 w-full"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Search teams"
            />
  
            {filteredTeams.length > 0 && (
              <ul className="absolute z-10 bg-white border border-gray-300 w-full mt-1 max-h-60 overflow-y-auto rounded-lg shadow-lg">
                {filteredTeams.map((team, index) => (
                  <li
                    key={index}
                    className={`p-2 cursor-pointer ${
                      index === activeIndex ? 'bg-gray-400' : ''
                    }`}
                    onClick={() => handleSelectTeam(team)}
                  >
                    {team.city} {team.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </form>
        <div className="relative max-w-xl mx-auto mt-10">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-700 rounded-md pointer-events-none"></div>

            {randomPlayer && (
              <div
                className="h-64 w-full object-cover rounded-md opacity-30"
                style={{
                  backgroundImage: `url(${playerImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                alt="Quiz image"
              />
            )}

          <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>

          <div className="absolute inset-0 flex flex-col items-center justify-center space-y-4">
            <div className="w-24 h-24 rounded-full overflow-hidden border border-white">
              <div
                className="w-full h-full object-cover"
                style={{
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: `url(${randomPlayer?.img})`
                }}
                alt={`${randomPlayer?.firstName} ${randomPlayer?.lastName}`}
              />
            </div>

            {/* Nome del giocatore */}
            <h2 className="text-white text-xl font-bold text-center">
              {randomPlayer?.firstName} {randomPlayer?.lastName}
            </h2>
            <div className="bg-gray-800 bg-opacity-60 backdrop-blur-md text-white p-4 rounded-lg shadow-lg w-64 h-22 text-center overflow-y-auto custom-scrollbar">
              <h3 className="text-lg font-semibold mb-2">
                Guessed Teams ({guessedTeams.length}/{randomPlayer?.teams.length})
              </h3>
              <p className="text-sm">
                {guessedTeams.length > 0 ? guessedTeams.join(', ') : 'No teams guessed yet'}
              </p>
            </div>

            <button
              onClick={skipSilver}
              disabled={!silverSkip}
              className="transform transition duration-300 hover:scale-110 mr-3 absolute right-0 text-white p-4 bg-gradient-to-r from-gray-300 to-gray-100 rounded-full shadow-md"
            >
              <img className="w-5 h-5 cursor-pointer" src={Right} alt="Silver Skip" />
            </button>
            <p
              className="text-white absolute right-0 mr-3"
              style={{ top: '40px' }}
            >
              <img className="w-5 h-5 cursor-pointer" onClick={() => setInfoGolden(!infoGolden)} src={Info} alt="Silver Skip" />
              Golden Skips: {nrGoldenSkip}
            </p>

            <button
              onClick={skipGolden}
              disabled={!goldenSkip}
              className="cursor-pointer transform transition duration-300 hover:scale-110 mr-3 top-0 mt-3 absolute right-0 text-white p-4 bg-gradient-to-r from-yellow-500 to-yellow-300 rounded-full shadow-md"
            >
              <img className="w-5 h-5" src={Right} alt="Golden Skip" />
            </button>
            <p
              className="text-white absolute right-0 mr-3"
              style={{ top: '130px' }}
            >
              <img className="w-5 h-5 cursor-pointer" onClick={() => setInfoSilver(!infoSilver)} src={Info} alt="Silver Skip" />
              Silver Skips: {nrSilverSkip}
            </p>
          </div>
        </div>
        
        {/*
        <div className="mt-5">
          <h3 className="text-lg">Wrong Guesses: {wrongGuesses}</h3>
        </div>
        */}
        <div className="flex justify-between mt-10 relative mb-10">
        <div className="imdb-box w-20">
            <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-lg opacity-70"></div>
            <div className="flex flex-col rounded-full p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] w-full h-full items-center justify-center relative z-10">
                <div className="imdb-score text-4xl text-center font-bold text-white">{correctGuesses}</div>
                <div className="imdb-title text-center text-sm text-gray-300">SCORE</div>
            </div>
            </div>
        </div>
        <div className="imdb-box w-30">
            <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-full blur-lg opacity-70"></div>
            <div className="flex flex-col rounded-full p-4 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] w-full h-full items-center justify-center relative z-10">
                <div className="flex justify-center items-center mt-2">
                {Array.from({ length: lives }).map((_, index) => (
                    <img key={index} src={Basketball} alt="Basketball" className="w-8 h-8 mx-1" />
                ))}
                </div>
                <div className="imdb-title text-center text-sm text-gray-300">LIVES</div>
            </div>
            </div>
        </div>
        </div>
      </div>
      </div>
      {infoGolden && (
        <Overlay onExit={() => setInfoGolden(!infoGolden)} text={'Simple tool to use when you don’t know some players’ teams, You just have one, Make sure you don’t waste it'}></Overlay>
      )}
      {infoSilver && (
        <Overlay onExit={() => setInfoSilver(!infoSilver)} text={'It’s a particular skip that you can only use for specific circumstances: 1) the player has played in at least 3 teams 2) you have guessed most of the teams but struggle on some So you can use it if you guess: All but one team for players who played for 3-5 teams All but two teams for players who played for 6-8 teams All but three teams for players who played for 9 or more teams'}></Overlay>
      )}
      {gameOver && (
        <Overlay homeButton={true} closeIcon={'New game'} onExit={refresh} text={`Game Over! Final score: ${correctGuesses}`}></Overlay>
      )}
      {gameInit && (
        <Overlay closeIcon={'Let’s play'} onExit={startGame} text={'The current mode works like this: As you get a player you have to guess all the teams he has played for. By doing that you increase your score and get another player. Do the same thing until you can. As the score gets higher the game will automatically get harder. You have also two tools to help you.(golden skip and silver skip).'}></Overlay>
      )}
    </div>
    );
}

export default Teams