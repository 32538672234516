export const player = [
  {
    firstName: "Trae",
    lastName: "Young",
    teams: ["Atlanta Hawks"],
    level: "easy",
    firstNBAyear: "2019",
    college: "Oklahoma",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfbZQICtrIEOjUvZBD9daXlgORVDtCaNWkceKET0p6_CQN8fiU3w_9_YVpfN6ge8KBqtXLEYpzvdt-_qr3oESDsva8LjxPODRUAPoYA-N9jY-bgtLv_x79j4224E_jN9tAeqqeS9Hj8nSwWOLLX0wDX=w113-h157-s-no?authuser=4",
  },
  {
    firstName: "Bogdan",
    lastName: "Bogdanović",
    teams: ["Sacramento Kings", "Atlanta Hawks"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Serbia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf6ErUiXVvBeVUvBtvIBoRFAdeCUbAYvp39jZxQJqe7VfrgfYlgi2k9mjV08Kikae2tGDhAOIMlp7Y6o3vofl5m6WyKS3_4aw-JsEFBbjmun0wwslH3GFoMa1c5jKGMdTXIG2GltJHPZGoyXEN68bOw=w118-h159-s-no?authuser=4",
  },
  {
    firstName: "Cam",
    lastName: "Reddish",
    teams: [
      "Atlanta Hawks",
      "New York Knicks",
      "Portland Trail Blazers",
      "Los Angeles Lakers",
    ],
    level: "medium",
    firstNBAyear: "2020",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdy6ALY11iJHRhjeHrQ_0kR9-Aa3BO7H0TW8tGbnqecVSd7e6HljFHz1Zk3G-7i9qDoeJMHJD46HYDAdI5OKFuSAoswvl_5tbYTOUKB4d74kuD1FxKJzgiV42lDL7VinmJ8l2YG8R9nYMJUEkg_LDNL=w111-h145-s-no?authuser=4",
  },
  {
    firstName: "John",
    lastName: "Collins",
    teams: ["Atlanta Hawks", "Utah Jazz"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Wake Forest",
    img: "https://lh3.googleusercontent.com/pw/ADCreHculeF52UQ4jG7hqin9Y1COnnhIFo-WQttbnRn8yUerYmJ1zsENrcHVJKsGBl51yCIoZqXqqlqQDi_GtZTiOe83lQQlK-SmuhKg2t-ZbNx6_mmCPXWNswQeQLiaQQNEWvRnvgJm0BkY0vWok8GOgMCg=w113-h146-s-no?authuser=4",
  },
  {
    firstName: "Kevin",
    lastName: "Huerter",
    teams: ["Atlanta Hawks", "Sacramento Kings"],
    level: "easy",
    firstNBAyear: "2019",
    college: "Maryland",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcEAkOHthS97BfjOcDfeiYWsrXLmd3PSVdjnyklJo7mqNdExLdkFbDSFYtiZ8cM7bLbRQD4ezDuzN43ZiwmeL1fSOPo3TG6_YN5EPqujeppRRQzSAefdvpBuQ837r0dgle6hoV0-Gy58AyexRcgz1ot=w109-h133-s-no?authuser=4",
  },
  {
    firstName: "De'Andre",
    lastName: "Hunter",
    teams: ["Atlanta Hawks"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Virginia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHem4r-NYGD3m6akxTwu7luSH7EiX1xcooGPqKWzMmxpFqiMB26wO09VKWGdbCw5zh4i82MlIpTIdLxhqC3pt_JyFGBxXWnLyPpSY7D5o6UPaZ3hQWrxgmP7a6fX4COMQbkmWlS8F8kAZVLd4gzfKTdJ=w120-h147-s-no?authuser=4",
  },
  {
    firstName: "Onyeka",
    lastName: "Okongwu",
    teams: ["Atlanta Hawks"],
    level: "easy",
    firstNBAyear: "2021",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdCli--DNBK95BU-p-4fODU8uSGvLu8Du2qeJoQtno58n9hkgBlvhRS9mwuNZnZFiyqH0KnFzwyc4qeZe-tShy3-zcBPSlUZ_MUyMch3YorR36bkwr3SOR2HlP-AWMdntwcFwUQJdL2mqW7v2nbqAH9=w109-h142-s-no?authuser=4",
  },
  {
    firstName: "Clint",
    lastName: "Capela",
    teams: ["Houston Rockets", "Atlanta Hawks"],
    level: "medium",
    firstNBAyear: "2015",
    college: "Switzerland",
    img: "https://lh3.googleusercontent.com/pw/ADCreHch3e4tOjAe_5k7Rc9F5dqD15FxNQyBi4OXoM8jq7XmPxRv-mChBNzy8lZKhuReb1USRXFSS51Onxcc_vAnDPqy0Gn2onIbQoiNVRLJZuTI_aH95nfScqIJvAPn3twZuN7qGYKBZpptfBk8ozWjMSfp=w115-h145-s-no?authuser=4",
  },
  {
    firstName: "Skylar",
    lastName: "Mays",
    teams: ["Atlanta Hawks", "Portland Trail Blazers"],
    level: "hard",
    firstNBAyear: "2021",
    college: "LSU",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc-yOD-hlae_Ur3Y3A9bd6DGw1y_bVTwNbjpM-s8At8pjyYf39uIdBAAkv5i5cMswUK_sGUliBt_RVZiai2-CWCldbg9UFwVLTznkb-fGSCCNb-6Gapdbcat_g9P1UR1XnzC2ClS_eSUquODxI2Y81n=w114-h142-s-no?authuser=4",
  },
 /* {
    firstName: "Gorgui",
    lastName: "Dieng",
    teams: [
      "Minnesota Timberwolves",
      "Memphis Grizzlies",
      "San Antonio Spurs",
      "Atlanta Hawks",
      "San Antonio Spurs",
    ],
    level: "hard",
    firstNBAyear: "2014",
    college: "Louisville",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeY1cobh4SxavTmO1NWtduhNb2aLnwXGKfXvfbkckNfaqflUN2AbhX_9K82LB-IYCtg8Lks6Q6z4dpruLN-glq3perWGmTwcs97nOyt6WuUkPPPoto7kS5-NDfq21wonSDQz2CGVxFdtLP4jtelz7vw=w108-h147-s-no?authuser=4",
  },*/
  {
    firstName: "Jalen",
    lastName: "Johnson",
    teams: ["Atlanta Hawks"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeiPk_thHmxwtvwHxLaxEx6NuZxOx0TQ941KTGNWFPC7K2daXFu6hpxh3Je0Gg1hhI6svimXG0btOrZh6cJJyMLYhKMA2sR6QzILD6DM5AGNb7Qs3Yk559aa7f4DnpxBV-gfVUA2I2PNI5MtTJ16nym=w114-h140-s-no?authuser=4",
  },
  {
    firstName: "Danilo",
    lastName: "Gallinari",
    teams: [
      "New York Knicks",
      "Denver Nuggets",
      "Los Angeles Clippers",
      "Oklahoma City Thunder",
      "Atlanta Hawks",
      "Washington Wizards",
      "Detroit Pistons",
      "Milwaukee Bucks",
    ],
    level: "medium",
    firstNBAyear: "2009",
    college: "Italy",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfz61BpjZ97OpaP6z8UG-WDN_dkq33e655m7DQ-5fVdOTDg370jgNFlVLxajufbsSSzWlFbGPjpk0gXV_8ZZAOdCo7P_UfXO0xdiRR3t8T5p0qevaMeiqZZlx4yfIn6-LH9cO_-qLDoe3o-VYYhyaTH=w100-h125-s-no?authuser=4",
  },

  {
    firstName: "Bruno",
    lastName: "Fernando",
    teams: [
      "Atlanta Hawks",
      "Boston Celtics",
      "Houston Rockets",
      "Atlanta Hawks",
      "Toronto Raptors",
    ],
    level: "extra-hard",
    firstNBAyear: "2020",
    college: "Maryland",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdVVBIS0AUNYck2oHMvxhjVnvZdJRSVLfjydj3YxgiJZ1PZBCZRQHs1DOJaoioW68HhOyNgbaqWHoDnw5yGOOWKLPLrRav_mDjZNVLUHhpjwcctbL7x1HflDdChmCgvOyeUpOyFnAv4WbiOF8Dk5CkG=w107-h133-s-no?authuser=4",
  },

  {
    firstName: "Kris",
    lastName: "Dunn",
    teams: [
      "Minnesota Timberwolves",
      "Chicago Bulls",
      "Atlanta Hawks",
      "Portland Trail Blazers",
      "Utah Jazz",
      "Los Angeles Clippers",
    ],
    level: "extrahard",
    firstNBAyear: "2017",
    college: "Providence",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfWnqmi07yHffiWshfa5D57ezHWUV-Yl_-IozwZdFGwhhmvP5tnvw8vXs7iElFp1aJ_dVrazdY9aX1so_s4XWUKxo1x-6H4ecDdxvJwmr5NuLq_AIiMALew3hwzkHiBAeJ4_gyTOXiYHU0Lpic9aFve=w113-h139-s-no?authuser=4",
  },
  /* {
    firstName: "Nathan",
    lastName: "Knight",
    teams: ["Atlanta Hawks", "Minnesota Timberwolves"],
    level: "hard",
    firstNBAyear: "2021",
    college: "William & Mary",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdNc9lkgzlMa_PkTEBL8fHBIhUfvMSKB46X3rd0vkRM8HH6YxSTMlQNQ9YoZ0PahDQKO6BN-NUoW-BlAjf2J4F5AZciMpue9ewtFTM1L06-arFdBxefpVePYo6ekxefWxBAmlYoYEIXoZeO7vU_S8P9=w115-h147-s-no?authuser=4",
  },*/
  {
    firstName: "LaMelo",
    lastName: "Ball",
    teams: ["Charlotte Hornets"],
    level: "easy",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHctnwM94ZeFbW98p2jwH60XQUO7vKkfUXb3glwo9EsceRyf62c_kFcXxqxPurpNnbtMmV0x1ni4l4GCrBX0w3Apo1WceeVwGnQtr7kZqg0OKUk5RtEcHM5hK6v5y5vXTBtvyFd_06nOZw0qWaB4R0ID=w122-h147-s-no?authuser=4",
  },
  /*  {
    firstName: "Gordon",
    lastName: "Hayward",
    teams: ["Utah Jazz", "Boston Celtics", "Charlotte Hornets"],
    level: "easy",
    firstNBAyear: "2011",
    college: "Butler",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeUn9HsGnRpWTGeOFNcn_BHHdLtF6WgyQaFB2bzmOzUFzk-okVOzsr6d3CDvu2ck70dQIx6wheSE-glQZsZjV5UQzoQQVaSjRoc5aoCWrKd2-1k4cYiEEfCGNv49-SRsd4PiIY7GWnxoJb1wqkZoWy7=w119-h133-s-no?authuser=4",
  },*/
  {
    firstName: "Miles",
    lastName: "Bridges",
    teams: ["Charlotte Hornets"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdydnTokVvxlIyTq1uXu5ZHm7dQLUJ95zDfV1yMMQuOvO3wfi4boCGGF5k81dPJ7MGAV1-yV7gvR4lxfqmzFecRbOfxGu-aaHOOr4pcnLEZ4bScveeP5ClKQyAsY5OenclfiAnySeMhXF9NKn54GyAW=w116-h123-s-no?authuser=4",
  },
  {
    firstName: "P.J.",
    lastName: "Washington",
    teams: ["Charlotte Hornets", "Dallas Mavericks"],
    level: "medium",
    firstNBAyear: "2020",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeoOV6YXyp70fGEk2zt2VGqNRzZsHtO8zmd3ddFfnA3CnAzwuJ8cuyLu4ibaGD85EYVr_sPRwQRNBuUb2B228lcHUbt1_-_Y3KP_TDbDeB4gr9mHpZq9CeFuhntKS3DegggfUgkhi0JGeTGersgQBZ2=w115-h127-s-no?authuser=4",
  },
  {
    firstName: "Terry",
    lastName: "Rozier",
    teams: ["Boston Celtics", "Charlotte Hornets", "Miami Heat"],
    level: "easy",
    firstNBAyear: "2016",
    college: "Louisville",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe_eayKDojXHnIWaQwysvY-529UvYlWdOq7OdOXpBpZdxSSj89Hr8eDNk4pAQN6uG2MryxUGv9w01FcZlaYmK5mDFWfW5YhHsOqMpL-oOEfYg4ywmUCmne12nqzddKePQKfV2bJ7MQ620IPq6BZff9U=w115-h129-s-no?authuser=4",
  },
  {
    firstName: "Ish",
    lastName: "Smith",
    teams: [
      "Houston Rockets",
      "Memphis Grizzlies",
      "Golden State Warriors",
      "Orlando Magic",
      "Milwaukee Bucks",
      "Phoenix Suns",
      "Oklahoma City Thunder",
      "Philadelphia 76ers",
      "New Orleans Pelicans",
      "Philadelphia 76ers",
      "Detroit Pistons",
      "Washington Wizards",
      "Charlotte Hornets",
      "Denver Nuggets",
    ],
    level: "extrahard",
    firstNBAyear: "2011",
    college: "Wake Forest",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe2QY7UsMXl1BO4-4RiZySYUAX2gSkcTGplRo15ronttHq5ImmxY9vQPg3lrAiELEC0njfzq9sBYcA4cD_ZnJQW4022vqE_3XJIPvTFFMJXGQXKjtzcjdiUOA4pdIJEXM45EIclZAZoo9rprz3QVrdV=w112-h140-s-no?authuser=4",
  },
  {
    firstName: "Cody",
    lastName: "Martin",
    teams: ["Charlotte Hornets"],
    level: "medium",
    firstNBAyear: "2020",
    college: "NC State-Nevada",
    img: "https://lh3.googleusercontent.com/pw/ADCreHekKqO5t3a9Tkzzl5SEQrU8R-f0U0FmBbCPfaq03BoUk4We2oIV6o7BoT_NsAjDUkR0FbzFsCyV2jPkJdEeWldoRBrctHd391EmFReItFxqKXtXhar8w03drWTQDQHMvH-vYEvw2_WAvQaZaaMl2_3-=w112-h132-s-no?authuser=4",
  },
  {
    firstName: "Jalen",
    lastName: "McDaniels",
    teams: ["Charlotte Hornets", "Philadelphia 76ers", "Toronto Raptors"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHet8rHlGTFR99OKAcYB0j_MX39XWsyv6_NN6w0Wj9X_mdJg2puwV3TqLclum2XMVO1k0hwWljeykRJIhDp1Mxp58oV0MKS51QPSIJ6VNI897SDKe8a-WBmwXq1fjkzOFCJd5N7h07FaD43XtI--sgTH=w111-h143-s-no?authuser=4",
  },
  /*  {
    firstName: "Vernon",
    lastName: "Carey Jr.",
    teams: ["Charlotte Hornets", "Washington Wizards"],
    level: "extrahard",
    firstNBAyear: "2021",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd9jYP3BHBPlA45mujg99VbpZHcdWBiKNpqMtS1EeXGqgOw6pS20SFvw0YTQf3oPm2yNpRnGAPE5ptFhlus6dj7KMstfs46d1tG4VQSoSOx9JJtaaROYHUxIwNLRLbmIWLGsMdAiZbtEdqzuRqW-exA=w115-h140-s-no?authuser=4",
  },*/
  {
    firstName: "Nick",
    lastName: "Richards",
    teams: ["Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2021",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHencXd4dIb0o_DDFeHTZZpovvOMZpwSnEY7pfZg0uWYNjD-zmsUvsZ6rfP9DcMCcezXQCvgiycOGbc6jpnCo0LjPHK11U3cgdc9PUAgnjHmNEyJZXDRjcS2tkCkGmV3jcUhshwuUhoMXxw4c2yQdiC_=w117-h134-s-no?authuser=4",
  },
  {
    firstName: "Kelly",
    lastName: "Oubre Jr.",
    teams: [
      "Washington Wizards",
      "Phoenix Suns",
      "Golden State Warriors",
      "Charlotte Hornets",
      "Philadelphia 76ers",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdBM5ZA5XGRgxvi8nT3jx8URwFQm-Uw0-5ct1oDIGyb4rGvnIU6pHIyaMBE5kU1bVmmm7K7BEfeS9Do9a5RP9DdsHZe-xy9WCdiLJpukUK0CDPSzL55yP5igCa0f9vB8jAONqVHmLR-L34fGQC0eVcI=w120-h132-s-no?authuser=4",
  },
  {
    firstName: "Caleb",
    lastName: "Martin",
    teams: ["Charlotte Hornets", "Miami Heat", "Philadelphia 76ers"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcgHmsSqCzMywrgP0CCMTjJLmf_KalKqjGtIykATA4cQvTjssmNXNafpksyiIGRBt_K6cBAjGbVzApB7rPbCsFnC5FZ12gr9Z_BTJUyQ3Ciw028ZpupoIKzpw-nVEv4xtkiIb-Pry9d2MlPyGtgAxRy=w118-h128-s-no?authuser=4",
  },
  {
    firstName: "Mason",
    lastName: "Plumlee",
    teams: [
      "Brooklyn Nets",
      "Portland Trail Blazers",
      "Denver Nuggets",
      "Detroit Pistons",
      "Charlotte Hornets",
      "Los Angeles Clippers",
      "Phoenix Suns",
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd49ZX8EyGuZiP263pN233oZML9L-kLqs2TtKltBk4GCPQTgF8pC0Go5MmWExNM6DX14fn7z9ShUjQSR86usjbh7HLeqFdgzvOxvHqq02QoHpIBqXg3gO8bXAgdJgh1hMkg4GYl1KkLSl419MlQuivr=w112-h147-s-no?authuser=4",
  },

  {
    firstName: "Bam",
    lastName: "Adebayo",
    teams: ["Miami Heat"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdN8e3RDCierOfDmg-IPCv4mVy782UytNvR3OZyPegio0-jV59B6EIqblFIOR6T5BfTuO0OFT06PYYjdcha8qhx0GJWYjJSlr0HVXedhrhi1qYAG2Kz3JIlvuhILMmcUfeMaswmrCdD8CJk2mdsV6Wk=w115-h125-s-no?authuser=4",
  },
  {
    firstName: "Jimmy",
    lastName: "Butler",
    teams: [
      "Chicago Bulls",
      "Minnesota Timberwolves",
      "Philadelphia 76ers",
      "Miami Heat",
    ],
    level: "easy",
    firstNBAyear: "2012",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdScyzXygkxbgJGWIDPaZBiKtZWJQgLjUjt8Hj-gAy0j5rk6UmemtoU9DCSE_RSYxKPDGKu-P2XFlazS5lzzBJgtVpMzag26b8pIKhsjvyIpoHg-bZcOfdSscitAdnkDqmE94zbgQUOe3GVL6lq1Bkq=w111-h148-s-no?authuser=4",
  },
  {
    firstName: "Kyle",
    lastName: "Lowry",
    teams: [
      "Memphis Grizzlies",
      "Houston Rockets",
      "Toronto Raptors",
      "Miami Heat",
      "Philadelphia 76ers",
    ],
    level: "medium",
    firstNBAyear: "2007",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfQLHTy6s0TQkcHDv5qGK8rxtPaj5Tj_b_iyfvWTg-tLWs1Y9ToEYOh8awmB4LlFWwQAGqfl-b_N1gVDZWf4Dr_LEG1162bHqdQ8fa7ROe62suyHUTZVjNlEKFteUgqO5CPOUqm0a54gY5BTgzC2GA6=w117-h146-s-no?authuser=4",
  },
  {
    firstName: "Duncan",
    lastName: "Robinson",
    teams: ["Miami Heat"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeaBy479YNGYMhMjzize5ELp2ayE-audDKzsc549hva4yMSyRFdWzDriAd-DNrlxFAXk996ydhYGCb1Lg5lhxQDMA1fjzsZIuyuaBO4PgM7tnPZ_sKIF_c-1JQODzKaesABfEZ0n5I-dNMTPWMrv3c0=w122-h145-s-no?authuser=4",
  },
  {
    firstName: "Tyler",
    lastName: "Herro",
    teams: ["Miami Heat"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcFp4m6uMZZ_jdTUFN0t3brCHNqDL17SZ1TuZUk-mi1XNYL_7G8vlIOZv8WcHZyDQJdy--kWrVlFSbIDSORd8x4j0bGOQbFi-RbUQ6-5E06YorHcnSwOHiMwJ18G2yVGqvlmDm2nVmBd1_WW8Nu-H4C=w117-h153-s-no?authuser=4",
  },
  {
    firstName: "P.J.",
    lastName: "Tucker",
    teams: [
      "Toronto Raptors",
      "Phoenix Suns",
      "Toronto Raptors",
      "Houston Rockets",
      "Milwaukee Bucks",
      "Miami Heat",
      "Philadelphia 76ers",
      "Los Angeles Clippers",
    ],
    level: "extrahard",
    firstNBAyear: "2007",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdfwRcrWnRlvBg6WSOiyhnVtpFkcBmUOUP94Ms6yDMTRKofa4BzdTps0mtJ1RuhXVJvBVP2JGR59ekRaOmoYY2qsheGm3jKlAI3J6leDZyocICTcfU2Sr4qz_UvBn7uxzLZyEQhVLb8qFBXXuzzeB0z=w115-h138-s-no?authuser=4",
  },
  {
    firstName: "Max",
    lastName: "Strus",
    teams: ["Chicago Bulls", "Miami Heat", "Cleveland Cavaliers"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcgbF70IgwFp1PnahJl4SCkUaCC08ZS2dsEdGOJHJA7nxyggUk8bp4WOZlFweqtq2KK4pvT29xidANfKBlQQoEhO8EBMJJ3JjNIhqcofgIxwrJPrXwScRJmAQL8Fi5gi_AtRHArQHrQiPR1ZDm15A5z=w117-h145-s-no?authuser=4",
  },
  /* {
    firstName: "Dewayne",
    lastName: "Dedmon",
    teams: [
      "Golden State Warriors",
      "Philadelphia 76ers",
      "Orlando Magic",
      "San Antonio Spurs",
      "Atlanta Hawks",
      "Sacramento Kings",
      "Atlanta Hawks",
      "Miami Heat",
      "Philadelphia 76ers",
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfprG2zprs9u69xf5Cnlt-deiA3xem24i5ol6jGAq4NZgB97_48lXoHjSv5m5GnUFz4RlVr7KVCryZk2eZGawsCxiz_0JiF5HZRaFkm17JZ5EGUUYjsOjir00quFJSs8wClBlhl4mb7_RpGLq5eSP2F=w112-h139-s-no?authuser=4",
  },*/
  {
    firstName: "Omer",
    lastName: "Yurtseven",
    teams: ["Miami Heat", "Utah Jazz"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfv-1BhXemW9o4kJvcWwlHoaLzvINq94GhV9k3XmRFZpp0EA3eWZsbhLPA8M6TL-qKW4qA7dofXP36yeLgEuXBSZW-AgW90UNs1MSIPaTWUB2d3TUsAdk7iyLkAK5kkswyVqrEb0EiHrxWc-VG3xQ2R=w108-h141-s-no?authuser=4",
  },
  /* {
    firstName: "KZ",
    lastName: "Okpala",
    teams: ["Miami Heat", "Sacramento Kings"],
    level: "hard",
    firstNBAyear: "2020",
    college: "Stanford",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfzCriMWiE_JFSN7Ya2HHx9B1DOerBZlw3dlxAUVRL_J7wEiw-7NBDDOTY6bwuhNDfveVSx2D5bMretjxUnfHI1r1NnigLnsj8fWNQUpDff1QfBgP4O7AgujepDKDUHHF0sfvX-KpwOOF0JlAKS1vK1=w113-h141-s-no?authuser=4",
  },
  {
    firstName: "Goran",
    lastName: "Dragić",
    teams: [
      "Phoenix Suns",
      "Houston Rockets",
      "Phoenix Suns",
      "Miami Heat",
      "Toronto Raptors",
      "Brooklyn Nets",
      "Chicago Bulls",
      "Milwaukee Bucks",
    ],
    level: "extrahard",
    firstNBAyear: "2009",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfE5JCSUQ3RYh9LDbXmrEAbaGtxgtazy1diYRZjT9WZ2MXgAansGWbAyKmSFP5GphatHpNMu2rgCya0yckif6XSpZbeESuauIN1ramTRt5DIIsRR-Za1ex_mG7LVtS_6hZs7gFvoFvssZU0-olsBUHZ=w101-h138-s-no?authuser=4",
  },
  {
    firstName: "Micah",
    lastName: "Potter",
    teams: ["Detroit Pistons", "Utah Jazz"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe5jSU1iT43wNQW0nlMumORJJvYYYwGTzEGIYLDamhWT29ok6YhTtQw_DvYqkF4HzJGBVA-EXWwiYVakqmWMvs0Okpkp92DYKqZ0nkvSnAJWZbWpXHyP8USptKzfJBSmdYxbz7QS_ZRDdNHDv-m3HdT=w115-h140-s-no?authuser=4",
  },*/
  {
    firstName: "Gabe",
    lastName: "Vincent",
    teams: ["Miami Heat", "Los Angeles Lakers"],
    level: "easy",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe1Kv3phciASc1Yj4UOB8nj7mJJDHqp8-0cQwtt5rkOS_d3JRbMfvE9YeonlTVOSGAq9m0cO-pt-dFFVXD3CodM8z14hf5Clr6r0GAOjwd8LJX0Z1G2eAxM65PKvfjzQflGDcL34bwVE7d3ya_0QMCA=w110-h136-s-no?authuser=4",
  },
  {
    firstName: "Davion",
    lastName: "Mitchell",
    teams: ["Sacramento Kings", "Toronto Raptors"],
    level: "medium",
    firstNBAyear: "2022",
    college: "Baylor",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdYZfC5A9ZPWns9CPhHGZAxAicIXgV30AyaOP9pXBeDA9zJ5r9BJE7wXFzNCTjNa4uW7iJIsEAiDRctuoAxkBQbeB8j6c2vRsqa8Vp0g5RbGD545jI0SNob0tdv1DKsmxMNtL265KcUboxvTP_WKdIP=w116-h143-s-no?authuser=4",
  },
  {
    firstName: "Bradley",
    lastName: "Beal",
    teams: ["Washington Wizards", "Phoenix Suns"],
    level: "easy",
    firstNBAyear: "2013",
    college: "Florida",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeVCUq3zOZXIYmmZCJptCrjg5_J7jF0SlV7NVBadRt40i-nBSOgm4D0bvJ5RwXe6HHkeNVNz-Sp6n8Ga_Gx7Uy5GZeGIBIeKyloDT4SHwaM8waMNp2pTptk1T79n6WDzYeDC1RPgr43RpID7lAydffY=w109-h137-s-no?authuser=4",
  },
  {
    firstName: "Spencer",
    lastName: "Dinwiddie",
    teams: [
      "Detroit Pistons",
      "Brooklyn Nets",
      "Washington Wizards",
      "Dallas Mavericks",
      "Brooklyn Nets",
      "Los Angeles Lakers",
      "Dallas Mavericks",
    ],
    level: "hard",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfZZDijNpwg1Tfz5wfEojP9tb-EClF-uxCnqD9Mic3ufscAxY9YD0O9HQ6i-HA5Hh1orSSPaNFrTscucfocexsWbhiSGPmjg_DpAIL9P0eQH-d-A9h5ppZOnY78fhAaq83B0gILy5FemS-jT6ZsUyeS=w112-h137-s-no?authuser=4",
  },
  /* {
    firstName: "Montrezl",
    lastName: "Harrell",
    teams: [
      "Houston Rockets",
      "Los Angeles Clippers",
      "Los Angeles Lakers",
      "Washington Wizards",
      "Charlotte Hornets",
      "Philadelphia 76ers",
    ],
    level: "extrahard",
    firstNBAyear: "2016",
    college: "Louisville",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdVbQvV_61uet00VUVrOJtXwKZj_Q-ZevB9cdsO9kVExON2FrzD1jpTLGetGQryEkjE20LvZT5lC2eziuJJFV3B7E_LreCJVSYlrMvzt-VkhfH8cPnmcmNaxq_XLd4VDYx_U-R1aFMRaw3tHfIY93pS=w107-h159-s-no?authuser=4",
  },*/
  {
    firstName: "Kyle",
    lastName: "Kuzma",
    teams: ["Los Angeles Lakers", "Washington Wizards"],
    level: "easy",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeqJqT2Z98We_wddjGGchYRZ6hsJErXonSU64wcOhetRK6fsU9rSQ0Ktsby7E-xSJlgC29F04rrPtuJeyvD4-5U_sCIKN-yWkl6CXqMQ4QbdnSBZPpTgUUrNb8rqI5K6tE7pKWTOFqyTa8RN9UrKktt=w109-h134-s-no?authuser=4",
  },
  {
    firstName: "Keldon",
    lastName: "Johnson",
    teams: ["San Antonio Spurs"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc3C2U56wXeQmzCuve6rN8K8PLVicBBlfqtKQnfalU0XfC76gJmwyiV-F0QR8ndm3KYbVXHSVNwrPQAkUwQMGTAW8LA0yTgPjo32N6C_H_EV3yAuqxamoy-xTThOQiPqV9IscTb_u-2xN-K7044nQch=w109-h138-s-no?authuser=4",
  },
  {
    firstName: "Rui",
    lastName: "Hachimura",
    teams: ["Washington Wizards", "Los Angeles Lakers"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdGv4xipPlndOCs-I7APu0YCzXARYj_jXbjLIlggENHrZtp5BWSzd_76NdfrlwOvS_CAd03zshjIPkkJ9R_DJcxgET8ij4vcJTO5-__CPt54YFEEfPC5fbrDV0FwLBulS_0RPN24MpoXozXIbJ_WuBv=w114-h138-s-no?authuser=4",
  },
  {
    firstName: "Daniel",
    lastName: "Gafford",
    teams: ["Chicago Bulls", "Washington Wizards", "Dallas Mavericks"],
    level: "medium",
    firstNBAyear: "2020",
    college: "Arkansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf7G4Cecu6fRb-FDET1MD81ff-NTK-zKrP7o4pjVRFw8Yku5Dn0qadWjOuPeDUYqY5LlMag8t8OcGeUUfJbtYd-mm7FBH3HZgQ7REj3xTRyeGMWHv2pXTtSFJ2tu_zSvoG_5WEpFrG3FdHmn-Hspi-a=w109-h140-s-no?authuser=4",
  },
  {
    firstName: "Davis",
    lastName: "Bertans",
    teams: [
      "San Antonio Spurs",
      "Washington Wizards",
      "Dallas Mavericks",
      "Oklahoma City Thunder",
      "Charlotte Hornets",
    ],
    level: "medium",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdqxkGOPHUFmGOyLe9rC46yzaQUTX2I8s97Oz4cRzX6qvlx9FpXBP2BbDJ_j9GZ8-1ehre_arhWT9iSGtug-hw9I3tfE638Dgs2FxB8JFZo2TM8Q6f1YyJytcL7UlRwaSTNVX9UOXjKtFbIein8zP8X=w103-h137-s-no?authuser=4",
  },
  {
    firstName: "Thomas",
    lastName: "Bryant",
    teams: [
      "Los Angeles Lakers",
      "Washington Wizards",
      "Los Angeles Lakers",
      "Denver Nuggets",
      "Miami Heat",
    ],
    level: "hard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfKQ0uh6oKFOAAAOykOEao99vhmy8LUwVxp1VnKvCmv8wexUQG1kXN-_GJ5yCnyCC29Iodi6OO1Z1wK_KbzE6cVUGwqSXrIfAFsM3eiYLgLvoY2C4HKfVDizaXYUcsmeI7ciL0yEKt2NpKAER5vsbrB=w106-h130-s-no?authuser=4",
  },
  {
    firstName: "Corey",
    lastName: "Kispert",
    teams: ["Washington Wizards"],
    level: "medium",
    firstNBAyear: "2022",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcLgNtcrNmWbF9M_-NZ-S_uQyVIPXaQA7WLx4_y2kWN3kZu5PdWVHaaHOBuKIhr63Je85uRgtO5FUqU59NAqi_1wdzBZAu0lV4hlfPUqnvmA9BqfrySwC5NfGGUQkZ2uzxrKPzpa3lrmLcK7PZYtWoo=w115-h145-s-no?authuser=4",
  },
  {
    firstName: "Deni",
    lastName: "Avdija",
    teams: ["Washington Wizards", "Portland Trail Blazers"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdoqDAUmVyt7fQ5iyCqGxc9YInW6rBZaZKPAqG_p9uabIbrydJxCBWJNf_2D4tTT2RmRyjhYVwOuAKAAopxs9vptaOYOyVYUo-gn4UhuaBSPEb_0iFLqej7MvoRDoGQN5o7Y6unfVUxzEOIIcfHrh38=w109-h151-s-no?authuser=4",
  },
  /* {
    firstName: "Raul",
    lastName: "Neto",
    teams: [
      "Utah Jazz",
      "Philadelphia 76ers",
      "Washington Wizards",
      "Cleveland Cavaliers"
    ],
    level: "hard",
    firstNBAyear: "2016",
    college:"",
    img:""
  },*/
  {
    firstName: "Kentavious",
    lastName: "Caldwell-Pope",
    teams: [
      "Detroit Pistons",
      "Los Angeles Lakers",
      "Washington Wizards",
      "Denver Nuggets",
      "Orlando Magic",
    ],
    level: "medium",
    firstNBAyear: "2014",
    college: "Georgia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHePjmcczpey9UT2_zmPAEUUhF5jYfskF-t9VM_ULqbfC6Zx14UARvmGBcTLvIhRU8doQMWmbbW7dqTxdJ3rip5bo1Gaocu2C0Hqoysc2UEM0Mef1CWTgHO9nuPWRyeWBWhlfHgkyDHcdB9uFT12y2Qk=w112-h142-s-no?authuser=4",
  },
  {
    firstName: "Anthony",
    lastName: "Gill",
    teams: ["Washington Wizards"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe63LeGYc0twHrEIVMsAXL0wsSwXQR3H22jTPz7YV2a0PbXA_p-bPHhfP913url1t2cNlE8vDI1g4zhRXbC2FtTqUgTm0n0ZnV0-1frcwvuqw4UmG9oWP9q035385Mx84r5g9eBCIICoe57jSXx8rG9=w115-h151-s-no?authuser=4",
  },
  /*  {
    firstName: "Jordan",
    lastName: "Goodwin",
    teams: ["Washington Wizards", "Phoenix Suns"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdFcyXmbYb9VmUpyAVMTSf2_jAnvtZjFwM0OoOz-kuaTuOEUYmGxM8Gd_BhZ4Rj2lOl1-TwZ8cehEd-0o3wHdN0iZYw30ft8A1LmsKCSmN08O_Rn8H-g7CuSr8_598ghEi1s2MzMHpRM9_SEZSoPA66=w117-h151-s-no?authuser=4",
  },*/
  {
    firstName: "JaVale",
    lastName: "McGee",
    teams: [
      "Washington Wizards",
      "Denver Nuggets",
      "Philadelphia 76ers",
      "Dallas Mavericks",
      "Golden State Warriors",
      "Los Angeles Lakers",
      "Cleveland Cavaliers",
      "Denver Nuggets",
      "Phoenix Suns",
      "Dallas Mavericks",
      "Sacramento Kings",
    ],
    level: "extrahard",
    firstNBAyear: "2009",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHczu0u9GuK9-LDqzMYrQ5NuZXywIMKVQUJK5SciFK9c_bhj4f-jsVOBnihLCRo3F_XdFTc7wLapjMExv26mKElShHAYiKSRk9hjEbITgodgT1uMjLFe6mThgIntBfMxhC3XLKQxNAOn7CNUt9Hxg8p1=w107-h138-s-no?authuser=4",
  },
  {
    firstName: "Garrison",
    lastName: "Mathews",
    teams: ["Washington Wizards", "Houston Rockets", "Atlanta Hawks"],
    level: "extrahard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfUDhKzrJ1NSwqU1D6hVZXqZwHaSg601egzFWUCCf73gV316GXaIz-L5eGa59_NwhPHgpvs71aX2OgV4wLt8WwNuQb5BEKWTjJgMuHL0bBjdm6xw_qZof9Vf8e_MtwCmBLciYHbOR56lbHTroCTnlwe=w115-h147-s-no?authuser=4",
  },
  {
    firstName: "Markelle",
    lastName: "Fultz",
    teams: ["Philadelphia 76ers", "Orlando Magic"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Washington",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc4nybKv0YM8LPbhvDL8ecO6pwPjS-5IQsJquKHUKpnMAlrDCzV9208zg_Fl9t4jkh8C98Je-xsoP_zOXFawIdfInRR2eKfui2q9bVscdNQRCrXv8r_juS-dShXWgBYCaJj2oeLA1o2q0xZqkp0zTI0=w117-h145-s-no?authuser=4",
  },
  /* {
    firstName: "Terrence",
    lastName: "Ross",
    teams: ["Toronto Raptors", "Orlando Magic", "Phoenix Suns"],
    level: "medium",
    firstNBAyear: "2013",
    college: "Washington",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdw20fnUvnxJYwg4SXM4GAes5Oin8zIPeBOpkX1ANkqEv9GoMThOhIkcp8rKlepzLyoFTUaEcsGqzFucqKbO_OQf_ItcLwv3uZXSsHPcuqBsnz9SE-MAFdKKN87yuoaIBNelf2eo2f-plgnl8n5vRvF=w98-h131-s-no?authuser=4",
  },*/
  {
    firstName: "Wendell",
    lastName: "Carter Jr.",
    teams: ["Chicago Bulls", "Orlando Magic"],
    level: "medium",
    firstNBAyear: "2019",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfVWxDnmxx_ODjr9dPz3CHNuyzq-MvozrR0DNNwzkZTsOIjJ-453Yc-ENOSaT5PY5wLWAiVk3XPkkNKzebAllGRi6bXrUrez5aG0263pWjnK6gm4JMAOI4lAtQzeB9DxnZ65MKS7tiN-22VVt-yZWY6=w114-h143-s-no?authuser=4",
  },
  /* {
    firstName: "Chuma",
    lastName: "Okeke",
    teams: ["Orlando Magic"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeK6AH_h_n1odHwIcrH0f-Rzva5J3jD4CPJRX1o2UX_6rgrxeZTUpdj_tdg6GSgME9rhMH2SHzQlkm2oCy6bUmWJ72uT7DePTgD3HJPFnfLz95KNiN83CKIayZXKNbSRpSH36f-oikEXJxTUTwlQ2ui=w112-h138-s-no?authuser=4",
  },*/
  /*  {
    firstName: "Mohamed",
    lastName: "Bamba",
    teams: ["Orlando Magic", "Los Angeles Lakers", "Philadelphia 76ers"],
    level: "easy",
    firstNBAyear: "2019",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcoJBS2mZAU8A5jRW8aknHjE-6BOKm3DTrnNmqyY4DB5kebTx_vCVBZcqT51lWPcYbOp7J4kieSQHw7IT7IcvHamRnYAvQcXyTgyYMGzCE0hYg1_68ErseLZHar4SBN7_kS-lLiSlDknMt9aFYcQiga=w115-h143-s-no?authuser=4",
  },*/
  {
    firstName: "Gary",
    lastName: "Harris",
    teams: ["Denver Nuggets", "Orlando Magic"],
    level: "easy",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfUpwtu7Fbfp40J8_p7MqPRKunSVtrn8ggydRrLc0kJ4bPtz_7islYBGucxQCjCIRHi9RlnJWT3pEe0Y82-Zr6wMn7Ur6tbnvsb9aqCCmvX02SQQwFyg1FZpDiSe1SP6zHCvTxxXPjODnYWqC7W3OF5=w110-h138-s-no?authuser=4",
  },
  /*{
    firstName: "Michael",
    lastName: "Carter-Williams",
    teams: [
      "Orlando Magic",
      "Philadelphia 76ers",
      "Milwaukee Bucks",
      "Chicago Bulls",
      "Charlotte Hornets",
      "Houston Rockets"
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    img:""
  },*/
  {
    firstName: "Robin",
    lastName: "Lopez",
    teams: [
      "Phoenix Suns",
      "New Orleans Pelicans",
      "Portland Trail Blazers",
      "New York Knicks",
      "Chicago Bulls",
      "Milwaukee Bucks",
      "Washington Wizards",
      "Orlando Magic",
      "Cleveland Cavaliers",
      "Milwaukee Bucks",
    ],
    level: "exytahard",
    firstNBAyear: "2009",
    college: "Stanford",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd5CHTZpVeL2PSyC_cv16Y2zDltLE0xMJYpvB2ZZQnCIqUcz7MOsRpaGs7m6Crc2tYwIkZAS8Mfht0V9VeqA9HuHj6mFcUKdbau3do6_2Fwu2bJpx_Kir0NIw4MwV8JxmPM9MD8jh4qpGj4hoV1Z4N5=w115-h144-s-no?authuser=4",
  },
  {
    firstName: "R.J.",
    lastName: "Hampton",
    teams: ["Denver Nuggets", "Orlando Magic", "Detroit Pistons", "Miami Heat"],
    level: "hard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe0qOsB-P3wl7CT0R5V5ktpEGxkMlHlMIVmA__ZEkbJRGnBAZQcjNLHVgLWVXasakKmdgI8l8qFRaW1UduMHb8BG3D2V_lJm2cVwiJ9shwxc0ax9vhBobFFjWF-jYZTj0skjxMkep0ppl-ETM9ADSNL=w101-h138-s-no?authuser=4",
  },
  {
    firstName: "Cole",
    lastName: "Anthony",
    teams: ["Orlando Magic"],
    level: "easy",
    firstNBAyear: "2021",
    college: "UNC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdpE6v2ZQH2cBiaMjCyJPSTcJwqLlA-5LMyGO9rp1OZ4ph85meFXlaaPR5kzrHzEYzA2ahg8rZMNbbFXA58eOKCErqAEmmFSS1Z60eCbC_N2lMVqhEbVBB8GnszA-j69Y0xricPcuY9a3gLQur51sSs=w114-h139-s-no?authuser=4",
  },
  {
    firstName: "Jalen",
    lastName: "Suggs",
    teams: ["Orlando Magic"],
    level: "easy",
    firstNBAyear: "2022",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfsSx4csUwQnF7AsK6heu1C3RS2Z16o4S3AA1Z68kE6l18st6UMTDaF467e_y3N4cI_jmKu32FbPQ9wmTftY3guqrNRNpWXgHqaPNx0mStpXz7R8MDuHxImif9oWhK0S790Ux90wcknuldHA1jCUOIs=w110-h142-s-no?authuser=4",
  },
  {
    firstName: "Anfernee",
    lastName: "Simons",
    teams: ["Portland Trail Blazers"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfK1YcaZxldN2bIEpnVRJzhDhRvWwEXemFQmZd2HvPC4dDV-BxuujNzxyGZ2UDuxU3vjIRIRu3kYyqaOQBh-I2gs5ZkKK59yN0YN_ZW1ARMJkZ1lGvTC-O499lYxSnZCY2jJvTy-BKzuI3zcnOfAjF3=w114-h139-s-no?authuser=4",
  },

  {
    firstName: "Jayson",
    lastName: "Tatum",
    teams: ["Boston Celtics"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd6FJ7UQb9RC3pf5cHXoEEJW8MWn1yqP6Pt-2vqffu7vKrh_WIHAonBs_kERT24m_yDW01e8NrlnzImHoimj36A52I0QNXypp4jVJZcv_KBALYlVNnPLcYmK2mNLBhUkbq6EHEZM0cyoNv1g_peNQh_=w96-h144-s-no?authuser=4",
  },
  {
    firstName: "Jaylen",
    lastName: "Brown",
    teams: ["Boston Celtics"],
    level: "easy",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf3QYz5oCeLP-bXc49J72wJn7l_lSTrsKAGMrewq6FEnaa6HO9LOwWB4oWyRJT0mMQc_A4UlQ4vWX2W6D4ntr6R0_lyP_7GleeKYa_VkvjPm9xajP80WHULzbY73PNLpa8FTFd69H-F-L3dNoB_yPF1=w110-h140-s-no?authuser=4",
  },
  {
    firstName: "Marcus",
    lastName: "Smart",
    teams: ["Boston Celtics", "Memphis Grizzlies"],
    level: "easy",
    firstNBAyear: "2015",
    college: "Oklahoma State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd840DeTtP3-W6oP5jsFoEbJl1woQXQteiKUqe3AoqymxuI22xDVocIdhTLU-eqXB4L6UPrItD4bMpzBmQ8EfITJUaaiJB-JiZx8js8zSPIOA4h8IyiuCmetfZud1CJWN7frpjhYlxxgGAG6fYJ3xEJ=w114-h148-s-no?authuser=4",
  },
  {
    firstName: "Al",
    lastName: "Horford",
    teams: [
      "Atlanta Hawks",
      "Boston Celtics",
      "Philadelphia 76ers",
      "Oklahoma City Thunder",
      "Boston Celtics",
    ],
    level: "hard",
    firstNBAyear: "2008",
    college: "Florida",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeCRV5OUTHYgz9diaqfWLnia4NurXQ__TUoqgKY6n3haZd_r4OlxRq0jXdEbkrhgdnC1LwEuY4SB_jdlqNvtPywHEtsWGTKu36jf6S3lt0UOey4WOVKLIEnKa8PRFAXs-jwzhnBXAuoumtr52B_OVCJ=w103-h131-s-no?authuser=4",
  },
  /* {
    firstName: "Romeo",
    lastName: "Langford",
    teams: ["Boston Celtics", "San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdeac8KWe15Jxu6e7J4uODCbDfcHCawax2RpE0BNXDd6w3rxjBUX9oqrvD1UD2sTcD0Ugb4WWYlbaBzOwpIq1uBdhzx9fSUHhgYVBRYV2uHtZgUOQc4Wz-dljyWCu3s2lYMe1LtRzoFZlI02rualMqT=w108-h143-s-no?authuser=4",
  },*/
  {
    firstName: "Payton",
    lastName: "Pritchard",
    teams: ["Boston Celtics"],
    level: "medium",
    firstNBAyear: "2021",
    college: "Oregon",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdEOsq6HGuASi6Z9bg7KjFFtN4iymTlZrISYIdRGCue9uzBfn6oqs4f4Ryz3q7U4ca0aZ1a_FnzRPRsj9DXQABL33jSGbJrN3ch1vanUeUB7ktBWcYkfVLlGFSkM5WV7Kk36edSLbYZJv3WTWXeyhQE=w112-h135-s-no?authuser=4",
  },
  {
    firstName: "Grant",
    lastName: "Williams",
    teams: ["Boston Celtics", "Dallas Mavericks", "Charlotte Hornets"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Tennessee",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdSbswtqOmgxQWquNcPIGHhFwpxj1CojjZ48hphJVKwl-YQ6AsC-MhuVBS57JFi_Z4T13X9zhTDb70hdaA7zrUAb0dELTvk-V4958-NWFyz0PoWNdISdCyr52hVmMOoCCjXXlEW4xFHLBun4Ky3walU=w111-h133-s-no?authuser=4",
  },
  {
    firstName: "Josh",
    lastName: "Richardson",
    teams: [
      "Miami Heat",
      "Philadelphia 76ers",
      "Dallas Mavericks",
      "Boston Celtics",
      "San Antonio Spurs",
      "New Orleans Pelicans",
      "Miami Heat"
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "Tennessee",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfK-gBbUOi23bMnO8DWMM86lk0VPvelbEVRVspRTSaT0z1GcNzzwVlVMWT3NB7Q0N6yVqHrRgfWyP2poxiNUBK6EUtI-KEmT7ftwHifHQvJGHhEywUQn93dqET3oSn_kpaY7mjyVxayoEvM32AXUd_M=w111-h145-s-no?authuser=4",
  },
  {
    firstName: "Robert",
    lastName: "Williams III",
    teams: ["Boston Celtics", "Portland Trail Blazers"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdulNLzRD75_AcipberDhOQ9zr6Npqe61UoHmf3mY__3ii4baF69I7ihYFrijcji-VAeznkcFhuWhXmghgsi4HrACXmMYl9VofGdKc2_y5EIjvIQMKgqCZzRVSflVE0B6Do092_cq6YqkDGsCfOakVz=w116-h135-s-no?authuser=4",
  },
  {
    firstName: "Dennis",
    lastName: "Schroder",
    teams: [
      "Atlanta Hawks",
      "Oklahoma City Thunder",
      "Los Angeles Lakers",
      "Boston Celtics",
      "Houston Rockets",
      "Los Angeles Lakers",
      "Toronto Raptors",
      "Brooklyn Nets"
    ],
    level: "hard",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc62c0K_m2ymGo8aVKfzgedUoIyMggY6I8Y-6JSi55feMXulHIFoOyU08zrNRN_nTse-BGPlrRsFZHHblO9bwortDpUUbKcyVLVQMQQeg-0XvLiwkSpVDXXevtwnal_LKClopde6_GrZmh3h5KQqh50=w97-h147-s-no?authuser=4",
  } /*
  {
    firstName: "Juancho",
    lastName: "Hernangómez",
    teams: [
      "Denver Nuggets",
      "Minnesota Timberwolves",
      "Boston Celtics",
      "San Antonio Spurs",
      "Utah Jazz",
      "Toronto Raptors"
    ],
    level: "extrahard",
    firstNBAyear: "2017",
    college:"",
    img:""
  },*/,
  {
    firstName: "Sam",
    lastName: "Hauser",
    teams: ["Boston Celtics"],
    level: "medium",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdAfsMjLJN1CIHDhN-d8CgLeDFXnR5rTx1V2FTmgTcsHY72vsGPZW2Wv694_i8qiZ2VYSsc67MZrnIcqp9nP0UjYewTe8cS0L19ZYGYIdd2Clqs1x0sRduP5F-GLf1x54rnDepAW5a2nqFwBIB6KPI8=w115-h142-s-no?authuser=4",
  },
 /* {
    firstName: "Evan",
    lastName: "Fournier",
    teams: [
      "Denver Nuggets",
      "Orlando Magic",
      "Boston Celtics",
      "New York Knicks",
    ],
    level: "hard",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfCzLQUSZd5Dtjt8kfkbC1QdejwqJrJ-KNLBoUyOk8_OuWh2-s1b5Jq1oDOt8nfBlMOw9hs8ME0R-aYZsplyk8WYIVTSQGgKObDtw4NivEnCh_fPmW3vOUELjYZfzt1jaYWOkPdUMSOUHEdFGc7uqQl=w115-h145-s-no?authuser=4",
  },*/

  {
    firstName: "Aaron",
    lastName: "Holiday",
    teams: [
      "Indiana Pacers",
      "Washington Wizards",
      "Phoenix Suns",
      "Atlanta Hawks",
      "Houston Rockets",
    ],
    level: "extrahard",
    firstNBAyear: "2019",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHceLB9-A__JSTrMEkeMmwGV-G4IPzKhnu8wX3R5YyIC1cRg8wvrOIZVfIYfJ9nj3AbxutvRGf2noq9lZjI3oMp6E4V3SZOL1H1q1TKgRf2t-o43ArXQGxN9AMfH3zxQtMSQvyBYyxBvpwQooz-f17ya=w119-h124-s-no?authuser=4",
  },
  {
    firstName: "Kevin",
    lastName: "Durant",
    teams: [
      "Oklahoma City Thunder",
      "Golden State Warriors",
      "Brooklyn Nets",
      "Phoenix Suns",
    ],
    level: "easy",
    firstNBAyear: "2008",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdDALAhLuquTSh12th4kdAVnuTamXCk9rSk-llrj3e_ChrX8jhNmja7ELyajgPqQegPweKPJenZCbfykcLxvqNYzsSPkoEJNn1aQoKwfXmaZH9UEme2lkc2LEkf114v70WA1d1XRbbJooGIOCMzDXyC=w99-h145-s-no?authuser=4",
  },
  {
    firstName: "James",
    lastName: "Harden",
    teams: [
      "Oklahoma City Thunder",
      "Houston Rockets",
      "Brooklyn Nets",
      "Philadelphia 76ers",
      "Los Angeles Clippers",
    ],
    level: "easy",
    firstNBAyear: "2010",
    college: "Arizona State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc6Lb5dNc2GL7nLQB6U7w5EhGy5HOXp1lVCZ3nhV8E1NrUV3e5Zb1gtVGslRjuGFfCOCGZIk9PnBIBVcbnore6nK3CWcUNLuGwA_etYvutzxzR2dAjGMaIeyf0mT3iGy9BRtgSrAr65Gt9FAlI294Pm=w113-h153-s-no?authuser=4",
  },
  {
    firstName: "Kyrie",
    lastName: "Irving",
    teams: [
      "Cleveland Cavaliers",
      "Boston Celtics",
      "Brooklyn Nets",
      "Dallas Mavericks",
    ],
    level: "easy",
    firstNBAyear: "2012",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe1lOrh7kfW8OKU-MmMBr1Wj4wZz5MDxDdIQkGEOpukcOgyGzncAlRNQ15It3sijkODnGZvAhuy2Ca6DcNP_rmZYJKhMhnV3jFFVzh296bkS0BsGt9BJ28XQeVESkes50KfDpDyeSwYooFkxvAawpSz=w119-h132-s-no?authuser=4",
  },
 /* {
    firstName: "Joe",
    lastName: "Harris",
    teams: ["Cleveland Cavaliers", "Brooklyn Nets", "Detroit Pistons"],
    level: "medium",
    firstNBAyear: "2015",
    college: "Virginia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcqIY0E0eAxr7lmNHVLF3GP3TUn_SyGeg5YDgQu-M4Hfuz73odW-qKEJPpbM-7WOqanJk1phJ3bMvQZji-OsaGpbF9alCchA931yZn622hoVVhC_RkTh7Sq2SBe8D4wUW8sCv6pnLauTKi_s6_amWu7=w104-h143-s-no?authuser=4",
  },*/
  {
    firstName: "DeAndre",
    lastName: "Jordan",
    teams: [
      "Los Angeles Clippers",
      "Dallas Mavericks",
      "New York Knicks",
      "Brooklyn Nets",
      "Los Angeles Lakers",
      "Philadelphia 76ers",
      "Denver Nuggets",
    ],
    level: "hard",
    firstNBAyear: "2009",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcmf1mxtQw4jr83_KYNSIHpsVAGXWnJqGY-Ger7_1FsCTfdRPnw45v5Is7eqqYCbavhnM2CCZkzmwbsh0oBovOhsfAFGdQtV__Bu_bBQURVL34xFubWUCyCG2vDb534GR7R6HD8zmQAECA9kiFHq2-Q=w120-h138-s-no?authuser=4",
  },
  {
    firstName: "Bruce",
    lastName: "Brown",
    teams: [
      "Detroit Pistons",
      "Brooklyn Nets",
      "Denver Nuggets",
      "Indiana Pacers",
      "Toronto Raptors"
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcO7JMpWmXvIAhOVq-8JIn39phEfolMaBwA5rIGEKLd3wfb0NJqlr358a07qWGKSWswifaxVh3u-RVaA3I-DpD5b4v2F9qZy7LqNpJj52Vk_gJGE___qbYQfJl5IyB43v2hNoCQWF9BRymF-WRYDoA-=w112-h134-s-no?authuser=4",
  },
  {
    firstName: "Jeff",
    lastName: "Green",
    teams: [
      "Oklahoma City Thunder",
      "Boston Celtics",
      "Memphis Grizzlies",
      "Los Angeles Clippers",
      "Orlando Magic",
      "Cleveland Cavaliers",
      "Washington Wizards",
      "Utah Jazz",
      "Houston Rockets",
      "Brooklyn Nets",
      "Denver Nuggets",
      "Houston Rockets",
    ],
    level: "extrahard",
    firstNBAyear: "2008",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfietJTRZCEsxY5tecYYUyR4EVR-gH96ukHs1IEUWbnv38ByFpafpt_DA3HhZMItnbw4ZQFcIFG28dW7wQ9wDdXyFrZJ5hQTXyhYr270e1zl8JpL2DhxLhFqPnYc-Yq9EhRGMB18IssZzVwiDB6iAjZ=w112-h135-s-no?authuser=4",
  },
  {
    firstName: "Landry",
    lastName: "Shamet",
    teams: [
      "Philadelphia 76ers",
      "Los Angeles Clippers",
      "Brooklyn Nets",
      "Phoenix Suns",
      "Washington Wizards",
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHetG50T7cH6pk_dOhovI2Zc_yAkZypNoJo6wOjAqXbjUz4SdjDc3VVR2rIOSq5UxDv2WuPCn_qbQwaBV1z2oYoXGbUtTzDBsKImxvlUacu4blqRFHd-VrVWnRd9c7jl8zRPRdqdExRbYyRIECf19Hlb=w110-h137-s-no?authuser=4",
  },
  {
    firstName: "Nicolas",
    lastName: "Claxton",
    teams: ["Brooklyn Nets"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Georgia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHftT4jWuKQ0VQ8b4RgnpiF_R9bxf0l4dwGHRT8I66yPArbaJTpD-sDXxl2CmVgpnbfYjaP9E8kgD93kUS0KoPYf42XXoSoS4uBcXCOAHSpsYoXNL6rne_SBDgLXw8UOCmwB-eASC5fgR6C6LeRkkk1K=w109-h132-s-no?authuser=4",
  },
  /*{
    firstName: "Blake",
    lastName: "Griffin",
    teams: [
      "Los Angeles Clippers",
      "Detroit Pistons",
      "Brooklyn Nets",
      "Boston Celtics",
    ],
    level: "medium",
    firstNBAyear: "2011",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcSzz3_7VPP1TcdRLTacsO0KN1PJq_NPsmq3kmVUZj_wGY4DYHiZaqAtFSaWtYRJ5yL-hwK31ZXnhElOFdMsHOSoaL8nZFF5kD0LsY-lTdZa_vE1UZuDMNPLFCzrY_rdF0gtdq28AcExuNDlm4RpN28=w118-h143-s-no?authuser=4",
  },*/
  {
    firstName: "David",
    lastName: "Duke Jr.",
    teams: ["Brooklyn Nets", "San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf0nUCwzkbwFEMKdEkDGqin7Z9C3heo2jM3HKxklquG9hKlIsN0oteTwnCHz4hGmIABvlclAq1NOKZJXc7XSFF_6kchjiZ08uwc0VZvw8osMVROXYz2O8O8Mad5AcCLWlbgtEKVq2zVciHQRvdMC3QK=w102-h135-s-no?authuser=4",
  },
  {
    firstName: "Cameron",
    lastName: "Thomas",
    teams: ["Brooklyn Nets"],
    level: "medium",
    firstNBAyear: "2020",
    college: "LSU",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfTFKqZ8UbptQX86cpl6uI8vk9CI-7sFPhXSgh8I2zMQ2evalH2la97AeKwPDyBEbH6KnhFapSWZo5w9_7c5fEWnwax99mOIvFh4EwVrPyGFrbQhRhTJwVmHVbuhFPBeo5YfKZdegZTGn94BZaJgkbh=w111-h136-s-no-gm?authuser=0",
  },
 /* {
    firstName: "Alize",
    lastName: "Johnson",
    teams: [
      "Indiana Pacers",
      "Brooklyn Nets",
      "Chicago Bulls",
      "Washington Wizards",
      "New Orleans Pelicans",
      "San Antonio Spurs",
    ],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf9W9bn1VI-KB6dsNNH4Jn7c3W3OUKCQ7F3R5eEOR8CxSbkbGMX1rOgbrftcybSkb2JibtgGywJk6EmcMgFh3JXYop5_rQQ4MIp_8Lsg9mB4YZHhafmuHcvHf0WFMTf7AewIqp3VoCN3CtZDLLa3lWE=w110-h143-s-no?authuser=4",
  },*/

  {
    firstName: "Mikal",
    lastName: "Bridges",
    teams: ["Phoenix Suns", "Brooklyn Nets", "New York Knicks"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcEXf-K0wYEcmI9KA081xusFZbslRrjIGF_-0mybU3K4IsXohDPLxOPdTxVCqu6xYsz00NS4rFI-VQLTkXGfmSaKD3Xuzewhl2yhNsy82bvz2HVZu8yUzfaH_8iJ4Rf86Lu276PvzTShtKs2KIXoMiu=w118-h146-s-no?authuser=4",
  },
  {
    firstName: "Pascal",
    lastName: "Siakam",
    teams: ["Toronto Raptors", "Indiana Pacers"],
    level: "easy",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeUoobRq3mBDGeNphGV41h9JJ6l5V8tgDb5qvYnN7pDNd2mMd_g_hDQAxxegwvuv1IICqhacfMTDwbI2XcrxcnO1GEtkcrZVaBcMllRAazns0uxllPooOrNeDNFz_ZyrLpg0KBcUTt3TKMkfBOGpchX=w104-h128-s-no?authuser=4",
  },
  {
    firstName: "OG",
    lastName: "Anunoby",
    teams: ["Toronto Raptors", "New York Knicks"],
    level: "easy",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcBGASPcV_UVue9_Hd5h3XfVQ_wij-EFGFY8u1_n1wIUyNG-Nu9IylXFpm1DVim9ho7bhxofz-2hp54jUe9Gxs5eWW2GoipWRifAO-jnR920U0ms2ps4ZxYDcNgcp1GYrE_RFxCOwsIktpSU5IBb4Xj=w110-h136-s-no?authuser=4",
  },
  {
    firstName: "Fred",
    lastName: "VanVleet",
    teams: ["Toronto Raptors", "Houston Rockets"],
    level: "easy",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcU9kwmrNhY31aZqY8igkNDhNP1LpxhwqbIv1xU70e9zQj62EVwEkbj-wY8EE_1L9zKhEw44IlRZRFXVZ7rXtpNzDcGdEOJj_ZEgE8ItJRbuZdRG5tW8EMJb0kcLa8eZ_Dt5MAHNFNDjCsKiJ3OcNyG=w107-h138-s-no?authuser=4",
  },
  {
    firstName: "Gary",
    lastName: "Trent Jr.",
    teams: ["Portland Trail Blazers", "Toronto Raptors", "Milwaukee Bucks"],
    level: "medium",
    firstNBAyear: "2019",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeUhcYrTp9HL47-GLRCwNzP-ShS-OsXn8YgqlysciUcpH3auB2CVpRMX_p2SRq2zVXRxrhsKbdIngsXZy46oyQdZLlSRvlBXfclCJj-JDd9rywdgvPu0bJee_WjZJn8km7E-qcSqozPJWokgzj5EqvG=w105-h131-s-no-gm?authuser=0",
  },
  {
    firstName: "Chris",
    lastName: "Boucher",
    teams: ["Golden State Warriors", "Toronto Raptors"],
    level: "medium",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe1kZP835qz5sYEunvN34o8lL_hpstyacdNEpLsPHNXf7SVq_kpC6aS7yQo_Gy4n1yWPisxWxa7ki3Rxyyq51dnqVBfI1nVzfsjodCQ7xBdlXixbl8GbYw7pqecpUYqgsODBor45FCXzLDxOKdb1lvu=w113-h125-s-no-gm?authuser=0",
  },
  {
    firstName: "Delon",
    lastName: "Wright",
    teams: [
      "Toronto Raptors",
      "Memphis Grizzlies",
      "Dallas Mavericks",
      "Detroit Pistons",
      "Sacramento Kings",
      "Atlanta Hawks",
      "Washington Wizards",
      "Miami Heat",
      "Milwaukee Bucks",
    ],
    level: "extrahard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc6uU9cVrwhLXczNTCKySmNDfuS_YviECYaXoJGWVgxCz-ib955zV2yKNOjXcYI34IhHKrijtyZh9Qcbir4VrQA5cTwDkZstDwvfb5QV5vkjx-kHK07y4x-l3-BrzCeMactidGydC3Zib6WPzYoJxf0=w103-h140-s-no-gm?authuser=0",
  },
  {
    firstName: "Khem",
    lastName: "Birch",
    teams: ["Orlando Magic", "Toronto Raptors"],
    level: "hard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcJYbKHmkrGJov2SeY43KTx_KcQFfncNFSsV8d4k1yfqUIkoazlD6R6T_c3dDXzBndw7pSlrwp8J19O2nXCNctsSwXzrcjG4xumc4gWZwx1lON_KSPg1ZojvAPto-OyO_kcmx_I_vEwmxuRXVQ1fQqH=w112-h135-s-no-gm?authuser=0",
  },
  {
    firstName: "Malachi",
    lastName: "Flynn",
    teams: ["Toronto Raptors", "New York Knicks", "Detroit Pistons"],
    level: "easy",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcCKKFtDLXEauEJ4PxgvzSbjvitsccH1VC2Y2021QlkvnWEh6f9_AbPfLwYVx-Lfoy9lYCnP_BEGgedYXolD-nJh5fkbZWtu6ETbQ27RVMhicWcEyhg4VdCwDTTP10010rD7NUkt_6idw_wXLBKZP22=w94-h127-s-no-gm?authuser=0",
  },
  {
    firstName: "Precious",
    lastName: "Achiuwa",
    teams: ["Miami Heat", "Toronto Raptors", "New York Knicks"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc_MbGJCGRZqCKq6nbNqsRxXaLhYBeCCQTCfruPSiM-TYJAqrw6NILw1BUkcVs9tel_doa3PbKyt_U4nkSjDH9YIF2aBtn6SPPzw7ChNXDAK6YDoYrug3sd3xiyxr_d_3WUh1WvAjuCTMrJrqpRyoPc=w95-h139-s-no-gm?authuser=0",
  },
  {
    firstName: "Svi",
    lastName: "Mykhailiuk",
    teams: [
      "Los Angeles Lakers",
      "Detroit Pistons",
      "Oklahoma City Thunder",
      "Toronto Raptors",
      "New York Knicks",
      "Charlotte Hornets",
      "Boston Celtics",
    ],
    level: "extrahard",
    firstNBAyear: "2019",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeX2nn_V_m9F23Ogo9ylX2utobUNngA_Nz6I7AiG5z0yOrk5L4VnohOJQomi7aOMwRl23KmJxp_PEJzCx9WgwDw4HgUbtsD6K3KIVGkLk1f-Q0tnXEsYlrv94hWn_5DmUoHJqe3SxKkSFhRW6_TJ7S2=w116-h136-s-no-gm?authuser=0",
  },
  {
    firstName: "Justin",
    lastName: "Champagnie",
    teams: ["Toronto Raptors", "Boston Celtics"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdxET12BYCscDM6sh5bIvKB9_WcUF4Mct5qLnRgUt0QKY0Ng8W7AN__iUMKeViq4yD5YlfDezQ4Uzinkxkw2Z0GV_iJZySw6fR-bLQenqc2lXA8G7ZOZUlGv0_gZxXrBCF0cbQ4WaMVSGOyWY1xrbq5=w108-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Scottie",
    lastName: "Barnes",
    teams: ["Toronto Raptors"],
    level: "easy",
    firstNBAyear: "2022",
    college: "Florida State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeFChlOEbILAm3z4I2WRxdCNCgB5E_TmuZHOZL5wTslOTxJuS6y-9wLt8jteHsAsLPr0D-RRryYueaCI_8RhBu-PXMJYFi3a9KEq3pWDKsVlaRCZWUuYUyRdA8Mv-oDpeho2XdPg1BmhP3APblBUreh=w117-h135-s-no-gm?authuser=0",
  },
  {
    firstName: "Ish",
    lastName: "Wainright",
    teams: ["Phoenix Suns"],
    level: "medium",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcmQtYWxS59phkUuXGjm-Rei-BX9Ber1lpn15Hl94dpS8W4H4jm3KuDCTlTH0ZegcRk4527865Scx7Z1m_gXF7P16yVVd-acYjnqSpx11-tQA2YPi7gqKPWtO4UHOfdCGacW1XWoGFbbxsVKIUwEkcJ=w112-h135-s-no-gm?authuser=0",
  },
  {
    firstName: "Ivica",
    lastName: "Zubac",
    teams: ["Los Angeles Lakers", "Los Angeles Clippers"],
    level: "medium",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcpH-LqNvDY6UM2-TX0ul2xOjA9B_reNwCurmgqHHuuiwIdD5h74c1IGwgU4DBfnjhWt1KolLHqDNgmaLPyeDqdabyKfzfnlMPQmKzBlwPcspGQ9-NFQGWsduQ1cRzYI3uFMsqR3USfru-EDTcEf5EK=w113-h140-s-no-gm?authuser=0",
  },
  {
    firstName: "Dalano",
    lastName: "Banton",
    teams: ["Toronto Raptors", "Milwaukee Bucks"],
    level: "medium",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcVII1Mz_AcNu0aEqRaf59f-XwIUe9wsAPBn50OCAhhNeiTVt_KzmRcDZLdUZCB92O6UI-eyQsvSHYeA7PSk0c5dRSzOmCg3r9CenKKtWlp9-robQOxUWOs1pW_Ws7dw6s89qIrkWHFRjkYnZ6a1W_7=w114-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Moses",
    lastName: "Brown",
    teams: [
      "Portland Trail Blazers",
      "Oklahoma City Thunder",
      "Dallas Mavericks",
      "Cleveland Cavaliers",
      "Los Angeles Clippers",
      "Brooklyn Nets",
    ],
    level: "extrahard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHefKwtLdDL4n6EMpswhTlUCAV6ZciNJX16Vk-kPQrBys6pUaBMyV4GP87JKiquUNTBEX5tgsB9YUzfpRyqOuPLbKjpWLCm9YxBamRxd7Z7oCZchDpar6q0jXwIQPD9Dd8kat7koE2fdxSOMazvqZMRo=w115-h147-s-no-gm?authuser=0",
  },
  {
    firstName: "Joel",
    lastName: "Embiid",
    teams: ["Philadelphia 76ers"],
    level: "easy",
    firstNBAyear: "2017",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe83qHW6gMfiiiw0SoKgyEd2asQqWgHG9-47g8OhKYt_0Xgtkp-oXgynNLUpsVQ9047Tkl8cKZDkPINnasAmdf_Q1GkyLZrSqnhAXPCX_2NJoqgTCyYNZXQJWTq9Atb80mh5EODykjG7hrmOZVgIZE5=w118-h146-s-no-gm?authuser=0",
  },
  {
    firstName: "Tobias",
    lastName: "Harris",
    teams: [
      "Milwaukee Bucks",
      "Orlando Magic",
      "Detroit Pistons",
      "Los Angeles Clippers",
      "Philadelphia 76ers",
      "Detroit Pistons",
    ],
    level: "hard",
    firstNBAyear: "2012",
    college: "Tennessee",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeIWtGu2ZRSaYPaZ79i7wHQdA2JM9UG7i0Eli3mo67VaQKHZ-kY3DyK4lT1A6J5jNLcQ6WulzjZ1swgkv_-pA_freJbLc6KiPqu0PjZLjiWuG57UaEzuKyrOOezypuvazU9dqFa5vQ4-HnaYmZtYziX=w101-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Matisse",
    lastName: "Thybulle",
    teams: ["Philadelphia 76ers", "Portland Trail Blazers"],
    level: "medium",
    firstNBAyear: "2020",
    college: "Washington",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc5x8O1c-AlOehwJQ_eAY5E-rObb-t3rfee4xzebDv_QTYtOWKaggTJHmoPAxnTNyrLDUol3fS-dBM4bAJ0bZ_BZLqoTltICKlc6h1nM7LZYEbPNryW2XTnN2ioSlgZE0hbcDs0SqmDaw0lKh0fi-7P=w116-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Shake",
    lastName: "Milton",
    teams: [
      "Philadelphia 76ers",
      "Minnesota Timberwolves",
      "Detroit Pistons",
      "New York Knicks",
      "Brooklyn Nets",
    ],
    level: "medium",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcAeVqEeQUxnYWL5GRUeIDIbkNrbZUfuX_COKeMfOpyiZY9CiVMUW3fA8qNCqB2121H9XDKHhBfTvwIUqhnqQfaR3ci-DQyMIEELzWpGFORSmg_AdzRvL77yk2Ka3xmK8HNQ9X0JHGoCEqvpfr8Pkuw=w100-h137-s-no-gm?authuser=0",
  },
  {
    firstName: "Furkan",
    lastName: "Korkmaz",
    teams: ["Philadelphia 76ers"],
    level: "medium",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdQHYGStOfttLj-vlhsdNunNO3ob7x1MYDxNB-rXoVLjfY-nE17cuKI0ponkj8XDVNjVqcY1RRlR_lWWStmu9y54xcSPi6MEGJwpHza0VSuJhEfrhm_x5MzLkYLnwM0-ScCPjAwk9VVz521Ff9xFNDe=w117-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Tyrese",
    lastName: "Maxey",
    teams: ["Philadelphia 76ers"],
    level: "easy",
    firstNBAyear: "2021",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcR80hBx8GoDYH07FGFoX71R8IfVQia5zjK15vRWBnmI7D36_bEbMN8Tne_FckM5K4qpjhUWxAn-B6Lp4uIb3f3fCV398EltCjMUq9qWz1ZJyupkAIcSBG9TliJDjwBZy2EIRhAHJjgVEX2rnU5udzb=w108-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "George",
    lastName: "Hill",
    teams: [
      "San Antonio Spurs",
      "Indiana Pacers",
      "Utah Jazz",
      "Sacramento Kings",
      "Cleveland Cavaliers",
      "Milwaukee Bucks",
      "Oklahoma City Thunder",
      "Philadelphia 76ers",
      "Milwaukee Bucks",
      "Indiana Pacers",
    ],
    level: "extrahard",
    firstNBAyear: "2009",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcBRLO5bFL3XNolzxcrg1VBLzqV7TUlBWZKGvapSdXwPhWhznx_xTDEYllDbDCcnn0SqFIazLoTxb0X8a_4EurPKmJdkXDAPz-FKI70LM7VS9Yaj_WH3I73qURLFVnIo57RKsu9DH9cY8SvWUfbRJDw=w109-h142-s-no-gm?authuser=0",
  },
  {
    firstName: "Isaiah",
    lastName: "Joe",
    teams: ["Philadelphia 76ers", "Oklahoma City Thunder"],
    level: "hard",
    firstNBAyear: "2021",
    college: "Arkansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf-imP84A_pLE5aND44kF1_RNS6QaAULza0GiPzBrVuWJcRPVEgBujGZwHkgXU4FsY-IQYIRZiyVEyVGn2f3FOz7gOqKeDsAek4gQk86Kt9Lpw6eYXt2NrwjJ9UbwqOCNiHQXYpQy8T_cD9bMvezAEr=w99-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Charles",
    lastName: "Bassey",
    teams: ["Philadelphia 76ers", "San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf6egtTQ3mPRWuPIFvCersZCXwhR9VvNoYhpyw4fMJ0Yw7baXMQxkdtNE4JoVoBK9Ix7KP5Pz7kTiTB_OtrHTs5r2rwInscrSpJ8y-V9Hh6OFIQjBe9qQTXqdHwqLCy_H-Ww_BBW1L5PT0DMeTh09bX=w99-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Paul",
    lastName: "Reed",
    teams: ["Philadelphia 76ers", "Detroit Pistons"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe-0C2rMyOm2ijUMa2GvmOEB1euZq8QcEubVFJcsBEnqNOkOgs94UmOBMEJvfJi7N1iBjorSKdX1qi-pDTdKnC00a3PWiPpu6kLM3bIlFsoGeqoHVHQ1b4F4XdjVK54WWGFrgbWt9hDXPhJYbE2lVzd=w108-h136-s-no-gm?authuser=0",
  },
  {
    firstName: "Jaden",
    lastName: "Springer",
    teams: ["Philadelphia 76ers"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Tennessee",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcsn1woHqqsbCa3hgQoPaQ_kGNc8b3BWqdkxCEP4u7lGDhcJkwzzdqbmX4-jK3qNyx-QrFzsQrOvLXhz0wMIMxV8ueIZS5rDjGjrXh74QlfVNTBuEyyzoWBSdV_GJn4nl0ipUVYAaWZ8Ju5H6frw7p7=w97-h135-s-no-gm?authuser=0",
  },
  {
    firstName: "Andre",
    lastName: "Drummond",
    teams: [
      "Detroit Pistons",
      "Cleveland Cavaliers",
      "Los Angeles Lakers",
      "Philadelphia 76ers",
      "Brooklyn Nets",
      "Chicago Bulls",
      "Philadelphia 76ers",
    ],
    level: "hard",
    firstNBAyear: "2013",
    college: "UConn",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfRQTygUPqwjtmDaMv8I33BSmX2nW6pEhjSpFmbj-8-dqh2LOSTlftVQ6SdO4PIh3Z2pRJf-0qBOw3O7yuxkZ8qw4OhEmeSvKN46sI9lsOiCncNUa-Iz-ZdcpQ_-MOVz8PXHsFW2-nfGJVTfvw0Fk4V=w111-h140-s-no-gm?authuser=0",
  },
  {
    firstName: "Seth",
    lastName: "Curry",
    teams: [
      "Memphis Grizzlies",
      "Cleveland Cavaliers",
      "Phoenix Suns",
      "Sacramento Kings",
      "Dallas Mavericks",
      "Portland Trail Blazers",
      "Dallas Mavericks",
      "Philadelphia 76ers",
      "Brooklyn Nets",
      "Dallas Mavericks",
      "Charlotte Hornets",
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfuN8KyaAUzdLJ6dysZQ6xA_ENnEw8e4hJHVFW4_rQQBieIS9V9F7c3ye4ZGjr4GbH9VS2wIg5KELAmcT-K0wzrJ_V9w4u_nPvR0yAaSI_uT97gzTtJQNNHrQ8536YeGyNwfM9JNcd2clCeA3rfuANy=w110-h138-s-no-gm?authuser=0",
  },
  {
    firstName: "Ben",
    lastName: "Simmons",
    teams: ["Philadelphia 76ers", "Brooklyn Nets"],
    level: "easy",
    firstNBAyear: "2018",
    college: "LSU",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd9dwkxZKtrIlwCWEqgPyiifXcJOASJPSmJpeEEmXL15ZxJfvGuM_tl2Qzt1WATJewKNljVKu04pDo8T5ZloXPoKbWdF18aE_84EnF98vgS12sd1PPzHUtGZD-gJLX0X65PTLdnmH1Xd_U2nYyKZWZ4=w105-h137-s-no-gm?authuser=0",
  },
  {
    firstName: "Danny",
    lastName: "Green",
    teams: [
      "Cleveland Cavaliers",
      "San Antonio Spurs",
      "Toronto Raptors",
      "Los Angeles Lakers",
      "Philadelphia 76ers",
      "Memphis Grizzlies",
      "Cleveland Cavaliers",
      "Philadelphia 76ers",
    ],
    level: "hard",
    firstNBAyear: "2010",
    college: "UNC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeeFEKwSXfF8RtaFOO6B79gAhK4cXm83ylXLVAiuG24gNQ4n-zqytANeOZUZA8vXOZjS9z0OF6JcE6TvU_hApdASN3DHrfobGQCgcNcFmldqPZAy73AGCLv-imWkiUZv7WmdlBOCtqxvATGcispaACi=w111-h136-s-no-gm?authuser=0",
  },
  {
    firstName: "Luka",
    lastName: "Samanić",
    teams: ["San Antonio Spurs", "Utah Jazz"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd6h_51MAZqpGq7d2bQjPWQGgyNVCd7Ix41msMZzQmXElbQBEk5QY7Su3kg5o72sNyIONJDVGRFkw1_xpPutzCyn4sz2CSHtdTeNJZ0T5LhodUV6p_0dTKULqogamJ85hS6eR095IhJFRFy6gNf3PXe=w95-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Bryn",
    lastName: "Forbes",
    teams: [
      "San Antonio Spurs",
      "Milwaukee Bucks",
      "San Antonio Spurs",
      "Denver Nuggets",
      "Minnesota Timberwolves",
    ],
    level: "extrahard",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcPofWYPnMOE2LllRb_80k2VWReCVJfjIlsz7al2iWG6QyVMYU5BdrEDbQk8_PhWkRqbnTlOSRuyaB3LIsdTIy7BRcHvGWd6eOksti5s8qQz4seykAeBi8gSM_Qu1ai5eDIjgaXLCcCarlGJMIXFnA0=w97-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Julius",
    lastName: "Randle",
    teams: [
      "Los Angeles Lakers",
      "New Orleans Pelicans",
      "New York Knicks",
      "Minnesota Timberwolves",
    ],
    level: "easy",
    firstNBAyear: "2015",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfRor0-ySvADeziW3a3fBOZRMR-e6OicUmbhHWjxN5MdJ_nFY2N0jflDDu4MRxPDHIF17-TwZhNRXH3WOtslRF_xIDWGDk5Y6y1hhjKjYnq4u5MR0h2hvbAMgIHf-qAtetBwqUJLBxzB83i-ehBcUXz=w115-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "RJ",
    lastName: "Barrett",
    teams: ["New York Knicks", "Toronto Raptors"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcr8zGFF16EsFr4LPcIGmcfIE8Vf6JjX00zul0UKlP1Ezc0F_yB_-2gcvG9rMrVzgynXzMKV8PoLRCF9PVfJwlfq9vBTKOBYsQSXSM3mqWxKUoRIGiohr9H-DFa_swOrEE7Uzv98RePGGysaIJcefri=w99-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Nerlens",
    lastName: "Noel",
    teams: [
      "Philadelphia 76ers",
      "Dallas Mavericks",
      "Oklahoma City Thunder",
      "New York Knicks",
      "Detroit Pistons",
      "Brooklyn Nets",
    ],
    level: "extrahard",
    firstNBAyear: "2015",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfJ-t5S7kQlZRUiiulYGdyi_84C1mcvKyJBbBm9CLEAWLGAr1tN7Vr3NNiFi23uArFJ5Lw1ksks9GzBubvP0WWdAB4RH7QwJZeBS9n4mbuZO4pIU9zdwU_LDCSloDEzWbRQ7XEzd1VbUNeeg8EZvbPi=w100-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Immanuel",
    lastName: "Quickley",
    teams: ["New York Knicks", "Toronto Raptors"],
    level: "easy",
    firstNBAyear: "2022",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdISExWTyyPpQt0IxTiF_IgDQMnIHQRa7jJz7bUEeFmxM97UTilwqOYIjmn0mn9IAfml0Dim-qENVNBUIqyzuWmYm2ovXJtot4yTSwNQyFVak3SV8gW2BUAqHomnJFUGrGfyMw_iFKeV3ghZi-T7Qpn=w107-h137-s-no-gm?authuser=0",
  },
  {
    firstName: "Obi",
    lastName: "Toppin",
    teams: ["New York Knicks", "Indiana Pacers"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdlrkSv_-4xhVrNWgWY47az8HmSWo8Ui_rCUoHU_ZChJwwOowghjPoDYDTn5jKnOUiiK5pvkA84xB_Bj2QJ6LM4zhbbaZ-CyDpe12eybxgjnVrZjtgnMiaVppCvLoiw88Ld9mKcmNhJ6TvOB4-85RiC=w109-h136-s-no-gm?authuser=0",
  },
  {
    firstName: "Mitchell",
    lastName: "Robinson",
    teams: ["New York Knicks"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfLaeNcYz081XWPnKu64NpTkBWdqdzOYWh-lzP_2uaRcSSHdhRiXQ-jAaw_2ve8gu3eAyWwD1peKP_4TZmmvuCGaQKgvRPRsC2JHeOK9Kf2wKCamylFviWrXo0X1oa6mDqj12wT19jO83eonZdBZMg0=w115-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Kevin",
    lastName: "Knox",
    teams: [
      "New York Knicks",
      "Atlanta Hawks",
      "Detroit Pistons",
      "Portland Trail Blazers",
      "Detroit Pistons",
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcA2rQ9_Mp-HmNUPCVOobLITfgUa6Ia9i8rB4wrLmIvoIQzhdpOiHW1XYSXk59sbzUjXn994YqMx-gxXwkQJuDtlqFP6WCtbdEub4F9-J207WBtB6lHYyIQFPgGdWSXOuWApApTDcas2idw5PbhuUEG=w114-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Derrick",
    lastName: "Rose",
    teams: [
      "Chicago Bulls",
      "New York Knicks",
      "Cleveland Cavaliers",
      "Minnesota Timberwolves",
      "Detroit Pistons",
      "New York Knicks",
      "Memphis Grizzlies",
    ],
    level: "medium",
    firstNBAyear: "2009",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfCkQw1vOilbyBS5P6BoFH9NXB1yYWotPdHtPAdM4XPMVRA_L0HLvSPwNYByNL_oIkO7bmXCsTxl8gNgt3DoG_m0tSxhE2Af8zJmBaMWUIPHN9N8M8KJIS1vHBVknzxjNZ7XJDY_ADEoGPr2ND88xfd=w100-h146-s-no-gm?authuser=0",
  },
  {
    firstName: "Taj",
    lastName: "Gibson",
    teams: [
      "Chicago Bulls",
      "Oklahoma City Thunder",
      "Minnesota Timberwolves",
      "New York Knicks",
      "Washington Wizards",
    ],
    level: "hard",
    firstNBAyear: "2010",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd5cSDGJqXUegebzGhr9jHpWENTSd1z00587XoIjfo5DT6L7cbrJn1EYE8dQpoNrfb5KrR_UD_Htx01tmmrAYNSv_QCMuAbkUAfywfduEoTls42CBctCP_YnwgBqVCqBa2XnqB-Y_CNS48cm50pGnag=w110-h151-s-no-gm?authuser=0",
  },
 /* {
    firstName: "Frank",
    lastName: "Ntilikina",
    teams: ["New York Knicks", "Dallas Mavericks"],
    level: "medium",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeu_eTtOw1ZLqQOMXi5vBAQioyLYzRiEdRDCB8clQwGbHhjQvtsHo4AViwmqvFFcVuyf7yG6wbB6-Od-35Cnq8cWvtUlmfl8-v94zXPHleGp56lH_KGbpJ45f7O-myKlbCY0V0LMp2cu1v6WCpU87wn=w102-h144-s-no-gm?authuser=0",
  },*/
  {
    firstName: "Alec",
    lastName: "Burks",
    teams: [
      "Utah Jazz",
      "Cleveland Cavaliers",
      "Sacramento Kings",
      "Golden State Warriors",
      "Philadelphia 76ers",
      "New York Knicks",
      "Detroit Pistons",
      "New York Knicks",
    ],
    level: "extrahard",
    firstNBAyear: "2012",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcuec7-6M4iPRkXaaFiYDvb80d8hqzL9X6IJ_D2wGtkAN-617TYTAIxI7Y_AGTGjSQgWalFHi66rSfxuhHgSStwoyksgGaDPTbTgLrbu_Vxpf43E89lD9jzzMjlTVz90mvfb641PNYzvRxzShztnrYx=w99-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Miles",
    lastName: "McBride",
    teams: ["New York Knicks"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf6Wz1u_6oYRJgnhS6DXPTB6pGQ0MAHKfUgV1wKK6p41sqYZUkTZKRlCoxO3lDp-ZXKI2xXFCuSNdADxgAVEs5JiC82zn-G576JDbhdrmiCfATOfVrK66ENoh5d8KYRxFNlkrSL9MirB5ikAPNsKXMo=w109-h164-s-no-gm?authuser=0",
  },
  {
    firstName: "Jericho",
    lastName: "Sims",
    teams: ["New York Knicks"],
    level: "medium",
    firstNBAyear: "2022",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc9YUvB1TEJbZ_sqbT1NUnLJ0dC_5mNJG4cx6f0cId5J5nHNmymTwTu7A7QIvGSSSBPoY0bCrV8J0ZpQjZz3OjUiMmWJcW3SLgtPD2YYQVgkN_L_gNZqVLHNMAhYgKIaaCquN8rI3lOsEL2QjbfjRTP=w100-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Isaiah",
    lastName: "Hartenstein",
    teams: [
      "Houston Rockets",
      "Denver Nuggets",
      "Cleveland Cavaliers",
      "Los Angeles Clippers",
      "New York Knicks",
      "Oklahoma City Thunder",
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf_WM7ZaBGQEI8inw2g64p4U2ciknnk6wdTO5Nv1cMt-kL8pzYbISgyUGDzxIMC9eMrACFSgo6flK3acTgXJnvB_6EgcOJ15BqfLuG6fOVREH48UQsHykv16eb_OXLylmNkBsXYoiNMhlVUuLiJS69q=w106-h156-s-no-gm?authuser=0",
  },
  {
    firstName: "Kevin",
    lastName: "Love",
    teams: ["Minnesota Timberwolves", "Cleveland Cavaliers", "Miami Heat"],
    level: "easy",
    firstNBAyear: "2009",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfLVulDHQARzBFh6MEpwMSWKcS_tTnGeFJOfOmTGpaDCk14KqbLU9jqiT-z8gvfIDXmsAYMUWzYD-74rZOJ99H7F0pqt7wug75okVj5ugkrbwcPYaF5InuWncbERISwzUyRL0_P_6zAGwuYAC5bs-mK=w94-h158-s-no-gm?authuser=0",
  },
  {
    firstName: "Collin",
    lastName: "Sexton",
    teams: ["Cleveland Cavaliers", "Utah Jazz"],
    level: "medium",
    firstNBAyear: "2019",
    college: "Alabama",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfWnEIRKauwiUWH8z5ysZBag_jscIDDazM5KZcLMlOC5k2yAZpk5Y8pHwujfeZnjr0JEsFHU_fSBNXBz6bjtWcCUA3WDF54BaEnPSXztfaL1wtw1AWsfZ0w59FAg0hh1u2r4rRCV7OJzLbpBN0sp1Bq=w117-h155-s-no-gm?authuser=0",
  },
  {
    firstName: "Lauri",
    lastName: "Markkanen",
    teams: ["Chicago Bulls", "Cleveland Cavaliers", "Utah Jazz"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeQFeW5gTUj9Rqgs_cTemOTn7b-f2YQ1npWi49HpAb7W1ORrGgUuwLL5jd4efL0WhaqGq0roY3TfIDtifnyzc9ZcwxS0OdD6O4Mtu0L7TL83_fufJYp4AgUdDxq-sl-B3maG13qhieDtqPvUQZ1j_EK=w111-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Darius",
    lastName: "Garland",
    teams: ["Cleveland Cavaliers"],
    level: "easy",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHexFg46R4asPkO79cVb5ErWTsLgA6kDl-41RMyfBzeetv9ATCrFN7WnogTvNbLS-kNTPYzmeMfiXLPQOLtzIFn9HqQTi0NGFEwILCj9g2GIF-xP35yDx9aqbSYCpOpPP_-43mmkzhEkcydTUjBxfpcE=w104-h135-s-no-gm?authuser=0",
  },
  {
    firstName: "Isaac",
    lastName: "Okoro",
    teams: ["Cleveland Cavaliers"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfIa_sZlqhIQd3RGBQxyYTllgFcPdp5T6VIUo5Z1kCe23a15YPwyw35QQsemaANwv2dRbu2YHwpXIzGOFN3VSpLyzV9CO6Y3SsjjIw6FwnVTRQ90NYrXo6Sr82zbNbU3UMdGcy04uaHJvetpTp50E3q=w96-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Jarrett",
    lastName: "Allen",
    teams: ["Brooklyn Nets", "Cleveland Cavaliers"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc-r-4oe-pv4JJmdBMtHHotMlo7QmF6KPMXjj839RVOCWoVi5nPY0bsaV13hGPIVelMrKgOMLlWbjzRKEkI3f4P2Hyn79UckdTSGaF6MVkCxKi8X_O0ELiEVX2SVokEMYr-cR990zXmqUr_NIzrklOo=w104-h138-s-no-gm?authuser=0",
  },
  {
    firstName: "Ricky",
    lastName: "Rubio",
    teams: [
      "Minnesota Timberwolves",
      "Utah Jazz",
      "Phoenix Suns",
      "Minnesota Timberwolves",
      "Cleveland Cavaliers",
    ],
    level: "hard",
    firstNBAyear: "2012",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdN2fTUkvl6_PO2TFk8eylFEMwZUUODdu9WAuhn5RE8n7SbTUEP-_abb3AaolnXVkuAXO0Kppcge_KNTT6zCTAtixNi4R1Tn16KsEZW6DFzNjst7lz6TnhaNqA6hghKWNs_Nm01SVvdtVUZoYiIeEG0=w103-h146-s-no-gm?authuser=0",
  },
  {
    firstName: "Cedi",
    lastName: "Osman",
    teams: ["Cleveland Cavaliers", "San Antonio Spurs"],
    level: "medium",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdEXyUy3nDosmqg_pfyvr62QIhPbGnfrj9ziNYaLHUNF-ALrthN8alN3LyK-LSmGbdIYCxZKr980STSauPCqsSv-zPZwH_3wiFBdUnnzdD-9MjO85aZ0GSMbt-X_BGjOT3MDAViQQ4pm92cXVU9j1pW=w109-h129-s-no-gm?authuser=0",
  },
  {
    firstName: "Dean",
    lastName: "Wade",
    teams: ["Cleveland Cavaliers"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdKUUDqagzWzO3jsl-oI0a8ZP-GVdjoT4XR91JO9Vl_kmYVyKnvQjMycaFCThtrSoQjo5zeq3X_FhPsQGzVH2Iy--NFhJRS1Hefx0pBI0P3WLAcSdjHqTUCusqYnyzm9Y55apT9jvaUZBNhIx0cPuTh=w107-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Jaren",
    lastName: "Jackson Jr.",
    teams: ["Memphis Grizzlies"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdVN1Y66ZRRlAG4AOtoXpy47KZNbs8OXLG2MZjwHCA2WpOFnLyMj54Om3NX-BL0MXgDWz5kdV5WZhYTk6Hlhh39PLpJvtg1RqAErXvcEzdNJPExdwqIXLl0cb5L3ZLTDVQE-mHMrREXcbTRRGLW0hoK=w114-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Damian",
    lastName: "Jones",
    teams: [
      "Golden State Warriors",
      "Atlanta Hawks",
      "Phoenix Suns",
      "Los Angeles Lakers",
      "Sacramento Kings",
      "Los Angeles Lakers",
      "Utah Jazz",
      "Cleveland Cavaliers",
    ],
    level: "extrahard",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeCqTFwqNUnxEIOWowzrHo_f1XkBrZc9y75i1sPku0kGwZALAPvzkS_Cu6wX0VLUkZwuHBMO_avEtQgB2fEmRTKxqU-UxIv2YPKkqhF-DmAmNCtocJoIRYe5ez0YjNShi3z8usFIESxIz0SDzB12Auy=w114-h145-s-no-gm?authuser=0",
  },
 /* {
    firstName: "Ryan",
    lastName: "Arcidiacono",
    teams: [
      "Chicago Bulls",
      "New York Knicks",
      "Portland Trail Blazers",
      "New York Knicks",
    ],
    level: "hard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc8HYt46IJ14PiNfEUZnMwiituIznYx85F4hD5eOd1iNOmV5ZL__VOSx3ZYVX4pSE_hKY3MRpx2FkY65EZLKnn1QF9KIIKTjw6HYC4-VnKqYTGDTHjs2KIpnXRdnqNcARK16PldQEzli31wFGUNQYvU=w105-h151-s-no-gm?authuser=0",
  },*/
  {
    firstName: "Zach",
    lastName: "LaVine",
    teams: ["Minnesota Timberwolves", "Chicago Bulls"],
    level: "easy",
    firstNBAyear: "2015",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcdK1x6PqC-HVgJ1Cgego7gZgYKOJmtPKeuhgx1BEwuWyuMNCPSCPLq2SsYAN1aKaLOW88hMnRs4S_7nuJUU6b0jWzui7uKgo84bGqoTk3EVAKDjAWob764M_bkPC37DGROsrPCVHLWp__DOycGkAMX=w122-h118-s-no-gm?authuser=0",
  },
  {
    firstName: "Nikola",
    lastName: "Vucevic",
    teams: ["Philadelphia 76ers", "Orlando Magic", "Chicago Bulls"],
    level: "medium",
    firstNBAyear: "2012",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHftWcVFOgptjjho6saBkbbgUfLTUnY3gtOjPZMPxxA4aRmSfMpytTee_b7Ld3vWxd4WVozh6oN-lvVuH7YQIzEYhbZT9O_gPNw_q_Ixfc8cdP5u2brkgDHmInY3sPcz6LFdd_iMHec_pAyantVylP-w=w105-h152-s-no-gm?authuser=0",
  },
  {
    firstName: "Lonzo",
    lastName: "Ball",
    teams: ["Los Angeles Lakers", "New Orleans Pelicans", "Chicago Bulls"],
    level: "easy",
    firstNBAyear: "2018",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeNaNzDhUQ0NyYBbIDu0EOVMJ4m6O4hJcDiyQ4cVNJsOguhCBm665my8klT7OTXv_VcJg-SdP0Ic0Wj_60177bU7w6H7MDa8evo3QHOZcKsX0aEr9btVLMd6H7dyNgM4AsMQzY25sjG8CwXoARtQBJx=w106-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "DeMar",
    lastName: "DeRozan",
    teams: [
      "Toronto Raptors",
      "San Antonio Spurs",
      "Chicago Bulls",
      "Sacramento Kings",
    ],
    level: "easy",
    firstNBAyear: "2010",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe4IV-chV1gBnOku0dWkHznC3xOK_bIs3GjBsPzBFl8DFhQJAZVaTwORq4v2fzAl7PIRJ5Ih1tfew0JWfdUU-S7n3ROo4D1-XntWcYOlYXAk9ukjD6JnZLRm1IDKeFfr21BZwZtWePtG470a3OtVwIn=w108-h139-s-no-gm?authuser=0",
  },
  {
    firstName: "Alex",
    lastName: "Caruso",
    teams: ["Los Angeles Lakers", "Chicago Bulls", "Oklahoma City Thunder"],
    level: "easy",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHegJzua14jR6-zCSbLZKUeLmI0ly3cCDBf1TVdaQY7vyAmUP6x1pdyCrINL7_FxumrxGg1q6xzZZqWa7zaZ6X6C1r1TsQfr5gxEZL9IovHd8swA86eRICTmW_c-sV91XMnlnbEiExxIzEVexsVYaaVP=w112-h138-s-no-gm?authuser=0",
  },
  {
    firstName: "Patrick",
    lastName: "Williams",
    teams: ["Chicago Bulls"],
    level: "medium",
    firstNBAyear: "2021",
    college: "Florida State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfThO7sbwMB_LaRM8R27Rc7RVA2Ma3d0XVe5mHO0Yp0TmJ2dppd8dWOL0brdDPlR29LmGBdh9A_Pcshc1YrdEr1pVn54TS906rJD5z84D2qREkqaNn59ZNFodrs_5JbwutV6vs5G-cLVBhc9G0mUZ6S=w107-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Coby",
    lastName: "White",
    teams: ["Chicago Bulls"],
    level: "medium",
    firstNBAyear: "2020",
    college: "UNC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc3cYCvkGXbOk2-HDkTAV9zq4j2rE5bbCwwfIDiy76RwsxhJuL4peoetNKm-cIeHgqO3U0vtkujlivF-g0hlbYJZ8bFo0iyVHRy-2JvJFJow17eBYoT1hCEVgoZDBMM2noeyXAvXjlN6TiBLsNPaH7d=w115-h139-s-no-gm?authuser=0",
  },
  {
    firstName: "Javonte",
    lastName: "Green",
    teams: ["Boston Celtics", "Chicago Bulls", "New Orleans Pelicans"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd7E6R6tiHbGml_VZfNNYHYph1ir1INv_Qxj0O6zKa6-ZP1cVekVQAOJ72SM9k4tuF5hiHJLOBTfdcbeJy9jpcwYR55I5yyKdMVpzUq38D-VzY0DsEqilmkrrSC0w4I9nCTlCR8A7Uqa0meD32WTgTb=w110-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Troy",
    lastName: "Brown Jr.",
    teams: [
      "Washington Wizards",
      "Chicago Bulls",
      "Los Angeles Lakers",
      "Minnesota Timberwolves",
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "Oregon",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfojEvO4ozlrDyZ7op6LGzurOGaH_V4Z5Gk0t7UPYZhEEvyzW9bNQN40H89bsiyBAf_FHoJGdsmbA2_nJZ2xOaO8SWkltgK62Z_s8QCV9FIqSzBG6Oeq9FCI2huZ2UqCfF_W5cTXLbVxVbpGWDRJK8r=w112-h139-s-no-gm?authuser=0",
  },
  {
    firstName: "Ayo",
    lastName: "Dosunmu",
    teams: ["Chicago Bulls"],
    level: "medium",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHerz0DzUu2KYdHcLKa8qzCwOEIcuOjcsDS1vsN7bIS9CNRkhU-ZNk9gJCoYUOSGvEjvQuvbn5dPFIs0OpSv-JmAJ3P9e5a5vm_s_3LEuSsXKErFewHHO9vO-Q5XswqV0ApKcqG-TsZ2c6Lw8EM6mSCY=w107-h128-s-no-gm?authuser=0",
  },
  {
    firstName: "Tony",
    lastName: "Bradley",
    teams: [
      "Utah Jazz",
      "Philadelphia 76ers",
      "Oklahoma City Thunder",
      "Chicago Bulls",
    ],
    level: "extrahard",
    firstNBAyear: "2018",
    college: "UNC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfApbJZCQq8G_03omc6TfiNlNLSl4Q8FjANz5cyN1DkwtcNAk31aAOj4gkBf4S1miHy_eQv2WTaXi-cesdBRh8Xd7oER4K8BeWrOmr6fAgt35roym7ClwNjZFgG6njA9FEHhjxg0GR4r7ofsDuBoydz=w114-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Stanley",
    lastName: "Johnson",
    teams: [
      "Detroit Pistons",
      "New Orleans Pelicans",
      "Toronto Raptors",
      "Los Angeles Lakers",
      "San Antonio Spurs",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcnK3KWYz7p9Ns_zr7XCbqolDJyLsk_sqbgYX7m19glUSbZJWlUeRE90oyY1w7Xylbo3RhYFVK1gTtllari-42GdxLILEVsNxJH2b2c38ZEMRoV87esHZK_P9b8aBV5BuGxVIBeW8S0l0juj_qbXOV0=w113-h138-s-no-gm?authuser=0",
  },
  {
    firstName: "Marko",
    lastName: "Simonović",
    teams: ["Chicago Bulls"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf8dZXL9fxlqNYGt_6B6khtYndI3Yish7GNvOlX_VO-3lLPrUEe1hYjvP9REMSZs-VIkH_SB-ntweSJOfGNIxXpFXst19HwKEb57CU1Fh2xvq3P1_16KqxdUo5eO1qAF9rAl5j0eWsznZu-Tn7bbyu4=w108-h135-s-no-gm?authuser=0",
  },
  {
    firstName: "Devon",
    lastName: "Dotson",
    teams: ["Chicago Bulls", "Washington Wizards"],
    level: "extrahard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcD3BQOjp7SM42ByzUM5uYyo9BR4bx5oIVJ4IyKwQgBVX1cdDgSjP7BxcnmxdKubjnV8LVf3qtTNGLnPqWpj8XWMOOaf82MgjgnxAJAcesoJsVOL5przkmWTdpCXMHriYzY1ZeqTwYvt7ARQULqe2y1=w106-h128-s-no-gm?authuser=0",
  },
  {
    firstName: "Derrick",
    lastName: "Jones Jr.",
    teams: [
      "Phoenix Suns",
      "Miami Heat",
      "Portland Trail Blazers",
      "Chicago Bulls",
      "Dallas Mavericks",
      "Los Angeles Clippers",
    ],
    level: "hard",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeFBjCxH30vlL5Esd55KXSAPk7tsHbaqK7rJzWg1jNzu68p1HEbrD1znrqhmBWibCmksTGQD7Ct0pONRoxA-ico6WRlnJsudWdm4psShmw5qOdng_AokUk2h3FSg8U2q6VXaSMrG-upchkf6nVaI5bc=w117-h139-s-no-gm?authuser=0",
  },
  {
    firstName: "Giannis",
    lastName: "Antetokounmpo",
    teams: ["Milwaukee Bucks"],
    level: "easy",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcElmIJ6UGcXhaKlgqvohg2cgWR0gwhEIWIKq_b0XdqVHmJkolzeUUOm7lhCta-YUb-JHGeHrsMxQrfPPzMzDmEk6T-ntjVJUw7tEV5VMZeQ-hhxrwxESr6eAdYl5hXbYCRE3SGKCzhleJDxgNGGYFt=w124-h138-s-no?authuser=4",
  },
  {
    firstName: "Khris",
    lastName: "Middleton",
    teams: ["Detroit Pistons", "Milwaukee Bucks"],
    level: "easy",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc72FkXIaMs33uZpGkFFCiOUh9PXPnQuO-39q5tmLXJvOrZTiMWSD0j4xboJEUiyXmfdA_liUaLjyJ2-PggrE-MyKdxSTcjyiVZUv5TIhhL8eK7tSxGySB3bd4SphtMgLj76uLj1OxTa1jANbHpFfY0=w100-h136-s-no-gm?authuser=0",
  },
  {
    firstName: "Jrue",
    lastName: "Holiday",
    teams: [
      "Philadelphia 76ers",
      "New Orleans Pelicans",
      "Milwaukee Bucks",
      "Boston Celtics",
    ],
    level: "easy",
    firstNBAyear: "2010",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcNZzN14Wr-9wrGKqdu6ECbfC5xyS3PakMmV7vcXbc9aj_VQsQ6_3CQ3b9njF0Hv51pvKTZVGh7k0ZNvug-TDTcU4vKPHLfqs_3cNWa3quF1n0ipp2mnEuJFiPh3kVpydpV7L1XkVwSja4axBIN84nX=w106-h147-s-no-gm?authuser=0",
  },
  {
    firstName: "Brook",
    lastName: "Lopez",
    teams: ["Brooklyn Nets", "Los Angeles Lakers", "Milwaukee Bucks"],
    level: "easy",
    firstNBAyear: "2009",
    college: "Stanford",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdmum17_1S3puIdXSkBuVWpeI8fVIKTZqYZCsVbYyjl9DoEbk_gEt8FuuOaXM4_dTNWS9mPIId0CWG9SMV3pekKQ2_bD5j7b1WesJ1CAqY7Lou29XtABlzrwEys_MjT-Qpqtifq6lAlmas9eZ3Xw2eq=w98-h142-s-no-gm?authuser=0",
  },
  {
    firstName: "Donte",
    lastName: "DiVincenzo",
    teams: [
      "Milwaukee Bucks",
      "Sacramento Kings",
      "Golden State Warriors",
      "New York Knicks",
      "Minnesota Timberwolves",
    ],
    level: "medium",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeXbzRzfezS6mQ4sWdUjB7iNxMlO2aRvHWZ4rmFmce0EeE70nQJQjf__ie0_jZ5bj8U6ClisHMY077rc07306H-ObYUeYDUqehagh9B8pSQMyOA5E_AAzoXzGB07LBuCrigX4AFc1ivR6VqR1UJ4Er2=w98-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Pat",
    lastName: "Connaughton",
    teams: ["Portland Trail Blazers", "Milwaukee Bucks"],
    level: "hard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcDylvr7WIuh5Gy9VluUO1kxE2dL7GSaOGz7gGTPbiL2Y4Y3ys205wmBLcFo8QVyh2dGk62ckvc_BisPNcwXST2s71y38We-62aUKCWNrsaS86LKAw2IoVqkMLPCkOfJrhHeZZoVZIKy5vFoCTcQq8e=w109-h142-s-no-gm?authuser=0",
  },
  {
    firstName: "Bobby",
    lastName: "Portis",
    teams: [
      "Chicago Bulls",
      "Washington Wizards",
      "New York Knicks",
      "Milwaukee Bucks",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "Arkansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfTvmToj2hKM8Rz1fxxc81wvWaWJfWwQwV1MxW_7mxlsvchIgbw80cpJf25R-ygmTOlUpszE7eboMZMU8fuwKuZQ7xaxFq6moZ8U_BhalRxYDUAybozwQa_Uj58DOWp9DNP92qTHdyf_ZG0V4fMORup=w90-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Grayson",
    lastName: "Allen",
    teams: [
      "Utah Jazz",
      "Memphis Grizzlies",
      "Milwaukee Bucks",
      "Phoenix Suns",
    ],
    level: "medium",
    firstNBAyear: "2019",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHctvcw-A_57iX99sWokGsEaTeXFUJA1zWXbKLNFs61BQ4HNvu88sZ50pYjpbwIQEVHItk8yEWmLjGgOE_z46LrV_I9sQMK5wn2eQTQXqY_Tt9ogNwL46TFJkdc9t6UybmqlvMzlm_SIEDyx2GwbTFcV=w108-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Jordan",
    lastName: "Nwora",
    teams: ["Milwaukee Bucks", "Indiana Pacers"],
    level: "hard",
    firstNBAyear: "2021",
    college: "Louisville",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfrBXATkHly3RWG-wjMK8ITW0Ybt3a0bk8BqNnOnU3LjizjzGungI_iEa3Pg1YjrizAFHGRc3KqxuWm9vHF79DBJEwFcScQkDgT4fl_yaQPln5SBeVOxSMYwSzCXhci0ZR2YwwLZqrWtsnck4Xulim6=w116-h138-s-no-gm?authuser=0",
  },
  {
    firstName: "Sandro",
    lastName: "Mamukelashvili",
    teams: ["Milwaukee Bucks", "San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdu5Z1vQrghePi84-WznALYK9VpitUbv80Y6JVXVEcz24G8QTVO1rFUA2-fswIWI0KhVqsuI13mzwxcxQDpX1M6kILGGZJo-HyzRVqs63XQPVu1J7W78uoERne77mMYs02HKFlJoRFNyeiuOEjOwUsR=w108-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Thanasis",
    lastName: "Antetokounmpo",
    teams: ["New York Knicks", "Milwaukee Bucks"],
    level: "hard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfpzlp2H6OZbOS9Fei4hVZOAu3Lq0sgedMiHE44wRQI-1CS4Z-jQcUQzAsxssFQd_ctO0jTejVdomJg0LMkJ6jBBANJsHxYahS_EhgVQUoh1CFvz1qOWZvC2gnkztwpnxR6vkfAGxJ84zufU5_W7egP=w108-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Mamadi",
    lastName: "Diakite",
    teams: [
      "Milwaukee Bucks",
      "Oklahoma City Thunder",
      "Cleveland Cavaliers",
      "San Antonio Spurs",
      "New York Knicks",
    ],
    level: "extrahard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc0oA38tvSNqpElZD9cfmtfxj4BCwu3ASrwcEZlbhw8TrDGLnahDep9cbZYtXdAVFccGCMWTmc98enRrG4p3NwYqTqVx0PWsgpxLn7CMTZsiLBiFBNqigOSUmErYMgM3ovCxkrgSfUbaOjjiA7hih-n=w92-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Cade",
    lastName: "Cunningham",
    teams: ["Detroit Pistons"],
    level: "easy",
    firstNBAyear: "2022",
    college: "Oklahoma State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdddOhu6tQGxoc_OXw2ZnTDwcYKiqUjtX3o_Widtj7Jcu6OGpVuVFtuBfuBR-iv3gmhjuzbpJm5KRG332VkE5uNHFlwDGtpQk89J92SYRxYqR3xSngFLMR6RgUQvf5NJRUGXMwvXyisVLs-bjwN1IeA=w119-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Jerami",
    lastName: "Grant",
    teams: [
      "Philadelphia 76ers",
      "Oklahoma City Thunder",
      "Denver Nuggets",
      "Detroit Pistons",
      "Portland Trail Blazers",
    ],
    level: "hard",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHecJdf-8z7hRRVzRDCnpCEiuQgTgWPw7YnvMe94dzg-T7in_uhMTj_XO7gntTAKO5PHrVDO3UVaWfZdjF-HcVqloWhfFj-S4RToCAJ978Q1tRgn8dtknBW9R3kJyAk5yQizq-47xzIRHfTD_8GpUYBP=w118-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Kelly",
    lastName: "Olynyk",
    teams: [
      "Boston Celtics",
      "Miami Heat",
      "Houston Rockets",
      "Detroit Pistons",
      "Utah Jazz",
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHez0lTqTViXM1LqlDpw3ZHJ0ThNDSE4MBcwrzwFwEUwqopGwF9JvClOpfySF7f3GywyqI9fnxWW7DxUKWTq6Bh_8e9U_ouZfoQLg6Hm3ITSUGbFY6uSBPCgXARyKE59OtjuE9c4R-Yw2n9-5fFWC_AR=w111-h158-s-no-gm?authuser=0",
  },
  {
    firstName: "Isaiah",
    lastName: "Stewart",
    teams: ["Detroit Pistons"],
    level: "medium",
    firstNBAyear: "2021",
    college: "Washington",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeqH9ZDonVgIpzFH8xBBTG_42oiqF5h7XhXMuARY8YRGLo-fw4DnsszTvTo5_uYbnFq5W_yGVTOsV2L4t41VfUE864bThfLmozbxMbDvT6aJxAz7JmanoqN9HVWaYz2b1yWKI8ipbklpWTv326a7_nd=w106-h162-s-no-gm?authuser=0",
  },
  {
    firstName: "Hamidou",
    lastName: "Diallo",
    teams: ["Oklahoma City Thunder", "Detroit Pistons"],
    level: "hard",
    firstNBAyear: "2019",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc7UA6cbl1uByTk44_5PaI52abx9loP9bATVKUiJXU4h3QOZ-cooHMMQwmy0uFzHPWq8UC0CfJAoI2gYPMJxqWyySyeP0BvlNqACz3ZUaElFLcz9pnsFOtr6cQk2RvwSGMbrZB9viJsxb_RUtbxUz95=w111-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Saddiq",
    lastName: "Bey",
    teams: ["Detroit Pistons", "Atlanta Hawks"],
    level: "easy",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfZgQPQeJRXbni5Y5r3j9d0jG-AVXmj8VMfChXIAGiQtS8DvMEyTowcI6hDYPPzuiimJQbgEfzDTpQCaYJtbMDY_USbLOe4cGrQBmzsSriA8psIPNkpKO3D-vhYCrsDb7eWnEuiNtyOmA9GJ7bbsTml=w115-h157-s-no-gm?authuser=0",
  },
  {
    firstName: "Killian",
    lastName: "Hayes",
    teams: ["Detroit Pistons"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHewZYcgzcBPJQkeUVLBl9_ucmYIu6OXD00ArSAIUT__JoiejWFD76PKMIbxU3-8npG_6WUbmTqRWqr5h9LSbSMDNSf-sdXg4psXhYCG5A5gMaKrw-0qab6nAXVfa97SMgvb1-7fE7FhLLjPTMu1GSdS=w111-h153-s-no-gm?authuser=0",
  },
  {
    firstName: "Frank",
    lastName: "Jackson",
    teams: ["New Orleans Pelicans", "Detroit Pistons", "Utah Jazz"],
    level: "extrahard",
    firstNBAyear: "2019",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe0K6f20qvMZj4rPt_6zSkJzKAhxakh8rk0xSv-k_GBqpYq2lxjvkv1sD_SkRTRPEsR5tMUusvjwfsMjMza7B2tNBzrD14Fg8xOC5ePeGtcUm8D5xhR21P1Ti68gfxM_82BLdw9fadvOCxQG-vSvp-t=w112-h136-s-no-gm?authuser=0",
  },
  {
    firstName: "Saben",
    lastName: "Lee",
    teams: ["Detroit Pistons", "Philadelphia 76ers", "Phoenix Suns"],
    level: "extrahard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfOl4xRYkIn8jw7cwzdk7MYWFguGojZkAV6xRymQCy6qbysglyN7oVNoh_viWg3hvIAcuN_r83ijcMRPsM9FhbJH_QA-iOok2OF9oYj-xSiFAOEtfuSh8jV_Sw4zilJ098dwGO1v16ZQtT9VfmlRDfC=w109-h159-s-no-gm?authuser=0",
  },
  {
    firstName: "Cory",
    lastName: "Joseph",
    teams: [
      "San Antonio Spurs",
      "Toronto Raptors",
      "Indiana Pacers",
      "Sacramento Kings",
      "Detroit Pistons",
      "Golden State Warriors",
      "Orlando Magic",
    ],
    level: "extrahard",
    firstNBAyear: "2012",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe4OfQyLqSLFtEYUmxzCmDlKWAX5jOVqUqck3WV5taVQbPMRIo2vhuyHY0VmLK3YJ_ojQmkDQ6o1cDVCFrt0Xts7eZ83s7pMke9Z_kyJuvBFR4tPAWv1-w2X2rNPV_WQ2mYZ808Qs5BD9Zr5wN9XUBG=w104-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Isaiah",
    lastName: "Livers",
    teams: ["Detroit Pistons"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Michigan",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdDvLhyrB4Sjv3iv60UMGhp6_TfIsJ2WjOngoqwraYln-eJpbseIRLG7nnNkquMNHFpH42ROROEesPTOywsAQg1T5JsE6YcTQVsqzoUYUFpV56nko7SNRT-1v_ydMuHT_sXzLp1DVVSuNIrWrwolzsX=w116-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Luka",
    lastName: "Garza",
    teams: ["Detroit Pistons", "Minnesota Timberwolves"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfTxYsyWqG8WqH2lTahV5hwYPYN7F9kEI1lI0AGbIOJEPANbINMOPQkkRgHpioQSjHh3lRBjI2-Lj65ggaTDLGlX8bkSiTByq0vaYzvkWMdOPoo8RY-r9LakMlvwVzrgUQLXFwkVDMGh4SwbclqztVW=w109-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Trey",
    lastName: "Lyles",
    teams: [
      "Utah Jazz",
      "Denver Nuggets",
      "San Antonio Spurs",
      "Detroit Pistons",
      "Sacramento Kings",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfT-YLDJ-7vdYnURAEZcxN_RUhHpkAu4h8dFizMUhzVC1ftSHZgtWKfpwuYe_3uJLrLbKQBLae_Y9p-ntRomLytsvu7LSVPIeWq9jlWzN51eLRbCsLHDZFjfAxKpMVXEkXgbpap1tyN-VUd0sQvs75t=w115-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Rodney",
    lastName: "McGruder",
    teams: ["Miami Heat", "Los Angeles Clippers", "Detroit Pistons"],
    level: "extrahard",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc6gGw5zG5B5y2N87xh9NP3cYXFvsbCG6ts3c9DL0mvM1WZ-74NFdcFDLicFMxdouA1MU7sv7YvElG8rBpOvtyTaI4jSAoYW3k4-eqFpRh_LeVf5J8UKY-dYpxYJuC49CEjBQGOpEeN7XF2idm2YBaS=w102-h138-s-no-gm?authuser=0",
  },
  {
    firstName: "Daishen",
    lastName: "Nix",
    teams: ["Houston Rockets", "Minnesota Timberwolves"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcnXfJMiT0VIdM9stxsTQp67SLSPW8QkzR9hMn2LAICRrfrriEDHV8vAZwM4t3jHgQzsUAw6_B5pkX-WvPfrMSLWVt8bsLGljN-akntt1SZJxNrbYO5ehBBPwPIClFiXnQQHrKGaZeIGAGZ9z8SNRIk=w120-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Malcolm",
    lastName: "Brogdon",
    teams: [
      "Milwaukee Bucks",
      "Indiana Pacers",
      "Boston Celtics",
      "Portland Trail Blazers",
      "Washington Wizards",
    ],
    level: "easy",
    firstNBAyear: "2017",
    college: "Virginia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHevpOxTfLEE-v-VonCfCnyCZtS8zRosIpMi2LdQNS48dVHpjbpBLkEdHRJCnEPKOV8Az2o29D7Knq86LEfP6cRM5wnaW5OwA0UJDwRWX4xV80mpJznJhp40XwzkrljfFUP6LE2XZB1NF-BVtfnbIsq-=w90-h152-s-no-gm?authuser=0",
  },
  {
    firstName: "Domantas",
    lastName: "Sabonis",
    teams: ["Oklahoma City Thunder", "Indiana Pacers", "Sacramento Kings"],
    level: "easy",
    firstNBAyear: "2017",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcNso6X09ywcChEXL92YyIg22c52DJiJ95VvjOw5XR2W3B3JfHTXT8MGkceUAhNGsYSkeznWHQV15uM96CKAHIroy1hdQxHmufp92oVhFtwQD_CwKXNXj24EwcLBAHkd02CbKflYjk7LVjLGlOE-JXa=w110-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Myles",
    lastName: "Turner",
    teams: ["Indiana Pacers"],
    level: "easy",
    firstNBAyear: "2016",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdJocBXNVOlpvs25X7HND2x7dygEC7xaetHDBORGuZmrPkL9kLj5jzffT1PAPyJ50rPJJFoqZE712bxws_jnlziL3vjL4vJ7Bazo-41Y4zXeNqs_AIAIdFk2blMzUF63f0DZW9635Qm0Yiyq6TkmzYF=w106-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "T.J.",
    lastName: "Warren",
    teams: ["Phoenix Suns", "Indiana Pacers", "Brooklyn Nets", "Phoenix Suns"],
    level: "medium",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcVBay2VhNOh-BLDBt44cejulhkDRIiwhXY9ykEZAE5N1KZvq4DYhzEYomohVhH4kJhcBc0dBoV6Ienz01_jZJGcqhmI2KlyXYag3Od_Ngdij_sSA7zgMk3UbBhmrUVAnbBkeQLuhSeV3bElZtx01UT=w108-h139-s-no-gm?authuser=0",
  },
  {
    firstName: "Caris",
    lastName: "LeVert",
    teams: ["Brooklyn Nets", "Indiana Pacers", "Cleveland Cavaliers"],
    level: "medium",
    firstNBAyear: "2017",
    college: "Michigan",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe8m6M7nsjasPhntgUn5VVBPTo5cgvu3dMAdoL6sdzwO2JpT2UPKeO95thqhNDYAHo6oah_qo8ulm3T56upIdlCinunqmtse6Bsur8-dDwdEC8amhvoK4ejyYOmSt-KZJ2t-ZRyeePHWXyqqPPKXDrr=w106-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Goga",
    lastName: "Bitadze",
    teams: ["Indiana Pacers", "Orlando Magic"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf2i8k66_CYRuxfz8AK577VF0Uyai0ML2NxdnDGRa_844aZM1VKaNHErVeHNmJ1cg22GSamvnq8b3ZpNgeUP4LSM1-NrlYPTHR3ohrbJTMlmDSSETrQ_Ou6qcE2qtJLyDWHCKu3a4vzRzRPcxNbdu07=w110-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Edmond",
    lastName: "Sumner",
    teams: ["Indiana Pacers", "Brooklyn Nets"],
    level: "hard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHczeE6yWwHX9SwZCa6UE5yANTCXvGZ0ftG3d8lgMXP7LqE549SgL8ylfydX6xBcyQP1IZ5bCi17tEoSdOz5HoDDDeCHvQu8cEsPD64lgSNrAUMc4R2q_gxwtmEeLooXPSPHkPPOYbjeCaHEsCT-SUxy=w119-h135-s-no-gm?authuser=0",
  },
  {
    firstName: "T.J.",
    lastName: "McConnell",
    teams: ["Philadelphia 76ers", "Indiana Pacers"],
    level: "medium",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf_rq6LyBqOqHn5C4ZfjhXkYb26LLvpp3H0wSGvy9CTNEO28rq1DFRnRL8JQgBPgYqu0cyTOr3WdXQJBfbCC91Ilk1gxlfmnTLHYc_m-l2tmLXXLBPOPuAQDTi-TrHQJrS_RntIsHcYdTV6fJToVYrD=w104-h136-s-no-gm?authuser=0",
  },
  {
    firstName: "Oshae",
    lastName: "Brissett",
    teams: ["Toronto Raptors", "Indiana Pacers", "Boston Celtics"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfov0dBhNGqkzj_DtD12a3eetA1qMenXyQGY_TcPzQ5n_RdEMzvLlo-ZGl51ClNwMNFFZq4kGnioOqtfaZl1--Z-RWgdikTcQEZf_3mBWhTw_1xYjB2BoU7Tf5WAecQjb0J_BhEk3988ts1q854BXC5=w120-h146-s-no-gm?authuser=0",
  },
  {
    firstName: "Chris",
    lastName: "Duarte",
    teams: ["Indiana Pacers", "Sacramento Kings", "Chicago Bulls"],
    level: "easy",
    firstNBAyear: "2022",
    college: "Oregon",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeRkEjo9xGET67v0xvd2oZjjW7MsjIuXqXNCNkuCr9KkFs3WlSdPLUObkfcMoBKvg2sThtDx5XHKY9DlGgLrfyOBk8UQ4EIB69IlEGT8Q0CjT9DdFcnBydqQv0iazJyyRJozL6pBHr0pCyGQsP3Gy2i=w116-h148-s-no?authuser=4",
  },

  {
    firstName: "Duane",
    lastName: "Washington Jr.",
    teams: ["Indiana Pacers", "Phoenix Suns"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeLEx_wdeFFQQqMnHzgqiJr0HVwGGKWAHx31bUOIqDzctH1zADGJdHO6EjCLwIY2S4D5vcPDiRSAVNuznd5CMZEXNkHsAbWAolZBnZDdRa6kDY-D01jwKUNkULVtnP4a_Tr1_Sxfq3IQOO3ccwRE4Ff=w116-h145-s-no?authuser=4",
  },
  {
    firstName: "Isaiah",
    lastName: "Jackson",
    teams: ["Indiana Pacers"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeBBDugwpgkBlYkERzP7pM4YedMJaNK9wl2_nz0S3xNwrBABfXW-b2zZQjNwMLh0tvoFocCzW3cWfsY4HaJnZfazutW4Bl3bALZ5TF3JNsC2v9Toh9IUR60eIHNSQc7xhfco8beq_AtzMxthYttxZZp=w102-h148-s-no?authuser=4",
  },
  {
    firstName: "Jared",
    lastName: "Butler",
    teams: ["Utah Jazz", "Oklahoma City Thunder", "Washington Wizards"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "Baylor",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcIrvpQkd4jlU5sXqA8LsB5BloCyTkxOP1hqWHUMpDQHQLMQhejJhIdGFswZoTdIAkyBOWtPAhzLFE3VW0pRQG2Y6dXmaLdVrVygB7pI-U8Glb0KCSt1BBGYUbmrv_hKDdud5ifJf6gUFhA-gULqxV8=w108-h151-s-no?authuser=4",
  },
  {
    firstName: "Nikola",
    lastName: "Jokic",
    teams: ["Denver Nuggets"],
    level: "easy",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHflcRw8WfDNN8IsPJsYxPfcRozpebtCnrSZ1eFurpWhgg374DzU5eq-RNa-kawxYOcYYDafH9G7svTsx6VL48hMfC-2uADZ2N5nO3rA_gqIhyvhxZfz0d7-gKQGkPhf8m4iaBfEkI1sV9jvd-GrxvpC=w114-h143-s-no?authuser=4",
  },
  {
    firstName: "Jamal",
    lastName: "Murray",
    teams: ["Denver Nuggets"],
    level: "easy",
    firstNBAyear: "2017",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfvf87j3X1LpljG4gKi0rezMMVU6cL1U2NeU110z_-o6dTHgRasjJo6IpCmtj12V5qGOg6fvVydQGnCWyDeIUl_IN0ukyf19qwpHH6l4XMJtjHDh79sPU3xUS-KG9dVcsZhSSnvDQU13TVPUf8IZhjB=w117-h150-s-no?authuser=4",
  },
  {
    firstName: "Michael",
    lastName: "Porter Jr.",
    teams: ["Denver Nuggets"],
    level: "easy",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcGzno5AsDDfCCmAAhfjCNIlKY7USUr0geDz5_lFWI6QCE3mUlrDSllM00uunBJlrNNCZMi3zz6qITXq4YB0AnhVGq1AvxAzFpXe4XrLpwaK7iiFlS_54ZiCfH0epjqImCndNf29pQV9x0JxAvFpY1G=w109-h157-s-no?authuser=4",
  },
  {
    firstName: "Aaron",
    lastName: "Gordon",
    teams: ["Orlando Magic", "Denver Nuggets"],
    level: "easy",
    firstNBAyear: "2015",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHct3lD0JF1UPEzUNy92c9DjNmkEmTmkVc9OihvP1xrZqf2Mh5iSJCrxo-jrpqkC1rjHzRV4My_8poTXW2DSwtPVK4WNTe3AWwMHUYJ-8xxXUTyIKh2McxMBjZ62A9EKzYNTRPBkgaB0S26pHgRUwEJZ=w103-h140-s-no?authuser=4",
  },
  {
    firstName: "Will",
    lastName: "Barton",
    teams: [
      "Portland Trail Blazers",
      "Denver Nuggets",
      "Washington Wizards",
      "Toronto Raptors",
    ],
    level: "hard",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe4jF_jGHZ4uGXFG0ZY2YIhBv-4eyS4l6mTwXYKF_M6PTG-dpbUQWFr6ygy9tlBx-cQhRDR9zRI_2gf7OKQeojghfEI1QtdGUcUZW6ghZWSn7HX91vMrK0bh2aPwrwgZeNkTj8g5nNNKd4itqhzrfdL=w103-h155-s-no?authuser=4",
  },
  {
    firstName: "Monte",
    lastName: "Morris",
    teams: [
      "Denver Nuggets",
      "Washington Wizards",
      "Detroit Pistons",
      "Minnesota Timberwolves",
      "Phoenix Suns",
    ],
    level: "medium",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcRTDcQUecR8A1SnuEb5LYwHmf6YJjzBZBMb7gNko1jBusBA-1z1_OiM5GOo1ryJuQsGvqF-rdb_RUjIPj-Ix8hddjA0YYQ4yR-vgxpP1oxAZgDUy6JYCF7GBzygWzLM7kV9gOH4C5zfaRaP3Ql4MdZ=w108-h154-s-no?authuser=4",
  },
  {
    firstName: "P.J.",
    lastName: "Dozier",
    teams: [
      "Oklahoma City Thunder",
      "Boston Celtics",
      "Denver Nuggets",
      "Sacramento Kings",
    ],
    level: "hard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdD6doEQkmfKxrAvO9-QZvBixpLyiMVCtZm8j4qYEL2AxI4gM3NNHSPLRUkBrQg0sarpUkVBBgnmqa4RpqRbwRHbsCsol42kg_UOJ_sksERjwirwZmAFX4kevb5gkYKxUumuAmkjRd093Fl6_OpOB43=w88-h156-s-no?authuser=4",
  },
  {
    firstName: "Bol",
    lastName: "Bol",
    teams: ["Denver Nuggets", "Orlando Magic", "Phoenix Suns"],
    level: "medium",
    firstNBAyear: "2020",
    college: "Oregon",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcqsKQf6JichkpsqqS8GycgvvoT5eY9gVNd5wB52SWqry-5pfRQXG2lUQCne037AsGln_N8wn7gn8uoxvKl39CfGCS3XTdG3ERhZ9ey-Q4z_LgDsFVOd60Q25a2sNjWH7foqc1id7DYByWe1ieMTSUU=w97-h163-s-no?authuser=4",
  },
  {
    firstName: "JaMychal",
    lastName: "Green",
    teams: [
      "San Antonio Spurs",
      "Memphis Grizzlies",
      "Los Angeles Clippers",
      "Denver Nuggets",
      "Golden State Warriors",
    ],
    level: "hard",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcFTi4JXvRGGNjkkhCgF-KIFeHhwfog0GUYb96w5N-emV46s4N8P_VI_1mGVvI_XsjFsXBKx03-9xnQM1IjQqlCyfFU4l5g58scQEMGnY-f-axiZn9nIbtggWHwNCR3HFxvLZCXXeKhTtmYYpemxEQT=w100-h152-s-no?authuser=4",
  },
  {
    firstName: "Austin",
    lastName: "Rivers",
    teams: [
      "New Orleans Pelicans",
      "Los Angeles Clippers",
      "Washington Wizards",
      "Houston Rockets",
      "New York Knicks",
      "Denver Nuggets",
      "Minnesota Timberwolves",
    ],
    level: "extrahard",
    firstNBAyear: "2013",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeYO1fjum8Ykjy57jceJ4cvrknCmBR7RhaMiRming8no6TBTA5kWyPeUbWNZ2a-TC84BcfXnlvQ3HNqrrGD5V0zkKoc-ryJ_qHlCiWzXqrrboTlQjj5J10UT2QYQMNbsYtxZeSlNys8QXbiXUx75fLA=w97-h143-s-no?authuser=4",
  },
  {
    firstName: "Zeke",
    lastName: "Nnaji",
    teams: ["Denver Nuggets"],
    level: "hard",
    firstNBAyear: "2021",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe4H4DWzG4YvlVhGU8S1KyeRfptRJHY0z07Z_J-B0Ojm_tNA0NtWxYpw0ULajAem_2S7zuLD2vUnidshMfDGTjLM9ZM8HAE5IWZwHSIgvdS2tfTKuX334LltxKY3hN8An8VvRG4BtIiOMX3R4dHORCf=w116-h162-s-no?authuser=4",
  },
  {
    firstName: "Vlatko",
    lastName: "Cancar",
    teams: ["Denver Nuggets"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdAfajk-4Jr7IUYr_L2VD8rlNTP_6Hqg7L_emGSYH8eJDn8Pbf4tTQswVoWthCfqYmva4YRLxgYC0RXgC1hWDMW37KLJxifTGA97GWJca1KOauxhLTWmTmKatolAjyoAVuqCCZ1u4H9pUnSr_os8N9b=w116-h150-s-no?authuser=4",
  },
  {
    firstName: "Bones",
    lastName: "Hyland",
    teams: ["Denver Nuggets", "Los Angeles Clippers"],
    level: "medium",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe2BzXwE_YXfx7L6kY-poulKQSDnUfEJEnjXdBbPDMFMePU56HADDqiPcWDxT70OJ9gsc8ttsvppL292AidC9v08E3A8iaxKyxAV31mgs1uiOpEG7HrYHpymffhAcJYmqs40izqHv6wrA2ho218xKcT=w102-h158-s-no?authuser=4",
  },
  {
    firstName: "Shaquille",
    lastName: "Harrison",
    teams: [
      "Phoenix Suns",
      "Chicago Bulls",
      "Utah Jazz",
      "Denver Nuggets",
      "Brooklyn Nets",
      "Portland Trail Blazers",
    ],
    level: "extrahard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdIPlNIX2Q0jiPdTUaBODqoz0vXIh9kPlPIAcXQLnQDYE4OPcbOAMR-D24rrK1afkkGq_YMDZGBeqbsQZOlhe5MAkdHyVyRWji87hY_IKiuO9kAD15p5X7RJ_0eNWGmmLgOqbeNh17mCdn7XM7-nd5i=w105-h153-s-no?authuser=4",
  },
  {
    firstName: "Karl-Anthony",
    lastName: "Towns",
    teams: ["Minnesota Timberwolves", "New York Knicks"],
    level: "easy",
    firstNBAyear: "2016",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfjWQ3sql2IGgsPuyLYtbeGQTb1kZeQXObKYkrhTz6KUdTlMZj_47qco_sGpbIFxNliWlGA6A2i1OuqxHV8m1EDL12vrpzxfmfJF_2v62F0gpX6rGHuRfqNHxQk7ivXJ1pOJAEDqEe4mg_kXVEWDS2-=w113-h159-s-no?authuser=4",
  },
  {
    firstName: "D'Angelo",
    lastName: "Russell",
    teams: [
      "Los Angeles Lakers",
      "Brooklyn Nets",
      "Golden State Warriors",
      "Minnesota Timberwolves",
      "Los Angeles Lakers",
    ],
    level: "medium",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfyA0LsJ2ILI5XC84izPX0v-DbWrkw43KwiO77OXIQxXSYFsw2_J5PrpN5916ou55LwpsgxGY_J5cOdUk-Z26xm_01tOlcVPvQSHQoEfIvNkZprmS2tTqB3ShZTCWZ5z8i-yaPDxRn_CWMmlCSlmmpZ=w102-h165-s-no?authuser=4",
  },
  {
    firstName: "Malik",
    lastName: "Beasley",
    teams: [
      "Denver Nuggets",
      "Minnesota Timberwolves",
      "Utah Jazz",
      "Los Angeles Lakers",
      "Milwaukee Bucks",
      "Detroit Pistons",
    ],
    level: "medium",
    firstNBAyear: "2017",
    college: "Florida State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdqxjr1taIlzHixh4UakQFLCi7HuBDPrRXEWpfE8JKAt0qOVFLALYyVuY_ip6BPTtws7r3Slmwy2CNZ1qIu8ENkK66-lXiaOakOpkPs60IQAY8mQ3vm4BkyTjB4t9vi8XPolOE7xF_15m6gmYkLFk3x=w116-h161-s-no?authuser=4",
  },
  {
    firstName: "Anthony",
    lastName: "Edwards",
    teams: ["Minnesota Timberwolves"],
    level: "easy",
    firstNBAyear: "2021",
    college: "Georgia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeAzUjXZSbR-Q_NLmVJGkWfJYL2m9ikozQFnvDjIgCj1TZ7ylXEvEaK_6V15xbMwacXYsKNS0xZaYMz5v5qg5RXQkXWkSZJ27K9Eo8rOBACzi11xl343oWgpK5HlYzsXjja4ckc2UqrI4UAhjEaJCyh=w111-h152-s-no?authuser=4",
  },
  {
    firstName: "Jaden",
    lastName: "McDaniels",
    teams: ["Minnesota Timberwolves"],
    level: "easy",
    firstNBAyear: "2021",
    college: "Washington",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcYVf5ANs6UNfTuY0BP3b763OPZWerW4a3ikaBd0H0_lL0yNP9GPhYheMwotNFJucVrmVGwgJBpCMMOPY95LrUZ16M-PRNKD2yUoB9KOhI4V0_ageK3bbPaFz7gqfwuDHPOihmdQ8FkoxYoV87rN5_P=w108-h136-s-no?authuser=4",
  },
  {
    firstName: "Jordan",
    lastName: "McLaughlin",
    teams: ["Minnesota Timberwolves"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeq1tl16HbXbihoR1Ss8iPp2_oe5UMYKEcwFwvFou6iVBthFyvpirEkF5xWkWwoQyeu6b7hY6aymC7k5kg8XnhQKDeBmKKhs7VDKSD5Hc68x9vs39qlbgrIyULgGbTzs-NukmGs94qfOvgRzsKaHn6S=w116-h135-s-no?authuser=4",
  },
  {
    firstName: "Josh",
    lastName: "Okogie",
    teams: ["Minnesota Timberwolves", "Phoenix Suns"],
    level: "medium",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcRquBOjFNcvrdLYYsew9po956MmuSzIS2LScMc4pePQZxBdpThbZXYHYguEfli6oJziXmoPnze4fIhpdWaSOtC0_5S_m8AaSg6Oq3TrQlnNdRhiL1zqRRTOy944CpNq-qjP-Ei9QkTY5w1ZOV4BUb3=w99-h130-s-no?authuser=4",
  },
  {
    firstName: "Naz",
    lastName: "Reid",
    teams: ["Minnesota Timberwolves"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd9mFFnt1hD5c8JJuUTAKkLr4Mv_k0CDG-f7-Xr0ukBaaWc_y0g4RtnTD4ZUv3JmA2obvntnH3Byg7JkDjxQ8sbwbRwPCi1f4HTY60OuPT2H4_aiblFucj_0P-yX048UanaHmFaNa7ZrGglalqtk4YQ=w115-h147-s-no?authuser=4",
  },
  {
    firstName: "Taurean",
    lastName: "Prince",
    teams: [
      "Atlanta Hawks",
      "Brooklyn Nets",
      "Cleveland Cavaliers",
      "Minnesota Timberwolves",
      "Los Angeles Lakers",
      "Milwaukee Bucks",
    ],
    level: "hard",
    firstNBAyear: "2017",
    college: "Baylor",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcWsQJ8IXP1RkXt61p4D_rPAiDab0HsHXa4b5VoSvLE_ns_QxN_bhRsHtuJldATmx7xqYpuRl_EFp6KU7-0l-nB2AQ2dxY9sV9eumU4Rn3MVCgEpvRRSxw9Bw_uHUR7MiTNxco3XWQmnqoNY3i1wtrT=w109-h145-s-no?authuser=4",
  },
  {
    firstName: "Leandro",
    lastName: "Bolmaro",
    teams: ["Minnesota Timberwolves", "Utah Jazz"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHefqAK3_dF7wc6mxa175KZRomZR0a1mttltftYr_an9qanr85ZGol1CDGGDCDIDYHkGUCXoAMJcelgLqc7SN4QsisL-ctt-WJuN9epjmyM9ypl_mNlqvNg_3CYOHrLXjA8K1-SLa6try8FZ1BwbRgPd=w110-h149-s-no?authuser=4",
  },
  {
    firstName: "Jaylen",
    lastName: "Nowell",
    teams: ["Minnesota Timberwolves"],
    level: "hard",
    firstNBAyear: "2020",
    college: "Washington",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc3RmPFR0OxR9CYlYFTT-FEHipzHBz6D8QLrxNtgu9QaifZxEXvv4ORRmuuqRwX1foosLidPgG9eTy05T2hM3gUWObldWHAorAAqL1QdAWOeXlWllqe8IDZjTdy-qT8CcKi_6uTmmm5cRdldNB2lcDT=w104-h144-s-no?authuser=4",
  },
  {
    firstName: "Jarred",
    lastName: "Vanderbilt",
    teams: [
      "Denver Nuggets",
      "Minnesota Timberwolves",
      "Utah Jazz",
      "Los Angeles Lakers",
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfKUJaOKhcEhIS_MCdlo0_lg5Qk1t3dqLM4B-QAJWBz5Ob3LUaHP79xE7M9jdxnPKHsmTxbkoxVEBgGbQqpmYwVPB5z-5h_WDfiFBylWubqkzi8K-lGj0Sqcd9f8ncEQyAsTdUtt7aVWarHU5iRtP5h=w112-h142-s-no?authuser=4",
  },
  {
    firstName: "Dennis",
    lastName: "Smith Jr.",
    teams: [
      "Dallas Mavericks",
      "New York Knicks",
      "Detroit Pistons",
      "Portland Trail Blazers",
      "Charlotte Hornets",
      "Brooklyn Nets",
    ],
    level: "extrahard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe6c1fsSdq6hxw4HOsqkEBzLpvQ7s1_8hMVUTSLWM3X99NqcLPKo-23zlloiWv8zucpNXcJKTUwgobu2otSf5wHWjg8DKADOBdlp9mbWaeuYMWffHTBXtKlTEr5mPWg1z4Xqm191vNbFpEOudbKwTq6=w113-h142-s-no?authuser=4",
  },
  {
    firstName: "Damian",
    lastName: "Lillard",
    teams: ["Portland Trail Blazers", "Milwaukee Bucks"],
    level: "easy",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeaKnVQWn5La6QcYrZOcBgHSzM6QA43iA0IRCA5TTfxCBzwJQRUHjRVu1YNRYX3imotnH4JeAdL4Ev8rutdHh80Ic8Ttc_-LY-_VKMTa0cgoLchJbv9z6WARnkY_n0RoL2vDy3g_Drjdd2jYUsp17pH=w113-h145-s-no?authuser=4",
  },
  {
    firstName: "CJ",
    lastName: "McCollum",
    teams: ["Portland Trail Blazers", "New Orleans Pelicans"],
    level: "easy",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcBM02xzf8-vYgCOyZaWeU8eEV6so6tvENh5WhUh8hGE3jgTVc7o7SCJxcvn1cHfU6K3sSDJ_j5e-zUqEVYX35vlmYfS-mhYCDNxI-CQVv5ASWLLfK8C72zDUHWcCKeNJtIXu4m9NdGWX_JnVwjNLVL=w111-h158-s-no?authuser=4",
  },
  {
    firstName: "Jusuf",
    lastName: "Nurkic",
    teams: ["Denver Nuggets", "Portland Trail Blazers", "Phoenix Suns"],
    level: "medium",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd5XKOnfk7fN-iTn_UGaq8sZA2UANI9FjAeRALLiWUVl1NXPTkREvINfgYVqxVeDF31VHfCJkLiGbOCx9TAgEqw8s7YHCgQ4ixp61GQIBQgzCnHnneuc19xdkqK_2RxOC-XUc26PMbHJ11lW2wTt4ne=w113-h148-s-no?authuser=4",
  },
  {
    firstName: "Robert",
    lastName: "Covington",
    teams: [
      "Houston Rockets",
      "Philadelphia 76ers",
      "Minnesota Timberwolves",
      "Houston Rockets",
      "Portland Trail Blazers",
      "Los Angeles Clippers",
      "Philadelphia 76ers",
    ],
    level: "hard",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe9I5bEkZZuFhg0Ak20VPKYIfzzJKdetor2L-jsZdncGzvaqWgQ-Qi80K0yC98X8lC1R7P-i6HFMXCtw_JI2OlZ3DB0dxc3coRAMx7jC1wa-PAMx6f6IWwIe_iVhCzLyKh345vECtPITR0AaMY--NyB=w99-h150-s-no?authuser=4",
  },
  {
    firstName: "Norman",
    lastName: "Powell",
    teams: [
      "Toronto Raptors",
      "Portland Trail Blazers",
      "Los Angeles Clippers",
    ],
    level: "medium",
    firstNBAyear: "2016",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfpWL5RQkTFlNM_BHi0T5eoACd20DnJbq9IsbT8gWkgNtUAzyWkbc3OSz48zxlwOKzE-XnapB4JzyiEFga202Et53BheMUWbk7KT3SigFSxx5s3rbNimEXpD0PIWoPB0HCtW9FT8pyLObpMgdW1ltDx=w102-h156-s-no?authuser=4",
  },
  {
    firstName: "Zach",
    lastName: "Collins",
    teams: ["Portland Trail Blazers", "San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2018",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfWqidtkwZVS6sZxDSi3G3c_HN6s5xG9trtyTxBkQfzoVrk5dxtm27roNHsPmeQS6gAhl1W2IauKMC8Lvym1Y54WAiZazfBHbnWEyQBgbwYBJ13cINBJThoj3KnTB2OvYcSpcie2i05HvqFfsPlNdcc=w91-h149-s-no?authuser=4",
  },
  {
    firstName: "Nassir",
    lastName: "Little",
    teams: ["Portland Trail Blazers", "Phoenix Suns"],
    level: "medium",
    firstNBAyear: "2020",
    college: "UNC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcZ3mgwv2SXaEftI2E2jcQJQe85E_GkMQq5RBE8zjORqmE76aQ-dmmnEVr8DGZw9MOkgM52rclw1cOvfTU_VSlUBnZL55-lk9wFCzGBxZq_VBOJnMZXDqJo8xYNf9MKSopURwOS7tFdvjX93_IldzuG=w104-h144-s-no?authuser=4",
  },
  {
    firstName: "Cody",
    lastName: "Zeller",
    teams: [
      "Charlotte Hornets",
      "Portland Trail Blazers",
      "Miami Heat",
      "New Orleans Pelicans",
    ],
    level: "hard",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe01MaDlV8Wnu3tV_uqTExIzZJFM_HFHfaE_TOI4FYv-siFpWmcYNNMdcC7julssj5hV7DJp0XdRujExeS0NeRbb7CoyBtJT6Ofm6NbtdcQegDk3x9xXg3k2fx8T4lqsUJI5mf1puaDtYSVlvauLsk2=w110-h155-s-no?authuser=4",
  },
  {
    firstName: "Larry",
    lastName: "Nance Jr.",
    teams: [
      "Los Angeles Lakers",
      "Cleveland Cavaliers",
      "Portland Trail Blazers",
      "New Orleans Pelicans",
      "Atlanta Hawks",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfGwNqWZAwuIqpxUKy3LXFfSUhWV9C6IlKFF-WVB6FFgaMgomOM2hJy__MCMWAHaO9FRVDJCwvcJ35oJRUv1cW5A1YjWN_LkRICMZDQvnWDNZkT4ewLoINa9NvLKCzSUAjaVEWABoKNxBwv80PVSj-z=w111-h163-s-no?authuser=4",
  },
  {
    firstName: "Greg",
    lastName: "Brown III",
    teams: ["Portland Trail Blazers"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd-aMABapiPysLnBFeIAEYQK6h_g-kQG5hgh8Faitm1JLm6Kf_v43RdRjjDnULS9oPCOujlXe0hAmJIfpixQoGYWOiIgezg8waIyRDCoQLfwGxS6LVI5FwacQihKlnjIlV9YBH5PO_X4GecUjL7T6ZO=w120-h163-s-no?authuser=4",
  },
  {
    firstName: "Caleb",
    lastName: "Houstan",
    teams: ["Orlando Magic"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Michigan",
    img: "https://lh3.googleusercontent.com/pw/ADCreHen8pPTu90siVXkr4B3NjOZy45wnNWCVU2CuAhigUuGjSCNQ8HhbtA6MBeFQvOK0RChvrWONkysupKIeZbTzcufIcl4kJVj-smrsmjKGdpPCFJatkoZnU2_GqzU-eHzHY_dk0DyrIyOtJtM1o8m-Bw7=w104-h148-s-no?authuser=4",
  },
  {
    firstName: "Shai",
    lastName: "Gilgeous-Alexander",
    teams: ["Los Angeles Clippers", "Oklahoma City Thunder"],
    level: "easy",
    firstNBAyear: "2019",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdmoAw347MRgMVdyt7x71tIYO76xym0d3u236_kE56CjsEkh-nMd_cG6idXiQCQdxIhZlJvgZPm5hFWDTL0J8OY6uboyQRoSTmPC8P9rTM-zwd3UXCAH2KCL-aqi50yl7niNrs6KOI2mDzzQeV3OhWS=w117-h149-s-no?authuser=4",
  },
  {
    firstName: "Lu",
    lastName: "Dort",
    teams: ["Oklahoma City Thunder"],
    level: "easy",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcG9XssaYwW0EPVaT9ETUvCeLGCM1RjecFmpEsvAotyqdOYY1L0y3s-evnps3yi-WtRI_iZ1EA2k2K-X4az9nROoHl_-FdsvvUCdanbpS8HNuC7VZxX-tzwpcvkAJtJ2mpAHpp4a7etTzVJ8JYIqG21=w115-h144-s-no?authuser=4",
  },
  {
    firstName: "Josh",
    lastName: "Giddey",
    teams: ["Oklahoma City Thunder", "Chicago Bulls"],
    level: "easy",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcj1BJEZPPeD-lTYoAlLc25r9u7ZO-mE9bjfC46HM6DXLEeS7TpdJkt1FemVElh3qY1BxfakIwUramBXGHDtdz0aQgWlAYh9TA-28NN5TgF7lRdsT0N_VDEEynGhoDH-n1wBZyJezIeY4G6mzX45l1F=w115-h145-s-no?authuser=4",
  },
  {
    firstName: "Aleksej",
    lastName: "Pokusevski",
    teams: ["Oklahoma City Thunder"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeavz2RPfO8aJAzYa5DHlDzGr8aGrFZL9uspGSjPa86nVMqwC1vpoSVjMGvAamNJdR1CG10FY5xc3eHdvRu4g_MHcSelL5_i8-Ik_H2IK-ns12Gn8wrZOg_nHl9lxLQHDJbu4WcgJ3tiLE1PdSzbWKm=w109-h141-s-no?authuser=4",
  },
  {
    firstName: "Theo",
    lastName: "Maledon",
    teams: ["Oklahoma City Thunder", "Charlotte Hornets"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdR3z0E-c9jLYDuZxrrZgVNmPRYI8kYN8I2p5SsOq5X1UZu1auJsoHRjuw6LSGZRfaHyuJNHuxAuZT89M83M5YW5VGI0sA0nxD214aKeIUnWnqLRYaULZQYIuchL4lfO9oQlOe_Izna9L-MTD3zU_yr=w114-h158-s-no?authuser=4",
  },
  {
    firstName: "Darius",
    lastName: "Bazley",
    teams: ["Oklahoma City Thunder", "Phoenix Suns"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf1YqlKK9AtPZQzs4qmx16IIFqipRCnb0kgLNnTR5VtHDsf825_A0mbrfsdCbPx4eyhCfUEpRx8YVuvbgjOUzvcuLzM539JNP3knaQhZyDqDGSRY1DZkZaDaj2DmmLcEGnE_WgjBX4X7C3o7hwK8vKJ=w119-h148-s-no?authuser=4",
  },
  {
    firstName: "Isaiah",
    lastName: "Roby",
    teams: ["Oklahoma City Thunder", "San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcEQEvHWdEJWaQtNbLfJ5vHtnwbTqdERBZqbzroy--0xVEi3q6LHeuRTXQDS0qy8-MFDJgi3J3KHp3daU2YTRah8VsHh2BL9DvpW_xIjOS3J8w91UKB2A1sRBvnpquVMb28MX5WdrD-JXMNI6xnO2sm=w107-h148-s-no?authuser=4",
  },
  {
    firstName: "Tre",
    lastName: "Mann",
    teams: ["Oklahoma City Thunder"],
    level: "medium",
    firstNBAyear: "2022",
    college: "Florida",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcBQ4hWsvd31cs5H8mjH3KZ5rUbNwUxEAlTZ21KoyI2EXoLSCUDx83jRz2dEGXRY1FRdthndYk501MKIk0hbRljtxDtTsgoVT7d-iS0YrR1Mj8NIVv03ijztZcSjc1_CcQoDYkiPtJlpZw7GXA3utZ9=w120-h158-s-no?authuser=4",
  },
  {
    firstName: "Mike",
    lastName: "Muscala",
    teams: [
      "Atlanta Hawks",
      "Philadelphia 76ers",
      "Los Angeles Lakers",
      "Oklahoma City Thunder",
      "Boston Celtics",
      "Washington Wizards",
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcOC2sUNfRlscZcN9qcx8OoWt6fdmplYanqW_6unAS5hONl7BiofX3qAEPLBHFN_sj_g7UdXqqakb2xOIl4Q0J4bK8wHe1L1Y12WHTD7vX4kgCxFalgouDw_UF8Y0U7kEdC5syRY8-3Gpi3kPYgtqGq=w109-h153-s-no?authuser=4",
  },
  {
    firstName: "Jeremiah",
    lastName: "Robinson-Earl",
    teams: ["Oklahoma City Thunder", "New Orleans Pelicans"],
    level: "medium",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcTLnMz0PGN_xeX_0qcor5UkZD75TwsX61LWnrcpfs2wcS0l8SX3uLA_-8nnLsM6vhVQ-oN9g_AI_Rc4uUKpoaAmiwKA7UCP3UFtLTxSsTYMKvKRvzoEqOIm5x45hcGCqcAf5GBMr0RVzZ5NryZBzES=w114-h157-s-no?authuser=4",
  },
  {
    firstName: "Kenrich",
    lastName: "Williams",
    teams: ["New Orleans Pelicans", "Oklahoma City Thunder"],
    level: "hard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHehAR1oenQjWj1AhEp5UmxZ3v44gUhJRNKufTSkbg_rSaQLH0n-1QVy7sWfx3ioi0sNWnPtqOA27jTNKpGoDE1mnSv8sUvwZerKQyr5y-7YaHRIOgiyVJMfQnSQss3ge-MGAB7rTCvZg_QFqNBwwbfU=w103-h154-s-no?authuser=4",
  },
  {
    firstName: "Aaron",
    lastName: "Wiggins",
    teams: ["Oklahoma City Thunder"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Maryland",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdZ8RYIiMN-IsNSiO_NSv9F7y4c3-wicQxjV6P0xkhpeqBUFDVjcDKvTedRK4DIONFrmtFtNwwIS78yF3fdqxsLlRj5v1lw8erpet-AC9SKDpokYIcBQ2EcgxFWyq4UfMSDUpHARrmNcqrRbSq-pyLV=w105-h141-s-no?authuser=4",
  },
  {
    firstName: "Ty",
    lastName: "Jerome",
    teams: [
      "Phoenix Suns",
      "Oklahoma City Thunder",
      "Golden State Warriors",
      "Cleveland Cavaliers",
    ],
    level: "extrahard",
    firstNBAyear: "2020",
    college: "Virginia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcO2vzgIwpDJHUeVeUMH5gRSAXVXypHAUxQHV-gh-DnjGEq7is9YzbkKcw2Ig5U7tIkVy0AxadtQ1zCfpS-Z6mIWSVCVTmhf_usnLcyM8lbPAoxtTW6nGCpLjARlmCPJDpKajAfPsLuRIP0wgUEisSl=w113-h142-s-no?authuser=4",
  },
  {
    firstName: "Vit",
    lastName: "Krejci",
    teams: ["Oklahoma City Thunder", "Atlanta Hawks"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf09nC3S2LV4zDOwaWXtRN12e6Goiz_Jow6OFAiH8ZbDFgc69x1betjOu5XNv6wFeOHO2_AApNeRlPzFAaOE1oPWkz47KGLT-9eoZUuTEA6egrlGTNd8zIqYDefleF0KSSB9IxMG1sYca_V6wliN5Ng=w118-h146-s-no?authuser=4",
  },
  {
    firstName: "Donovan",
    lastName: "Mitchell",
    teams: ["Utah Jazz", "Cleveland Cavaliers"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Louisville",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfBk3D9zbydtr0mnQDeUx_FIkdUh2C_k8fAPQ9ohcOrXiDEwEywavJdwNiIhC_7br6EawpOhR17SzKZNUuhkdRVfqLohyX-TE48LK5_W9_9uN1vYLfjbURMRigUPrvwS_mj4Qk1ZfZOXTDd984ffEXB=w106-h144-s-no?authuser=4",
  },
  {
    firstName: "Rudy",
    lastName: "Gobert",
    teams: ["Utah Jazz", "Minnesota Timberwolves"],
    level: "easy",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfi0fliPlh5Yup2CauT-lLcknM3gTRbImS0x1fESW-5OOVwPax_gk6MlKQKhsDxsnNiOxBmQMjUJ8mwBQKSyYY57J2R6fJHKt4-Kseh9p6tBNaXG9oQZW0f6GdS3GnJJ4ITo0XT_Sz91gjcx9-cSE0a=w107-h139-s-no?authuser=4",
  },
  {
    firstName: "Mike",
    lastName: "Conley",
    teams: ["Memphis Grizzlies", "Utah Jazz", "Minnesota Timberwolves"],
    level: "easy",
    firstNBAyear: "2008",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcpaW6xU7R67Z2HxR1ltEZ7plOCUlSeaPiyjZjoHSwJm8GJ90TGGheL7yZwipkoBwaadbCykfZ85oE1qLU17LGeTRkmsjxjJ0RJ5ThNd9Ay8eSX0Xz-IXd4lnIQMM3dcsuG7UF8Xscc5Ncctm--jcWQ=w88-h153-s-no?authuser=4",
  },
  {
    firstName: "Bojan",
    lastName: "Bogdanovic",
    teams: [
      "Brooklyn Nets",
      "Washington Wizards",
      "Indiana Pacers",
      "Utah Jazz",
      "Detroit Pistons",
      "New York Knicks",
      "Brooklyn Nets",
    ],
    level: "hard",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfPdT_HDxHpkat_7o7Oxr7881yCFP9merAkagy3a7Wlw-yUUytWXHvveo4olcUWcxSv_R3FZExxOySZ1cgIZjOZ0J5Tw9Zbo0XJaIyac1gnMCtPTpyiyd_f1ekA8bvF3dlDm2cjWu_w51ElMnS--ohT=w118-h151-s-no?authuser=4",
  },
  {
    firstName: "Joe",
    lastName: "Ingles",
    teams: [
      "Utah Jazz",
      "Milwaukee Bucks",
      "Orlando Magic",
      "Minnesota Timberwolves",
    ],
    level: "medium",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcuKvMo3QQJyscuGgeP2xORZ0Vg_DfLKqlAQsvBl9CxdWWePJcJMmp28L-Zg0dG_nyS080fu703kJBkiO_iSNgnq9J-_0pKyFkmM6udSpj1HddURysNgWf_xOrVyk9YRIR99K-cZUMCl6WdlenwBXyv=w100-h142-s-no?authuser=4",
  },
  {
    firstName: "Jordan",
    lastName: "Clarkson",
    teams: ["Los Angeles Lakers", "Cleveland Cavaliers", "Utah Jazz"],
    level: "medium",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdmzr4LJrbGlxMtPMkLsCkX_Lg9e4dAEXuiPyH60u4h-M5uHolUR5CY1Rc1hNyziI-6g3-RgoULA9diTzLsNIohZnbl6mNmFtsSTw1WKlr-BX-tSJaQs5ruKVjhLt21ayGvDdMW0gBiL4dyS4n4f8IS=w98-h171-s-no?authuser=4",
  },
  {
    firstName: "Royce",
    lastName: "O'Neale",
    teams: ["Utah Jazz", "Brooklyn Nets"],
    level: "medium",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfgJpECDdMSZN9L32QMwGeOoFlo_EcAVNQAxWjOv_EhfLhkSUADTLBFF_gX0Coi4rXkhjX2-QXCEYNRkiTMUO3GkpZ1B36tUfqAIdf8HtdPoWOeCcgEhw51ZPdfH6bQFUCCANEQXTzt_Dw4eMkl0Akt=w106-h160-s-no?authuser=4",
  },
  {
    firstName: "Georges",
    lastName: "Niang",
    teams: [
      "Indiana Pacers",
      "Utah Jazz",
      "Philadelphia 76ers",
      "Cleveland Cavaliers",
    ],
    level: "hard",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfubzjNEuRP8jIqSPi_7lbQzzX3rVDyWaL9TpZi4jsPBH-gXi-GKCxN60F4LvXMSuDntZair0ePJZDBbqs7poDIuhFqszgmHJizupSKWuKyVO2jpAapn17RL5rcJiaXZVGm-zt5QMAbxSoph8OmRRbc=w107-h146-s-no?authuser=4",
  },
  {
    firstName: "Jarrell",
    lastName: "Brantley",
    teams: ["Utah Jazz"],
    level: "extrahard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe8yq0gpBncLJQjDa0lgK0Z3brazYhjZ7hik1WwtSXb9xEKichXqROkNjQPB3znIlKtEuiiNlJHXlGuf-mkcBLtH1iCmuTDwEXuIgP_4As9KG_MVSE-1LqQfnSbiY5X0SzP_cWJZuDOOaLskc3XUj51=w109-h156-s-no?authuser=4",
  },
  {
    firstName: "Trent",
    lastName: "Forrest",
    teams: ["Utah Jazz", "Atlanta Hawks"],
    level: "extrahard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHenT1JVu_8m7-hYfbxVfAMdsLIkpLfjWamWHWI2KAhSlgXzxyEwav1RdfzISxPBsKG3_6AL4sVhvfbFLMcdxS0RVWUvHs1tbGjsEvKb6GSlvmMw_crCdt_R8wPCJX3oJCFQ95knJ02wAmBmYxcs2zyi=w100-h159-s-no?authuser=4",
  },
  {
    firstName: "Udoka",
    lastName: "Azubuike",
    teams: ["Utah Jazz", "Phoenix Suns"],
    level: "hard",
    firstNBAyear: "2021",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeCpkpQXB1TXh9LBmEnyItvGUVsXaaYaKx4RXmgYc3xWstf5XD9xfzXnDX3ek8rAYZpD1v2ObfaYbd4oqDaPWOwBH7eFGykAcYF0k9HuET_1adwzYeCv2x1m0VUgtCR6kpQRb7zUaI43KhV0c1GahuJ=w101-h170-s-no?authuser=4",
  },
  {
    firstName: "Trey",
    lastName: "Murphy III",
    teams: ["New Orleans Pelicans"],
    level: "medium",
    firstNBAyear: "2022",
    college: "Virginia",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe3FYv5EvaJ1-lv-dBEOaAxHMVXxjwggDaptFOW6_468MIV818_xfuXFGmABpTX1S6fL_UlzpSXpJe6bD8sMXHZbaBhxlrP4n6QL_c3jYqj6krhb2ZjodJDczS-ARhg0livXuIONvWb8vQDgCA6DUYd=w108-h161-s-no?authuser=4",
  },
  {
    firstName: "Stephen",
    lastName: "Curry",
    teams: ["Golden State Warriors"],
    level: "easy",
    firstNBAyear: "2010",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcbAx-xfSE0CbQdy56jChu0WJf-Q9F3awvnssBlnNXuhaIZx13osP1t8VIELM6xMn3zI2GnOIi_PeQH0nthn28zJ9dFFY6zgH1dyVULotbCRboXMJEVhoxDXedCZdJki9IwkLb3-QYtb2eFjJBmpFV6=w109-h156-s-no?authuser=4",
  },
  {
    firstName: "Klay",
    lastName: "Thompson",
    teams: ["Golden State Warriors", "Dallas Mavericks"],
    level: "easy",
    firstNBAyear: "2012",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHex0Tg9YVshWmJrtlH0trx2OMTUFiZQYN3bDOhULYzH9raiGCM8zQSbYXDjJavtLecLxwvgXtJtNVFQ_I3tzYMspEFQwCM5YsBTk-_Il4XYgael_xyKEPmZT3dp8Lv-KbwerBzcqHsTcQR0MLRjsFkA=w111-h156-s-no?authuser=4",
  },
  {
    firstName: "Draymond",
    lastName: "Green",
    teams: ["Golden State Warriors"],
    level: "easy",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeVwoT-tm10dgqHWV9ecSrf1UARdDB50l4x7JEjlyQco_RoQhC7LKGZMPBah9aKj5hz8NAH1AcYOeHk9eTdZ9znsdXrmw5ewO5cdzU5XqfEQw_UHim4cbCg-PbvJ6UFdnd9GHQHBXCKfqb1s8jzPgNb=w110-h156-s-no?authuser=4",
  },
  {
    firstName: "Andrew",
    lastName: "Wiggins",
    teams: ["Minnesota Timberwolves", "Golden State Warriors"],
    level: "easy",
    firstNBAyear: "2015",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHebrr8U3qCGKl6jxcNfT8Cty37N10HP8KOhDFa0jK2RxHALCuHyt9RYbwAUwujh-xJF3FYVmySSYXr89I7z_lQbKbOcBzCivrwBtOelTsqbsWRDiSJIJ40UMSonT4hNkenxhP7GzkeQJw_TRF3c8V9z=w113-h166-s-no?authuser=4",
  },
  {
    firstName: "James",
    lastName: "Wiseman",
    teams: ["Golden State Warriors", "Detroit Pistons", "Indiana Pacers"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe5UfZLT9CNiSqAMWAwXjQJndrC5ywVdxFe0qmQVGa_EHDMiW11KgG44h9WdA7i0eUl8LvdhLhPar7CY2x12lPeAnvglW6VwchVy6DcVVCw1M5a_U5NOyktJO_ely7o6Cp46YBaK4VhgVMw6N-dfQof=w111-h147-s-no?authuser=4",
  },
  {
    firstName: "Kevon",
    lastName: "Looney",
    teams: ["Golden State Warriors"],
    level: "easy",
    firstNBAyear: "2016",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfiPIUKoRh8Xc3oVdsj9n9PIbVi6K83uUalyIGk0gDHgFLf_iAlzdQNcAkJaS1RctkPfeyv0Lj-mh4JphmA4ax3EEGPNQvDgKWqRzPuKyBzaLKLNfaQI091dzs_zch5IWYC-nlRljhkzal1ibbbeHUS=w118-h151-s-no?authuser=4",
  },
  {
    firstName: "Jordan",
    lastName: "Poole",
    teams: ["Golden State Warriors", "Washington Wizards"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Michigan",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdbJrYCByy6qLl6ZKdWtTXeu0I_-KaN2CZ-0ogGkFE9BN1HYczjTzRKF3tNkOfVSZ6P7pCvcPqXQxW4A99iIZhB-5-e8qTQ0b6f33Gi6r1XNJ9cmw3Wf19zsMIBTB4MddcMb-_Zchy_bdSaGI8LEUqk=w122-h143-s-no?authuser=4",
  },
  {
    firstName: "Damion",
    lastName: "Lee",
    teams: ["Atlanta Hawks", "Golden State Warriors", "Phoenix Suns"],
    level: "hard",
    firstNBAyear: "2018",
    college: "Louisville",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe_yz74h_q2_TyFEE-HwOoU1ovtdeErDf940nEfjgo05RBAEtcI1wmStIsWB8eQD75Yt8mSfc95a0NyfAxSDtXNlnGGPi1L5-86ndAbr4xSdBPy8n54L4jfc80_WsB4KIhpDLXVIfJm3Fzot0RWyglh=w118-h157-s-no?authuser=4",
  },
  /* {
    firstName: "Andre",
    lastName: "Iguodala",
    teams: [
      "Philadelphia 76ers",
      "Denver Nuggets",
      "Golden State Warriors",
      "Miami Heat",
      "Golden State Warriors"
    ],
    level: "hard",
    firstNBAyear: "2005",
    college:"Arizona",
    img:""
  },*/
  {
    firstName: "Juan",
    lastName: "Toscano-Anderson",
    teams: ["Golden State Warriors", "Los Angeles Lakers", "Utah Jazz", "Sacramento Kings"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdQgmBxodePqO7t7sgCS-xYwgAQNb3y5AHfEIvDMLkX07prlMxk42EBBZ4JQYXVPzdhHLSL9HujAC9q0cHFE3nKv5HseKSpg5i5_WLuavxckR9X7U1lticjVb3O2YMxny5cfalg2FSE5JFfgG7HqXdC=w108-h147-s-no?authuser=4",
  },
  {
    firstName: "Moses",
    lastName: "Moody",
    teams: ["Golden State Warriors"],
    level: "medium",
    firstNBAyear: "2022",
    college: "Arkansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcptoIZjX_lfSqczhYSs7qaXO8_Sqn9L7Dvh81o_OOrI6e8hG8bENOhjbfiaDQ-EKrt1nJohFqZqRMxp_Ss8JXZu5ib0xwY5XzzGKfB5GD0FWmorfLuJjIVnTg2Ig1mieBjFn8pVPGS2UmhzP1qaw0c=w118-h155-s-no?authuser=4",
  },
  {
    firstName: "Gary",
    lastName: "Payton II",
    teams: [
      "Milwaukee Bucks",
      "Los Angeles Lakers",
      "Washington Wizards",
      "Golden State Warriors",
      "Portland Trail Blazers",
      "Golden State Warriors",
    ],
    level: "extrahard",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeg8XEYhS4V6Qd6qiU93kuIoHL51zMTvRs-PJ-Cc81C2LRa22PJw-slimfpndYv7qktK5K4YQXCu0mKaT-P1LfKo4zygp7pkFyfdbSkMBireclZmb1zozFJsyPz1nVxbVEY6DCrefSHekU4Csyw1xzm=w111-h150-s-no?authuser=4",
  },
  {
    firstName: "Otto",
    lastName: "Porter Jr.",
    teams: [
      "Washington Wizards",
      "Chicago Bulls",
      "Orlando Magic",
      "Golden State Warriors",
      "Toronto Raptors",
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcG_7M7WEVVKtrNhxSuDIRD46jWCOL1MZ2tAkKD6nFL1daXtCnw18CSZoohL156BnJj2NeRbQuHF3jhdCHU3BiKqH-5CnqqeX702IgKV86iPncp6mnnKqvhMPdob-Qa80ohGVJhlc1GmTTM6QIYEogI=w106-h145-s-no?authuser=4",
  },
  {
    firstName: "Jonathan",
    lastName: "Kuminga",
    teams: ["Golden State Warriors"],
    level: "easy",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdTUZfvEEYOM3zknLErsbFH1uQwJ8YsDjnz5VT08H9vTdC87MatasgbdkGV2kO-OlEsXMFIaHuNi_SJpDREYYxocTrrmg83nn5kOz3AqGbclSzhch5XdNmG1MqGqEWBsOQ91gxGxZoHqkrJru1Bd3mJ=w111-h157-s-no?authuser=4",
  },
  {
    firstName: "LeBron",
    lastName: "James",
    teams: [
      "Cleveland Cavaliers",
      "Miami Heat",
      "Cleveland Cavaliers",
      "Los Angeles Lakers",
    ],
    level: "easy",
    firstNBAyear: "2004",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHexORAVTesNS1ph2UDtTJizAn2L_YAU7Wv0iFgHAe3sbPPmS9E7A0OeJVHeo7MKLD6LGfo9VcP_qQOI_ylOU9EQGeoZJA4aZ-_wrTWA6A3evKWGKN5JwVHyjImMvyRia9ONieod8tYsIclA0zTRWUOd=w115-h151-s-no?authuser=4",
  },
  {
    firstName: "Anthony",
    lastName: "Davis",
    teams: ["New Orleans Pelicans", "Los Angeles Lakers"],
    level: "easy",
    firstNBAyear: "2013",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdFhGjPAoGZDCbPsGOGB2Ch2D3CcXvvxT1heeuaBSeCyC0_YVWoeTyMhmsdj_718Ef8rlBwFQwnrDsiKm0HEhJdlYmtN7n5z8jfuEQqOJN2cJnFBg2m2twhZ4kEITXLCIXSYINwkpmMpeedg-R4LkjN=w118-h155-s-no?authuser=4",
  },
  {
    firstName: "Russell",
    lastName: "Westbrook",
    teams: [
      "Oklahoma City Thunder",
      "Houston Rockets",
      "Washington Wizards",
      "Los Angeles Lakers",
      "Los Angeles Clippers",
      "Denver Nuggets",
    ],
    level: "medium",
    firstNBAyear: "2009",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdQy2GNUccNd2Py5Di9c63vF1NEwnXbez3zM6NagomTzJf-dt6ATDXwZIgZZhHQQATGf6W3asivecOeL3kMHDqdrJUTbfkF3Pzot9ra862DvBCPoWktDVXO_R3OtHQ8jc9oQe0IHKdpcsaTyAGy9pMa=w104-h145-s-no?authuser=4",
  },
  {
    firstName: "Malik",
    lastName: "Monk",
    teams: ["Charlotte Hornets", "Los Angeles Lakers", "Sacramento Kings"],
    level: "medium",
    firstNBAyear: "2018",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeVTTVIENEx-yH4rEY3IeZlnBUWAhBqg515FQ0obvJuE9AlhXXBgVRIEJEOlISeO1WgTgtvCkxofI4ogmPj_Sld8WP2NoyN1koR3Hf8vIR3yo82qlqdLeYAl_o8FqGoK9MZBQjUsqTgi1WmeiHMvIwj=w116-h159-s-no?authuser=4",
  },
  {
    firstName: "Kendrick",
    lastName: "Nunn",
    teams: ["Miami Heat", "Los Angeles Lakers", "Washington Wizards"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdvHFT3JRKUCWidhV4LuZ0jDVwZLA5L5iJ4YFh2B5I4b1_flgFqIPcgQSjzPP5jmVyx27M_PXE4Xtuuie4t5ijNeIh6mP9CBM3Eb4NtuljFgUleasfbfCreyB-iV0YZTD_pi5YoHsnOvEjFNQcweBLc=w115-h143-s-no?authuser=4",
  },
  {
    firstName: "Austin",
    lastName: "Reaves",
    teams: ["Los Angeles Lakers"],
    level: "easy",
    firstNBAyear: "2022",
    college: "",
    img: "",
  },
  {
    firstName: "Jay",
    lastName: "Huff",
    teams: ["Los Angeles Lakers", "Washington Wizards"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf1XQqvUDcKpvWQ4OaX1BAKGCwsW4EYcFY_L6UjSYH_0JwmtszZbhON5RTXc5ZD-mofu1GtmHeGpZvrOLIYmRCW2EVXTNWrKopuEqPZnEnU7X7AxDEwNdg9PEQCdlKvMKxrzbV1Czzn0R7soCd9g2d6=w114-h159-s-no?authuser=4",
  },
  {
    firstName: "Talen",
    lastName: "Horton-Tucker",
    teams: ["Los Angeles Lakers", "Utah Jazz", "Chicago Bulls"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe3P9Xaknz613YCpbcIp5zY5WSZmD6aR8quOA2o5JneHNT8rGPBvxPKRqDtzQfZJHAE8ytH86uaOG10nvcdIOfKLj8LMmJuAKYbTjjN3eBikjpkXMHgzIy38m9UwQ_xKZgHZze-y8xoptBhB3n-u-xN=w117-h135-s-no?authuser=4",
  },
  {
    firstName: "Kawhi",
    lastName: "Leonard",
    teams: ["San Antonio Spurs", "Toronto Raptors", "Los Angeles Clippers"],
    level: "easy",
    firstNBAyear: "2012",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe7ecNav0D7IdEIBAh93e4TBn7XxmoGyWITJeyuHSnt8AdZmZRrzcsNg3vi5SYJSfRHxsHTb8veUmOlOQJE-W6BC0kjxl7DusjA3hTshKBFOQbDp6cAXm3lB7ABhe4qvIUwUPnb_oO2h88ebdfKDvBG=w106-h135-s-no?authuser=4",
  },
  {
    firstName: "Paul",
    lastName: "George",
    teams: [
      "Indiana Pacers",
      "Oklahoma City Thunder",
      "Los Angeles Clippers",
      "Philadelphia 76ers",
    ],
    level: "easy",
    firstNBAyear: "2011",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcxb6dGQpUfSJlmKph9PJQymj4pvqqKPF8E_h2Ub_4wxICaYV4DHtbn9WoT_JPN-27RyWe0sR_lkpeWMkuSJ9Lfz82CwCkBz6QvfOiLbOK0sBQ6WUzsZbReQp4wzj_NPifM51vYOeT6aZU69TfoCoo3=w100-h147-s-no?authuser=4",
  },
  {
    firstName: "Serge",
    lastName: "Ibaka",
    teams: [
      "Oklahoma City Thunder",
      "Orlando Magic",
      "Toronto Raptors",
      "Los Angeles Clippers",
      "Milwaukee Bucks",
    ],
    level: "hard",
    firstNBAyear: "2010",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdi5W0GxT8_mR4FXRK2jOCXZqNdxtrAVbC-hDwf-G4hUYNMoVbdma32IldaNA0FSmr9oolWg8mevveJ8h5tW8McqxfoFr6u3Kpjd0YEFaoUbqFJ3dOdi9zrrdJFhLwE38rpEJZcwHNvFPnBO4hDFiJy=w111-h146-s-no?authuser=4",
  },
  {
    firstName: "Reggie",
    lastName: "Jackson",
    teams: [
      "Oklahoma City Thunder",
      "Detroit Pistons",
      "Los Angeles Clippers",
      "Denver Nuggets",
      "Philadelphia 76ers",
    ],
    level: "hard",
    firstNBAyear: "2012",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfmToFMNTU4wnZC54RfXSl3NjEEHSQjYK3u8IDT-WIHb_oRvfCQsUyf7GWDPIF4G1XPvDGmHunTfQPKuz0aIiYxFSv8vGdP2YVkfiUwj8bO-7hM_yYCRy7eKzvNyoanmBvGmxQ8MbPwVQZ3TZp2GhZc=w110-h161-s-no?authuser=4",
  },
  {
    firstName: "Luke",
    lastName: "Kennard",
    teams: ["Detroit Pistons", "Los Angeles Clippers", "Memphis Grizzlies"],
    level: "medium",
    firstNBAyear: "2018",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcE0YaFapg8QfFyitdpxbXl56YR4z85HLSXcjn0dNTchw2sO1kd6paWhcpMgvzSWwdEn6NI41aP8pGB7MKl20FH8GRVlX7A73NrNEnL0zRnb8OuPbGqmx1tQK14x9gA5dd0gXI_2_kgqOLuRBICGYO1=w115-h151-s-no?authuser=4",
  },
  {
    firstName: "Nicolas",
    lastName: "Batum",
    teams: [
      "Portland Trail Blazers",
      "Charlotte Hornets",
      "Los Angeles Clippers",
      "Philadelphia 76ers",
      "Los Angeles Clippers",
    ],
    level: "medium",
    firstNBAyear: "2009",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdAnSPLfKJ-qZxDY7tRIXV9E4fY6Bi0X8IMLTyS7R9FkCANUvAU0YBiXmeRj9HrF1SJd87DVM08sa0LVVh65EasI5JnV3ro8fLjH7vBZyApiVug6yQ7dpgM83x3DWp5IhooqccAQPJO-X2_ictyh5YV=w111-h150-s-no?authuser=4",
  },
  {
    firstName: "Marcus",
    lastName: "Morris ",
    teams: [
      "Houston Rockets",
      "Phoenix Suns",
      "Detroit Pistons",
      "Boston Celtics",
      "New York Knicks",
      "Los Angeles Clippers",
      "Philadelphia 76ers",
    ],
    level: "extrahard",
    firstNBAyear: "2012",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeA0LGdSJE5ZjeKF-VoOEqnU-42flTYj1BbYqiv8xko1kqlv9h8mxvoO-HJe5kCKqH1VdK7EmTtL98EZZd22uAYUsfOLG6f66wWbHOSbLyNZMFLKeX3EqaCDxolHIvDClBTPGiV-oVYZjOZOhkh4Frs=w109-h154-s-no?authuser=4",
  },
  {
    firstName: "Terance",
    lastName: "Mann",
    teams: ["Los Angeles Clippers"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Florida State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeAOK1nzwoFzT1jQvxKgxqMOcFV6Fm4W3oBx-vM2G3-sax_IL1dwV44eHXioyl4hn1ZB4Gb2TvMCS2WVeF7teskWEAyX9HAEufSe-TBpSli98Q4God8K6nCnxmHQLZFJQf1htvEZp9HEro5ynYx__6q=w115-h154-s-no?authuser=4",
  },
  {
    firstName: "Amir",
    lastName: "Coffey",
    teams: ["Los Angeles Clippers"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHexU9UAc5ozG2N-AOto1P7qTHlIoRVE-PlwfeE1I1bPDEoLEtwCJDTvkKdihlW3ksUMaxI8nVtrVjMTLlbxGsD6SRSWw0_dIGmpd4aDWikBldy2_HGDWnt2rOeZWOxvtw2wYyuu1ukMmkgfqsvk6-gJ=w109-h138-s-no?authuser=4",
  },
  {
    firstName: "Justise",
    lastName: "Winslow",
    teams: [
      "Miami Heat",
      "Memphis Grizzlies",
      "Los Angeles Clippers",
      "Portland Trail Blazers",
    ],
    level: "extrahard",
    firstNBAyear: "2016",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfM6vVDRcvukf9O-fFBRKZSuRHz45r7e_X5DTV9t5c1llW519bmcdzG0E9GOq2xRvVX_s0SUvSTvEfpdOD61Vg-e8OSmCZMH0z9ulwTwObER_tZPZS058yPDsJy0TireOJ3gUBkOeRUxMHW5xou8LtM=w117-h156-s-no?authuser=4",
  },
  {
    firstName: "Jason",
    lastName: "Preston",
    teams: ["Los Angeles Clippers"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHctdSkJW6Dxjo50lQNs0tL08v-NXAqC-Z6KfrBQPjSfwt7JGl0sTPTvC5q2I3tUy0DLxqUtIbqyyRtaeQUgs33dAdTk8wMjUKwNcYZFTSpgSDSH00ZEgc4m0wD_tS8HU5oW-ys66c-TS8fcFqaNDp14=w113-h155-s-no?authuser=4",
  },
  {
    firstName: "De'Aaron",
    lastName: "Fox",
    teams: ["Sacramento Kings"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd-TksMaRbHd8SBfyU9BpOBS-90u0_rwiu9keXmmbTFe-0gmLxAmS0qzDgxWQOaGqS28rUs6_JOgWz5Fukj4tvsjAO7Jb4fXWgdlxUQ-EtY04twmUNn8SLWwGXOHzSmDR9ygRj2JtZiRn-9vttlxfYK=w114-h152-s-no?authuser=4",
  },
  {
    firstName: "Harrison",
    lastName: "Barnes",
    teams: [
      "Golden State Warriors",
      "Dallas Mavericks",
      "Sacramento Kings",
      "San Antonio Spurs",
    ],
    level: "medium",
    firstNBAyear: "2013",
    college: "UNC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdMjSEzkJunOKnBDJjZiPb7pKlO13oUzeCBqn3bwJmsx70ZdHWvnTvnO9w4-PKR_d5wr8at0M9m7OS2xlYNwRQeL9T8KQbtJR2qkfuXkwieERBUetGYXtaG98cX2iPRM4WAdqi63aiGJl35bYjdU8o8=w111-h156-s-no?authuser=4",
  },
  {
    firstName: "Buddy",
    lastName: "Hield",
    teams: [
      "New Orleans Pelicans",
      "Sacramento Kings",
      "Indiana Pacers",
      "Philadelphia 76ers",
      "Golden State Warriors",
    ],
    level: "medium",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHceDqxfgi-5A4wWRvrtZ3WL-uftquGoinlTL4MZCsF-3UQAqNgQ7tRJA3Qb-Y2U0KssMo04esb2FFCUw_KlHZ4--dYcHgWzQZqt6nWgPT54uuWLZtk8rpxCbdAjlZplt0BkyCzBblDBBKrU2mMT0IDz=w116-h149-s-no?authuser=4",
  },
  {
    firstName: "Marvin",
    lastName: "Bagley III",
    teams: ["Sacramento Kings", "Detroit Pistons"],
    level: "medium",
    firstNBAyear: "2019",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcCeKTUqzUpOZpwMQ7YT3WN8jBeBf38YsD4ARovyH826Gq_a35XCPTIEVhyViH1U92qXjaGGg4jml-vNWuwZcFw2G79wW1kyOVmMOp8ch1zCz44XzLCISoEwAAKYuqAyleM9q6KJ8xrAVbxLZ1MmSpW=w122-h146-s-no?authuser=4",
  },
  {
    firstName: "Richaun",
    lastName: "Holmes",
    teams: [
      "Philadelphia 76ers",
      "Phoenix Suns",
      "Sacramento Kings",
      "Dallas Mavericks",
      "Washington Wizards",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdfw-Cd64o2x-L1iN9SNgYVSoSbb7WPNn0kLT2cOkeECMZHUkXEV801MCF4iVQshQLl__P_g7Ymbeq7nnO4rs-K_dI-sponQmuy7LGRgfVj9X9ro9PSPa6ytzVLrjBPOJqHp2H1TLH9f0OTezvNgAFR=w107-h146-s-no?authuser=4",
  },
  {
    firstName: "Tyrese",
    lastName: "Haliburton",
    teams: ["Sacramento Kings", "Indiana Pacers"],
    level: "easy",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd28EjjVLtxzPrxb5kV8xAPi-LKs07sNd5AZnBEOQNIYy-OupmsbwQio5WnecwRP8wXqASIIJk6y3Tgf3JdkzUKj9_q-CzW_CG-clvDFWRT-t0h_kC8d_rHhmfCncwYscTUrTndWHKXnvZw0EefK3NK=w114-h144-s-no?authuser=4",
  },
  {
    firstName: "Alex",
    lastName: "Len",
    teams: [
      "Phoenix Suns",
      "Atlanta Hawks",
      "Sacramento Kings",
      "Toronto Raptors",
      "Washington Wizards",
      "Sacramento Kings",
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    college: "Maryland",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcIK_3-blKPEH170AJttSM-_v2MAB6wuz6w6mvFnGMY9PO20P6Ans3efvLh9jHzsr6FEal-nCXRA4EEcs06DKmrmpLYd2EOqlIHeSxJhg64wOzmApqLHCR2siNGltCfU4ooBkAjjgh05_qwfdnnM6f6=w113-h138-s-no?authuser=4",
  },
  {
    firstName: "Terence",
    lastName: "Davis",
    teams: ["Toronto Raptors", "Sacramento Kings"],
    level: "extrahard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdpBuViT4yFH7CGOJK6aRnTbs3RQfuHHNUhlalPQyAYnYWvEXboN-3am-hn17QB63IRDI0Chczyy2_a1F_a0q37NfoXlQJAfcW0MT2t067tihQS6ZdvYUovF2um-fbq9dtnwTJg605ksjf8IlifTkaS=w108-h148-s-no?authuser=4",
  },
  {
    firstName: "Chimezie",
    lastName: "Metu",
    teams: ["San Antonio Spurs", "Sacramento Kings", "Phoenix Suns"],
    level: "hard",
    firstNBAyear: "2019",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfAWALdzxELIjzJTbSTmMTKLoexdU-U88OSDAh68-40hBczfeOu43h5nvJgxdnaHCc8jUK295PKTfrg7_eJrAlTKC4lgvaB056CAu-9vnBPHszeRVYXEAYXm6eTS_qfHYxqqFnumhOyI7FZs1eTlapa=w113-h147-s-no?authuser=4",
  },
  {
    firstName: "Neemias",
    lastName: "Queta",
    teams: ["Sacramento Kings", "Boston Celtics"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfvprJhKzKDJQbyzNsnNjXpK-8O_DvcOBO2F_N06kFudi331_Xg8t-hi__2LzKoxKqxixEskq5gPKaRyRqDcUbCIQ-vIiYmuVGeuiE_LPfIz_ujXDVeEiOFbucgabJkAE2VesJdyoopy6Yca8YFp1lo=w118-h147-s-no?authuser=4",
  },
  {
    firstName: "Louis",
    lastName: "King",
    teams: ["Detroit Pistons", "Sacramento Kings", "Philadelphia 76ers"],
    level: "extrahard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdUrCJPmWKQG_d2kAhI7k9-l1e0x2GhJ1nHONvsNsb0b4dd7py98Zx1AUWzK6YSB1HOB02trx0JDj2PUXQ-A7O45s0MkFwRPtdb9Gaa8tQmPjOguuPOfdS0veNyMkSUb2kiHq94DB25XKn9shjS-pd3=w106-h154-s-no?authuser=4",
  },
  {
    firstName: "Chris",
    lastName: "Paul",
    teams: [
      "New Orleans Pelicans",
      "Los Angeles Clippers",
      "Houston Rockets",
      "Oklahoma City Thunder",
      "Phoenix Suns",
      "Golden State Warriors",
      "San Antonio Spurs",
    ],
    level: "easy",
    firstNBAyear: "2006",
    college: "Wake Forest",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdJzSSacGTbqB2HDcxtqU1h-Plc5grSwIEApWzcPm1Hmf7y3bFnoBfN8UiQ3m23yGPviNhcM_GlDUHmLLJw1ggL9ZvCqZhUeUH7C0U9J2jGn1jB0e2vgBovvoH15fuvjWhRNyd0Fj42wcOg3m1OeO4p=w99-h150-s-no?authuser=4",
  },
  {
    firstName: "Devin",
    lastName: "Booker",
    teams: ["Phoenix Suns"],
    level: "easy",
    firstNBAyear: "2016",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe7WhM5QIl7sycEaAfVZxPEDD5yrVK1M2dcTuWXn8GsHNxlszJfT1Pen0uojaqBP_E76BnIX--8IS4BR1iDQ-MipESpE8WxnelTKTrrA2S00PlxtpjrjZjSpfTdfGxHWnCBBHUNyjE1dMnIDOGE3PDN=w114-h154-s-no?authuser=4",
  },
  {
    firstName: "Deandre",
    lastName: "Ayton",
    teams: ["Phoenix Suns", "Portland Trail Blazers"],
    level: "easy",
    firstNBAyear: "2019",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfZsPmEMc7JlB33ajN0VoCjnGDmD-1glGKE7UjgCq7PTTkb2iUNXxJE3RJJZgNiGJwx1GFIySHRRPnye6L1lGtAR6Nov-pq4CbmHIr3PFkG1LmRZRmOihoTmi7Agth1vkN_pDY7rFNZK1Kylxgyy1EB=w110-h148-s-no?authuser=4",
  },
  {
    firstName: "Jae",
    lastName: "Crowder",
    teams: [
      "Dallas Mavericks",
      "Boston Celtics",
      "Cleveland Cavaliers",
      "Utah Jazz",
      "Memphis Grizzlies",
      "Miami Heat",
      "Phoenix Suns",
      "Milwaukee Bucks",
    ],
    level: "extrahard",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHefL9Bbvn6O6qXrc0VRqDtRRahTDQidegmU6bL0l5GbcqASYyTw-pnaoHa7qymSZX6-ee-98kHVV6k8PiO3zzlqLYMxdt1wblPOijvhoP_vePELrZ7E93hJ7MRkJxvUm8n5Y2usbHTexngVI26AVAys=w113-h161-s-no?authuser=4",
  },
  {
    firstName: "Cameron",
    lastName: "Payne",
    teams: [
      "Oklahoma City Thunder",
      "Chicago Bulls",
      "Cleveland Cavaliers",
      "Phoenix Suns",
      "Milwaukee Bucks",
      "Philadelphia 76ers",
      "New York Knicks",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdXNNdzChaTDiJjllu5Mb25vX-z8bvWdxumkmLupDAl9uV6utRGPladyEKRYNGvQEmQ7sT2e-snf45nBV9cxmU5PGPzfe3BsgGn0mg9UWsbm5_ncOm7t1oPDCbGTnO5nevUNXzrZ_UxEWNPW8N6dtcB=w110-h149-s-no?authuser=4",
  },
  {
    firstName: "Dario",
    lastName: "Saric",
    teams: [
      "Philadelphia 76ers",
      "Minnesota Timberwolves",
      "Phoenix Suns",
      "Oklahoma City Thunder",
      "Golden State Warriors",
      "Denver Nuggets",
    ],
    level: "hard",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfaAXEe_ksQnq_9BLdFMnuPs4KI6NqSLK1079LdM71Wdp4Gzo4b05usbiXIbHcYv1YsoNaR8YFlkaGCWd_zObfnCBCjZLgCCEVJu_36IUzyq38lwnUg7WJ4SUaptUKe-b0ZbTJy039sOcYRMiUFDs4Y=w108-h153-s-no?authuser=4",
  },
  {
    firstName: "Cameron",
    lastName: "Johnson",
    teams: ["Phoenix Suns", "Brooklyn Nets"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeu0UCl_qbOYwdADHEWiSTVECa5-Iq8UEa0FkaYbkPUNNhuiZWGQ4fYBjR6BHfaLpiBbIKCWRcD3vVNaCW_mrIJSb6yy8B9Svtd5pz4tWqpEE665ccja8IkrTYKHT7jfZkzvgSwd3DIKwlRWH01m3p2=w122-h140-s-no?authuser=4",
  },
  {
    firstName: "Jalen",
    lastName: "Smith",
    teams: ["Phoenix Suns", "Indiana Pacers", "Chicago Bulls"],
    level: "hard",
    firstNBAyear: "2021",
    college: "Maryland",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfWhm2WYMdXrgjgy1SfnB8sVRmJiD6FWiRA6_OXLHMtZsMk8WL9aro9_7w_iNdoPaMPn-pc1DsVuizkaTNItaGU7o4vyXLbox4YxRuRlZi77O4TlyIJBg4WNKcJavzeTIvV_AREa8EaECvAwjrQzSEa=w107-h153-s-no?authuser=4",
  },
  {
    firstName: "Jevon",
    lastName: "Carter",
    teams: [
      "Memphis Grizzlies",
      "Phoenix Suns",
      "Brooklyn Nets",
      "Milwaukee Bucks",
      "Chicago Bulls",
    ],
    level: "extrahard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfA6wQEbVi7oMOgoyH98DgPWFK-Id2NZFdj-6Jq8bUbgDc8VIWvKCsfOftDqxloryZm4siJsBcrBBv7xQFGDqL-99NPpQA9r3QAKwaaGgm95_oecdBli6n5WNcR4TUj1d_5Y_0wMADY4itKMaFvoa-d=w108-h146-s-no?authuser=4",
  },
  {
    firstName: "Frank",
    lastName: "Kaminsky",
    teams: [
      "Charlotte Hornets",
      "Phoenix Suns",
      "Atlanta Hawks",
      "Houston Rockets",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdtzVyqdlopIqWiE2v6OyPp4JSaB9uDBN4JQUPECwibGv9-Ezbb03J4D1DblLqZdC9_NzoEJgMAFP4aDAs9_b5lf5JXHy571ludocUUJHRerhf30SzJsVBm1XGG9_8kJUrFn-9NdCjPnsIvhwg1cp1P=w114-h150-s-no?authuser=4",
  },
  {
    firstName: "Luka",
    lastName: "Doncic",
    teams: ["Dallas Mavericks"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdPsEZ79-MMU_-EZiTwYKK6H8Eu_oviMmIYGHfBOgANv3TrSziEzPKQ1Do8CF0n_ozMhLMmitMHmSEKwyKvj9OTtsRHnoEe7JEXgtsESUZjo_JIkR4CSM-zpsQU9aad6KOecBH6Nkw3H_JUF1qJvB7r=w106-h148-s-no?authuser=4",
  },
  {
    firstName: "Kristaps",
    lastName: "Porzingis",
    teams: [
      "New York Knicks",
      "Dallas Mavericks",
      "Washington Wizards",
      "Boston Celtics",
    ],
    level: "easy",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe0a5FrMiz85h3SjZFxypq7JGejDFBQePBy8xuCdZCfowgS524-T-artGFSYyeWbRksTGVlcAtubFT2yE3REvzq590eHClNLcfAhqHg4c5e_K-tys4UUWlFgRfrONsuzvH553z_QGZI4BDFsCFjC2rM=w113-h143-s-no?authuser=4",
  },
  {
    firstName: "Tim",
    lastName: "Hardaway Jr.",
    teams: [
      "New York Knicks",
      "Atlanta Hawks",
      "New York Knicks",
      "Dallas Mavericks",
      "Detroit Pistons",
    ],
    level: "hard",
    firstNBAyear: "2014",
    college: "Michigan",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc_pFW2JKwEtMi50bahqoe91AuXqQL3loMZM9MON7vOgPPvffO226iRZVmL9Nc4o4NiB-UQowKaawWefYjCFaZ5f_p2tuUe69UIiYXZ-IvKrQqOWHvBcVRt4IKuiRi7VZFh5-wgdPhulNeaGWQEW7hR=w115-h148-s-no?authuser=4",
  },
  {
    firstName: "Dwight",
    lastName: "Powell",
    teams: ["Boston Celtics", "Dallas Mavericks"],
    level: "hard",
    firstNBAyear: "2015",
    college: "Stanford",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdADokR0FMOXRV9hRIeZFsxreimOQ9U5vYddeOdgGOkLL0mscTpyorQ-_PLYKrxD8-olDlqaoBEwSKPDISyhqwMShPOhS9U_d676YK0SNOPR-ruAsAjRBabh_fDkOWBQIoIUvnqC78xIvXsgXJOxfgp=w91-h158-s-no?authuser=4",
  },
  {
    firstName: "Maxi",
    lastName: "Kleber",
    teams: ["Dallas Mavericks"],
    level: "medium",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHedCdxR3DvMUr1LECkbHjsTM_o6Y3CgSuOnwMXKoZYgBEkzuStnguUHAISK0qbdKhf9SJCjj5S71UCDnoLftNr5ddgzlRLibCZcktRHPPk6GEIgZJelx8_LRNPC24PPOI5wAb0w9SNKXY7F1twe-vXl=w121-h141-s-no?authuser=4",
  },
  {
    firstName: "Jalen",
    lastName: "Brunson",
    teams: ["Dallas Mavericks", "New York Knicks"],
    level: "easy",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcJ1Q5mRDmqX3yMJiE12rAxm_zeQUDkCmUJkm9fIaxFquCwBMcTnNzXEvV1_yTL9qyVcHx0M0jDuVdVxShBjgSMdfPuOlJqUnlQAvgBByR2TmeyMiA-_9xM3NkPK7uQshwWUtmPuc1cYnll-pVhSjrg=w112-h154-s-no?authuser=4",
  },
  {
    firstName: "Dorian",
    lastName: "Finney-Smith",
    teams: ["Dallas Mavericks", "Brooklyn Nets"],
    level: "medium",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHevwQ6tOCguNrI-s4FRtEtwSMmTWH2LWrc5ZaqW30vmz1F8vBzwQNk_EnUtTVpHekE8LnygqrujT7VI_rdXPRN4Fc8jT1efgbL0zkwqucno3OMzxs7kgYDRYimCOweBRHj7XbOjDlz0b5C4w8gZqTXo=w112-h145-s-no?authuser=4",
  },
  {
    firstName: "Josh",
    lastName: "Green",
    teams: ["Dallas Mavericks", "Charlotte Hornets"],
    level: "medium",
    firstNBAyear: "2021",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcgA0q2miFtl4RxGj8jGWzkZGIyt1942o9sDOkR9FeBpH_ZY525V2g0zlSprEXdk1hZ-uzkjdogLMSqjqPkDkNVgIbkFEHQp4qlXyOT8fcyRcwrokL5pxa66Gb92kU_ZOab6pVQoWvkrO_y0rLWUpfH=w110-h161-s-no?authuser=4",
  },
  {
    firstName: "Sterling",
    lastName: "Brown",
    teams: [
      "Milwaukee Bucks",
      "Houston Rockets",
      "Dallas Mavericks",
      "Los Angeles Lakers",
    ],
    level: "extrahard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfTnlEiJ2bcnaMV-5I9Zuju1DasWp6jaMbOUR2pQbUp9nuep6P9RLZFA_slcI8FfU6ob7FLg4NoCk2Mjpo5IAPZYDVni_-rqKux_nVP3XE3yBaa3lqP5A0TxJGZGb4WmHxAmgYY4GYLKjlj8_J1sEOp=w115-h150-s-no?authuser=4",
  },
  {
    firstName: "Eugene",
    lastName: "Omoruyi",
    teams: [
      "Dallas Mavericks",
      "Oklahoma City Thunder",
      "Detroit Pistons",
      "Washington Wizards",
    ],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfIQdm5kW6QugTk34GAuQckFCjg1hnc9FfO6WRaE8KVRc56pWxSR07PFLB_fn2XnSaytfUVsW3-BCutS5leccOoBdJM7KQa0nlHWCpVpstWf4VdDck_5_q_mqBcJBdrCzZsVJ9LPfCfAAAU5w1Av6_g=w100-h142-s-no?authuser=4",
  },
  {
    firstName: "Christian",
    lastName: "Wood",
    teams: [
      "Philadelphia 76ers",
      "Charlotte Hornets",
      "Milwaukee Bucks",
      "New Orleans Pelicans",
      "Detroit Pistons",
      "Houston Rockets",
      "Dallas Mavericks",
      "Los Angeles Lakers",
    ],
    level: "extrahard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcIO42edg8iAUoFL46tSfz6njCmDbEydVcF0EdeOkmhkNTpPEoqttv2rYfz4Zka8CZXc_f5E_lhiT5bPIVnLagaKLhxUcAZoUjpBtMlHluSCAT-u3aZNu12v1zZXcAUFBk2I6OlUvw1OwqcVy4rMSuu=w111-h130-s-no?authuser=4",
  },
  {
    firstName: "Jalen",
    lastName: "Green",
    teams: ["Houston Rockets"],
    level: "easy",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHczSgCeykfLEfGey6TlKTYyTX1xi_DLmrabI90QkUYLSAkGprXWS4hFS0JKcmdonCERGzb8ZjP7jYE2lpoos9IoHKOpGkEfgcm5-xhizIszR9yfxv3uW2z7uqI2cCK5qV8WIfdBUHmhfNTG88CqK_fa=w114-h147-s-no?authuser=4",
  },
  {
    firstName: "Kevin",
    lastName: "Porter Jr.",
    teams: ["Cleveland Cavaliers", "Houston Rockets"],
    level: "medium",
    firstNBAyear: "2020",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfrKPxXgBSmRQ711WRFfeLN7K8a2W5xlyfQno98RtuXWxFYUY6hVJ6WPfBJZYDX1AV4gnvY4N2AIbpm0gf9eDKAdgZ3avi9LWO3JpblB-DVQ0mMdIM_gyNqy-sGHoZkz9QBH1Ttaen6Sz6APBN5qVdp=w98-h154-s-no?authuser=4",
  },
  {
    firstName: "Alperen",
    lastName: "Sengun",
    teams: ["Houston Rockets"],
    level: "easy",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcFk9zVp77ga_CusV7FxxKHAoiLjV0caVuBKnHNp_Uq6wD8FnwQ1d5bURXJhpuRO5DwD_7fCGjezVxBJaEhSOXcdtHFvztVa1dxg825pz5h8waGb71cuLuGsLAiSC9lVc7Xk8FlyzHECBKCP8F1e-Er=w107-h143-s-no?authuser=4",
  },
  {
    firstName: "Kenyon",
    lastName: "Martin Jr.",
    teams: [
      "Houston Rockets",
      "Los Angeles Clippers",
      "Philadelphia 76ers",
      "",
    ],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd4jYtm4SMG9MGq6eK9LvfsBVv54f0FwlMCcNi9pKpVVIJZGOrbrQWD0lLq2wTpBWMFz9CwqjqJOxzjnKTezGOMhU7L-rcf0BmTAq1jmDvU0KLK7QRxP8FlR5wHp2WP5y62U5F4tbnp-gMhB_6G_AUA=w111-h148-s-no?authuser=4",
  },
  {
    firstName: "Usman",
    lastName: "Garuba",
    teams: ["Houston Rockets"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcl8w3Fw9xuOtw2bJNff2-4dFSVXGyrLoJb8GvmAi06Y191okxS0Q7z6YDzYGPmk81BxQzbBF7N_BtJd1FkXzxZ98WTHryEaHFJOHLJ7q5a8_oHHpQKXfw8SK-xFZB0NNf_cl4Uf3HusJZKIODh6Tta=w104-h152-s-no?authuser=4",
  },
  {
    firstName: "Josh",
    lastName: "Christopher",
    teams: ["Houston Rockets"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Arizona State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcZ1SmjNJsoRdAi75p2N4dZ93Yu2w8LherZN4meKNBYgNjz0thaxHLa9qf-pfXmqJbIrGFWubArQons1uMvWGtue5JiO-xXRuD20tIrUhENdbD6Uk3G73OydrJT5r8Chl7-8jZyW89-a5qwY2FfUJQm=w119-h155-s-no?authuser=4",
  },
  {
    firstName: "Jae'Sean",
    lastName: "Tate",
    teams: ["Houston Rockets"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf4Z2fGOgbd4PiDdN9VtltRXS2mzGxnGxUvCX-rOKHo_bNRp48rLXpXs8ZlP2i6YfthU0a4FgAuQaqgWPSJWspUh89FWKg1k_U4EziUMJ3e2gO3JCGwK7voF2AUkiaz83SPamZkoLTPpPvbIt4j4a4q=w110-h151-s-no?authuser=4",
  },
  {
    firstName: "Anthony",
    lastName: "Lamb",
    teams: ["Houston Rockets", "San Antonio Spurs", "Golden State Warriors"],
    level: "extrahard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdV7ebv6nkYQ8wX5_zQ4niPVcFPO4uI6gdb4slgeUWKRpyrgi8aQdqiIQgwySKtvRsxra3rjuJhc9rQ89cNy5-ZbnA-ZX5jiwiNaV4tMZaI1IOyFIquJNFbMUa9AHogy7g5fce4uTFdU4WJb7hjr53Q=w114-h141-s-no?authuser=4",
  },
  {
    firstName: "Benedict",
    lastName: "Mathurin",
    teams: ["Indiana Pacers"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdMcKE83XgD79XsDC42GXUrlQ9GnXsJ85j46_CxMjxGCJtJEt7q_t1szFUJR_PqvHSlyEmVjlJX72jwmGP1TbPk9oQoxFMe1OGzH1eDpCiPPfYUHumYI9o2NJkqSCRM5F4E6tcttbLe8XyI5DKahjdd=w105-h162-s-no?authuser=4",
  },
  {
    firstName: "Eric",
    lastName: "Gordon",
    teams: [
      "Los Angeles Clippers",
      "New Orleans Pelicans",
      "Houston Rockets",
      "Los Angeles Clippers",
      "Phoenix Suns",
      "Philadelphia 76ers",
    ],
    level: "medium",
    firstNBAyear: "2009",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeeYljNoiiGAzXEHA3S6pUHP7y1E0nwlgKmWaiNu5cyOWgo0OKEQ_9m1awpfIuKWLU7YySuxBYKB55SQgRac6eNAWQaATvZRaW3rVanuVftil_59AOjrreSUxCu9KI1SgFCHfvQiPvlWc416mdPfcZg=w120-h151-s-no?authuser=4",
  },
  {
    firstName: "Daniel",
    lastName: "Theis",
    teams: [
      "Boston Celtics",
      "Chicago Bulls",
      "Houston Rockets",
      "Boston Celtics",
      "Indiana Pacers",
      "Los Angeles Clippers",
      "New Orleans Pelicans",
    ],
    level: "extrahard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdf-C7mP-E17q46UMUV8knVjgJHoQ6F-drQifhxW7J0wwxUxcN-WTMBKZtjIChKpe83q31BeulkwrySYfT8yBJ4VgBGneqSgwOxkPib5HytmGLuT-TbNVf16WcDvSGFOV8bnYASausMckZRlpD2z_at=w112-h161-s-no?authuser=4",
  },
  {
    firstName: "John",
    lastName: "Wall",
    teams: ["Washington Wizards", "Houston Rockets", "Los Angeles Clippers"],
    level: "easy",
    firstNBAyear: "2011",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfH3cpFpotxQocWq8CalfwN51SXwMfw4kKvuM_7O0aCUUVXsumagRv7SixRtzahQHCQdRoBr5Ou5YOy_NwlD__M5npv8JYbLkZhmnSukJuPej-yECoQ84XDf10QYe-aSnQLfzn08enB9CQGg7WFRKdK=w112-h147-s-no?authuser=4",
  },
  {
    firstName: "Jakob",
    lastName: "Poeltl",
    teams: ["Toronto Raptors", "San Antonio Spurs", "Toronto Raptors"],
    level: "medium",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcFYPBxZTNQhNA6euy0lLHYQWO-0zntZy23G3owG9vf7vkuL9vacxb4aNlklR-trsmN1eKMX3V6XEQSdNYPftixYINGhIb-XEWAlBPiUcsUEB0gUlcnUP7JcfTOT89bCX7qT9udmDBiUmKSogFpfkGV=w112-h151-s-no?authuser=4",
  },
  {
    firstName: "Lonnie",
    lastName: "Walker IV",
    teams: ["San Antonio Spurs", "Los Angeles Lakers", "Brooklyn Nets"],
    level: "medium",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHctiX070Pk9vJAm-1v8warcPA1MqohYX9F9VvIo7kTNNsvj0Op9C23Ol6DiLL9ZpdLZQ1TQCkrMzT5cgQTaKkgbsT8TNOQPemym44Nyw9y7c8sGVJODzLUR1fcwp5xUb7a_TA--Xn5_28ON7mxtz5Uq=w116-h145-s-no?authuser=4",
  },
  {
    firstName: "Derrick",
    lastName: "White",
    teams: ["San Antonio Spurs", "Boston Celtics"],
    level: "medium",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfDDrogQIj9B3I8F0Izrufh0J5NIelDQXUgzTqnWmYNGhS4px3AVx2pHxcUqnInqrbxiBwLeJgvl6lqrxoFxsKD7TW1Lqsg-zsHnLzlGORnDzRyR8PPJrLWjlZMowlcykOowhQxR0VMQqkPRGd3TDZw=w110-h150-s-no?authuser=4",
  },
  {
    firstName: "Devin",
    lastName: "Vassell",
    teams: ["San Antonio Spurs"],
    level: "easy",
    firstNBAyear: "2021",
    college: "Florida State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHciWNlIi7vTVn314l_WGKk9fq8I-bNYJxmL9VWQE5pXMknibFgBF927gbJjbojwxNpZLXgWaDjvWIeNVBLp7H1iE6QwiIG2qgz4wK_6wr4udhhIhpEbZW8cnXvWvZ12rrEryuHSAQB3_77W883ZnicS=w108-h142-s-no?authuser=4",
  },
  {
    firstName: "Doug",
    lastName: "McDermott",
    teams: [
      "Chicago Bulls",
      "Oklahoma City Thunder",
      "New York Knicks",
      "Dallas Mavericks",
      "Indiana Pacers",
      "San Antonio Spurs",
      "Indiana Pacers",
      "Sacramento Kings",
    ],
    level: "extrahard",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcqkm32DnxdMzjbVFq-Wbadz0xT0epiJQiJoKcARcO6m_ID-GytAKIOqLvFa8VEm1HXTtHYZtCRCEYwM96lNQmV7GQ1gEt1U2efTG-WhKiZaRnIx01G9az2kkGarg731hKGkx7Ih3eHbrnj4iTVl1gV=w114-h147-s-no?authuser=4",
  },
  {
    firstName: "Thaddeus",
    lastName: "Young",
    teams: [
      "Philadelphia 76ers",
      "Minnesota Timberwolves",
      "Brooklyn Nets",
      "Indiana Pacers",
      "Chicago Bulls",
      "San Antonio Spurs",
      "Toronto Raptors",
    ],
    level: "extrahard",
    firstNBAyear: "2008",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfz93Tg7wDMqq5jOkr8Zu9wxEPYf_wpa4JaoCUxLdjl-hnzg3qLMUwtfj9fd2AmqRFAMIDLbp4WAMtOmuALd-VGYqfjisCn3l32quEaLhDEqzL_NkjxLWalF0_qv7BI7x_aI2eola8c9pQbJpNIc8ZN=w109-h151-s-no?authuser=4",
  },
  {
    firstName: "Drew",
    lastName: "Eubanks",
    teams: [
      "San Antonio Spurs",
      "Portland Trail Blazers",
      "Phoenix Suns",
      "Utah Jazz",
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHebQGLPQPNOV02LL74WFnw9rgin-DzuSq9OxQssD923R6h2IelmYeTTstVD4_5S18TLe_STpQFpcHcDNuZuH1MQ9JgqMKiYk2mLGamh70zvxXNQOu2P9YSYjaDjv3UhVC3ZDbXqMh1BnH7QjjB5RcIT=w101-h154-s-no?authuser=4",
  },
  {
    firstName: "Keita",
    lastName: "Bates-Diop",
    teams: [
      "Minnesota Timberwolves",
      "Denver Nuggets",
      "San Antonio Spurs",
      "Phoenix Suns",
    ],
    level: "extrahard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfyztg6mfbT-_bmLC22OhNRciBSlNPWT_LiZHuNqU7WKfI_PupRMwq6DnguDTgPsmvwC5ZnNZLzldgr0qOY7wZ1Sv62X9PJKVq-DrakzZ4gpjUvzW4P-hpmQmTMv5EYCVieNNzWUJS8p5Hs5gJ_YPSF=w104-h152-s-no?authuser=4",
  },
  {
    firstName: "Jock",
    lastName: "Landale",
    teams: ["San Antonio Spurs", "Phoenix Suns", "Houston Rockets"],
    level: "medium",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHemBtr119uYeyEHfJi__fZ5iAkspAPWupfJK8v7wmz04QF9irc6cIueq-83q1l9oM7KC9ZaEzZtUjeuCihcMquA9S_oJWcJALjb8MpZ9D1zGTN0eDlxIQ8oeAREEIz4SFe6yAvbQ-dJ6hKsdThP9F-C=w115-h145-s-no?authuser=4",
  },
  {
    firstName: "Joe",
    lastName: "Wieskamp",
    teams: ["San Antonio Spurs", "Toronto Raptors"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfV7F3ShSCLtXubaXdqnR3i33NbnDMsqtWMHXlUdX8mnJp779cTPhIASS3bFvXUcuTZ3OdWq8QjlWBVo3Hr5xApXZLSgG_5Q9ymt_FgbeAD-di7tvvgZDxRRJaQ5MnyIHcC_FPPr5-Usd2epYz_VaFu=w107-h152-s-no?authuser=4",
  },
  {
    firstName: "Ja",
    lastName: "Morant",
    teams: ["Memphis Grizzlies"],
    level: "easy",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfdFtrU8hQqoKZ6ScQHh8cs7_BB_DnrtHW28xEksxHlydCaMmFQE106TyjitW2uznmBBOIB047vVgJJF5hvgSIcQeYxmkqNGaIzJp27Wd8unRXwmZq9FBSnJH8KgLT1tgDqdpZAKSmLeRu1jdsRo_FS=w112-h153-s-no?authuser=4",
  },
  {
    firstName: "Dillon",
    lastName: "Brooks",
    teams: ["Memphis Grizzlies", "Houston Rockets"],
    level: "easy",
    firstNBAyear: "2018",
    college: "Oregon",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdVVqg1rvXuV91vaMDl5yD6U5N2cticR2Ydf7kWvTBzt4dvbzrhS8memMJC1UVGg_Now4fj48QkufuyHnY2PNjHvi8eNcFVAf_m3iV6b0xaonxzdM5wlelB86wczGibH_sYICfZ5b-mQYEo_akhWBHK=w109-h150-s-no?authuser=4",
  },
  {
    firstName: "Kyle",
    lastName: "Anderson",
    teams: [
      "San Antonio Spurs",
      "Memphis Grizzlies",
      "Minnesota Timberwolves",
      "Golden State Warriors",
    ],
    level: "medium",
    firstNBAyear: "2015",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfILa6WIyepKyFMk63qX5to80peYpViQDYerRi4AE6qrbctsi4AvT2znxOI-I3WJVs36SlUb9NdwHbMYEMUDBPU10XedWh0bSeVVpJ1Cn2tCUuRPbG4iCwKCjO6XmdVROQLUP5vdk6Hr3uB3lN2xvrl=w116-h149-s-no?authuser=4",
  },
  {
    firstName: "Steven",
    lastName: "Adams",
    teams: [
      "Oklahoma City Thunder",
      "New Orleans Pelicans",
      "Memphis Grizzlies",
    ],
    level: "medium",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfoJyOMmsSC_e9ocDRGZ3a6W7khigTSidzBC_xTf2iis7osHfNXMQhUCqIBDwsAbT2SzcTlpUkX89t3QyISEkREPD_bO1VUGwtfpzCl3P-G9vpP3urePu2MkCuTroWm11je0fQV8Lx7Tb74QSt1uwn2=w101-h161-s-no?authuser=4",
  },
  {
    firstName: "Desmond",
    lastName: "Bane",
    teams: ["Memphis Grizzlies"],
    level: "easy",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcvxEltsRjPtzkJPLzbPwtWiFoiTUB5eORgc4wBxj-YeIwC1P17db7tOFlhMUlliMRHgiUmo6t1-IPi1rAOAh0jUWIelV3w2rl0lamtGTdJEZ43JyS26mnePx0j7WW90FbU8u-_Dgu_bbeUfUbwB-Mt=w115-h146-s-no?authuser=4",
  },
  {
    firstName: "Brandon",
    lastName: "Clarke",
    teams: ["Memphis Grizzlies"],
    level: "medium",
    firstNBAyear: "2020",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeK1gFqyF3ABhWNqvYDuL5NdeQm-FYADYCEwvlUVAsjIlFT4lVpl1eX2QV19nH_-nx3tWA44WNkguIHFfJhrZMKcBoz6Ouujz7UK5Ho2OAKyOnG9Zj1zi7SjXaMNP6APkBXD3VfpoieUXCrT2kclQQS=w123-h139-s-no?authuser=4",
  },
  {
    firstName: "Tyus",
    lastName: "Jones",
    teams: [
      "Minnesota Timberwolves",
      "Memphis Grizzlies",
      "Washington Wizards",
      "Phoenix Suns",
    ],
    level: "medium",
    firstNBAyear: "2016",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHegklAlJ8p7aR_HWOC6NEx9Y29HFsNuE7FFPMt5KjNPhZD8LuCIBNcYolxRvJuw-W_p6u7NpRHuOrZ72Tqg1Cm5sz7s2HTO-p0fti2L1e-QMPOQyZzVSYPpxt4usAJ12py_hZABR7iWMCg6CNJJYFwA=w117-h143-s-no?authuser=4",
  },
  {
    firstName: "De'Anthony",
    lastName: "Melton",
    teams: [
      "Phoenix Suns",
      "Memphis Grizzlies",
      "Philadelphia 76ers",
      "Golden State Warriors",
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeQvAdXxjC8IsW1nGD-rqjhtGV5-CsmkKvytpoylKc7UWhSKVw5OvOhWS5O9p3f6jDH5RZ93mIV1w3uEQk1OKWp0S2iHRvlvKJDFt8HT4l7G0SaINki-hQNDgo-3t2rTlWQKaeYowkkoDh6Db91Bi_2=w112-h136-s-no?authuser=4",
  },
  {
    firstName: "Santi",
    lastName: "Aldama",
    teams: ["Memphis Grizzlies"],
    level: "medium",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcDRyVPmOiMVIiCLvp9QXv5VcnQhw3jMvh3St6tH66JZPzjB44qdpFeNYcOOYZgYYXuBAHBLex_2Ymb6qp_do347ynr9HfqCsxBIS7_iWMMjDnzLLnmCHgWuJHQUmNUivNU0-f8oHj10WA1JaRTXRMf=w107-h152-s-no?authuser=4",
  },
  {
    firstName: "Ziaire",
    lastName: "Williams",
    teams: ["Memphis Grizzlies", "Brooklyn Nets"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Stanford",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcGQ2zqlRYodD85tKHVwnP-GkwOq5hEPpUqGQe2Vs7wYztPFw6gtG7Rdde6I_LQN5vA6SpOjkSEMt6UuIiSvBUlr3-3RdRJ1ojtUD9UbZ1qhDl_F02Zh-ZI5GzTACrd6OWIIsTyiHnQjU1ZODsUxfwP=w117-h139-s-no?authuser=4",
  },
  {
    firstName: "Sam",
    lastName: "Merrill",
    teams: ["Milwaukee Bucks", "Memphis Grizzlies", "Cleveland Cavaliers"],
    level: "extrahard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdV6zKYudVvT5deedNen2Al4zOgzZZSUFARtcZwhPls1ld7d65a05_0CL2PWc5IIQekEH_JOM56uikLpR66FLChxhhXgaEahXgcaweD0HI2mJr6NO_nM8e1BUt25xjSkwEj0TpqTLFfGhI460fuUYI-=w99-h155-s-no?authuser=4",
  },
  {
    firstName: "Zion",
    lastName: "Williamson",
    teams: ["New Orleans Pelicans"],
    level: "easy",
    firstNBAyear: "2020",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcHAoOwtlOKL--D7DU6SuG-W9kDjZpmDoS-zoDE_hWlck7Z1oi9aFqR28JfOhKAGRU2wcxENqnAOeMi9_YdP7ePpvpw8pb0hfrImDiTD6pwqNExGmu79udWMYllARtV1XW2aAbu15QsnO28vMMM-86d=w118-h151-s-no?authuser=4",
  },
  {
    firstName: "Brandon",
    lastName: "Ingram",
    teams: ["Los Angeles Lakers", "New Orleans Pelicans"],
    level: "easy",
    firstNBAyear: "2017",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHePNsGfHsCULO_6bqWfF3EsgfNbRxuEXHnSp_4Yj3sTei2d-giuYqPxD-jXFEOiTUXQNoq-l6tJSpfhKgKVYBHcX1LZ8O0_BNZ2-8s5wqK3PRK9UbYJmrxWnJwIoSM7btANjdEvczpmRcaclQmjhf-2=w101-h148-s-no?authuser=4",
  },
  {
    firstName: "Herbert",
    lastName: "Jones",
    teams: ["New Orleans Pelicans"],
    level: "easy",
    firstNBAyear: "2022",
    college: "Alabama",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcHp6Y7kC28_xsB1cxMJZyvp1yOizwOz_28SiooxD9_HWkuZd0TXRfSWRwxCyI7Enw4YVPgKnc-rIhJ52Ty3T7Pq5524ddfeSeh-PFSdnKB3_plPAfd-EKmuOnWWSTHAfN6JuyeCO-TwOMuqAtnH7BH=w98-h156-s-no?authuser=4",
  },
  {
    firstName: "Wenyen",
    lastName: "Gabriel",
    teams: [
      "Sacramento Kings",
      "Portland Trail Blazers",
      "New Orleans Pelicans",
      "Brooklyn Nets",
      "Los Angeles Clippers",
      "Los Angeles Lakers",
    ],
    level: "extrahard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfVq28yag0ESnqXCI2bMS6ADJlezEFmjzXrAavnRJisH9eVl64Sh-OGcvtgSL564pgPB2ZQ-XmXISptv8q0ThvpsZMX3DrKsEXkFJaybktzysj-EZRI7_Bc1hwPX_J7fI_-8SQJt05CsDWjKhLu5IEL=w116-h153-s-no?authuser=4",
  },
  {
    firstName: "Jose",
    lastName: "Alvarado",
    teams: ["New Orleans Pelicans"],
    level: "easy",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfFs1_L32Yfq1bnMGYWA0sm1a0Tm-biYzGFMDpd-xZFIlcV8d0QmYa5AG07rBqitykr8AX2azdDi2Ewp_WC6DjpEiQUDUgPHpVb70zPeunH2ahyifFGs2jSZXjqmPRkrOOV9Iq06WHu0tbUd1uaUC0b=w98-h148-s-no?authuser=4",
  },
  {
    firstName: "A.J.",
    lastName: "Lawson",
    teams: ["Minnesota Timberwolves", "Dallas Mavericks"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHftDKEyGnxHptzkIfNz2vX998-5o9flUl6HixPG909O2AGXbiWXjjE8kpAPwWJaHveX2qDBMwpRsG3aHBRifcZfGBbomXBmKKcIXk1C2wAd_SsdOjm9m0kerl1KxPxfdUsX4E10yuDyzd4B5CGjyaLx=w109-h154-s-no?authuser=4",
  },
  {
    firstName: "Josh",
    lastName: "Hart",
    teams: [
      "Los Angeles Lakers",
      "New Orleans Pelicans",
      "Portland Trail Blazers",
      "New York Knicks",
    ],
    level: "hard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdYx1Kca7Py2vvEXMRTjE9rFQUfLBT_WQRo8DDuZ8cjuLM4Egr3TkxuZxayb0-sGvgzfZueM_cFHNr9cWXpWNzBuu0dGFl_SnMP86-fuH-7NyCQqvWaYXNGJiNNeWjTXgbty3XqZMnvwAaUEcfHaSvA=w109-h152-s-no?authuser=4",
  },
  {
    firstName: "Nickeil",
    lastName: "Alexander-Walker",
    teams: ["New Orleans Pelicans", "Utah Jazz", "Minnesota Timberwolves"],
    level: "hard",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcXHR3U3N2O0Bi_Bk7_mImYi5LymsZGy3HL9Pc4p3Yk12FtXb-_e5v3d9xEb6mFd20N39t9sAbp7k4if-sZRRayv8ItAmM8BY0BdLY25kVT3fRCnqbAINCcgGH6xfpCSWbRbBx33_iTuXZMBRvYJJPx=w106-h143-s-no?authuser=4",
  },
  {
    firstName: "Devonte",
    lastName: "Graham",
    teams: ["Charlotte Hornets", "New Orleans Pelicans", "San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2019",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc5ouMfI9BwPMkroWOfIkw43mzE9aspwzwS5D2Ty2LCwjE0BPGlJa994NlKPzQytHtTt-qJHSXKKjIvKEyIDcq741L6mX_SctUvekcwUdJ2Ot0vSVSkd0UpTynniepxtkogLnKtJmZeoh8r7dBfAf7r=w114-h146-s-no?authuser=4",
  },
  {
    firstName: "Jaxson",
    lastName: "Hayes",
    teams: ["New Orleans Pelicans", "Los Angeles Lakers"],
    level: "medium",
    firstNBAyear: "2020",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdupsclJ8FD0NQ3nhSPARRxa3tpUo9ROjs2ZBP98-O1-4R2vZstVNYK6qdOdhN-BoAZudGvavL4pQTdApMY0wuDYgEwoFoHYUl0Rk5O7488fo8fl3JKmfMCiZGSydQvUs92RYL8iFk-4etdEOIqVtsG=w112-h147-s-no?authuser=4",
  },
  {
    firstName: "Naji",
    lastName: "Marshall",
    teams: ["New Orleans Pelicans", "Dallas Mavericks"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfcnscHVycDlpdBvtVORzQm95AKIr6qDiWU7zIZ2OVJ-VzB620dMc1VOlZX28zD-RlYXP6rbjIXM2bqrGo2h5MdXtDxd5Rezwevx1pq4F09BB2-THllUEOtuCcGKhS6V8pUftA5sNUIdTKeftM0Lsk5=w109-h147-s-no?authuser=4",
  },
  {
    firstName: "Kira",
    lastName: "Lewis Jr.",
    teams: ["New Orleans Pelicans"],
    level: "medium",
    firstNBAyear: "2021",
    college: "Alabama",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcSQdTSydhqK93L_izMs33MUJhGFswo1lv0cvHv5VTmEw40WfwMXV9wKC1KSDrDyqch4Eptg-6qVFng79giHq2C30iyfbkMDlT3zWqN-BMb4OvtiNZ3JMEf9Vr_GG3oIgoQwCyc12dPlC0gVte1lJ2l=w123-h149-s-no?authuser=4",
  },
  {
    firstName: "Paolo",
    lastName: "Banchero",
    teams: ["Orlando Magic"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfPv3bRz2SfXoUHy4zeAPEX5zzzWJLZ4Ni229qWK-B72D8c0yZAZbcQxDLfYViP4xIZNd5L5XfT3nwLFHspFc68lM0q3QMOGGX7iFcw2Bcq5H9OcVrLrgQgt4uSeBDZ2cgcnUqAVur2bHtN97pCEVvy=w115-h153-s-no?authuser=4",
  },
  {
    firstName: "Chet",
    lastName: "Holmgren",
    teams: ["Oklahoma City Thunder"],
    level: "easy",
    firstNBAyear: "2024",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdtyGRTEmv9SVzZJVA5af3yizgsdO75g-ZCaktRTIt1Mylxi0516LzYnYbadM1WSXuUCldGM4O7sEaAjkj9AFYXp4iDLse8u-MIqSBNXHehafw_k6iQ-4t82p2kP4e-m7xhvia7TYDSbnzm3Sy39H3N=w111-h152-s-no?authuser=4",
  },
  {
    firstName: "Jabari",
    lastName: "Smith Jr.",
    teams: ["Houston Rockets"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Auburn",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcNE6qy6Nd9AX8D2BxUlJRvdw9yOzuxPXPVKLRNndzaJeDuHJgBKmOw2PrPhgiKMWFBti-IoWNsXEhuBdkeUlwjWz3mNemhLmGpjNTs6w-DYhaoKQ8nBDT27xIPHT3q1m0e0YQWCoeikCSFIEPLzsAC=w112-h158-s-no?authuser=4",
  },
  {
    firstName: "Keegan",
    lastName: "Murray",
    teams: ["Sacramento Kings"],
    level: "easy",
    firstNBAyear: "2023",
    college: "IOWA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdxm-yO3Gk8aP2DGSzn_H5dY4-8tXMU43xkrqf28BXU6PbBNINB4rKyRRyDu340Arb466uXO8tzIp13NBWQI2_Xl8l2HVqRDFi0m4Vjmct1IwLPlnUjR-0WplIsygsZZnGaD28vFa_RKKA-lo0q8jTm=w110-h139-s-no?authuser=4",
  },
  {
    firstName: "Jaden",
    lastName: "Ivey",
    teams: ["Detroit Pistons"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Purdue",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeIBo2C37VT8PUQLOzJxAk873b2Yk8wESOGiJkrmqTlUdqPZ7vTXUETR927pXBfmrA7WiK52Kbu4H8Ratq7O7Hh6bpOk1I-psPPmxG8MS9zh0MpdyCq-jLJ7OUWNC6oZHJWTkOumXH7CDIuIuy6v7Xi=w120-h151-s-no?authuser=4",
  },
  {
    firstName: "Shaedon",
    lastName: "Sharpe",
    teams: ["Portland Trail Blazers"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe4f_EAsy1A3QxeGuVU9NC54C8A7Q2wgoSDqPYj1LXGl7flgA-8HCcoMdLw_Zg4xr2jxCNB0S4JEe_OkNCtTEJBNb_kEtoVOAqwf3KHFVtbPIgR3zXfoQqJXO9ZxvGqsomoCYsszyTNC1F0GWtZj198=w109-h141-s-no?authuser=4",
  },
  {
    firstName: "Dyson",
    lastName: "Daniels",
    teams: ["New Orleans Pelicans", "Atlanta Hawks"],
    level: "medium",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcoDn9KtjaAAxjyenvbiZVyd-uinRYZa6MK1YuWIceuA9TanKHKPJ1KCSKV2iAI3tN09mYYWL9Yx4j8QQ2jDqZEjW3p-tkCxPCehMFHLTwbERPhSBjyAWuZDCSwV2rIUImdt-pHLI9U-HUm6mDtkcM6=w107-h146-s-no?authuser=4",
  },
  {
    firstName: "Jeremy",
    lastName: "Sochan",
    teams: ["San Antonio Spurs"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Baylor",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcPItk5iC3EZMIoIiPD3ldvu-yw6l5MTQlIphsbuCjBOzQSkxCDYG2D1V6EzA2qMIv5VgifOBa_FrVkcl-Meq0WzvxHQGtzOhVk5iZNuzUpcX2lvzITP_AnN0m2DYkfyYcwkAH_T0XNG0v4PNgfd6BS=w114-h140-s-no?authuser=4",
  },
  {
    firstName: "Johnny",
    lastName: "Davis",
    teams: ["Washington Wizards"],
    level: "medium",
    firstNBAyear: "2023",
    college: "Wisconsin",
    img: "https://lh3.googleusercontent.com/pw/ADCreHctBkvGP1C4eWaPNsn4Q4CqrPw5jFl06HYXQfLC0Ot8dJLzOpZplrOE_ztjqv9y25-32AJVzB6s0Ozlv-F96nRkdDH2Cu7GLW2nTM75ooBbBTJxDXRHCRpfXYm5nLnT7gfR8omxMMt1S6Q7lKe7J7Sa=w114-h149-s-no?authuser=4",
  },
  {
    firstName: "Ousmane",
    lastName: "Dieng",
    teams: ["Oklahoma City Thunder"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe1rQM0eQDxkJ26Va9-QCgTZa6McyZUez4Ch8lxKnJN_mC8cMASgFaZF1y6mljyko3TP9xsJa4vhRC19zs1BdGv6CufAck-6_ZcgDV1ajMvTzS8uartQJGHR8vAVluJPm_hN2UyzkF_qw_FVxsHGGzo=w115-h162-s-no?authuser=4",
  },
  {
    firstName: "Jalen",
    lastName: "Williams",
    teams: ["Oklahoma City Thunder"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Santa Clara",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdA4EBIiYRobYuQQ5KAALpUE6uDRwtFYWbg3dIsskezlHr7R1JfPzxE9O9nz1ZgfaJImEOJYzMMV6Wdwpo0SbDWiZZzrbTavvAaeIYJdpY_yIvYFriNBgDWx7_oaCGKk0md1vchSFf6fCFf4UBpj4bX=w112-h149-s-no?authuser=4",
  },
  {
    firstName: "Jalen",
    lastName: "Duren",
    teams: ["Detroit Pistons"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Memphis",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfNbuxmaek85va_YYSLTI39c2Ym11v2EdRlcMrsqqCgubp9h0jS4WXzLQrC--Zs0s3FetYViE_PchEqwSkMZ25PuVhjq0gwKWzfOnQNJNgvvRRRZN1ki3NE9wTre0NGv7jD2O6i7tw9A_GSheZ9WcAs=w111-h145-s-no?authuser=4",
  },
  {
    firstName: "Ochai",
    lastName: "Agbaji",
    teams: ["Utah Jazz"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfaj7K0nVZQQhyk_ys6BX74B0Uu1UOnm9Kwxh4LI6WkRDL9Ly5S9xk-NJyikKPzboZi0fxjJJrH666UBEJcQ9p741-EkS6EuFkQEoyxJXbM4LfGzQwIX6GqFQFPzI8hPbTXtfFh5j1e-uNHFqgHF2wr=w114-h138-s-no?authuser=4",
  },
  {
    firstName: "Mark",
    lastName: "Williams",
    teams: ["Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdfoXXLU9oDtFQoE7qXRNWhJD3kfvMaeAdLhq6u1XsyQP7fF59D1_Rcgjwr4tiXI6CjqrQyivb7Rwv9_Cs4Qd2ifcgJq0YEcbrsy1ZsGLbDKXiTkGK1zc7PfeSFCxfq3XIc7bSEE7OFIw1FTvA4tzuF=w119-h146-s-no?authuser=4",
  },
  /* {
    firstName: "AJ",
    lastName: "Griffin",
    teams: ["Atlanta Hawks"],
    level: "medium",
    firstNBAyear: "2023",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeYIuzxQx-6-j1_0drj09gBlHzTkhf-hZ9_pNRdsgCuCCkb8xtXOTDJtjH5RRjUBaOV3fDyq0d4UkgJGnn4N_O7GK7_6GRAA6gzJZ2OJb8kzPCTPEp6BOTtKQBfkOpqgG7S48AGYQz2FYyTxmc296e3=w114-h153-s-no?authuser=4",
  },*/
  {
    firstName: "Tari",
    lastName: "Eason",
    teams: ["Houston Rockets"],
    level: "medium",
    firstNBAyear: "2023",
    college: "LSU",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe0IPWg2ZSd0XINxCnN33DrYqLFyKibTQU9a8g30QsnWvJcVDtOwHaGO9WGg8cWGX1V_-hfNgdr57dLPL_wzBhXB3EDJu1MzHX9kDL5hTWtXg7NwaKAg845IuvMvauwMRX8jzw-YgHWDp9GrPBI4li4=w116-h156-s-no?authuser=4",
  },
  {
    firstName: "Dalen",
    lastName: "Terry",
    teams: ["Chicago Bulls"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHePGhkMNUabbO33EojmUDLXlSIXMH0qmn6u8mqTFzNkHRC__YgN1v7MHJJBQ6bCAn2ZaOdmkEm6mCGE_7xf4_39_Oh13z5R9GlqJ5qPGuWYLcmDgftM5pxkmfFqxKuEwzAaXDktQ5bHjbWSG17PiDNv=w111-h145-s-no?authuser=4",
  },
  {
    firstName: "Jake",
    lastName: "Laravia",
    teams: ["Memphis Grizzlies"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Wake Forest",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdISATEcG14i6qIktxIpV8NVwxqopnFWaKDqDOjziMxfDLKHGxaZtq74s7yHNVCcqvsUcgFvirIZMgeurqfW-9OQkuJvFV6YFo3w001UtF79EYw8tZ8qpR1YNyEUO6Sft-DfoyAqBukqPUk6BT9YE0z=w115-h152-s-no-gm?authuser=0",
  },
  {
    firstName: "Malaki",
    lastName: "Branham",
    teams: ["San Antonio Spurs"],
    level: "medium",
    firstNBAyear: "2023",
    college: "Ohio State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd_wuBhutBxTVE1_u64hrvyTD8A866oC_rB8j1rICC9V0-Hn1YlE9cuqSosYM0cjdx8JybBoDafJvspZ-UWuWnBx13dA-M-g5RxKI38e-t8FoSK5GUUy-9RPOrG9V3wiM2icLwfaAKjIwjUsXjBPzjo=w94-h155-s-no-gm?authuser=0",
  },
  {
    firstName: "Christian",
    lastName: "Braun",
    teams: ["Denver Nuggets"],
    level: "medium",
    firstNBAyear: "2023",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe5Yb-uRHDINFkn2hRYuzkTI8vWRIJDiduoEpQl24fyGd4UcTvwPgYKTxSdSoPgqGaMvIWi430m7z-Mc3UJofmeXksxLZISetX7WrMSINNKw6OMkxVOOmtjc-hGCmOZi0ommUXTV6z-OHGK53Zw4E8k=w113-h147-s-no-gm?authuser=0",
  },
  {
    firstName: "Walker",
    lastName: "Kessler",
    teams: ["Utah Jazz"],
    level: "easy",
    firstNBAyear: "2023",
    college: "Auburn",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdsxRHUJZ-HropE08vEyqnXXAXObrAROqjDMqtvSvvC2xwkrawpok6X6ygWtun_Kro7DbF8Hy1wUVtl7j7u522kuDzLWkowdhKfYa0c_LDACAOg2r-4S48eotkkT2z0k7oGUr6gJq4JcvVg78SAqcVX=w104-h157-s-no-gm?authuser=0",
  },
  {
    firstName: "David",
    lastName: "Roddy",
    teams: ["Memphis Grizzlies"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Colorado State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfADbabAMS6AQsLC0y2eO_OnaoiUlXIB9aYFZ3nYPiLqbG8bdvqADfPtqy9jZkCcRu9vFMZgcpOEfHP3OV3h3V3xWZ-kxW_-mLHRF-7nXIcpHbHdGsoNtOHc_GdsY9BWmFXxisE3UG_SYBxfZLyYvEH=w125-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Marjon",
    lastName: "Beauchamp",
    teams: ["Milwaukee Bucks"],
    level: "medium",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdrgkFyjP0uFKH5jTz3iaIuefHEbQNkgH-Qd6LbzXIcbvyzdZWobi-9B4xH8_CtTd0rzsf902gZurVg1sK6lHvuTQNXZbwrEFQ0K5_XW168AdtakFTDM1bj_qjLGh4wp_25RTgohipssFvchxrq_CWw=w110-h164-s-no-gm?authuser=0",
  },
  {
    firstName: "Blake",
    lastName: "Wesley",
    teams: ["San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Notre Dame",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeJyolAl3iibFjqe0hTVX--1m63n95aTsAk3s5d36gxmy2qttvKwKIhZH_SX1tRf-EgPkaBwi7TRWdDHrP5E35oBCO2Y2ohnjXZbQ4bF63FGFlKO_5o438Spi69nVgJYilhm63PjwYeuHH5f-IFsR7T=w114-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Wendell",
    lastName: "Moore Jr.",
    teams: ["Minnesota Timberwolves", "Detroit Pistons"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeNniMq70NMtKQu79OhpFTuJVUTlAdYmXhTzEyQVEQ-pZA9pDMPZP0n14pf0_wQpSbNaOJ_COxzuS5tkgC577YXMt7bqqfozvPcY6WsdTGxYxXgIkX6UkhJZRyRBQYzqXlSAqhfgJ3ItERoRmzpRbjV=w110-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Nikola",
    lastName: "Jovic",
    teams: ["Miami Heat"],
    level: "medium",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeg-KNsyqw0Z5WsxmuI9i7QKtwjqI07suy1wpFIjikCydX9OyuAn8M9xDay9YcnhyHWHqwt83uzIPeUwS5axK9ZXRdgan03PSXuvdjIIuHjf2yoXSLm5gAvN8FhYT-7eNbhRoEUgi3CcgGmQ1aBv2Ep=w113-h159-s-no-gm?authuser=0",
  },
  {
    firstName: "Patrick",
    lastName: "Baldwin Jr.",
    teams: ["Golden State Warriors", "Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college: "UW-Milwaukee",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdoPTuJJgNDb35sJMXJq4OkiLMIQ19BTYERzZX3tUVkaiswxlLp0PfIqsL3icAd9LJkEqkKY0Pf4oKpGIVyDaYSJTRl0MmUzjx7EhCb1vs_n6DRmyF4smk5raDkzwJ-04h3WnUnQAtDs3acb-yJFlqA=w110-h158-s-no-gm?authuser=0",
  },
  {
    firstName: "TyTy",
    lastName: "Washington Jr.",
    teams: ["Houston Rockets", "Milwaukee Bucks"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc-j-PHAaeM3lMibTmO-dhNDGZReedwB9Ongtj7rtYBSVtXJ38ItCnTcjJ4wyagU_-YpniDOl5Fw9yMATX3DkKym6fySH42A-UFvyueUrOSyhCfJGUr3_EbkkXgJU2dnlbwQDY3e-ls7SgzM3qZ-gns=w111-h155-s-no-gm?authuser=0",
  },
  {
    firstName: "Peyton",
    lastName: "Watson",
    teams: ["Denver Nuggets"],
    level: "hard",
    firstNBAyear: "2023",
    college: "UCLA",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcrdygmeeVmIO6ARqSMBrlh7_YXnMnx7hzkJLWtnIT5wdVnFgZqsxdG4I4cd4xgkON-uhdfbMLgVCQIiHsjTSeETTYDQ-qzcHKF3k4XsQyBOpG585Y-JF-6MdXD4fIQoXV1RdWXfrrIiBqb-B438CFB=w116-h169-s-no-gm?authuser=0",
  },
  {
    firstName: "Andrew",
    lastName: "Nembhard",
    teams: ["Indiana Pacers"],
    level: "medium",
    firstNBAyear: "2023",
    college: "Gonzaga",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeF-GXkZPwBlPMEBeObUW2QwQToDllQuunLy4-n_Jv4ARziIJGBYxMb9bjZNnprtTxQ6dJh0CPbpqyRNGjUn2r8ZmaZ9Uo6ukCuZ1gfYZ6xBiyGwVlWn2EoNhko0m_z_e_okzH7aVxR6qmqdRHBOa3G=w102-h152-s-no-gm?authuser=0",
  },
  {
    firstName: "Christian",
    lastName: "Koloko",
    teams: ["Toronto Raptors"],
    level: "medium",
    firstNBAyear: "2023",
    college: "Arizona",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfY5RaXmzw4UqMqxCPYAydHIjQw2QNJv1WgyBrCvWcMNxcSOsTUGSzHhFhXo_DBDeEMsOq6QWem7V_TWWXQKrmn_OB5HUOXEaso2xl-MaqwB4W4q0GgKAPlyLKq_YWM7zYrmI0kpR--GUc-r1agWFLB=w112-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Jaylin",
    lastName: "Williams",
    teams: ["Oklahoma City Thunder"],
    level: "medium",
    firstNBAyear: "2023",
    college: "Arkansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeuvxL3D7ENhe6O7HF_55acZ6fgU6bTnZNyPG3acTLGZvieeqYRFF-foj9Mmy4UbZKbh7Ezc3Z5oHspIgA-0l7eeDV44lNBILsmouW2ocneBakctcCC9XAAn_JN3h5cZVGSrv0zUeftKJkM7yvTMXuN=w107-h164-s-no-gm?authuser=0",
  },
  {
    firstName: "Max",
    lastName: "Christie",
    teams: ["Los Angeles Lakers"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Michigan State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe6BaF-A4IxBe3u1qXMrqK0TFdCtGofOiMyCId8UqO-A62MuWUn8YbixxPi9id8_ZIYbM1QMTt4OWDKPuJtlDIUHGJOkUUHLQl4ion1i9m0r8jhxXuXyLWE3BctLvEgw0ufF7vnq-n8uklyQWyRV_c0=w114-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Jaden",
    lastName: "Hardy",
    teams: ["Dallas Mavericks"],
    level: "medium",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdnj4G9BIzfRJXS5gp_brj0cSx6h3xdYTokO-6hLQpzWgjsKuUIY7Qd2iRWM8o_yHZXIQwrWPjf0qPBzI5LAt73hDAdJNUK4hePbX53DR9iy6QrPKXMHaYSpqQIua-9HabNUkopllwtPRTNPzVtqDtw=w105-h166-s-no-gm?authuser=0",
  },
  {
    firstName: "Kennedy",
    lastName: "Chandler",
    teams: ["Memphis Grizzlies"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Tennessee",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdkBzUzxDqoE5wHLZ3bL1iYRDjfYHn9hRXgosV2OTp7wUJ4v5h4t-IESkJc9EiWNcbwJ7sEPTwhI6k3TT7fVUDf0_-I5wbwpC_m-r1o-6pXVcjb7_zp9RBVtiuJlXzcCwwOhC0otXhyCVEtgFVTD1Q3=w125-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Bryce",
    lastName: "McGowens",
    teams: ["Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Nebraska",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdwxUWRVVwokDWPLg0rjNYGHBRSZtn1jWmE0y306wsnPKDA5azOC4BCdwcohSSoqBX-AKvfcNsZp0-vxOehu75EypwIK1Z5Ij2hfSIPcgjOXCi3dJc-M7EuYgEuENtqQn-RM_ZIYrbfLkVplDOsXaje=w107-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Trevor",
    lastName: "Keels",
    teams: ["New York Knicks"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc4ArXCy0xbN2w0gVFvzZgEfVdpgPfLW8oW2k_rt2MRjh8e0DA31d4bAl-5r3iuHuGmjnNfne2viHzyKh7ve_eG8dTIJBM2BwKv3lOJDxiFWWMoSnP0O5Q1qUiFZ3DZyG45owH56cnSGEJPSJPVQSxa=w104-h139-s-no-gm?authuser=0",
  },
  {
    firstName: "Moussa",
    lastName: "Diabatè",
    teams: ["Los Angeles Clippers"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Michigan",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcL2uTRWCPnjh9unzzjk8pQiuncyHyzfzP2kZFLwHhCJUU-VTldFx79ad-6TbTk-aL-6uW_4C2_dJ4p3YGB2J7FnXhja0w_-GE1YyaiwVxc5-RWYGhwO5G9rKfyOjiklHUeIWw1XLjwlmSNUp2vTfK5=w105-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Ryan",
    lastName: "Rollins",
    teams: ["Golden State Warriors", "Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Toledo",
    img: "https://lh3.googleusercontent.com/pw/ADCreHenTuI0zgHDyzJtuSxp7vfEotLYX8cHPdvuTe1LYzy43e4xPqpND1v2rTFCR-g-eULUs9bOVAM3AsqHiKZHqSY8xuP2EN8b3UnEbjULWa4zRAMCWuFgiuXWuB9eLrBksnJnyIOt2H6MD5prx90RqbB5=w104-h159-s-no-gm?authuser=0",
  },
  {
    firstName: "Josh",
    lastName: "Minott",
    teams: ["Minnesota Timberwolves"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Memphis",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfr_A3lqhpwvXKE01giG5suSFYj0aqdw-qVyfhX5gfvJ1Al2tdCKOIb14xOkib8YZ8LK1ye3_Il0A25AIc2mKpTH95ghQ04DaM1xeJXw-j42tWAVbddYIu4zSKDZ-wtwHiSx9nfnq0wtrgDqx_CKhvM=w96-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Vince",
    lastName: "Williams jr.",
    teams: ["Memphis Grizzlies"],
    level: "hard",
    firstNBAyear: "2023",
    college: "VCU",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcE4iydlvRYHxonVbFmQ654OTblCztqG63h4Fc_RVZFmn4PqRs-Ecusr5V1f4dNfdyK6mW1npNtGFhBTIQKNh6icQ6HK4sM56KE6kNTmYF3TpWjCqeZlvkmhEtgl_8kPkM5Mvs1vZC1TUxGbybCiauL=w113-h160-s-no-gm?authuser=0",
  },
  {
    firstName: "Kendall",
    lastName: "Brown",
    teams: ["Indiana Pacers"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Baylor",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdsFz4mDxm55FdoEyNfXIoKR2yPpDa3rAJfn5MnqZwAn97teO24nX_OLHJefRImNkgCO1S6RtDA5A_r_box-ZNdkaWByT2oQxy5IUnNOB5xscDTQrBGZ4Lp_b--rzO0z_obKDiVBaC9KooyrhxcgFrK=w119-h156-s-no-gm?authuser=0",
  },
  {
    firstName: "Isaiah",
    lastName: "Mobley",
    teams: ["Cleveland Cavaliers"],
    level: "medium",
    firstNBAyear: "2023",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfK0SSYqIqtL7ur9uw8uoTWrOATo0zxa_o22_UgEnvuzpLOr64fLv27W2BUv2OCWHgXaoM5sABrq9T_TVoH_Sx9taIl90tv4vgHpnB1IR3VKl9PNlMaTy45p2wFge3_eFLd--HmkP5uuV_tB16Ge2TJ=w105-h132-s-no-gm?authuser=0",
  },
  {
    firstName: "Tyrese",
    lastName: "Martin",
    teams: ["Atlanta Hawks"],
    level: "hard",
    firstNBAyear: "2023",
    college: "UConn",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeRKgAr_QFm1gsgGrTpIA_PiULJoHV1PN9vH2uWr81Gd3VEMwlAGSlmT6pRbHwgLp_lbkDn_henPcdPuUE14lVMxXGnHr-UFGpgqc0xXzHFCFWOUvuMjU8aegjc8QDSowFSWebekMHk5W9efSvVWww3=w108-h142-s-no-gm?authuser=0",
  },
  {
    firstName: "JD",
    lastName: "Davison",
    teams: ["Boston Celtics"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Alabama",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdHqk408x7HrZ722teMxXpqJO0Jf76q6QSkJSj-1AMyIFLmpeG3t2YANTMkwV8hkwgGtYc6Nb5boPiDgB2sdbP_aKn30DDCCk1AWdA-YAxFxGMoZBv_EJtnsIMsKZKUQouU7NKO_uE0b-uBHDyeFgqC=w110-h158-s-no-gm?authuser=0",
  },
  {
    firstName: "Jabari",
    lastName: "Walker",
    teams: ["Portland Trail Blazers"],
    level: "hard",
    firstNBAyear: "2023",
    college: "Colorado",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfHsbF0ErI0rax2zkIr4vpWoZtEcnH02J05NSavIPzKMXw02bHeRNDs0E6ezwVsk1Z90NTfwr4AZjPbDUzF0Uy8bvcJD1CW2HswmRJqBqBrbstX0focS-cJmwyBMOwl-bLRRmZ06EnqJf48fLaVFXgN=w114-h167-s-no-gm?authuser=0",
  },
  {
    firstName: "Dejounte",
    lastName: "Murray",
    teams: ["San Antonio Spurs", "Atlanta Hawks", "New Orleans Pelicans"],
    level: "easy",
    firstNBAyear: "2017",
    college: "Washington",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd4Se7fSYYkJTkkQ84ppac-FPD6yaIf0z6Z621jzToPdIG9W9obMs-NQ11wGhTi-EUdk2y9V9mUJ2XO3y2VNVXcFyOS08jXgWw3cyif8BCWWBwU5sVzU6MGO93eJNbIgCneNnROMHkNj43vMdOcuoyB=w112-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Donovan",
    lastName: "Williams",
    teams: ["Atlanta Hawks"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcf7YFjp92LD6OcbIpO9YmFBPAbGVlmCSDjirx5Qhg-IaVQ70K8LHRyY_Gg8TTEDxG2Zdq5G2_LAdwe2bqvZQG-rePipDAD6h32i1vUmzQFWTaqPmr-KbiOSGk0f0ehTDB_0N5yNtQUpVg3dsrxpR62=w118-h161-s-no-gm?authuser=0",
  },
  {
    firstName: "Mfiondu",
    lastName: "Kabengele",
    teams: ["Los Angeles Clippers", "Cleveland Cavaliers", "Boston Celtics"],
    level: "extrahard",
    firstNBAyear: "2020",
    college: "Florida State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd8CvHKuq-5xrhqQlP0nJIny7GeVUrVgt0h9DeSbVTZn9bqAdASk4OC-oDScDAAAEbFK3-mw4OqztLcKkvVF1J4SctTDLwNtSU_AEOsvjFDX0DqG_casGmr0Jqlm3jkYtEVUw1y7S7DmEHB5ijRrhBq=w119-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Luke",
    lastName: "Kornet",
    teams: [
      "New York Knicks",
      "Chicago Bulls",
      "Boston Celtics",
      "Cleveland Cavaliers",
      "Milwaukee Bucks",
      "Boston Celtics",
    ],
    level: "extrahard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfMJiQGQ-2jtLfkdNPDDDQkTxsgosKux6aU5HkmzC-MjiFqU8auQe4CxQ1Ko_vLPWfcbmkURCg68cdEcoCle0xRDkh7APEJthT102bwgHuh_oUzOdVPs6E2D_w9_C1ub3-Db2SonERXrl7BPHLstgkh=w107-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "RaiQuan",
    lastName: "Gray",
    teams: ["Brooklyn Nets"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "Florida State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHceohDbjJ3_1RkGuA-ANYQ1G5b8KZGjK9IGZK-vTYDjnXozgbPyAIUnrwGta2AH2EF064ilFn8WfmWDPT2hLlTG64vc30INxbSgXyekv2KHySizE8Hk5-XvYRoBrvnMGoTqz3O33MDiSlqibJS87Itq=w118-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Patty",
    lastName: "Mills",
    teams: [
      "Portland Trail Blazers",
      "San Antonio Spurs",
      "Brooklyn Nets",
      "Atlanta Hawks",
      "Miami Heat",
      "Utah Jazz",
    ],
    level: "medium",
    firstNBAyear: "2010",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdNsCKQMY1yMXZf1t3SzR2_TrtJDhuX_utjDYnYChvEwDdxuqZZhRK2ahdgXGJqcbTrLK9aL9RzYtt0ryO2UHJ4-eRtPtaC1ogkt3pBlLTaC0ChHNnK-_2W7LFuO7wB0UMxLE3HnewW8XG1EmGFv-h8=w117-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Dayron",
    lastName: "Sharpe",
    teams: ["Brooklyn Nets"],
    level: "medium",
    firstNBAyear: "2022",
    college: "UNC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcPpDN5yIyxpB4hEAkgMpbphPNjH4Al5fX-SS561VzjTndyiJI3G6ZoLLp2ZHZ48meKXv-1kJ6BTyqkpR8NILJegaN9KB68I2TGI8oNXgjO4fB24zwuEs3-iOlXJGpFCOYFbfSuNbsqQY4ZCQ1dy1lG=w114-h136-s-no-gm?authuser=0",
  },
  {
    firstName: "Dru",
    lastName: "Smith",
    teams: ["Miami Heat", "Brooklyn Nets", "Miami Heat"],
    level: "medium",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHftdhNUapLuuIxy8Dr1sx-xsv3-LQgggc100kUyNXggeWG9gASTml0gH4DdUY1gwjIyW24C02CrOrPpAQHAR3x5JoEFfetAfGOQFUVk_o85P1418i2yiROzzFQwM2k1sjdw6BNzEmuF7yj5qdMazlEm=w117-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Yuta",
    lastName: "Watanabe",
    teams: [
      "Memphis Grizzlies",
      "Toronto Raptors",
      "Brooklyn Nets",
      "Phoenix Suns",
    ],
    level: "hard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdQNJrmXbpk6Yx7M3f4nZYvUQ2XXoa-IUiwVpI1o6jywjS2AT9ntvk351PJEgc6cIhSCtP6lYtcM3-8XUbRt8KUZzSFwvancH3beNnPS0Kldtqr-Rhn8OqaUYa_WRavREUpzQPBxNg7oOshTyn0tAC3=w113-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "James",
    lastName: "Bouknight",
    teams: ["Charlotte Hornets"],
    level: "medium",
    firstNBAyear: "2022",
    college: "UConn",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdUdkpBdD5Uufj5qlTegoMDCkK3UnwOraRzMNBs4-UJyQCFWOIDsO2KvPF1EqyaXzptqIlW8yvokJxOqBBVjrooUxELdxA0NQwH_GmBjutJWK1nBpkOXr2rTzM3Snh7Sc9Itgaoz4rfr8PshrkQG_0b=w104-h154-s-no-gm?authuser=0",
  },
  {
    firstName: "Kai",
    lastName: "Jones",
    teams: ["Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Texas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfN98W6Q9hmHCZxGsU6I2hLzrqvIGukIxXSO2239-WzsZSmfAK2hRfvF7xYZ2jYiCG31jzA4zpzlVHif3R_DjMICqkuGoT4BXIUwUeqir48B43skaaEROX3MEueGx8f9h3_gSkdBz0O1iMOsiuw04P4=w116-h147-s-no-gm?authuser=0",
  },
  {
    firstName: "Kobi",
    lastName: "Simmons",
    teams: ["Memphis Grizzlies", "Cleveland Cavaliers", "Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHep_3Rm-zAASVvxqNycQGlQ0oiq4_e2LTt7OrrVBqTEsfuHOtrzaXPfCG3H7tGpnEmXqdmaVokg56xxhlklmmoovib_EeHdKJoEvEKowpERaXpKdoG3-8nHqBgFtHtz25F0d_nQYniR0Mi5nu88FPla=w92-h161-s-no-gm?authuser=0",
  },
  {
    firstName: "Xavier",
    lastName: "Sneed",
    teams: ["Memphis Grizzlies", "Utah Jazz", "Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHctRzEvcVyJgSqkbG5CMPOX6b8_SmZeG211Zs5dOpwFxFEwOd8OeTcy3EfgZmZN-rghCdXz0BZgvTIxKxyHlfv7VwtQg8Cv5dCx1rbbvcqnTN6B-cL_600WKsP0PJtLtD8lEnjcxd7GGz-UauWjRJ59=w113-h159-s-no-gm?authuser=0",
  },
  {
    firstName: "JT",
    lastName: "Thor",
    teams: ["Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfrI21oSPaxYZXGQ5PScGzpeTEhCOqykCGD_UTYDP75aOFatuLIAsIgn7bOBeSImRalhFg_rwDDJQjaSKucDdVht6Uf-ayt4Hje2TpmXVEJdETn0VhDZVFvhfLziwkHLQXX98BtUoCWs5FMExRVXFjv=w113-h149-s-no-gm?authuser=0",
  },
  /* {
    firstName: "Patrick",
    lastName: "Beverley",
    teams: [
      "Houston Rockets",
      "Los Angeles Clippers",
      "Minnesota Timberwolves",
      "Los Angeles Lakers",
      "Chicago Bulls",
      "Philadelphia 76ers",
    ],
    level: "medium",
    firstNBAyear: "2013",
    college: "Arkansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe_bltqWXTLJx8F2i421Yo8j67gcl9i-XwADBaO8ogDLbjzC8oiMNzbz01D35qKwQr4LD5kWLpwMM02ARMHZX6sLUzUkHa7xuOt8tCX2bcJcDWCJeJKCXWMWDXwPU-SJ9F9geLkqlPKCkoLTW8KHjPv=w106-h145-s-no-gm?authuser=0",
  },*/
  /* {
    firstName: "Carlik",
    lastName: "Jones",
    teams: ["Dallas Mavericks", "Denver Nuggets", "Chicago Bulls"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "Louisville",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfO7rSTb5niQoIVCL3BqBgs28zVi8Cq7VnOjS7ftwekH_HaMcomrHnkmkrZyPBSDgU0uh9Ner-DdfaGbDB3pjU2dqpg63BlVZI6hHt-OuDPXAs89GnjK0FKPuhaWbFNDG0xxUp91KkKx3yB3U2zsPAC=w107-h158-s-no-gm?authuser=0",
  },*/
  {
    firstName: "Terry",
    lastName: "Taylor",
    teams: ["Indiana Pacers", "Chicago Bulls"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdJji1FjemV5hFNxaZ_hcRBALJHwj4xpw2YQd99ocbqWxjoxnA3Swd9rksn-3o2A_3x62GxslrHOf5PwPOPFGCfLF_Mtol2IXUl5IJsU-X_gS_bA9_9EvEm3ccFcL39CLykWf-vPQOs6I1Y94vjv5wx=w109-h156-s-no-gm?authuser=0",
  },
  {
    firstName: "Evan",
    lastName: "Mobley",
    teams: ["Cleveland Cavaliers"],
    level: "easy",
    firstNBAyear: "2022",
    college: "USC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfy0Lyez9idQpymdfhefLBjiMl7GyNW2uatuiq8yllKmfFhsDSyVvE-36RoYBZcNxysxVYVPItyb8H79d0JYhDk02yV4c-WwkPPbu7XRDWyRbmPpPWyQpfDBk0TYMPdGFpIh4l7I-CR0M_UnxZ1v-wf=w93-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Lamar",
    lastName: "Stevens",
    teams: ["Cleveland Cavaliers", "Boston Celtics"],
    level: "extrahard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdmpiHIg1UUQBTKUt6TLo92X04kzGGsEsGqia4waIegvVRZFTIG2Ngusp-FdYdgAFR8N5gcB4bmIDTjeDdsOHoCU2aR2MJW9ZeFnMTMWGYs0JuCePP-6rtHqeq-SjIq0WXrXcma9z4_Bi4N0xknYIro=w111-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Dylan",
    lastName: "Windler",
    teams: ["Cleveland Cavaliers"],
    level: "hard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfZe-QG7jL3R2u5luJu8UzdEW33AutP_lsdydeJRGNPYdmlrLtc_BXUiz_GtP6nv2G1BPFPc7TupTfjpDDQzhOaXnT9X5BaK0WNqoa9gHHkc0I9D1ZqHAwWdetywirXiIXdV_k7Vt2N5aJyTig4q_Pu=w106-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Reggie",
    lastName: "Bullock",
    teams: [
      "Los Angeles Clippers",
      "Phoenix Suns",
      "Detroit Pistons",
      "Los Angeles Lakers",
      "New York Knicks",
      "Dallas Mavericks",
      "Houston Rockets",
    ],
    level: "extrahard",
    firstNBAyear: "2014",
    college: "UNC",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeYDcN_YPH8lNiAA7VB4wGbiq8dqJcIwhnpNkQrKmDX1sHtgiKgbD1C9m8pj6W2tv-2gZUHhbdHag7JO-VdgvOZfJ94G-iaX7Z0s2SrtRyLoE2fwhSjMP3R1CapCmhyfQ5Bda-AfKYw1VemWmiPh-on=w107-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Justin",
    lastName: "Holiday",
    teams: [
      "Philadelphia 76ers",
      "Golden State Warriors",
      "Atlanta Hawks",
      "Chicago Bulls",
      "New York Knicks",
      "Chicago Bulls",
      "Memphis Grizzlies",
      "Indiana Pacers",
      "Sacramento Kings",
      "Atlanta Hawks",
      "Dallas Mavericks",
      "Denver Nuggets",
    ],
    level: "extrahard",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd44_LJZ76yZsGmAexVDcxDP53-QHYq4_AoRYsV91GiyFBzfqIe3cNnb4TkgHTaCqe5n8GK_9pLTMmIiR-Uz9yJ5K2q6aw7tHAGzbJfjJXiqaJUIdqexk2jVmVm6jSN8AUUwSEloSeoDWEHZ8FMO96w=w111-h159-s-no-gm?authuser=0",
  },
  {
    firstName: "Markieff",
    lastName: "Morris",
    teams: [
      "Phoenix Suns",
      "Washington Wizards",
      "Oklahoma City Thunder",
      "Detroit Pistons",
      "Los Angeles Lakers",
      "Miami Heat",
      "Brooklyn Nets",
      "Dallas Mavericks",
    ],
    level: "hard",
    firstNBAyear: "2012",
    college: "Kansas",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfVT1Tr6RuDDZaijHpFry6FMJxzjyhz5zdN_N7KZBJYZTnJsnzyjL1FvXwp1t_YTFdkH0vxKipOsfaROv5sL41usDLoQD8wYBrCviTUJgNZ6HPwBwgZiIk-69lph7nEvc3w__nSAXbfSkfT7qZq1F5N=w89-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Theo",
    lastName: "Pinson",
    teams: ["Brooklyn Nets", "New York Knicks", "Dallas Mavericks"],
    level: "hard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdb79LqPMRvXa_qZaY-WGZR0M8YN3BDIK1rZ93I1V0RQyfb3fBfUtnHP0hPyjZhFkzb_uYgMsYUwR5JkWRTgr8Nnk1sDaMQRJnhvRbqwpCPgVGFyE52L_Pd0yWzNZZo8xj56Ns7dnCikmm9INTUKc7f=w99-h169-s-no-gm?authuser=0",
  },
  {
    firstName: "McKinley",
    lastName: "Wright IV",
    teams: ["Minnesota Timberwolves", "Dallas Mavericks"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfOUazUQYdZ2OzOqj4yI2MgHGPB0-cRQVcu6NCu4XyGPP0Yal53GmhRua2iZAi9QIo5b7LP9F0Jj349Qhr9XoJf25gOMnG5FRcp1CEhiCkPGBfGQc2Yh2pbBY8nypsPMkITd0V-jvHwzaFHhIzQhuee=w105-h159-s-no-gm?authuser=0",
  },
  {
    firstName: "Jack",
    lastName: "White",
    teams: ["Denver Nuggets"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHduA7NgAwvYNvHpGu-QRNiDmJ99guuw9EnQ7kaCtfVb40N2nMM4HHlXk1xPjafWMEi9cHovh_seCuUdCWDJvIQfiy5CJ4NdtUgu0cPLN-19wNmpyH16iTSJugC91-bVEVaB0Ynp8IaiI3NlfIDz_n9p=w115-h165-s-no-gm?authuser=0",
  },
  {
    firstName: "Buddy",
    lastName: "Boeheim",
    teams: ["Detroit Pistons"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHchDeeL3-v2H83BK7PZacms7oei-nZFLr41DTIj5PBDvXW201_mlLpTedHuDNu1qtegwktUMMcgQpJx5H7QU7P4TzzhCWkt4cLtW_6HDAoJDvUcu5ZInTxzanZhLLs7PHMYIihaJC2pmDGwcIpew2cm=w107-h147-s-no-gm?authuser=0",
  },
  {
    firstName: "Jared",
    lastName: "Rhoden",
    teams: ["Detroit Pistons"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcuNnxULN1ytbFz-Z79Z8_bH2-y1ShuKvM5tJ2yr5SyR4cSQaWZJdy70-yoEzbaPkq-yrkqqGx96lRI09E_7lktT1c0cSJJOMPFr97dKIed1zWRiNaZt4blnEzMsJi53DuV9oIbJA-LWyb9rngNUE7P=w107-h152-s-no-gm?authuser=0",
  },
  {
    firstName: "Lester",
    lastName: "Quinones",
    teams: ["Golden State Warriors"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeU6ObId_Swr0qwDBLlEHBZe3gwhgizNai7XTY0lrsu0-lTziEC2vmbnvtuMO5uwktJWR9lQCXAkQ7Hx1CpriAt2K32VJjEZVi8B5BwpbkCqOamY0diL4zm0PDLChZ2VwYVyL39PYtYChMl5cAuIg5H=w115-h160-s-no-gm?authuser=0",
  },
  {
    firstName: "Darius",
    lastName: "Days",
    teams: ["Houston Rockets"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdAgNNXsS3tPW1lrqFzBLU8EjrSHkXHLdAKQbtlfZFFsoLmJr1iwlYKZuciEoS0XLPyMPfrELNZKzDiZdw513o7A5qbaU_ZqwRt_feYsrCVtkUQZQK5qLftbOPAylQ7_pWAat8FHSU00epBAAGrwSxO=w112-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Trevor",
    lastName: "Hudgins",
    teams: ["Houston Rockets"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfIMx6dDeU-T-BvDCfpS1cuHjrLSmEku3vb0kA_RqYrGQ_iZofCkArXoyUHiPtGI6NHlOfVRtc-lALWzVxLHLSGKc79xm8wIJyj9-MwnKDDOTyapktud8uy1uEwyEn9sqaNUjC73Do00lbZtqYFGUGx=w110-h154-s-no-gm?authuser=0",
  },
  {
    firstName: "Boban",
    lastName: "Marjanovic",
    teams: [
      "San Antonio Spurs",
      "Detroit Pistons",
      "Los Angeles Clippers",
      "Philadelphia 76ers",
      "Dallas Mavericks",
      "Houston Rockets",
    ],
    level: "hard",
    firstNBAyear: "2016",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe7lKhrz1uwDVHlyb2JPW5kF18hqr2RezcaHqWaQCtWFeUb1v6pp5zLQgaUE1aviqiLqp2IP-kuy7u5NrhdadBAQPAdPkfDTjZ5Juw-QFk9ERDsZROXK4XufbshhhowT_cKdDSrOgm-B4Q-exzeWVdR=w111-h152-s-no-gm?authuser=0",
  },
  {
    firstName: "James",
    lastName: "Johnson",
    teams: [
      "Chicago Bulls",
      "Toronto Raptors",
      "Sacramento Kings",
      "Memphis Grizzlies",
      "Toronto Raptors",
      "Miami Heat",
      "Minnesota Timberwolves",
      "Dallas Mavericks",
      "New Orleans Pelicans",
      "Brooklyn Nets",
      "Indiana Pacers",
    ],
    level: "extrahard",
    firstNBAyear: "2010",
    college: "Wake Forest",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe6FT0l57iNgW_aPSv7gNDSu1bE9muiscNgW7mg5dKYtNMP_zTqbv-CxXVGCnFJF1MBikKLdpQ8d6UPDUu_2kSKrk1vAISpY55MmAK-l7wdNkOxiYNpjhq31uWA-YKrYm_HSGmelJlnEAVQyXiOhTHz=w103-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Aaron",
    lastName: "Nesmith",
    teams: ["Boston Celtics", "Indiana Pacers"],
    level: "medium",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdz4xQ2D4j_UdFadOKCai-1fGMZQqhLRoC-G15Zfv8ZFGltfWiYwU1gNrrdx9Osj6Vu_zfVXRBmPR5aQ7B8hnc8iQbLyMJjpIvlNz4LpEj3th9Xn7kbeh_F-LwjrM0zvlNp3cyFR_3ekD6H7UK97is8=w111-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Trevelin",
    lastName: "Queen",
    teams: ["Houston Rockets", "Indiana Pacers"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfjoNT0Af7i4lzp-d_iu-58vVdWGSV8zCT-R-_Dvy0D042y30MeC6E06_u5PSEFaPQCVhul_kJ-hE0qNV81_ZyfuCq9Yz9fuGQxTiiI-qiYbX62z5FVTkA6acT8fhlP8R6pUnbd7fJWdXGLfeoi8jx4=w115-h149-s-no-gm?authuser=0",
  },
  /* {
    firstName: "Gabe",
    lastName: "York",
    teams: ["Indiana Pacers"],
    level: "hard",
    firstNBAyear: "2022",
    college:"", (non ha la foto lui)
    img:""
  },*/
  {
    firstName: "Brandon",
    lastName: "Boston Jr.",
    teams: ["Los Angeles Clippers"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Kentucky",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfU0wrMXyj_92Ao_vDdtdGDxm95n8KXH7L6xqzIv4-HGzEbKeiNTT9tObFv7TEmRKJSQACdaeTI-7-a5vkoUwzscX_DNqSBqtsJINV6Xr7tDgCaq3CwWKODBj8APiuqOqXeZVKeBm0_HGODn1_Hgb9H=w106-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Xavier",
    lastName: "Moon",
    teams: ["Los Angeles Clippers"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeWqS4nlc8SYj-vqaZxdKz110G3XRUhMoxdB3ufzq71kP34lGjtfCgJB491NVRTIBIwt0lbUSLoUwsEHh34sghjTqx4OOADE9Tb5mTR_tw7O1FDMFLOJcS_qxwGbAVBucrxt6Xsrifn_FFjcysehpJc=w111-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Scotty",
    lastName: "Pippen Jr.",
    teams: ["Los Angeles Lakers", "Memphis Grizzlies"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeTD-i673dVlAHfzZ7tOn0vJHQgeSloPcl2SOPK48931aZKFhDN1qjxIbh29KpTzVN_8Ez8q8YUZY0ZP7NRnJEJnyCXSgl5x6FD-m76lWyLyOA5uFAjU7rF0TdM7U4WsKKgUgJmuG59G9qAg8r8o35y=w108-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Davon",
    lastName: "Reed",
    teams: [
      "Phoenix Suns",
      "Indiana Pacers",
      "Denver Nuggets",
      "Los Angeles Lakers",
    ],
    level: "extrahard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfMddCAxBuLcbhHKGUI1lnK4R7C58a5k5LtbIqXa7mjkRVmPp2Dx7OMRJEtP8iHov6Dq86ZhTITw3UA_UWNoxV4ASDaCE8PvDl0_pFH9iH3o3RP3KwtSCncrjBgk1V2DzlsobvXjpbGt8cecAkPlfSQ=w109-h135-s-no-gm?authuser=0",
  },
  {
    firstName: "Matt",
    lastName: "Ryan",
    teams: [
      "Boston Celtics",
      "Los Angeles Lakers",
      "Minnesota Timberwolves",
      "New Orleans Pelicans",
    ],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHefa3uYH-g3mXso5RYLrP7UDHFWFHwEV9Xw9EaNDbcQJvj7sfnAVPbg0knoExNxhSbmi3nZrbJ3rKH6verPToWwNMnwRu7gurXDZdOgaZbKCtl5BYNoBAcNxYReUcqTRVNElloZ8mdHK7eLcX-mbxIw=w114-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Cole",
    lastName: "Swider",
    teams: ["Los Angeles Lakers"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdWKX7WaRalnDaF3C1z4sEyQvaRlXEl4J69BUVcv4pe7X5UjTlzZtMGcfgx-j_9YQbKdvooUObK1kiRQlPcUQqjJvkXzkqkErKESpFaQiz8nDYHxZsd6VCpmF6C5FAhTRrbDmxVzGm3XydPrxRNdRDh=w116-h150-s-no-gm?authuser=0",
  },
  /*{
    firstName: "Tristan",
    lastName: "Thompson",
    teams: [
      "Cleveland Cavaliers",
      "Boston Celtics",
      "Sacramento Kings",
      "Indiana Pacers",
      "Chicago Bulls",
      "Cleveland Cavaliers"

    ],
    level: "hard",
    firstNBAyear: "2012",
    college: "Texas",
    img:""
  },*/
  {
    firstName: "Jacob",
    lastName: "Gilyard",
    teams: ["Memphis Grizzlies"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfaJA9isxabiyeD02eO-cpCs3KxFnSP-OL8lCLzDdGjRfqeDP5fs5E8I9gp0lcL1XntzS8gfKfBNuA8vvVsfrGN3FlEj1sBQ9GANp02yOHrx4l6WMqe9Jo4KV_4XD-b7LqUumgx7vFfBvgPAybKR8SL=w103-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "John",
    lastName: "Konchar",
    teams: ["Memphis Grizzlies"],
    level: "medium",
    firstNBAyear: "2020",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfqf__aM4PvwG59dtkKKaVGXlQ_Na_INbYN9fRnHtWPESpSPU6xXLq5JtsK0-2ZM-Sw2-CXk5Sofma43QIMtPOjeV9TNUpNWRvBFAwt09USi5RtrnFORKesumR3RiDphtoMMYoLcJCilNZhkzDW6W6L=w113-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Kenneth",
    lastName: "Lofton Jr.",
    teams: ["Memphis Grizzlies"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHco9EGldNM5hLUmqwo-SAmxXxFzMqdMBqqqxbHskYMYY-UpcrVUhwlQikfPbq7Wkm6ooJTig4FCtNz9Yu2xP5sZv_P5oAM0QBK4FLEyDmC2jvdCf1Y0OtQ-pK1dZczOySgJeXFHmkd3uEiOEMPM5UFA=w115-h155-s-no-gm?authuser=0",
  },
  {
    firstName: "Xavier",
    lastName: "Tillman Sr.",
    teams: ["Memphis Grizzlies", "Boston Celtics"],
    level: "easy",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHencYpACT5OxopNGA6IRrJCNeHERsALuUlubvBt2NMmbJoJg4QiVtXFyemt2JfuqZ4fHQQOGFK-SZoxwDvUvxX0tNIdEJSYIIwG8dCotCc-JprWBLqC_NVihRHr7sXrZqzvITBNP--c3naGtxUyO0AV=w106-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Jamaree",
    lastName: "Bouyea",
    teams: ["Miami Heat", "Washington Wizards"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHemb2qndVrUvlhTbhohAcPVR_f03qmX6xFHvn1RJAG6oNoXKsyofstfHHMLCmuHd0ZC-xnJ-1PYO07_d1Cp05YoA-N1ADC9CFYqy7rmEK8EBsgmnfx-tGfuvh4chYB99BBcNCn9swxjY8yNN8cMov3e=w109-h154-s-no-gm?authuser=0",
  },
  {
    firstName: "Jamal",
    lastName: "Cain",
    teams: ["Miami Heat"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfjSS8EKzXY-MQquuO5ggC-2q9Gcdb3lQfabiwhcmUlTCcwMSTFb4ohkYboWlMoH9S3YDeI4CCoO0sS6tKeAWhuVnVwNkSi1N1mo7ioMh9RWNQQMm9y8kw0wxJwPqy6bmIdfK2yyJBLlFV8puvmU7pu=w117-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Haywood",
    lastName: "Highsmith",
    teams: ["Philadelphia 76ers", "Miami Heat"],
    level: "hard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeB4ckOgjjK2KFVPLJJWAsm4fGK_BBrYCWRz_nBlupT8cdt9W0y-nufi5iFUWL9dLiLNdQFj7OBRz8_uG8_sxfHiF5VHDpyIqqP4HQAH00l9mIOdPHMOzy7eCWiM8ALW3aavNwAMnxhb-J_Bg4_7HSV=w112-h140-s-no-gm?authuser=0",
  },
  {
    firstName: "Victor",
    lastName: "Oladipo",
    teams: [
      "Orlando Magic",
      "Oklahoma City Thunder",
      "Indiana Pacers",
      "Houston Rockets",
      "Miami Heat",
    ],
    level: "medium",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHegQ7DnTnc4eLpN7YH0cMxoOGj5DSHWh7rnHsvnueXIimiFMXjp0Skr3RLwhp2A2YeTFHeSOEaKP_L2IIBLnFRu803gKNZQTPHvqTtiUi65aRiSzhnmagrbfB8SKNxO4ONH8JOoYVeW9YLJQ1UVrACY=w113-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Orlando",
    lastName: "Robinson",
    teams: ["Miami Heat"],
    level: "medium",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdxw3S5nsEJ_IHcytUClCQSdEsBC7oOwQYqoYKMevDx01ccHaMrIfpU2vkXcd6DQOXzex3xT5sevmmLXbOhrSlE3Tc6EBIUFfU6H5-N9IDx9Egai1TBkrV8r93raTEVu4_XVkPxOxTH71QBbtm_Ttmz=w110-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "AJ",
    lastName: "Green",
    teams: ["Milwaukee Bucks"],
    level: "medium",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcQA-JdYrdfafYrO0sLsnddOj4XpKuL6t5M-cws37jT2GmUrarVSnkp4xNWhIvucROUPk-xnBn-NdGJiBVdHK6fX828Ss1-SPAQjpdPEXmz18GRSpIixYKkCuY6jmJ6xh5uIl57tTQVYo2XexVJwCuA=w113-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Meyers",
    lastName: "Leonard",
    teams: ["Portland Trail Blazers", "Miami Heat", "Milwaukee Bucks"],
    level: "hard",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeR9lXqt8UD4F74ibmu4SpckpzFJ664RuZXy5wZSpeNGwguq70nq5fS-AmU9D9MoBxaQ4jgnkbY2YV1laE_s_T2lnkzH2pgR4YfROMvXH00DH_ptt9o8eqbfpNjZMrg38WHVoZ6pN4OHMZnAIT2XZJL=w116-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Wesley",
    lastName: "Matthews",
    teams: [
      "Utah Jazz",
      "Portland Trail BLazers",
      "Dallas Mavericks",
      "New York Knicks",
      "Indiana Pacers",
      "Milwaukee Bucks",
      "Los Angeles Lakers",
      "Milwaukee Bucks",
      "Atlanta Hawks",
    ],
    level: "extrahard",
    firstNBAyear: "2010",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdsdSQ5Unmi0A5Ayr_e54eBmaXHMZ4a1UQnOlFXQDMZyHLTyrRKeXsi3e7F0jL8kIRtpW7FtCIN3KWiXFLzW6DMUoyO57PF6rRChwe-jlpfXXZkf2dwJ2hcwWH85fuf5LFhhaeYJEUldhc437RhxniZ=w111-h155-s-no-gm?authuser=0",
  },
  {
    firstName: "Lindell",
    lastName: "Wigginton",
    teams: ["Milwaukee Bucks"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfOO8KQtChW2hEf4rpQ9ORwuCR9YL7sc5Ut12yBfHHIXM5n25r2Krv7dccifSd9Z8dtOn9YEzTm4btNtXbc-xVJcc15bneWklb9dHBY6PeSbG5oCwmXQFSxCQmSyIU8qkXrE8ybp5-AGt0x9GLzHGDt=w114-h162-s-no-gm?authuser=0",
  } /*
  {
    firstName: "Willy",
    lastName: "Hernangomez",
    teams: ["New York Knicks", "Charlotte Hornets", "New Orleans Pelicans"],
    level: "medium",
    firstNBAyear: "2017",
    college:"",
    img:""
  },*/,
  {
    firstName: "Dereon",
    lastName: "Seabron",
    teams: ["New Orleans Pelicans"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHegUtz3koJDghklrD7ehk17cqmO6iPC1CNdl2Zt3q_qnebNlK6IBUiogBJ_8tg7XaI03dpLdJHSZbjsYOegdNc0yUxCNeQVS16ux_MkxkilEvQujqy-Ve0oY_z9RTdDF2qBctmZFM0NZXaqfec62CAU=w108-h161-s-no-gm?authuser=0",
  },
  {
    firstName: "Garrett",
    lastName: "Temple",
    teams: [
      "Houston Rockets",
      "Sacramento Kings",
      "San Antonio Spurs",
      "Milwaukee Bucks",
      "Charlotte Hornets",
      "Washington Wizards",
      "Sacramento Kings",
      "Memphis Grizzlies",
      "Los Angeles Clippers",
      "Brooklyn Nets",
      "Chicago Bulls",
      "New Orleans Pelicans",
      "Toronto Raptors",
    ],
    level: "extrahard",
    firstNBAyear: "2010",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHemrl3I5Df99VnrWmCR9fD9xMHMMkVBOzipzaLJXrzQKX4jkYe5swjuAtOCLvnUVtuv6BZIUNTbA5RHQJzOxYH3_U8Ubw-xqBQEkTHXfiwYYQ8dNzq1kl0TVRFzkvep8kX6Zuk3SLc6o_j2e51eLmEk=w111-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Jonas",
    lastName: "Valanciunas",
    teams: [
      "Toronto Raptors",
      "Memphis Grizzlies",
      "New Orleans Pelicans",
      "Washington Wizards",
    ],
    level: "easy",
    firstNBAyear: "2013",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdLWZeWcOnalDCil92f8AXtcYojsV3rbYHmj9yxL2BvohJTRl7rXymW1PQLsM6jFiW7LGoNGyPxa9k6NfcF40iMtR24O7b2eVqV4DYdA6CK-vL-8DOzJb0XE1vZYH7_zYOfmXAO0rQ94kACW6aXmiiF=w103-h134-s-no-gm?authuser=0",
  },
  {
    firstName: "Quentin",
    lastName: "Grimes",
    teams: ["New York Knicks", "Dallas Mavericks"],
    level: "medium",
    firstNBAyear: "2022",
    college: "Houston",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdMlOZADGcOYT17FxNY2ckyiNFRD66pGMilQ_voFw7v_UodjiEDyksg5LJdS0plG1YKlGduAu_ewzP7rjCoCedz6EBOKnFrZl92qoXv9mphEHpMSXEgS6Ci_tMfGuWzd5MaiYlsfieIFIvCFz7EaPfz=w109-h146-s-no-gm?authuser=0",
  },
  {
    firstName: "Olivier",
    lastName: "Sarr",
    teams: ["Oklahoma City Thunder"],
    level: "hard",
    firstNBAyear: "2022",
    college: "Wake Forest",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeukhzBryAGqgx7MAwmS-SsGj6wrcBI9VYJ7jWAEvknaBG9XCHBaMWfJfnQ6_Hu9k1t7OmOpKZL5xzOEsK0hWDV9abJWS-Lq6kV9OFSO0KntbqZ1WGF606wbT0pebw7kVAKqtcJ5CgjEO0BEEViPNpA=w113-h156-s-no-gm?authuser=0",
  },
  {
    firstName: "Lindy",
    lastName: "Waters III",
    teams: ["Oklahoma City Thunder", "Golden State Warriors"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcxDnMIDcg7-gepPQLGExe_Z3Ddqf6niD4rkpgIJVFPT30T6wdm2YK2ked-7KVr1oBvmOlaCLO3icPoL3KmaZPkQA7QHpiiB626OYIMV4ouDKFzut9dRTIM-mmyyDcRT1Op31aUn3vucl-Mx2M96sx2=w117-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Kevon",
    lastName: "Harris",
    teams: ["Orlando Magic"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHehOLnwDHCRVO3xh8z1wDNjHfB5x8fiu470jetOVCC-J6MFpkRFQXqSsKyV7A6lTl-35z4Ga1q2zd6ID49oP00x4fdhsEpNpGRqpkvb4mx9Nm7Ns3laS2iuKaZSUErJjQpHkKV7Sao_ElPfzm7w1KR6=w115-h142-s-no-gm?authuser=0",
  },
  {
    firstName: "Jonathan",
    lastName: "Isaac",
    teams: ["Orlando Magic"],
    level: "easy",
    firstNBAyear: "2021",
    college: "Florida State",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdI6m7CNVihLPz_eOoviAEuSJy_NuLnYjDKWpm7Ofsv5p8-r-Kz6gJ9lWjkWK6N602oI8EFviBqqP9s9ARumikK5C97oO8MDaqXiyDjh4UoNrLCb3eC1ogNTlAvOjPt3BHIGYLzGcw6J0iHZNmeQK42=w128-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Admiral",
    lastName: "Schofield",
    teams: ["Washington Wizards", "Orlando Magic"],
    level: "hard",
    firstNBAyear: "2020",
    college: "Tennessee",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeP_wiQ-tyTXe9hBdaneIsDh56MOQdhRbW-PiseMvI46FlyXgXLBdH0L4Iu-2slsfrZtN-wlOo_bJq5E6LLwd19cDyBKsoHNrZ73c6tuPUMUKs6xSJFbsDzHF8C0buA6SJKv8fVQ2vebNqFqZw4gCLy=w111-h142-s-no-gm?authuser=0",
  },
  {
    firstName: "Jay",
    lastName: "Scrubb",
    teams: ["Los Angeles Clippers", "Orlando Magic"],
    level: "hard",
    firstNBAyear: "2021",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdymrfBUKLMyoTQicFnU8b6XD_uiyItF5fkn4PK-Iv3dTBbksJndblB_wDKduS3xj1QXxdawNiMP36j8jURbK-WdxYAp0oHJUfaxGZWJk921vZAO65Szl6GNg-O2J_AQcWXhGsyPESke7zvBaLxsmlC=w109-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Franz",
    lastName: "Wagner",
    teams: ["Orlando Magic"],
    level: "easy",
    firstNBAyear: "2022",
    college: "Michigan",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc2efCOBM6T9Wd-oUnreSuVpXJSQGXk8LFKUhkwiBEf5ixElHzfhIAL5-vnwZApu-UisqM3d3gBh0KGdoUuK4uZR7lZnQnHP-HFr7kBW1vlC_g9dCyUJDuQgGvccDSYrzLjepPwm2Jo1yLR2ax7qRIh=w108-h142-s-no-gm?authuser=0",
  },
  {
    firstName: "Mo",
    lastName: "Wagner",
    teams: [
      "Los Angeles Lakers",
      "Washington Wizards",
      "Boston Celtics",
      "Orlando Magic",
    ],
    level: "medium",
    firstNBAyear: "2019",
    college: "Michigan",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc9QWmCVsB9bcH0H3fLaRDSsaoIp17EMyCGlEdKbEf9_Bv68n966-F91p3SOSrgxyPa77_FfDoC1nddfgss5n_utc7hefkaSej7SpUP9JwOGTnKHNvRlhcWrYq283fn5Wqwm49XfF3Mto3NEhu4hIcq=w91-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Julian",
    lastName: "Champagnie",
    teams: ["Philadelphia 76ers", "San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf_Af1HDM2Co30xOdwyDGwzf0kAlovz0E1Epdaw6it5AIBHi3A8qaFaK6VtjQba_y1tiJM9dUI9544ewiyB-e4otai1XN7cS5s35kRc3z0JGlOG37uNuuR0MJEpJ2-qtiMF_wtR91vyJnMof_5Boq0H=w116-h154-s-no-gm?authuser=0",
  },
  {
    firstName: "Danuel",
    lastName: "House Jr.",
    teams: [
      "Washington Wizards",
      "Phoenix Suns",
      "Houston Rockets",
      "New York Knicks",
      "Utah Jazz",
      "Philadelphia 76ers",
    ],
    level: "extrahard",
    firstNBAyear: "2017",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfHfUzW-ZMmAoGECAO1gqsqxHq5A23hE6bjzq3ABv_OGnuVLIgTM_WyUkoAFf4ikmwxz99yDCFqNmf8ljgQ3wJF2O4E10aDbnMBE1Lsl2fxfxqp_EqmulcwGjXN8y6LzRmguRC8tY8Q1cmfaDkgf6-i=w117-h158-s-no-gm?authuser=0",
  },
  {
    firstName: "Mac",
    lastName: "McClung",
    teams: [
      "Chicago Bulls",
      "Los Angeles Lakers",
      "Philadelphia 76ers",
      "Orlando Magic",
    ],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHf8jflRiQhybdm7mTas054JfQKD0AyZrEvycZoHzYTN657PA6shkD6hi9vcT50rmiV_suzF1oNZyY7g2r3Rd8iw_bXTE6iE8nq2dciYkKbNGMQtHfaMQZncNDvPadFPOy7inJDIHH5i8dqk7Mft1FuQ=w115-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Bismack",
    lastName: "Biyombo",
    teams: [
      "Charlotte Hornets",
      "Toronto Raptors",
      "Orlando Magic",
      "Charlotte Hornets",
      "Phoenix Suns",
      "Memphis Grizzlies",
    ],
    level: "medium",
    firstNBAyear: "2012",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdHp_aWvJDafUSJtMse2dznnD_yatjgJKm4iNtLLQkuuM2uRvu8QS7zRaP1Jotyek9rCGWwcMquMT6T2VqzO7KI8q4vt8t0-myZHSx8Vqf6VEJ_SsE9w6gz_rVu4rZc9nSnmbh3Rhmrs5w1k3HNV6V-=w99-h137-s-no-gm?authuser=0",
  },
  {
    firstName: "Torrey",
    lastName: "Craig",
    teams: [
      "Denver Nuggets",
      "Milwaukee Bucks",
      "Phoenix Suns",
      "Indiana Pacers",
      "Phoenix Suns",
      "Chicago Bulls",
    ],
    level: "hard",
    firstNBAyear: "2018",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHceBb-3I5lVzCQZgMBm0lOkHcpz6XXwRBfxAWOrAf1Kt5uAP8JDsPnSVVC1Nh3jkaVBgFw0pHan8dds0ff52TsrLt4sA7XNXU6xMG4OY6UC3PimopSQ45wWG6be9VCTcrn8JjoBQb1Tz9p-lRp4-83s=w97-h157-s-no-gm?authuser=0",
  },
  {
    firstName: "John",
    lastName: "Butler",
    teams: ["Portland Trail Blazers"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfcGpbDvmilQ3gl5yBLUKRFAihVFxt5gAt8PL4U-B8wSR_114EZRtAeEiAXE_norCGsgoCbVVC_mxo2ePgU_8Kc3J_K7zcTzJPwjtH3SgPSrPtdQO3CJ3pJUECLJN87bUBPrwQ-6d4yLK4oEV5QhxNS=w116-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Keon",
    lastName: "Johnson",
    teams: ["Los Angeles Clippers", "Portland Trail Blazers", "Brooklyn Nets"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "Tennessee",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcjkpv9AF1oZE7HoMfGjK1PSpp1XjHVfJxq7toDuluRGcZxQ24t1EXD2Qkn6IAQgYoPuv2cUyDqqg7D5G1u51AwphWyWSVQPi0sjEHIbDWosKxHpXLF0wamMwjXWo1o4n4hJyesnBcP57nHImlx7NT9=w116-h153-s-no-gm?authuser=0",
  },
  {
    firstName: "Justin",
    lastName: "Minaya",
    teams: ["Portland Trail Blazers"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHceRSF_AtC5hqTDF565vYejjD3un2owJtfd398Csi7FRJrQLCYFyYm1KUZBZMCv3qlvVDCtxSa4bPQu8QEoLgTBmZAyIqZGVRNyHMSCpKiQeitKjctHR9RfwjFti5ujcryZhpN1Biuqk1-9YNc43pUM=w116-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Trendon",
    lastName: "Watford",
    teams: ["Portland Trail Blazers"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHePPs-6ZpFZM6Jg0joeKrgrr2QqghzftnITBptnC-jTGGgqoENZYnENi-3jWfeVpBTpGGGM6dkFbYQww2_4bDqMQozbaPyKMqTcFqSVTaoXNKrl_i2Ef-zqLBcSv-q-RqfYcLHEFBoJXeOTJkpYXBzR=w112-h154-s-no-gm?authuser=0",
  },
  {
    firstName: "Jeenathan",
    lastName: "Williams",
    teams: ["Portland Trail Blazers", "Houston Rockets"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeXbZMndMR6-BsFW0lvRfqvr21C2Ew72Pt6BC4_xFEeaIiyMsJ4MiGQkgMGiSrPKKRbX4VOCMHfqzSfPjRngNUaChC16pkNvqdviWWb2cN9vP0Lu5ntPxC3f8djF796iyosXyC5qkjFXSS8EbVysrk8=w111-h129-s-no-gm?authuser=0",
  },
  {
    firstName: "Deonte",
    lastName: "Burton",
    teams: ["Oklahoma City Thunder", "Sacramento Kings"],
    level: "extrahard",
    firstNBAyear: "2019",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcyK-cyPH7W1xml3owyhQ1GS77uMwbc09hY2t-tnqzmNGbowIjJWAhf075upfa54fS0KL3f8C7fKWPHaXFR7_33RKbgNMkBfniQ7F9o8B0JGCSpL39PruZaM4AC1Q4oUio5fjM5A3UEa6lKjO-l7BiD=w108-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Matthew",
    lastName: "Dellavedova",
    teams: [
      "Cleveland Cavaliers",
      "Milwaukee Bucks",
      "Cleveland Cavaliers",
      "Sacramento Kings",
    ],
    level: "medium",
    firstNBAyear: "2014",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd0COa3QduCWcHB-zEUzyywGnxBhmqGK-E89vNvNoKK5h8ThzoqgR8h0G3KljeJrRf2ZeXIdOzOZ6gH7hjOwy-uhI2xCEnlWUayXBda4HT6_neA6ED6BprijKsagbXfpFFO4RcCXealCAoL2oYMCejD=w110-h147-s-no-gm?authuser=0",
  },
  {
    firstName: "Kessler",
    lastName: "Edwards",
    teams: ["Brooklyn Nets", "Sacramento Kings"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHckW1DrCAZ47afZUBFP6igbTXUqHYUr7O3kO67-WQxSw1axk1uprK5n0gUfxmb658zBt0tvgWh68QMXpF6cHIzKCCC6SMlqi54pSonApj6TdT3o-GM0C-HANPLj1bJk8NGY9yc9bccQ3cgLzAAcmmRO=w108-h158-s-no-gm?authuser=0",
  },
  {
    firstName: "Keon",
    lastName: "Ellis",
    teams: ["Sacramento Kings"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeUyTTom8LHu7naftmjSyvUbXceJrtPj_yBBi1kAiby1-SgaHqnM2BaAY7L249VLmGaUT8o11fGJdTolbOeceyZ3kUVMSneruJXundZPSqT4JeiDmR6nyYmj2ZLN02Nrcts3JbOTWXA3Dped2OUxR7H=w103-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Chima",
    lastName: "Moneke",
    teams: ["Sacramento Kings"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfmalhVnLcRL2Yo6ink_b9kHWtHE6p70c7ysEiIkJk4LY-jO4s-xDr_y2n6DLrE4oyod0m7pA5yrAhcVXlWq90nP3WEaraygwrY0PUwS-etnMJaG-DQkWUuv9SSb3CQc8hONqu6b4fDjbPiK-Iwv7Ce=w116-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Dominick",
    lastName: "Barlow",
    teams: ["San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHejWsIdT6yK1LA7aSB-icLYn91Wzllhd8ZpOvjEaOx3nfUBrra4IgkboHa_vH1xSRmg9H51aka8HxocGmgItdJz7251zdQP2_yHy_tdLva0-e4_I3lRuWhP8TESSuk-gYpd2IFla3ZbGnU0rp50xDtx=w122-h154-s-no-gm?authuser=0",
  },
  {
    firstName: "Jordan",
    lastName: "Hall",
    teams: ["San Antonio Spurs"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdW1dqB5qkp3mnAWJriSE3S6SS6mlNpp5T6aAYfUylFxwaLz1l6uT3-dopm2QLWPmJfgFfYHl-a7aj9Nih9P2eN3nURjSjpTsg8QOeP2HqSIxMKyZPoqa-LENos3OMqh_sX_V4qZEEJG6KmRongHjyw=w110-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Tre",
    lastName: "Jones",
    teams: ["San Antonio Spurs"],
    level: "medium",
    firstNBAyear: "2021",
    college: "Duke",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfpsduXOJ86LpJ5aNlkNOcAl0IXvATz7wV8LT5Qs89sIqZb1MLfo7UE1OPWLXRLqVx7k7OM8AoUAUtsRuICq1Cu68_zkULw38Q7l25VInP0XTRX21-KvOQ1OU5m74ffxZfqf6C0UgzpK00EkHXgfhPK=w106-h137-s-no-gm?authuser=0",
  },
  {
    firstName: "Jeff",
    lastName: "Dowtin",
    teams: [
      "Golden State Warriors",
      "Milwaukee Bucks",
      "Orlando Magic",
      "Toronto Raptors",
    ],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHemxr8iWmiG-rxT8QHMtm5RpIBrhRyQjBl8rDuYgOJH1o3nSXq6PtIkHj2bstCxDIG7modOpFedLh8x0-n2eIyJ4hPUdWyprwJJki0bq35MsoJsr_nIL7YVBUHJUAWhZYRJA7zHm_cCYpHEv3XXQtay=w110-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Ron",
    lastName: "Harper Jr.",
    teams: ["Toronto Raptors"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdF-WRtqIAetbrOX2DL_4V-MwWSHq3KX6grvNto20A0JDAnRf0bRDpvSLO1Jo0IWiXDWi5dYiLHdRgybEPnYJsDpBersfH_Za_BHnkTXf20IaryaYMtSlW-GzggbtFoYeGpd8o6DYK_o4g7sEczc7BW=w124-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Simone",
    lastName: "Fontecchio",
    teams: ["Utah Jazz", "Detroit Pistons"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcjmYNvaj3U0I_rGLQUUiJmiM_hP4hSYI98RWHnl1kLoIRXXPiAIk2d8_pYcT3e-fCc2nfzv7ndqfrg5L3RaDlKa69w7dZ0vRcu0kZ4FjMyTr5ywZ-PZp_yJzMWPIqR5kaqCBL9VbBH7xBOMZXtgllw=w115-h133-s-no-gm?authuser=0",
  },
  {
    firstName: "Rudy",
    lastName: "Gay",
    teams: [
      "Memphis Grizzlies",
      "Toronto Raptors",
      "Sacramento Kings",
      "San Antonio Spurs",
      "Utah Jazz",
    ],
    level: "medium",
    firstNBAyear: "2007",
    college: "UConn",
    img: "https://lh3.googleusercontent.com/pw/ADCreHciizZuqc35Ks-pnuzVsfwUig7TdbyuHEcC9e_4puQWrAaJiXQsnY7bHpNiTS_QMy-8s6A7CdGZuwAF5NHmckQtnHmWkwy9f5gUtaAmVVogp7tL33F1qDykq4C4DTBIwBaPpAhHLqBvcLhhgkgOVBu_=w122-h142-s-no-gm?authuser=0",
  },
  {
    firstName: "Johnny",
    lastName: "Juzang",
    teams: ["Utah Jazz"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe4xrSlU-di9-8ZOn5B9bxnih20pX-QPS5iTYINIK_t0nomKAuLARbNS1H5NIVjBFjXI4sbWaZKnXvrkMnVapWEs1rG6IwYcKXGKAg9ncso0dTqeStZRku8X8z4xkIe9pHU-YwpnCREKjFcSH056Py0=w147-h185-s-no-gm?authuser=0",
  },
  {
    firstName: "Xavier",
    lastName: "Cooks",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfKoGU0RX9dxj4N9ATIkoYHziFB13e8rX6L6uMaEMILmcYVicf00Ca_tEdTJlXvt8Fyq0HnE0O8neD6V0EhpJtinq0OQA90fNFuKAXKJIuZNUoXXQenuBe5hKb-hI1piAtBy2p7MvveKo5akMSTqiQJ=w138-h196-s-no-gm?authuser=0",
  },
  {
    firstName: "Quenton",
    lastName: "Jackson",
    teams: ["Washington Wizards"],
    level: "extrahard",
    firstNBAyear: "2023",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdJRrqfE8uGyqKoyzhgpuWTP5dkUnHAv8CeBUEspw3axKTgWRUTrwfGNAy0n5EiVhC8tMXJ8FadHwqqjOxSHwpKzFnUN1rQOHWSzDQP8OjrfQA5CVzKJOM4hqP7bd-uilk3t6r3xtxCA4T2ABjYOszJ=w147-h191-s-no-gm?authuser=0",
  },
  {
    firstName: "Jordan",
    lastName: "Schakel",
    teams: ["Washington Wizards"],
    level: "extrahard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdwFcPgSt0P7t2CcAKpjPJVhBWmRmzMZQxc4lp2td5H_DJdAO4Um_Nj20obi-Rsp0vEFLlSCDYWjNOKocpN-EgO2ye8ZBQmwtIEWY1GG55-jbd3TUGK6ZXiaYduPmvEb5xxbHm99ihGiwygJo3fVJI3=w145-h197-s-no-gm?authuser=0",
  },
  {
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2022",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeT2QGSiYOCHNjK3I6irBIQGB26zLrt3b5FfTSowHVakeP7pTeizOmp9QBA6tbAI46jfU_93vtTSdi0VosunrdThrkjZ87MRRZJK4asBMyI4hnFSAWjbSlGrvX7ehPeiuzaDd9EdUaat_HTggEqJLTV=w146-h195-s-no-gm?authuser=0",
  },
  {
    firstName: "Victor",
    lastName: "Wembanyama",
    teams: ["San Antonio Spurs"],
    level: "easy",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfrZoPDaxG3HY-jvE0CQUajTOpV9pocGjfys0192MVCbhtqOSqbKA11ZCS5Jabk1yjXepn2l27fc-TLOG-F62mn2Av4eQ0APfwmyckDKTFF2Rp0WWqlbtHiFPVwhCREHqxNU0VT-MqTxofH0kDeNNv6=w119-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Brandon",
    lastName: "Miller",
    teams: ["Charlotte Hornets"],
    level: "easy",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeFqh6a5JvDrE0lKZvcLmtMR7_VlAWwhcMQYBTaRxG7l1pwL3IJdwuvSzJ84va9QzVIca9UYNHjvw6rpMFWBGytTDjuClwWpuj7B6X19Rrx3DemIR3hl66JPYEED7v0YkCzEQNgvuUOcve2yiJtf-bq=w115-h155-s-no-gm?authuser=0",
  },
  {
    firstName: "Scoot",
    lastName: "Henderson",
    teams: ["Portland Trail Blazers"],
    level: "easy",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeDboi0cxyjH4f1RFS8U9Qkphrmw-KLm6WLt3CgkijW8k4kQJ4YMcNo9vYFwHw9VthtDNEN5ThVkKh035PBN8LHQaWtpnZmre0VdxiknVjUl-efdvijTVjaVJCHEHRQCOBScHDKYAHN-wJL-nvw2rxC=w124-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Amen",
    lastName: "Thompson",
    teams: ["Houston Rockets"],
    level: "easy",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdnmSeZrDmy-j-RarwMOeWr17Iv7Z9o7BrdUe4xV5OWNb9H1GmML-IvQpZvDMMrl-Zvgdi8H3eMVFQeUaREQyhGc0Uh9O0s725JpBXBwbcpgJ9HQjHUeR-D_9yFB5P5EO-hUvOBJfMD7Tkctr_1iZkP=w117-h154-s-no-gm?authuser=0",
  },
  {
    firstName: "Ausar",
    lastName: "Thompson",
    teams: ["Detroit Pistons"],
    level: "easy",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHe5Ol8XBvSTfOyNLYx09hggSV2rkT1APZoajGGnyhT0rgHJGW9uyZYL8ogAfZBRw5Zcz2EGj1l8oZBaeesyJURlABslp4XnssxMyrGjMUNOWK5GqVfDLnlaVIyHKw9XYbBAWn8H9SwQxQ-QezoNmuPj=w117-h165-s-no-gm?authuser=0",
  },
  {
    firstName: "Anthony",
    lastName: "Black",
    teams: ["Orlando Magic"],
    level: "easy",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfv3V6dFW6rYNZSa_ZXhcL8AbhOOUiKowcsQg2uGNHLZPU5ctKFIERtQlew-y-MkOLpLMYmEvfDjEs2lecxROTx0r6Lhq4BuOjlgU2v-Ni9BCimsYW5ahlVBusDzttq4Tu8n114Z5sLU4fc5GSeL8Op=w117-h154-s-no-gm?authuser=0",
  },
  {
    firstName: "Bilal",
    lastName: "Coulibaly",
    teams: ["Washington Wizards"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdmeLedZOlTm5i8z7n_9AhKxsP9Oud9wZ1y0bHWeVXzcdm8Ev3F0PMKKmhREh2n0UGPkXkfON0MOEpv5hthUT4fbVp0330neWOMrtVHzvmR5FgSmgFtGE7ZYxEJMg-j97s-3aaSl53ATNlQgK3yhn_Y=w115-h160-s-no-gm?authuser=0",
  },
  {
    firstName: "Jarace",
    lastName: "Walker",
    teams: ["Indiana Pacers"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeEut6bVkulXeXTqAiGMrPaZS-9o30CRZfyZgTX6Oitfv9hkd8z5cJxgZHeZ1xU6a96SjwctYnGUsvtd5L0o1xMuMnYE35A94_YkeL-ekJ2ceA9RkFZr0etrUqrndVhTn9fGeg_sKm06_ORoscb6D-L=w116-h165-s-no-gm?authuser=0",
  },
  {
    firstName: "Taylor",
    lastName: "Hendricks",
    teams: ["Utah Jazz"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdDygQc1eXtOADIwD3OOP-U0v58CcmixXuF8XbEMRVNh0rMQIOPyGyhF2aNNAnbhkTawVzjEergt9cZi43LitqAj7wvOtFmVrlayk5EACmgktuDmShbNaXDaHlypBCZLIYJgtz7JNTdKkPYK4UYZ5Yd=w116-h155-s-no-gm?authuser=0",
  },
  {
    firstName: "Cason",
    lastName: "Wallace",
    teams: ["Oklahoma City Thunder"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeM6_qb3TfLhIba0nXrzpatwyLJ4gNuccctBi73VVW1W-Ronm44ssvMg2Mo4EjIvUKT-WGvCckH8qu_DEew9UcattDznRXGameyZPE5v4KjZHVv-IIeIMU0blu9V7VQM28BOHMEzZurwlSEYvBX424v=w117-h141-s-no-gm?authuser=0",
  },
  {
    firstName: "Jett",
    lastName: "Howard",
    teams: ["Orlando Magic"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeQxBuTTvsgG3BYFV6ySR_6-6faRZGjC9gKRkubaFP5lw2oIKoHCLLpwYAGHXIvvu6XoDC7J2yDWnoJbnVS8OmKqPaHfS-mDEsf99xVPwNwNcuKkR4rPMAi7K9VHsV_vMX2Jnm_zXgRNeFGSFSe0P8q=w120-h151-s-no-gm?authuser=0",
  },
  {
    firstName: "Dereck",
    lastName: "Lively II",
    teams: ["Dallas Mavericks"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd_ml2MTC06tk_a5aOLvx_eU5Ql4kA6GBaZMl22ckB4MAHRv0teW6C_o2-9WIfc-9G-abzq4EhzXjGoCKhyKBVP3GbxMnskFUipbw0upALoLCJAVmvNahnhT2u1tSEu21irmpB9IkQjztqUKmZrrh07=w113-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Gradey",
    lastName: "Dick",
    teams: ["Toronto Raptors"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdQSAvj4kU04VHX4od1zqWGHFTxU0CsU2TPCcHahbHCNED45P6x0gVfwPvdf8OemL5yNM4a5EXnRtF3iGkv_ZgGR4orqQxkcd2d7fgmhL1yoqIWYvLnf0pVkIZn9jPu1pxRRH8GtvnO65maLxUX0iCH=w106-h156-s-no-gm?authuser=0",
  },
  {
    firstName: "Jordan",
    lastName: "Hawkins",
    teams: ["New Orleans Pelicans"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdUfDyLz73WzxumWOAG10h7rmvYlou9VH6FYnhn04h_SckyF9AJDD_7A0bkbmSwQ_zzH9kalrEYaxiJCJPpHf0YTwD4ZtKGgHZk8Uy_Q7ldn0RQpS7CzuJ6HLxSgOMZgM2WyYn22HwNvvsbrVfXBeVk=w111-h147-s-no-gm?authuser=0",
  },
  {
    firstName: "Kobe",
    lastName: "Bufkin",
    teams: ["Atlanta Hawks"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfiknetJGlL--beK_PSJSiAMqst372vfXH_0D-QpUjXhdU8vMZ00SRAJ8fIuooDtSnmD1NiQU75UtUqBLezNPCx7eMqiIE-NuxuQouCEkHelzOZf_zyL6fWBnkOALH4XQdZ5_puV67LFTDvp7JgCDh8=w111-h149-s-no-gm?authuser=0",
  },
  {
    firstName: "Keyonte",
    lastName: "George",
    teams: ["Utah Jazz"],
    level: "medium",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcaJ7DIvMpU8nKnwoB4TPBlSbVvbGux9VvzzJd50Rwo9A6w7v57-rYS0MjGbmAbMSpVzT5pH_-94SWduLv6wmMUH2ng2548HHqs-RTxdjsSi_vUslro5uO3MSNEDqWFUTjSlAURxJb7B_s4QMKbaQ0h=w114-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Jalen",
    lastName: "Hood-Schifino",
    teams: ["Los Angeles Lakers"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfvcRbR8vUNI2EHTeMCz8EA1nLXWCLEjAbtgFeeTn0U8DGlhUwbL5niDOTJZBIBz_TJ_62oqdG5d9irPsfE09jliQQnVI2rlA4jyMTteIQtXg2vRnaqQ0fDBD3Ymp5tiBs6829nA76PnuxCkfqBzINT=w120-h157-s-no-gm?authuser=0",
  },
  {
    firstName: "Jaime",
    lastName: "Jaquez Jr.",
    teams: ["Miami Heat"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcRS4Q9f1D-M3-PnA4IFJ_CTPknrcGzNFTRsCVG5WrVqAQynYcEfVS6eVHMBbmm9sOhtkS0OaSfWqMjNRRpGBjCRnAKnPvZlOzHXxowbCG3jo9BgbGBbi1vMlxYbXr1XSkJHQhMmdigtr87qIdX9HDR=w118-h156-s-no-gm?authuser=0",
  },
  {
    firstName: "Brandin",
    lastName: "Podziemski",
    teams: ["Golden State Warriors "],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdsetWgp-5OV-30UfRTkfxhg5xHVZXAOImStPobSeziVJbXOG3-PlawR_ftHuuybw3wkN71u8RdIJm4EwqROjBnGm9SnEiU_HA0krnb1YUuI_XTin-S58bEmud6bn8ii6ou2VLDstacrnvQ8Lr1xjVo=w115-h150-s-no-gm?authuser=0",
  },
  {
    firstName: "Cam",
    lastName: "Whitmore",
    teams: ["Houston Rockets"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfHKpZlbUklicq5Xjy35p012qm9GIhOo7YexELLyK6Zp2-MGZzQ2oseLTN8OZ1rmz3OcDF9cIDNlZHEGSNoHV2wjoQFbY0DJeSItHsuAhwofKGnKU6FAmBqi7XY2T0GPpPlIfTAo5_4gNA5TOUg2TBo=w112-h143-s-no-gm?authuser=0",
  },
  {
    firstName: "Noah",
    lastName: "Clowney",
    teams: ["Brooklyn Nets"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHc3Znf7V_NycOghyQyeupK6zk4VK4v0UgsucqQ1wB3Ay12udpAxvf0hDIt92LNkpQ4bdkSgHkYa2oGY6Jtymc4mvlKwWodxf7amyuIv-ye3l0PQgI7PY03QRErY-yeThIMoDsdh-r-fB6x-iOQN1s6Y=w104-h140-s-no-gm?authuser=0",
  },
  {
    firstName: "Dariq",
    lastName: "Whitehead",
    teams: ["Brooklyn Nets"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeqcreXuj-akwOpdME37AfUyhAZxSFL-fHEnukn2eUHzZwY3m_Gi2Uw-mY1NyZGGRSeXXdFk3uTml2nqNCNxiuIAFlL-ttTwyFCJSyu1vVM2V0R9QC4hkH-1DYs3WMcaQf3h-8l93yPAD7HXLbkSqYE=w95-h133-s-no-gm?authuser=0",
  },
  {
    firstName: "Kris",
    lastName: "Murray",
    teams: ["Portland Trail Blazers"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHenzduhxJrFv45FpOFrBDidzpcna-rDtN2z4SgC2x_0Iov4TG3jMLu3tyLDhnDAzivx1zAuX652u7nurJQLgXiqgkjaFCwZHx2BTg9P_dYtEONk3h3QZEfW9rlVNRaWelMFLWCI66crDg5kmzcsedyO=w114-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Olivier-Maxence",
    lastName: "Prosper",
    teams: ["Dallas Mavericks"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdcNkHPIQ80XOCq0abaQhTn5-EM5wFbV5eaw4JSvwWoBaVnz58R1DwjOs0AvO-Tse3EL053JXdoQ8TvTxv3zMhj44z-EOeXr6vz7qIsZXW6aOD0HErSes0FgEXaH6z9U_2-aN6nYqrGuWEL7GB_KwI-=w108-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Marcus",
    lastName: "Sasser",
    teams: ["Detroit Pistons"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfMxFo0O7kxG7unihoRHeh0IC9BrPYSh9dNMbuv1MWYmmOrW-No2v0vVVyzu8mOsNWPDi_7ac-cYfadbQMCj-z8b7dDAX2UlmevLPPqJqbyyIeFO8rWaquyv1oRGjLHutHmhTyFM2BwnFIJmhuXRdvQ=w114-h146-s-no-gm?authuser=0",
  },
  {
    firstName: "Ben",
    lastName: "Sheppard",
    teams: ["Indiana Pacers"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeYTzdvqCaE5KvJVFEOhcZvbLXMAPjgFOqvLcZccgSHInH4haoigoNAn-Ws1tQUkYXPL0mZ_MDGy0JpR5TuWXjDq2jk9pGJZ_vytjhUf0kK84xqCvxY119aBf8A9ajTA51gc0i6Cv0wywXSTsnKieyz=w115-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Nick",
    lastName: "Smith Jr.",
    teams: ["Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHfPe2qTF5W5YrKq-rLwOyVO-xsobu81IjiGMgitiqu23m0cAk_GYqo6ENb20_z65K_S-AsI5PhWeErAL6LhYo6_YSoWKbtCuAQhAYk1Hk5ORFY2V4ViuTySO1lCVyE4YoJ9ptI67RFjls3j5er9U5OO=w107-h144-s-no-gm?authuser=0",
  },
  {
    firstName: "Brice",
    lastName: "Sensabaugh",
    teams: ["Utah Jazz"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHd4KfH7eppuZS-CaOkOegr2NFRS1BQArX7FKe7fGieZn49m6hvIusVYZN5hYQzPjg_0zSCrXn_s5y4pEzkMDhiFk4eqcXZeJ5M70ngqQJtxN2pTMljJsQbeKNi7481L8aC6FfzFvCHLhETBdX7M470Z=w96-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Julian",
    lastName: "Strawther",
    teams: ["Denver Nuggets"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcm0K-ZiGFPWidpHbbC2RgF8jv9beU1bzk8ooZq-5Pe-qMY9HaDHuiZpP2Y5C-YXK5rTYzI35hyTaHZk5I7y0zhrlR728ORYo0acfzSiklVzL9VyId_WktNHIqGwzykft2W8pTMAidic0-HUZHmSBmB=w112-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Kobe",
    lastName: "Brown",
    teams: ["Los Angeles Clippers"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdwjhX7xNMOleEF_rK-97SLzGDsJEJBqeQIIZZm4t-9Wuuv_3IzMpJ7tWMb_eBrVn7mUKqtOqtytMLgi-BtUBClyue8RRLkFSU1ojkF4uf4TRJJ7fKoNRvZaden5-0DiOKZxmL-RQ-FyBZiF-Xy94l3=w109-h137-s-no-gm?authuser=0",
  },
  /*{
    firstName: "James",
    lastName: "Nnaji",
    teams: ["Detroit Pistons"],
    level: "hard",
    firstNBAyear: "2024",
    college:"",
    img:""
  },*/
  {
    firstName: "Jalen",
    lastName: "Pickett",
    teams: ["Denver Nuggets"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdbCsp130zfUziPB0O9cuy354MxT28vd476hLTwB5k9idp-yYDqOPOX1e9EBHJSbY2Us8kETKEO-70yndZqJ0-VE_k7wkBZ2LjZx1XQ4JdlUd_WVnLyR4voGGszjse0AOzI1ZIzvb3QlxYRj-DCjJkc=w112-h152-s-no-gm?authuser=0",
  },
  {
    firstName: "Leonard",
    lastName: "Miller",
    teams: ["Minnesota Timberwolves"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHffYTYGfZp0NugQXrMzXv7l25yIN1MTh4ATPBeCIdcHAuVZ5hhfwbEthUUcv8KM3jsuhe_11pgADxSc8UT0zYm2vi9Kpq-mqXT2ltYuhS4al2rSkCONfgxRS9VWHn2lOU3KQaxqS9JJyqY4Tqourj9z=w107-h147-s-no-gm?authuser=0",
  },
  {
    firstName: "Colby",
    lastName: "Jones",
    teams: ["Sacramento Kings"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHclO4a5_TiMGGkPt63LMa38YUZH9pr6gbPqKFCDZbJE6tL7JxOJClW_rKZJmgjsuBpu8GpTDXQbaC4EawyJjMWB1sZdu01I-w3BJWvjsdXJ_bAYy0oDV49LyeToepWQgt4xR6mgF650OU2YrP9aSJ_g=w119-h168-s-no-gm?authuser=0",
  },
  {
    firstName: "Julian",
    lastName: "Phillips",
    teams: ["Chicago Bulls"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcRBesqPatAHJwFwSVtWQ19wHA8Ft982Pt1s3UF0dyYP2rUTlPo1GvqGnmhfmvzhPTtgNciIdNFK1mciWCvQPKz76DF7WdsgvH933aPfZsZ2Ap1OeVs4C2tIYNFtPh3ZSehyYBa87a9xe8eMdgFMeov=w118-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Andre",
    lastName: "Jackson Jr.",
    teams: ["Milwaukee Bucks"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHePJDbvUUui0S2IfrNwFwdfnUG5tegPyRT_gbdUT4Es3sL06KAFxEW2yGYv3qPV9X1g9Yt5oXaAoSVD9mwPcyUat84hB5mtXTk9AOqjbnwiBw7tmTbFSn-pI3Yt3ZHP-zCk86uRizn6beUI941pKqy0=w121-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Hunter",
    lastName: "Tyson",
    teams: ["Denver Nuggets"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHeRusMNMObPGMoDeXzzVK7-EcMhwyMizKsiQUuvjG0aThqdDRm5gqfzzYraMguHenlUdOkdqwXytaS4tzD2GK1OuMEYwjW12j9G0nfHdAfs6xYQcD7poepJ5gVPg6jSuafkZGvtWGq-hvrPiQt_RX5l=w103-h133-s-no-gm?authuser=0",
  },
  {
    firstName: "Jordan",
    lastName: "Walsh",
    teams: ["Boston Celtics"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "",
  },
  {
    firstName: "Mouhamed",
    lastName: "Gueye",
    teams: ["Atlanta Hawks"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcNqRKI3I2LlI7hGJ8c3clEMOl0x9cdlxMMDMeRUZb-GyZpZhgCSu8TBIEW7g3Mqm3dxkZic08k9Dii5MizfcAJIdVo3NHChhXr8iAPzvOWOb762vSLK7BNvCXx9KEPPIkgJzjljwu1m3cc7KWrJfrg=w110-h148-s-no-gm?authuser=0",
  },
  {
    firstName: "Sasha",
    lastName: "Vezenkov",
    teams: ["Sacramento Kings"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcD1GbE75CufNIjXsrY3t0-NMGoA6uP9Bwg7KqRAW5bY5daGV6Z-QYMOAzXBCGWPTZ9vTdCjI32_rE8_XUx6S3AuCm5n-4HWqKCcWYavrJLDsNX1Prd8BEHDA2GRxN4StgM7NBt1jHtA7A3pSx-GKA9=w117-h145-s-no-gm?authuser=0",
  },
  {
    firstName: "Dante",
    lastName: "Exum",
    teams: ["Utah Jazz", "Cleveland Cavaliers", "Dallas Mavericks"],
    level: "hard",
    firstNBAyear: "2015",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHdtSPD5jCe0t0uetGJLBTIPXAVZPRP_Dqabaz89DSPKhynB7z6kG0ZtqujDUU9teHXbK0a7dAy9fa0twON_PUqOe4S68rbw2Cp98msxuu-rPyFSWjolbeDw3lutSn7Th7XBVkrHD9KwIYdRlLsnMDqV=w115-h141-s-no-gm?authuser=0",
  },
  /*{
    firstName: "Gui",
    lastName: "Santos",
    teams: ["Golden State Warriors"],
    level: "hard",
    firstNBAyear: "2024",
    college:"",
    img:""
  },{
    firstName: "EJ",
    lastName: "Liddell",
    teams: ["New Orleans Pelicans"],
    level: "hard",
    firstNBAyear: "2024",
    college:"",
    img:""
  },
  */ {
    firstName: "Vasilije",
    lastName: "Micic",
    teams: ["Oklahoma City Thunder", "Charlotte Hornets"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "https://lh3.googleusercontent.com/pw/ADCreHcFWoNn7gl6gW9g0PIM83UfrSszvy4VIO41NAlCvG5YrrCOuR9jAuFukysC6Pp31lUGMgwlvwl8BckXdwXxU4dd3LnIc7Pd1wsPK-CPH4g8_KOso0iQQgxe_K1qOVy35F-jpcNMkc9wGBbGQtNaLhY0=w119-h141-s-no-gm?authuser=0",
  },
  /*{
    firstName: "Filip",
    lastName: "Petrusev",
    teams: ["Philadelphia 76ers", "Sacramento Kings"],
    level: "hard",
    firstNBAyear: "2024",
    college:"",
    img:""
  },
  */ {
    firstName: "Toumani",
    lastName: "Camara",
    teams: ["Portland Trail Blazers"],
    level: "hard",
    firstNBAyear: "2024",
    college: "",
    img: "",
  },
  /*{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },{
    firstName: "Isaiah",
    lastName: "Todd",
    teams: ["Washington Wizards"],
    level: "hard",
    firstNBAyear: "2023",
    college:"",
    img:""
  },
  */
  ,
];
