import React from 'react';
import './App.css';
import Footer from './pages/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './pages/Navbar';
import GuessPlayers from './pages/GuessPlayers';
import Top from './pages/Top';
import Teams from './pages/Teams';
import Nicknames from './pages/Nicknames';
import Login from './pages/Login';
import Register from './pages/Register';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
    <Router>
      <div className="App flex flex-col h-screen">
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/leguesstheplayer" element={<GuessPlayers />} />
            <Route path="/topplayers" element={<Top />} />
            <Route path="/leguesstheteam" element={<Teams />} />
            <Route path="/nicknames" element={<Nicknames />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
