import React, { useEffect, useState } from 'react';
import { json, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../Firebase';
import ExternalLink from '../assets/external-link.svg'

function OffCanvas({ isOpen, onClose }) {
  const { currentUser, userData } = useAuth(); // Usa userData qui
  const [leGuessTheTeam, setLeGuessTheTeam] = useState(0);
  const [topPlayers, setTopPlayers] = useState(0);
  const [leGuessThePlayer, setLeGuessThePlayer] = useState(0);
  const [nickname, setNickname] = useState(0);
  const [maxLeGuessTheTeam, setMaxLeGuessTheTeam] = useState(0);
  const [maxTopPlayers, setMaxTopPlayers] = useState(0);
  const [maxLeGuessThePlayer, setMaxLeGuessThePlayer] = useState(0);
  const [maxNickname, setMaxNickname] = useState(0);

  function avgScore(array) {
    if (array.length === 0) return 0;
    const somma = array.reduce((acc, val) => acc + val, 0);
    return Math.round(somma / array.length); // Arrotonda il risultato alla cifra intera più vicina
  }

  function maxScore(array) {
    if (array.length === 0) {
        return null; // Restituisce null se l'array è vuoto
    }
    return Math.max(...array); // Usa Math.max con lo spread operator per ottenere il massimo
  }

  useEffect(() => {
    if (userData) {
      console.log(userData);
      setLeGuessTheTeam(avgScore(userData.game1));
      setTopPlayers(avgScore(userData.game2));
      setLeGuessThePlayer(avgScore(userData.game3));
      setNickname(avgScore(userData.game4));
      setMaxLeGuessTheTeam(maxScore(userData.game1));
      setMaxTopPlayers(maxScore(userData.game2));
      setMaxLeGuessThePlayer(maxScore(userData.game3));
      setMaxNickname(maxScore(userData.game4));
    }
  }, [userData]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      onClose();
    } catch (error) {
      console.error('Errore durante il logout:', error.message);
    }
  };

  return (
    <>
      {isOpen && (
        <div 
          onClick={onClose}
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
        ></div>
      )}

      <div
        className={`fixed top-0 right-0 w-100 h-full bg-custom-dark-violet text-white transform ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out shadow-lg z-50`}
      >
        <div className="flex justify-between items-center p-4 border-b border-custom-violet">
          <h2 className="text-lg font-semibold">Your Playground</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            X
          </button>
        </div>
        <div className="p-4 flex flex-col space-y-4">
          {userData ? (
            <>
            <p>Welcome back, {userData.userName || 'N/A'}!</p>
            <p>Email: {userData.email}</p>

            <div className="overflow-x-auto mt-4 text-black rounded-lg">
              <table className="min-w-full bg-white text-left text-sm rounded">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="px-6 py-3 font-medium text-gray-700 text-center">Game</th>
                    <th className="px-6 py-3 font-medium text-gray-700 text-center">Avg. Score</th>
                    <th className="px-6 py-3 font-medium text-gray-700 text-center">Best Score</th>
                    <th className="px-6 py-3 font-medium text-gray-700 text-center">Chart</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-6 py-4 border-b text-center">LeGuess the Team</td>
                    <td className="px-6 py-4 border-b text-center"><b>{leGuessTheTeam}</b></td>
                    <td className="px-6 py-4 border-b text-center"><b>{maxLeGuessTheTeam}</b></td>
                    <td className="px-6 py-4 border-b text-center"><img className="w-5 h-5 cursor-pointer" src={ExternalLink} alt="User Icon" /></td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b text-center">Top 75 of All Time</td>
                    <td className="px-6 py-4 border-b text-center"><b>{topPlayers}</b></td>
                    <td className="px-6 py-4 border-b text-center"><b>{maxTopPlayers}</b></td>
                    <td className="px-6 py-4 border-b text-center"><img className="w-5 h-5 cursor-pointer" src={ExternalLink} alt="User Icon" /></td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b text-center">LeGuess the Player</td>
                    <td className="px-6 py-4 border-b text-center"><b>{leGuessThePlayer}</b></td>
                    <td className="px-6 py-4 border-b text-center"><b>{maxLeGuessThePlayer}</b></td>
                    <td className="px-6 py-4 border-b text-center"><img className="w-5 h-5 cursor-pointer" src={ExternalLink} alt="User Icon" /></td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b text-center">Nicknames</td>
                    <td className="px-6 py-4 border-b text-center"><b>{nickname}</b></td>
                    <td className="px-6 py-4 border-b text-center"><b>{maxNickname}</b></td>
                    <td className="px-6 py-4 border-b text-center"><img className="w-5 h-5 cursor-pointer" src={ExternalLink} alt="User Icon" /></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <button
              onClick={handleLogout}
              className="px-6 py-2 min-w-[120px] text-center text-white bg-red-600 border border-red-600 rounded hover:bg-transparent hover:text-red-600 focus:outline-none focus:ring"
            >
              Logout
            </button>
          </>
        ) : (
          <>
              <p>You're not logged in</p>
              <Link
                to='/login'
                className="px-6 py-2 min-w-[120px] text-center text-white bg-custom-violet border border-violet-600 rounded active:text-violet-500 hover:bg-transparent hover:text-violet-600 focus:outline-none focus:ring"
              >
                Log in
              </Link>
              <Link
                to='/register'
                className="px-6 py-2 min-w-[120px] text-center text-white bg-custom-violet border border-violet-600 rounded active:text-violet-500 hover:bg-transparent hover:text-violet-600 focus:outline-none focus:ring"
              >
                Create an account
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default OffCanvas;
